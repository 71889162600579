<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      app
      color="#F2F1F1"
      dense
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <template>
          <v-stepper-step :step="1" editable :complete="e1 > 1">
            Buat e-Bansos
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :step="2" editable :complete="e1 > 2">
            Tambah Petugas
          </v-stepper-step>

          <v-divider></v-divider>
          <v-stepper-step :step="3" editable :complete="e1 > 3">
            Tambah Penerima
          </v-stepper-step>
          <v-divider></v-divider>
        </template>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content :step="1">
          <v-container style="height: 100%; margin-bottom: 100px">
            <p style="margin: 0; font-size: 34px" class="text-center mb-5">
              Buat Bansos
            </p>
            <v-row>
              <v-col cols="5" sm="3">
                <v-subheader>Judul Bansos</v-subheader>
              </v-col>
              <v-col cols="7" sm="9">
                <v-text-field
                  label="Judul"
                  placeholder="Judul"
                  v-model="form.nama"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" sm="3">
                <v-subheader>Deskripsi</v-subheader>
              </v-col>
              <v-col cols="7" sm="9">
                <v-text-field
                  label="Deskripsi"
                  placeholder="Deskripsi"
                  v-model="form.keterangan"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" sm="3">
                <v-subheader>Jumlah Bantuan</v-subheader>
              </v-col>
              <v-col cols="7" sm="3">
                <v-text-field
                  label="Jumlah"
                  placeholder="Jumlah"
                  v-model="form.total"
                ></v-text-field>
              </v-col>
              <v-col cols="5" sm="3">
                <v-subheader>Satuan</v-subheader>
              </v-col>
              <v-col cols="7" sm="3">
                <v-text-field
                  label="Satuan"
                  placeholder="Satuan"
                  v-model="form.satuan"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-subheader style="font-size: 24px; color: black"
                  >Wilayah Tujuan</v-subheader
                >
              </v-col>
              <v-col sm="3" cols="5">
                <v-subheader>Alamat Tujuan</v-subheader>
              </v-col>
              <v-col sm="9" cols="7">
                <v-text-field
                  label="Alamat Tujuan"
                  placeholder="Alamat Tujuan"
                  v-model="form.alamat"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="3" cols="5">
                <v-subheader>Provinsi</v-subheader>
              </v-col>
              <v-col sm="3" cols="7">
                <v-autocomplete
                  v-model="form.provinsi"
                  :items="provinsis"
                  label="Provinsi"
                  item-value="id"
                  item-text="provinsi"
                  @change="(event) => selectedProvinsi(event)"
                ></v-autocomplete>
              </v-col>
              <v-col sm="3" cols="5">
                <v-subheader>Kabupaten/ Kota</v-subheader>
              </v-col>
              <v-col sm="3" cols="7">
                <v-autocomplete
                  v-model="form.kabupaten"
                  :items="kabupatens"
                  label="Kabupaten/ Kota"
                  item-value="id"
                  item-text="kabupaten_kota"
                  :disabled="kabupatens == ''"
                  required
                  @change="(event) => selectedKabupaten(event)"
                ></v-autocomplete>
              </v-col>
              <v-col sm="3" cols="5">
                <v-subheader>Kecamatan</v-subheader>
              </v-col>
              <v-col sm="3" cols="7">
                <v-autocomplete
                  v-model="form.kecamatan"
                  :items="kecamatans"
                  label="Kecamatan"
                  item-text="kecamatan"
                  item-value="id"
                  :disabled="kecamatans == ''"
                  required
                  @change="(event) => selectedKecamatan(event)"
                >
                </v-autocomplete>
              </v-col>
              <v-col sm="3" cols="5">
                <v-subheader>Kelurahan</v-subheader>
              </v-col>
              <v-col sm="3" cols="7">
                <v-autocomplete
                  v-model="form.kelurahan"
                  :items="kelurahans"
                  item-value="id"
                  label="Kelurahan"
                  :disabled="kelurahans == ''"
                  item-text="kelurahan"
                  required
                  @change="(event) => selectedKelurahan(event)"
                >
                </v-autocomplete>
              </v-col>
              <v-col sm="3" cols="5">
                <v-subheader>RT</v-subheader>
              </v-col>
              <v-col sm="3" cols="7">
                <v-text-field
                  label="RT"
                  placeholder="RT"
                  v-model="form.rt"
                ></v-text-field>
              </v-col>
              <v-col sm="3" cols="5">
                <v-subheader>RW</v-subheader>
              </v-col>
              <v-col sm="3" cols="7">
                <v-text-field
                  label="RW"
                  placeholder="RW"
                  v-model="form.rw"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" sm="3">
                <v-subheader>Pemberi</v-subheader>
              </v-col>
              <v-col cols="7" sm="9">
                <v-text-field
                  label="Pemberi"
                  placeholder="Pemberi"
                  v-model="form.pemberi"
                ></v-text-field>
              </v-col>
            </v-row>
            <div>
              <v-btn
                class="button-signup mt-5"
                color="green"
                block
                @click="nextStep(1)"
                >Simpan</v-btn
              >
            </div>
            <!-- <p style="color: black" v-html="dataBansos.deskripsi"></p> -->
          </v-container>
        </v-stepper-content>
        <v-stepper-content :step="2">
          <v-container style="height: 100%; margin-bottom: 100px">
            <v-text-field
              v-model="searchPetugas"
              append-icon="mdi-magnify"
              label="Cari Nik, Nama, KK, dsb.."
              single-line
              filled
              outlined
              hide-details
              @input="getDataPendudukPetugas()"
              style="width: 100%"
              class="mb-5"
            >
            </v-text-field>

            <v-row>
              <v-col cols="12" sm="6">
                <p>List Penduduk</p>
                <v-card>
                  <v-list two-line v-if="dataPendudukPetugas.data">
                    <template v-for="(item, index) in dataPendudukPetugas.data">
                      <v-list-item :key="item.title">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.nama"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="item.nik"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              icon
                              @click="updateListPendudukPetugas(item)"
                              :loading="loading"
                            >
                              <v-icon color="red lighten-1"
                                >mdi-plus-circle-outline</v-icon
                              >
                            </v-btn>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                      <v-divider :key="index"></v-divider>
                    </template>
                  </v-list>
                  <v-container v-else>
                    <p>Data tidak ada.</p>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6">
                <p>List Petugas</p>
                <v-card>
                  <v-list two-line v-if="selectedPetugas.length > 0">
                    <template v-for="(item, index) in selectedPetugas">
                      <v-list-item :key="item.item.title">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.item.nama"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="item.item.nik"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon>
                            <v-icon
                              color="red lighten-1"
                              @click="deleteListPendudukPetugas(index, item)"
                              >mdi-close</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider :key="index"></v-divider>
                    </template>
                  </v-list>
                  <v-container v-else>
                    <p>Data tidak ada.</p>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
            <v-btn
              class="button-signup mt-5"
              color="green"
              @click="nextStep(2)"
              block
              >Simpan</v-btn
            >
          </v-container>
        </v-stepper-content>
        <v-stepper-content :step="3">
          <v-container style="height: 100%; margin-bottom: 100px">
            <v-expansion-panels
              v-show="userData.role == 'superadmin' || userData.role == 'admin'"
            >
              <v-expansion-panel>
                <v-expansion-panel-header> Filter </v-expansion-panel-header>
                <v-expansion-panel-content class="text-center">
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    label="Provinsi"
                    v-model="id_provinsiFilter"
                    :items="provinsis"
                    item-text="provinsi"
                    return-object
                    required
                    @change="(event) => selectedProvinsiFilter(event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kabkotFilter"
                    :items="kabupatensFilter"
                    label="Kabupaten/ Kota"
                    item-value="id"
                    item-text="kabupaten_kota"
                    return-object
                    :disabled="kabupatensFilter == ''"
                    required
                    @change="(event) => selectedKabupatenFilter(event)"
                  >
                  </v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kecamatanFilter"
                    :items="kecamatansFilter"
                    label="Kecamatan"
                    item-text="kecamatan"
                    return-object
                    :disabled="kecamatansFilter == ''"
                    required
                    @change="(event) => selectedKecamatanFilter(event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kelurahanFilter"
                    :items="kelurahansFilter"
                    label="Kelurahan"
                    return-object
                    :disabled="kelurahansFilter == ''"
                    item-text="kelurahan"
                    required
                    @change="(event) => selectedKelurahanFilter(event)"
                  ></v-autocomplete>
                  <v-text-field
                    v-model="id_rw"
                    prepend-icon="mdi-map-marker"
                    label="RW"
                    single-line
                    hide-details
                    style="width: 100%"
                    class="mb-5"
                    type="number"
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="id_rt"
                    prepend-icon="mdi-map-marker"
                    label="RT"
                    single-line
                    hide-details
                    type="number"
                    style="width: 100%"
                    class="mb-5"
                  >
                  </v-text-field>
                  <v-btn
                    color="red"
                    class="button-signup"
                    block
                    v-on:click="getDataPenduduk()"
                    >Submit</v-btn
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nik, Nama, KK, dsb.."
              single-line
              filled
              outlined
              hide-details
              @input="getDataPenduduk()"
              style="width: 100%"
              class="mb-5"
            >
            </v-text-field>
            <span
              v-if="id_provinsiFilter != ''"
              style="color: black; margin: 0; font-size: 16px"
            >
              Filter :
            </span>
            <span>
              <v-chip
                v-if="id_provinsiFilter != ''"
                class="ma-2"
                close
                @click:close="
                  id_provinsiFilter = '';
                  id_kabkotFilter = '';
                  id_kelurahanFilter = '';
                  id_kecamatanFilter = '';
                  id_provinsiFilterValue = '';
                  id_kabkotFilterValue = '';
                  id_kelurahanFilterValue = '';
                  id_kecamatanFilterValue = '';
                  kabupatensFilter = '';
                  kelurahansFilter = '';
                  kecamatansFilter = '';
                "
              >
                {{ id_provinsiFilter.provinsi }}
              </v-chip>
              <v-chip
                v-if="id_kabkotFilter != ''"
                class="ma-2"
                close
                @click:close="
                  id_kabkotFilter = '';
                  id_kelurahanFilter = '';
                  id_kecamatanFilter = '';
                  id_kabkotFilterValue = '';
                  id_kelurahanFilterValue = '';
                  id_kecamatanFilterValue = '';
                  kelurahansFilter = '';
                  kecamatansFilter = '';
                "
              >
                {{ id_kabkotFilter.kabupaten_kota }}
              </v-chip>
              <v-chip
                v-if="id_kecamatanFilter != ''"
                class="ma-2"
                close
                @click:close="
                  id_kecamatanFilter = '';
                  id_kelurahanFilter = '';
                  id_kecamatanFilterValue = '';
                  id_kelurahanFilterValue = '';
                  kelurahansFilter = '';
                "
              >
                {{ id_kecamatanFilter.kecamatan }}
              </v-chip>
              <v-chip
                v-if="id_kelurahanFilter != ''"
                class="ma-2"
                close
                @click:close="
                  id_kelurahanFilter = '';
                  id_kelurahanFilterValue = '';
                "
              >
                {{ id_kelurahanFilter.kelurahan }}
              </v-chip>
            </span>
            <v-row>
              <v-col cols="12" sm="6">
                <p>List Penduduk</p>
                <v-card>
                  <v-list two-line v-if="dataPenduduk.data">
                    <template v-for="(item, index) in dataPenduduk.data">
                      <v-list-item :key="item.title">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.nama"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="item.nik"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              icon
                              @click="updateListPenduduk(item)"
                              :loading="loading"
                            >
                              <v-icon color="red lighten-1"
                                >mdi-plus-circle-outline</v-icon
                              >
                            </v-btn>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                      <v-divider :key="index"></v-divider>
                    </template>
                  </v-list>
                  <v-container v-else>
                    <p>Data tidak ada.</p>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6">
                <p>List Peserta</p>
                <v-card>
                  <v-list two-line v-if="selected.length > 0">
                    <template v-for="(item, index) in selected">
                      <v-list-item :key="item.item.title">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.item.nama"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="item.item.nik"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon>
                            <v-icon
                              color="red lighten-1"
                              @click="deleteListPenduduk(index, item)"
                              >mdi-close</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider :key="index"></v-divider>
                    </template>
                  </v-list>
                  <v-container v-else>
                    <p>Data tidak ada.</p>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
            <v-btn
              class="button-signup mt-5"
              color="green"
              @click="submitForm()"
              block
              >Simpan</v-btn
            >
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import Footer from "../components/Footer.vue";
import { getData, postData } from "../service/ApiService";

export default {
  name: "TambahBansos",
  components: {
    Footer,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      token_jwt: "",
      menu: false,
      msg: "",
      overlay: false,
      zIndex: 0,
      provinsis: "",
      kabupatens: "",
      selected: [],
      dataPenduduk: [],
      selectedPetugas: [],
      dataPendudukPetugas: [],
      kelurahans: "",
      kecamatans: "",
      provinsisFilter: "",
      kabupatensFilter: "",
      kelurahansFilter: "",
      kecamatansFilter: "",
      id_provinsi: "",
      id_kabkot: "",
      id_kecamatan: "",
      id_kelurahan: "",
      id_provinsiFilter: "",
      id_kabkotFilter: "",
      id_kecamatanFilter: "",
      id_kelurahanFilter: "",
      id_provinsiFilterValue: "",
      id_kabkotFilterValue: "",
      id_kecamatanFilterValue: "",
      id_kelurahanFilterValue: "",
      id_rw: "",
      id_rt: "",
      search: "",
      searchPetugas: "",
      e1: 1,
      steps: 3,
      form: {
        nama: null,
        total: null,
        satuan: null,
        keterangan: null,
        alamat: null,
        provinsi: null,
        kabupaten: null,
        kecamatan: null,
        kelurahan: null,
        rw: null,
        rt: null,
        pemberi: null,
      },
    };
  },
  computed: {},
  mounted() {},
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }

    this.form.rt = this.userData.id_rt;
    this.form.rw = this.userData.id_rw;

    this.getListProvinsi();
    this.getListKabkot();
    this.getListKecamatan();
    this.getListKelurahan();
    this.getDataPendudukPetugas();
    this.getDataPenduduk();
  },
  methods: {
    selectedProvinsi(value) {
      console.log("data value :", value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.form.provinsi = value;
            this.form.kabupaten = "";
            this.form.kelurahan = "";
            this.form.kecamatan = "";
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.form.kabkot = value;
            this.form.kelurahan = "";
            this.form.kecamatan = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.form.kecamatan = value;
            this.form.kelurahan = "";
            this.kelurahans = "";

            this.kelurahans = response;
            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.form.kelurahan = value;
      }
    },
    onClickOutside() {
      this.overlay = false;
    },
    submitForm() {
      this.loading = true;
      console.log(this.form);
      let formData = new FormData();
      formData.append("nama", this.form.nama);
      formData.append("total", this.form.total);
      formData.append("satuan", this.form.satuan);
      formData.append("keterangan", this.form.keterangan);
      formData.append("alamat", this.form.alamat);
      formData.append("provinsi", this.form.provinsi);
      formData.append("kecamatan", this.form.kecamatan);
      formData.append("kelurahan", this.form.kelurahan);
      formData.append("kabupaten", this.form.kabupaten);
      formData.append("rt", this.form.rt);
      formData.append("rw", this.form.rw);
      formData.append("pemberi", this.form.pemberi);
      formData.append("nik", this.userData.nik);
      formData.append("type", "perorangan");
      this.selectedPetugas.forEach((item, i) => {
        formData.append("panitia[" + i + "][nik_petugas]", item.item.nik);
      });
      this.selected.forEach((item, i) => {
        formData.append("invited[" + i + "][nikpenerima]", item.item.nik);
      });
      console.log(formData.values());
      const data = {
        api: "api/bansos",
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log(response);
          this.dialog = false;
          this.loading = false;
          this.snackbar = true;
          this.$router.push({
            name: "EBansos",
          });
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.loading = false;
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data) {
              this.msg_error = error.response.data;
              this.snackbar_error = true;
            }
          }
        });
    },
    back() {
      this.$router.back();
    },
    updateListPenduduk(item) {
      this.loading = true;
      this.selected.push({
        item,
      });
      console.log(item);
      console.log(this.selected);
      this.getDataPenduduk();
    },
    deleteListPenduduk(indexItem, item) {
      this.loading = true;
      // const index = this.selected.indexOf(indexItem);
      this.selected.splice(indexItem, 1);
      console.log(item);
      console.log(this.selected);
      this.getDataPenduduk();
    },
    getDataPenduduk() {
      console.log(this.selected);
      let formData = new FormData();
      this.selected.forEach((item, index) => {
        formData.append("list[" + index + "]", item.item.nik);
      });

      const apiListPenduduk = {
        api:
          "api/search/nik" +
          "?prov=" +
          this.id_provinsiFilterValue +
          "&kab=" +
          this.id_kabkotFilterValue +
          "&kec=" +
          this.id_kecamatanFilterValue +
          "&kel=" +
          this.id_kelurahanFilterValue +
          "&rw=" +
          this.id_rw +
          "&rt=" +
          this.id_rt +
          "&keyword=" +
          this.search,
        body: formData,
      };

      postData(apiListPenduduk)
        .then((response) => {
          console.log("data list penduduk :", response);
          if (response.data == "data tidak ditemukan") {
            this.dataPenduduk = [];
          } else {
            this.dataPenduduk = response;
          }
          this.loading = false;
          // this.provinsis = response;
          // this.form.id_provinsi = this.userData.id_provinsi;
          // console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    updateListPendudukPetugas(item) {
      this.loading = true;
      this.selectedPetugas.push({
        item,
      });
      console.log(item);
      console.log(this.selectedPetugas);
      this.getDataPendudukPetugas();
    },
    deleteListPendudukPetugas(indexItem, item) {
      this.loading = true;
      // const index = this.selected.indexOf(indexItem);
      this.selectedPetugas.splice(indexItem, 1);
      console.log(item);
      console.log(this.selectedPetugas);
      this.getDataPendudukPetugas();
    },
    getDataPendudukPetugas() {
      console.log(this.selectedPetugas);
      let formData = new FormData();
      this.selectedPetugas.forEach((item, index) => {
        formData.append("list[" + index + "]", item.item.nik);
      });

      const apiListPenduduk = {
        api:
          "api/search/nik" +
          "?prov=" +
          "&kab=" +
          "&kec=" +
          "&kel=" +
          "&rw=" +
          "&rt=" +
          "&keyword=" +
          this.searchPetugas,
        body: formData,
      };

      postData(apiListPenduduk)
        .then((response) => {
          console.log("data list penduduk :", response);
          if (response.data == "data tidak ditemukan") {
            this.dataPendudukPetugas = [];
          } else {
            this.dataPendudukPetugas = response;
          }
          this.loading = false;
          // this.provinsis = response;
          // this.form.id_provinsi = this.userData.id_provinsi;
          // console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          this.form.provinsi = this.userData.id_provinsi;

          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getListKabkot() {
      const apiListKabkot = "api/kabkot?keyword=" + this.userData.id_provinsi;

      getData(apiListKabkot)
        .then((response) => {
          console.log("data kabkot :", response);
          this.kabupatens = response;
          console.log(this.kabupatens);
          this.form.kabupaten = this.userData.id_kabkot;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },
    getListKecamatan() {
      const apiListKecamatan = "api/kec?keyword=" + this.userData.id_kabkot;

      getData(apiListKecamatan)
        .then((response) => {
          console.log("data kecamatan :", response);
          this.kecamatans = response;
          this.form.kelurahan = this.userData.id_kelurahan;
          console.log(this.kecamatans);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },
    getListKelurahan() {
      const apiListKelurahan = "api/kel?keyword=" + this.userData.id_kecamatan;
      getData(apiListKelurahan)
        .then((response) => {
          console.log("data kelurahan :", response);
          console.log(this.form.kelurahan);
          this.kelurahans = response;
          this.form.kecamatan = this.userData.id_kecamatan;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },
    selectedProvinsiFilter(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value.id;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.id_provinsiFilter = value;
            this.id_provinsiFilterValue = value.id;
            this.id_kabkotFilter = "";
            this.id_kelurahanFilter = "";
            this.id_kecamatanFilter = "";
            this.kabupatensFilter = "";
            this.kelurahansFilter = "";
            this.kecamatansFilter = "";

            this.kabupatensFilter = response;
            console.log(this.kabupatensFilter);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupatenFilter(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value.id;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.id_kabkotFilter = value;
            this.id_kabkotFilterValue = value.id;
            this.id_kelurahanFilter = "";
            this.id_kecamatanFilter = "";
            this.kelurahansFilter = "";
            this.kecamatansFilter = "";

            this.kecamatansFilter = response;
            console.log(this.kecamatansFilter);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatanFilter(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value.id;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.id_kecamatanFilter = value;
            this.id_kecamatanFilterValue = value.id;
            this.id_kelurahanFilter = "";
            this.kelurahansFilter = "";

            this.kelurahansFilter = response;
            console.log(this.kelurahansFilter);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahanFilter(value) {
      console.log(value);
      if (value != null) {
        this.id_kelurahanFilter = value;
        this.id_kelurahanFilterValue = value.id;
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}

@media only screen and (min-width: 599px) {
  .text-cp {
    color: white;
    font-size: 1.3vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 1vh;
    margin: 0;
  }

  .img-bubble {
    height: 20vh;
    width: auto;
  }

  .background-image {
    height: 160px;
    width: 100%;
  }
}

@media only screen and (max-width: 1250px) {
  .img-bubble {
    height: 20vh;
    width: auto;
  }
}

@media only screen and (max-width: 970px) {
  .img-bubble {
    height: 20vh;
    width: auto;
  }
}

@media only screen and (max-width: 845px) {
  .img-bubble {
    height: 20vh;
    width: auto;
  }
}

@media only screen and (max-width: 599px) {
  .text-cp {
    color: white;
    font-size: 3vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 2vh;
    margin: 0;
  }

  .img-bubble {
    height: 10vh;
    width: auto;
  }

  .background-image {
    height: 120px;
    width: 100%;
  }
}

@media only screen and (min-width: 693px) {
  .text-cp {
    color: white;
    font-size: 5vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 2.5vh;
    margin: 0;
  }
}

@media only screen and (max-width: 693px) {
  .text-cp {
    color: white;
    font-size: 2vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 1.5vh;
    margin: 0;
  }
}
</style>