<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil membuat voting.
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="red" dark>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          Mohon tunggu..
          <v-progress-circular
            indeterminate
            color="white"
            class="ml-6"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card>
      <v-app-bar app color="#F2F1F1" dense>
        <v-app-bar-title>Buat Voting</v-app-bar-title>
      </v-app-bar>
      <Header text="E-Vote" img="e-vote.png" />

      <v-card-text>
        <v-container style="margin-bottom: 100px">
          <div
            v-for="(textField, i) in textFields"
            :key="i"
            class="text-fields-row mb-10"
            style="
              border: 2px solid #5f5757;
              border-radius: 25px;
              padding: 10px 25px;
              background-color: #f2f2f2;
            "
          >
            <v-row no-gutters>
              <v-col cols="6">
                <p style="color: #ff0000">Peserta {{ i + 1 }}</p>
              </v-col>
              <v-col cols="6" class="text-end">
                <v-btn @click="remove(i)" class="button-signup" color="red"
                  >Hapus</v-btn
                >
              </v-col>
            </v-row>
            <v-text-field
              prepend-icon="mdi-account"
              label="Nama Peserta"
              v-model="textField.peserta"
            ></v-text-field>

            <v-file-input
              required
              prepend-icon="mdi-camera"
              label="Photo Peserta"
              truncate-length="15"
              v-model="textField.photo"
            ></v-file-input>
          </div>
          <div class="text-center">
            <v-btn @click="add" class="button-signup" color="red"
              >Tambah +</v-btn
            >
            <br />
            <br />
            <br />
            <v-btn @click="submitForm()" class="button-signup" color="red" block
              >Simpan</v-btn
            >
          </div>
        </v-container>
      </v-card-text>
    </v-card>
    <Footer />
  </div>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import { getData, postData } from "../service/ApiService";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "TambahVote",
  props: ["dataVote"],
  components: {
    Header,
    Footer,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      show1: false,
      textFields: [],
      rules: {
        required: (value) => !!value || "Wajib di isi.",
        min: (v) => v.length >= 8 || "Password minimal 8 digit",
        min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
        emailMatch: () => `The email and password you entered don't match`,
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
        return "Wajib Berupa Angka.";
      },
      snackbar: false,
      loading: false,
      snackbar_error: false,
      goldars: [
        "A",
        "B",
        "AB",
        "O",
        "A+",
        "A-",
        "B+",
        "B-",
        "AB+",
        "AB-",
        "O+",
        "O-",
        "NA",
      ],
      agamas: [
        "islam",
        "kristen",
        "katholik",
        "budha",
        "hindu",
        "konghucu",
        "lainnya",
      ],
      roles: [
        {
          id: 1,
          nama: "Superadmin",
        },
        {
          id: 2,
          nama: "Admin",
        },
        {
          id: 3,
          nama: "RT",
        },
        {
          id: 4,
          nama: "Warga",
        },
      ],
      status_pernikahans: ["Belum Kawin", "Kawin", "Cerai Hidup", "Cerai Mati"],
      menu_waktu_2: false,
      menu_tanggal_1: false,
      menu_waktu_1: false,
      menu_tanggal_2: false,
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      form: {
        judul: null,
        deskripsi: null,
        tanggal_mulai: null,
        tanggal_selesai: null,
        waktu_mulai: null,
        waktu_selesai: null,
        status: null,
        nik_pembuat: null,
        id_provinsi: null,
        id_kabkot: null,
        id_kecamatan: null,
        id_kelurahan: null,
        id_rw: null,
        id_rt: null,
        photo: null,
        password: null,
      },
      url: "",
      token_jwt: "",
      dialog: false,
      activePicker: null,
      menu: false,
    };
  },
  computed: {
    TglMulaiMomentjs() {
      return this.form.tanggal_mulai
        ? moment(this.form.tanggal_mulai).format("DD, MMMM YYYY")
        : "";
    },
    TglSelesaiMomentjs() {
      return this.form.tanggal_selesai
        ? moment(this.form.tanggal_selesai).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    if (this.snackbar) {
      this.hide_alert();
    }

    if (this.snackbar_error) {
      this.hide_alert_error();
    }

    this.getListProvinsi();
  },
  created() {
    console.log("data vote :", this.dataVote);
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
  },
  methods: {
    add() {
      this.textFields.push({
        peserta: null,
        photo: null,
      });
    },

    remove(index) {
      this.textFields.splice(index, 1);
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    hide_alert: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    hide_alert_error: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar_error = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.form.id_provinsi = value;
            this.form.id_kabkot = "";
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";
            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.form.id_kabkot = value;
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.form.id_kecamatan = value;
            this.form.id_kelurahan = "";
            this.kelurahans = "";

            this.kelurahans = response;
            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.form.id_kelurahan = value;
      }
    },
    submitForm() {
      console.log(this.textFields);

      this.loading = true;
      const completed = [];

      this.textFields.forEach((item) => {
        console.log(item);
        let formData = new FormData();
        formData.append("id_evoting", this.dataVote);
        formData.append("peserta", item.peserta);
        formData.append("photo", item.photo);
        const data = {
          api: "api/e_vote/post",
          body: formData,
        };
        postData(data)
          .then((response) => {
            console.log(response);
            completed.push({ id: 1 });
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response);
            completed.push({ id: 1 });

            // if (error.response) {
            //   console.log(error.response.data);
            //   if (error.response.data) {
            //     this.msg_error = "Gagal Mengirim, terjadi kesalahan.";
            //     this.snackbar_error = true;
            //   }
            // }
          });
      });
      const interval = setInterval(() => {
        if (completed.length == this.textFields.length) {
          this.loading = false;
          clearInterval(interval);
          this.$router.push("e-voting");
        }
      }, 2000);
      // this.$router.push("e-voting");

      // console.log(this.form);
      // let formData = new FormData();
      // formData.append("judul", this.form.judul);
      // formData.append("deskripsi", this.form.deskripsi);
      // formData.append(
      //   "tanggal_selesai",
      //   this.form.tanggal_selesai + " " + this.form.waktu_selesai
      // );
      // formData.append(
      //   "tanggal_mulai",
      //   this.form.tanggal_mulai + " " + this.form.waktu_mulai
      // );
      // formData.append("status", this.form.status);
      // formData.append("nik_pembuat", this.userData.nik);
      // formData.append("id_provinsi", this.form.id_provinsi);
      // formData.append("id_kecamatan", this.form.id_kecamatan);
      // formData.append("id_kelurahan", this.form.id_kelurahan);
      // formData.append("id_kabkot", this.form.id_kabkot);
      // formData.append("id_rt", this.form.id_rt);
      // formData.append("id_rw", this.form.id_rw);
      // formData.append("photo", this.form.photo);
      // formData.append("password", this.form.password);

      // console.log(formData.values());
      // const data = {
      //   api: "api/evote",
      //   body: formData,
      // };
      // postData(data)
      //   .then((response) => {
      //     console.log(response);
      //     this.dialog = false;
      //     this.loading = false;
      //     this.snackbar = true;
      //     this.$router.push("e-voting");
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     console.log(error.response);
      //     this.loading = false;
      //     if (error.response) {
      //       console.log(error.response.data);
      //       if (error.response.data) {
      //         this.msg_error = error.response.data;
      //         this.snackbar_error = true;
      //       }
      //     }
      //   });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    deletePic() {
      this.url = "";
      this.$refs.file.value = "";
    },
    onFileChange(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.form.photo = e.target.files[0];
      console.log(this.form);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>