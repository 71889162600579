<template>
  <v-card class="mx-auto overflow-hidden" height="100%">
    <v-snackbar v-model="snackbar" centered color="green">
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="white" size="25"> mdi-check-circle-outline </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title style="font-size: 1rem"
            >Password Telah Diubah</v-list-item-title
          >
          <v-list-item-subtitle style="font-size: 0.5rem"
            >Silahkan login kembali</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-btn @click="snackbar = false" icon>
        <v-icon color="white"> mdi-close </v-icon>
      </v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbar_err" centered color="red">
      {{ msg }}
      <v-btn color="white" icon @click="snackbar_err = false">
        <v-icon color="white"> mdi-close </v-icon>
      </v-btn>
    </v-snackbar>
    <v-navigation-drawer
      absolute
      temporary
      style="padding: 30px 0px 80px 0 !important"
    >
      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-dots-grid</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Fitur</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tentang</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn color="red" class="button-signup" block>Masuk</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main class="body">
      <a href="/" style="text-decoration: none" class="d-flex d-sm-none">
        <v-btn icon rounded>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </a>
      <!-- Logo Mobile -->
      <v-row
        no-gutters
        justify="center"
        style="margin-top: 40px; position: absolute; width: 100%"
        class="d-flex d-sm-none"
      >
        <v-col cols="4" style="text-align: center">
          <img
            class="img-logo"
            alt="Smart RT logo"
            src="../assets/smart-rt-logo.png"
          />
        </v-col>
        <v-col cols="12" style="margin-top: 100px">
          <img
            class="img-bubble"
            alt="Bubble Patern"
            src="../assets/bubble.png"
          />
        </v-col>
      </v-row>

      <!-- Welcome Text Mobile -->
      <v-row
        no-gutters
        justify="center"
        style="margin-top: 200px; width: 100%"
        class="d-flex d-sm-none"
      >
        <v-col cols="10">
          <v-card class="mx-auto" max-width="500" style="border-radius: 15px">
            <v-container style="padding: 20px 50px">
              <div
                class="text-center"
                style="color: black; font-size: 6vw; margin-bottom: 20px"
              >
                Masuk
              </div>
              <v-form ref="form" lazy-validation v-on:submit.prevent="login()">
                <v-text-field
                  v-model="nik"
                  :counter="16"
                  label="Nik"
                  :rules="[rules.required, rules.min]"
                  placeholder="Nik.."
                  rounded
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Password"
                  hint="Minimal 8 karakter"
                  @click:append="show1 = !show1"
                  placeholder="Password.."
                  rounded
                  outlined
                  dense
                >
                </v-text-field>
                <v-row>
                  <v-col cols="6">
                    <ResetPasswordEmailVue></ResetPasswordEmailVue>
                  </v-col>
                  <v-col cols="1"> </v-col>
                  <v-col cols="12" class="no-padding">
                    <Tombol text="Masuk" warna="red" type="submit" />
                  </v-col>
                  <v-col cols="12" class="no-padding">
                    <p
                      class="no-padding login-text"
                      style="margin: 10px 0 5px 0"
                    >
                      Belum punya akun?
                    </p>
                  </v-col>
                  <v-col cols="12" class="no-padding">
                    <Daftar text="Daftar" />
                  </v-col>
                  <v-col cols="12" class="no-padding">
                    <p
                      class="no-padding login-text"
                      style="margin: 10px 0 5px 0"
                    >
                      Atau masuk dengan
                    </p>
                  </v-col>
                  <v-col cols="1" class="no-padding"> </v-col>
                  <v-col cols="10" class="no-padding">
                    <v-btn
                      v-google-signin-button="clientId"
                      text
                      outlined
                      block
                      x-large
                      color="grey"
                      style="border-radius: 25px; padding: 4px 5px 0 5px"
                    >
                      <v-row no-gutters>
                        <v-col cols="2" class="text-left"
                          ><img
                            src="../assets/google-logo.png"
                            width="35"
                            alt=""
                          />
                        </v-col>
                        <v-col
                          cols="8"
                          class="text-center"
                          style="
                            font-size: 3vw;
                            margin: auto;
                            text-transform: none;
                          "
                        >
                          Lanjut dengan google</v-col
                        >
                        <v-col cols="2"></v-col>
                      </v-row>
                    </v-btn>
                  </v-col>
                  <v-col cols="1"> </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-btn icon rounded @click="$router.push('/')" class="d-none d-sm-flex">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <!-- BG Desktop -->
      <v-row
        no-gutters
        justify="center"
        style="margin-top: 40px; position: absolute; width: 100%"
        class="d-none d-sm-flex"
      >
        <v-col cols="4">
          <img
            class="img-logo"
            alt="Smart RT logo"
            src="../assets/smart-rt-logo.png"
          />
        </v-col>
        <v-col cols="2"> </v-col>
        <v-col cols="6">
          <img
            class="img-bubble"
            alt="Bubble Patern"
            src="../assets/bubble.png"
          />
        </v-col>
      </v-row>

      <!-- Welcome Text Desktop -->
      <v-row
        no-gutters
        justify="center"
        style="margin-top: 20px; position: absolute; width: 100%"
        class="d-none d-sm-flex"
      >
        <v-col cols="5"> </v-col>
        <v-col cols="6">
          <v-card class="mx-auto" max-width="450" style="border-radius: 15px">
            <v-container style="padding: 20px 50px">
              <div
                class="text-center"
                style="color: black; font-size: 3vw; margin-bottom: 20px"
              >
                Masuk
              </div>
              <v-form ref="form" lazy-validation v-on:submit.prevent="login()">
                <v-text-field
                  v-model="nik"
                  :counter="16"
                  label="Nik"
                  :rules="[rules.required, rules.min]"
                  placeholder="Nik.."
                  rounded
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Password"
                  hint="Minimal 8 karakter"
                  @click:append="show1 = !show1"
                  placeholder="Password.."
                  rounded
                  outlined
                ></v-text-field>
                <ResetPasswordEmailVue></ResetPasswordEmailVue>
                <v-row>
                  <v-col
                    cols="12"
                    justify="end"
                    class="no-padding"
                    style="margin: 10px 0 0px 0"
                  >
                    <v-btn
                      block
                      color="red"
                      class="button-signup"
                      type="submit"
                      :loading="dialog"
                      >Masuk</v-btn
                    >
                  </v-col>
                  <v-col cols="12" class="no-padding">
                    <p
                      class="text-center no-padding login-text"
                      style="color: gray; margin: 10px 0 5px 0"
                    >
                      Belum punya akun?
                    </p>
                  </v-col>
                  <v-col cols="12" class="no-padding">
                    <Daftar text="Daftar"></Daftar>
                  </v-col>
                  <v-col
                    cols="12"
                    class="no-padding"
                    style="margin: 10px 0 5px 0"
                  >
                    <p
                      class="text-center login-text"
                      style="color: gray; padding: 0; margin: 0"
                    >
                      Atau masuk dengan
                    </p>
                  </v-col>
                  <v-col cols="1" class="no-padding"> </v-col>
                  <v-col cols="10" class="text-center no-padding mb-5">
                    <!-- <a href="https://dev.smartrt.id/auth/google/login"> -->
                    <v-btn
                      v-google-signin-button="clientId"
                      text
                      outlined
                      block
                      x-large
                      color="grey"
                      style="border-radius: 25px; margin: auto"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="2"
                          style="text-align: start; margin-top: 5px"
                          ><img
                            src="../assets/google-logo.png"
                            height="auto"
                            width="90%"
                            alt=""
                          />
                        </v-col>
                        <v-col
                          cols="10"
                          style="
                            font-size: 12px;
                            margin: auto;
                            text-transform: none;
                          "
                          >Lanjut dengan google
                        </v-col>
                      </v-row>
                    </v-btn>
                    <!-- </a> -->
                  </v-col>
                  <v-col cols="1"> </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
    </v-main>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="red" dark>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          Mohon tunggu..
          <v-progress-circular
            indeterminate
            color="white"
            class="ml-6"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Tombol from "@/components/Tombol.vue";
import { postData } from "../service/ApiService";
import GoogleSignInButton from "vue-google-signin-button-directive";
import ResetPasswordEmailVue from "../components/ResetPasswordEmail.vue";
import Daftar from "../components/Daftar.vue";

const axios = require("axios");

export default {
  name: "Login",
  beforeCreate: function () {
    if (this.$session.exists()) {
      this.$router.push("/home/beranda");
    }
  },
  directives: {
    GoogleSignInButton,
  },
  data() {
    return {
      clientId:
        "607163310636-pruf5otek166ju26hlnufcp8jj5d5pgh.apps.googleusercontent.com",
      dialog: false,
      nik: "",
      password: "",
      msg: "",
      token_jwt: "",
      email: "",
      msg_error: "",
      show1: false,
      snackbar: false,
      snackbar_err: false,
      rules: {
        required: (value) => !!value,
        min: (v) => v.length >= 8,
      },
    };
  },
  components: {
    ResetPasswordEmailVue,
    Tombol,
    Daftar,
  },
  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
    }
  },
  created() {
    this.token_reset = new URL(location.href).searchParams.get("success");
    console.log(this.token_reset);
    if (this.token_reset == "true") {
      this.snackbar = true;
    }
  },
  methods: {
    async OnGoogleAuthSuccess(idToken) {
      this.dialog = true;
      console.log(idToken);
      await axios
        .post("https://oauth2.googleapis.com/tokeninfo?id_token=" + idToken, {})
        .then((response) => {
          console.log(response);
          console.log(response.data);
          this.CheckEmail(response.data.email);
        })
        .catch((error) => {
          this.dialog = false;
          if (error.response) {
            console.log(error.response.data);
            this.msg = error.response.data;
          }
        });
      // Receive the idToken and make your magic with the backend
    },
    CheckEmail(dataEmail) {
      let formData = new FormData();
      formData.append("email", dataEmail);

      const data = {
        api: "auth/email",
        body: formData,
      };

      postData(data)
        .then((response) => {
          console.log(response);
          this.dialog = false;
          this.token_jwt = response.token;
          localStorage.token_jwt = this.token_jwt;
          localStorage.user_data = JSON.stringify(response);
          localStorage.dialogBroadcast = true;
          this.$session.start();
          this.$session.set("jwt", this.token_jwt);
          this.$router.push("home");
          this.msg = "";
          console.log(this.token_jwt);
        })
        .catch((error) => {
          this.dialog = false;
          this.snackbar_err = true;
          if (error.response) {
            console.log(error.response.data);
            this.msg = error.response.data;
            if (error.response.data.message) {
              this.msg = error.response.data.message;
            } else if (error.response.data.nik) {
              this.msg = error.response.data.nik[0];
            } else if (error.response.data.password) {
              this.msg = error.response.data.password[0];
            }
          }
        });
    },
    OnGoogleAuthFail(error) {
      console.log(error);
    },
    login() {
      this.dialog = true;
      let formData = new FormData();
      formData.append("nik", this.nik);
      formData.append("password", this.password);

      const data = {
        api: "auth/login",
        body: formData,
      };

      postData(data)
        .then((response) => {
          console.log(response);
          this.dialog = false;
          this.token_jwt = response.token;
          localStorage.token_jwt = this.token_jwt;
          localStorage.dialogBroadcast = true;
          localStorage.user_data = JSON.stringify(response);
          this.$session.start();
          this.$router.push("home");
          this.$session.set("jwt", this.token_jwt);
          this.msg = "";
          console.log(this.token_jwt);
        })
        .catch((error) => {
          this.dialog = false;
          this.snackbar_err = true;
          if (error.response) {
            console.log(error.response.data);
            this.msg = error.response.data;
            if (error.response.data.message) {
              this.msg = error.response.data.message;
            } else if (error.response.data.nik) {
              this.msg = error.response.data.nik[0];
            } else if (error.response.data.password) {
              this.msg = error.response.data.password[0];
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
}

.no-padding {
  padding: 0;
  margin: 0;
}

.login-text {
  font-weight: 400;
  font-size: 14px;
  text-align: center;

  color: #ad9c9c;
}

@media only screen and (min-width: 599px) {
  .body {
    height: 100%;
    width: 100%;
    background-image: url("../assets/bg-slash.png");
    background-repeat: no-repeat;
    background-attachment: absolute;
    background-size: cover;
    background-position: center;
  }

  .img-logo {
    height: auto;
    width: 100%;
    margin: 60px 0 0 40px;
  }

  .text-welcome {
    font-family: "Oswald", sans-serif;
    font-size: 6vw;
  }

  .text-desc {
    font-size: 1.5vw;
    color: black;
  }
}

.img-bubble {
  height: auto;
  width: 100%;
}

@media only screen and (max-width: 599px) {
  .body {
    height: 100%;
    width: 100%;
    background-image: url("../assets/bg-slash-mb.png");
    background-repeat: no-repeat;
    background-attachment: absolute;
    background-size: cover;
    background-position: center;
  }

  .img-logo {
    height: auto;
    width: 100%;
    margin: auto;
  }

  .text-welcome {
    font-family: "Oswald", sans-serif;
    font-size: 10vw;
    text-shadow: 1px 1px 3px #000000;
  }

  .text-desc {
    font-size: 3vw;
    color: black;
  }
}

.text-content {
  color: white;
  text-align: center;
  position: absolute;
  width: 100%;
  /* position: absolute;
  z-index: 1000; */
}

.button-area {
  padding: 50px 0 0 0;
  margin: auto;
  text-align: center;
  width: 250px;
}

* {
  font-family: "Alata", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Alata&family=Oswald&display=swap");
</style>
