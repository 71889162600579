<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil mendaftarkan akun
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-btn color="red" class="button-signup" @click="dialog = true"
      >Detail</v-btn
    >
    <v-dialog v-if="dialog" v-model="dialog" max-width="600px">
      <v-card>
        <v-app-bar
          color="#F2F1F1"
          height="80px"
          elevate-on-scroll
          scroll-target="#scrolling-techniques-7"
        >
          <v-btn
            color="black"
            icon
            v-bind="attrs"
            v-on="on"
            @click="dialog = false"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <div v-if="status != 'lansia'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="black"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editDataPenduduk()"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Akun</span>
            </v-tooltip>

            <v-tooltip bottom v-if="status === 'aktif'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="black"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogConfirmation = true"
                >
                  <v-icon>mdi-account-off</v-icon>
                </v-btn>
              </template>
              <span>Non-aktifkan Akun</span>
            </v-tooltip>

            <v-dialog v-model="dialogConfirmation" max-width="290">
              <v-card>
                <v-card-title class="text-h5"> Konfirmasi </v-card-title>

                <v-card-text v-if="status === 'aktif'">
                  Apakah anda yakin untuk menonaktifkan akun ini?
                </v-card-text>
                <v-card-text v-if="status === 'non-aktif'">
                  Apakah anda yakin untuk mengaktifkan akun ini?
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="green darken-1" text @click="aktifUser()">
                    Ya
                  </v-btn>

                  <v-btn
                    color="red darken-1"
                    text
                    @click="dialogConfirmation = false"
                  >
                    Tidak
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-tooltip bottom v-if="status === 'non-aktif'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="black"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogConfirmation = true"
                >
                  <v-icon>mdi-account</v-icon>
                </v-btn>
              </template>
              <span>Aktifkan Akun</span>
            </v-tooltip>
          </div>
        </v-app-bar>
        <v-card-text>
          <v-container>
            <form
              id="myForm"
              name="myForm"
              v-on:submit.prevent="submitForm"
              enctype="multipart/form-data"
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-book-open-outline"
                    label="No KK"
                    v-model="form.kk_penduduk"
                    hint="Nomor Kartu Keluarga (16 Digit)"
                    filled
                    readonly
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-account"
                    label="Nama Lengkap"
                    filled
                    readonly
                    v-model="form.nama"
                    hint="Nama Lengkap Sesuai KTP"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-book-open-outline"
                    label="Jumlah KK"
                    v-model="form.Jumlah_keluarga"
                    hint="Jumlah KK"
                    filled
                    readonly
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-note-check-outline"
                    label="Status Kesejahteraan"
                    filled
                    readonly
                    v-model="form.status_kesejahteraan"
                    hint="Status Kesejahteraan"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-list-status"
                    label="Status Proses"
                    v-model="form.status"
                    hint="Status Sesuai Kartu Keluarga"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-crosshairs-gps"
                    v-model="form.provinsi"
                    label="
                    Provinsi"
                    hint="
                    Provinsi"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-crosshairs-gps"
                    v-model="form.kabupaten_kota"
                    label="
                    Kabupaten Kota"
                    hint="
                    Kabupaten Kota"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-crosshairs-gps"
                    v-model="form.kecamatan"
                    label="
                    kecamatan"
                    hint="
                    kecamatan"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-crosshairs-gps"
                    v-model="form.kelurahan"
                    label="
                    kelurahan"
                    hint="
                    kelurahan"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    label="Alamat Domisili"
                    v-model="form.alamat_domisili"
                    hint="Alamat Tempat Tinggal Saat Ini"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    label="RT"
                    v-model="form.id_rt"
                    hint="RT Sesuai Tempat Tinggal Saat Ini"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    label="RW"
                    v-model="form.id_rw"
                    hint="RW Sesuai Tempat Tinggal Saat Ini"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="status === 'aktif'">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    v-model="form.provinsi"
                    label="Provinsi"
                    item-text="provinsi"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="status === 'aktif'">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    v-model="form.kabupaten_kota"
                    label="Kabupaten/ Kota"
                    item-text="kabupaten_kota"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="status === 'aktif'">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    v-model="form.kecamatan"
                    label="Kecamatan"
                    item-text="kecamatan"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="status === 'aktif'">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    v-model="form.kelurahan"
                    label="Kelurahan"
                    item-text="kelurahan"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import { activatedData, deleteData } from "../service/ApiService";

export default {
  name: "DetailDataGakin",
  props: {
    dataItem: [],
    status: String,
  },
  beforeCreate: function () {
    this.showDetail = true;
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      rand: 1,
      showDetail: false,
      show1: false,
      rules: {
        required: (value) => !!value || "Wajib di isi.",
        min: (v) => v.length >= 8 || "Password minimal 8 digit",
        min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
        emailMatch: () => `The email and password you entered don't match`,
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
        return "Wajib Berupa Angka.";
      },
      snackbar: false,
      snackbar_error: false,
      goldars: [
        "A",
        "B",
        "AB",
        "O",
        "A+",
        "A-",
        "B+",
        "B-",
        "AB+",
        "AB-",
        "O+",
        "O-",
        "NA",
      ],
      agamas: [
        "islam",
        "kristen",
        "kathloik",
        "budha",
        "hindu",
        "konghucu",
        "lainnya",
      ],
      status_pernikahans: ["Belum Kawin", "Kawin", "Cerai Hidup", "Cerai Mati"],
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      form: {
        id_role: 4,
        nama: "",
        nik: "",
        kk_penduduk: "",
        email: "",
        password: "",
        status: "",
        status_pernikahan: "",
        nohp: "",
        tgl_lahir: "",
        tempatlahir: "",
        jk: "",
        suku: "",
        agama: "",
        goldarah: "",
        alamat: "",
        alamat_domisili: "",
        id_provinsi: "",
        id_kabkot: "",
        id_kelurahan: "",
        id_kecamatan: "",
        id_rt: "",
        id_rw: "",
        photo: "",
      },
      url: "",
      token_jwt: "",
      dialog: false,
      dialogConfirmation: false,
      activePicker: null,
      menu: false,
      msg_error: null,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.form.tgl_lahir
        ? moment(this.form.tgl_lahir).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    if (this.dialog) {
      self.rand = Date.now();
    }

    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    if (this.snackbar) {
      this.hide_alert();
    }

    if (this.snackbar_error) {
      this.hide_alert_error();
    }

    this.getListProvinsi();
  },
  created() {
    console.log("data item :", this.dataItem);
    this.form = this.dataItem;
  },
  methods: {
    hide_alert: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    hide_alert_error: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar_error = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    editDataPenduduk() {
      this.$router.push({
        name: "EditDataPenduduk",
        params: {
          items: this.form,
        },
      });
    },
    aktifUser() {
      if (this.status === "aktif") {
        const data = {
          api: "api/profile/",
          body: this.dataItem.nik,
        };

        deleteData(data)
          .then((response) => {
            console.log(response);
            this.$router.go();
            console.log(this.token_jwt);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              this.msg = error.response.data;
            }
          });
      } else if (this.status === "non-aktif") {
        const data = {
          api: "api/profile/active/",
          body: this.dataItem.nik,
        };
        activatedData(data)
          .then((response) => {
            console.log(response);
            this.$router.go();
            console.log(this.token_jwt);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              this.msg = error.response.data;
            }
          });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>