<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil mendaftarkan akun
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-btn color="red" class="button-signup" @click="dialog = true"
      >Detail</v-btn
    >
    <v-dialog v-if="dialog" v-model="dialog" max-width="600px">
      <v-card>
        <v-app-bar
          color="#F2F1F1"
          height="80px"
          elevate-on-scroll
          scroll-target="#scrolling-techniques-7"
        >
          <v-btn
            color="black"
            icon
            v-bind="attrs"
            v-on="on"
            @click="dialog = false"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="black"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editDataPerceraian()"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Akun</span>
            </v-tooltip>
          </div>
        </v-app-bar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-book-open-outline"
                  label="No. Akta Cerai"
                  filled
                  readonly
                  v-model="form.nomor_akta_cerai"
                  hint="Nomor Akta Cerai"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-book-open-outline"
                  label="No. Akta Pernikahan"
                  filled
                  readonly
                  v-model="form.nomor_akta_pernikahan"
                  hint="Nomor Akta Pernikahan"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-book-open-outline"
                  label="No. Seri Perceraian"
                  filled
                  readonly
                  v-model="form.nomor_seri_perceraian"
                  hint="Nomor Seri Perceraian"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-book-open-outline"
                  label="NIK Pengaju"
                  filled
                  readonly
                  v-model="form.nik_pengaju"
                  hint="NIK Pengaju"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-account"
                  label="Nama"
                  filled
                  readonly
                  v-model="form.nama"
                  hint="Nama"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-menu
                  disabled
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  :rules="[rules.required]"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Tanggal Kawin"
                      prepend-icon="mdi-calendar"
                      readonly
                      filled
                      :value="computedDateFormattedMomentjs"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :rules="[rules.required]"
                    v-model="form.tanggal_peceraian"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    min="1950-01-01"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-list-item style="padding: 0">
                  <v-list-item-icon style="margin-right: 20px">
                    <v-icon>mdi-book-open-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Akta Perceraian</v-list-item-title>
                    <a :href="form.file_akta_perceraian"
                      >Buka File Akta Perceraian PDF</a
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- <v-col cols="12">
                <v-list-item style="padding: 0">
                  <v-list-item-icon style="margin-right: 20px">
                    <v-icon>mdi-book-open-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Buku Nikah Istri</v-list-item-title>
                    <a :href="form.file_buku_nikah_istri"
                      >Buku Nikah Istri PDF</a
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12">
                <v-list-item style="padding: 0">
                  <v-list-item-icon style="margin-right: 20px">
                    <v-icon>mdi-book-open-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Buku Nikah Suami</v-list-item-title>
                    <a :href="form.file_buku_nikah_suami"
                      >Buku Nikah Suami PDF</a
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col> -->
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  label="Tempat Perceraian"
                  v-model="form.tempat_perceraian"
                  hint="ex:Balikpapan"
                  filled
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  v-model="form.provinsi"
                  label="Provinsi"
                  item-text="provinsi"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  v-model="form.kabupaten_kota"
                  label="Kabupaten/ Kota"
                  item-text="kabupaten_kota"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  v-model="form.kecamatan"
                  label="Kecamatan"
                  item-text="kecamatan"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  v-model="form.kelurahan"
                  label="Kelurahan"
                  item-text="kelurahan"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import { activatedData, deleteData, getData } from "../service/ApiService";
export default {
  name: "DetailDataPerceraian",
  props: {
    dataItem: [],
  },
  beforeCreate: function () {
    this.showDetail = true;
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      rand: 1,
      showDetail: false,
      show1: false,
      rules: {
        required: (value) => !!value || "Wajib di isi.",
        min: (v) => v.length >= 8 || "Password minimal 8 digit",
        min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
        emailMatch: () => `The email and password you entered don't match`,
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
        return "Wajib Berupa Angka.";
      },
      snackbar: false,
      snackbar_error: false,
      goldars: [
        "A",
        "B",
        "AB",
        "O",
        "A+",
        "A-",
        "B+",
        "B-",
        "AB+",
        "AB-",
        "O+",
        "O-",
        "NA",
      ],
      agamas: [
        "islam",
        "kristen",
        "kathloik",
        "budha",
        "hindu",
        "konghucu",
        "lainnya",
      ],
      status_perceraians: ["Belum Kawin", "Kawin", "Cerai Hidup", "Cerai Mati"],
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      form: {
        id_cerai: "",
        id_kabkot: "",
        id_kecamatan: "",
        id_kelurahan: "",
        kabupaten_kota: "",
        kecamatan: "",
        kelurahan: "",
        nik_pengaju: "",
        nomor_akta_cerai: "",
        provinsi: "",
        tanggal_peceraian: "",
      },
      url: "",
      token_jwt: "",
      dialog: false,
      dialogConfirmation: false,
      activePicker: null,
      menu: false,
      msg_error: null,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.form.tanggal_peceraian
        ? moment(this.form.tanggal_peceraian).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    if (this.dialog) {
      self.rand = Date.now();
    }

    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    if (this.snackbar) {
      this.hide_alert();
    }

    if (this.snackbar_error) {
      this.hide_alert_error();
    }

    this.getListProvinsi();
  },
  created() {
    this.form = this.dataItem;
    console.log("data item :", this.form);
    this.getDataPerceraian();
  },
  methods: {
    hide_alert: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    hide_alert_error: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar_error = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    editDataPerceraian() {
      this.$router.push({
        name: "EditDataPerceraian",
        params: {
          items: this.form,
        },
      });
    },
    getDataPerceraian() {
      const apiLansia = "api/perceraian/" + this.form.nomor_akta_cerai;
      getData(apiLansia)
        .then((response) => {
          console.log(apiLansia);
          console.log("data :", response);
          this.form = response.data[0];
          console.log(this.form);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    aktifUser() {
      if (this.status === "aktif") {
        const data = {
          api: "api/profile/",
          body: this.dataItem.nik,
        };

        deleteData(data)
          .then((response) => {
            console.log(response);
            this.$router.go();
            console.log(this.token_jwt);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              this.msg = error.response.data;
            }
          });
      } else if (this.status === "non-aktif") {
        const data = {
          api: "api/profile/active/",
          body: this.dataItem.nik,
        };
        activatedData(data)
          .then((response) => {
            console.log(response);
            this.$router.go();
            console.log(this.token_jwt);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              this.msg = error.response.data;
            }
          });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>