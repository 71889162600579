const axios = require('axios');

export async function getData(api) {
    console.log(localStorage.token_jwt,"helo");
    const response = await axios.get('https://dev.smartrt.id/' + api,
    {
        headers: {
            "Authorization": `Bearer ${localStorage.token_jwt}`
        }
    });
    return response.data;
}

export async function getDataTanpaToken(api) {
    console.log(localStorage.token_jwt,"helo");
    const response = await axios.get('https://dev.smartrt.id/' + api,
    {
        headers: {
        }
    });
    return response.data;
}

export async function postData(data) {
    console.log(localStorage.token_jwt,"helo 2");
    console.log(data);
    console.log('hello broo')
    const response = await axios.post('https://dev.smartrt.id/' + data.api, data.body, {
        headers: {
            "Authorization": `Bearer ${localStorage.token_jwt}`,  
            'Content-Type': 'multipart/form-data'    
        }
    });
    return response.data;
}

export async function postDataTanpaToken(data) {
    console.log(localStorage.token_jwt,"helo 2");
    console.log(data);
    console.log('hello broo')
    const response = await axios.post('https://dev.smartrt.id/' + data.api, data.body, {
        headers: {
            'Content-Type': 'multipart/form-data'    
        }
    });
    return response.data;
}

export async function deleteData(data) {
    console.log(localStorage.token_jwt,"helo");
    console.log(data);

    const response = await axios.delete('https://dev.smartrt.id/' + data.api , {
        headers: {
            "Authorization": `Bearer ${localStorage.token_jwt}`,  
        }
    });
    return response.data;
}

export async function activatedData(data) {
    console.log(localStorage.token_jwt);
    const response = await axios.get('https://dev.smartrt.id/' + data.api + data.body, {
        headers: {
            "Authorization": `Bearer ${localStorage.token_jwt}`
        }
    });
    return response.data;
}

export async function postDataString(data) {
    console.log(localStorage.token_jwt,"helo 1");
    console.log('hello')
    const response = await axios.post('https://dev.smartrt.id/' + data.api, data.body, {
        headers: {
            "Authorization": `Bearer ${localStorage.token_jwt}`
        }
    });
    return response.data;
}