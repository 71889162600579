<template class="overflow-hidden">
  <div>
    <v-app-bar app color="white">
      <v-btn
        class="d-flex"
        color="black"
        icon
        v-bind="attrs"
        v-on="on"
        to="/home"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>
    <HeaderVue text="Putus Sekolah" icon="mdi-school" />

    <DataTablePutusSekolah />

    <Footer />
  </div>
</template>
<script>
import DataTablePutusSekolah from "../components/DataTablePutusSekolah.vue";
import Footer from "../components/Footer.vue";
import HeaderVue from "../components/Header.vue";

export default {
  components: { HeaderVue, DataTablePutusSekolah, Footer },
  name: "PutusSekolah",
  data() {
    return {
      userData: null,
    };
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("data token: ", this.token_jwt);
    }
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
  },
  created() {},
  methods: {
    logout() {
      this.$session.destroy();
      this.$router.push("/");
      console.log(localStorage.token_jwt);
      localStorage.token_jwt = null;
      console.log(localStorage.token_jwt);
    },
  },
};
</script>

<style scoped>
.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>