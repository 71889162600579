<template>
  <div style="height: 100%; margin-bottom: 100px">
    <v-expansion-panels v-if="this.userData.role == 'warga'">
      <v-expansion-panel>
        <v-expansion-panel-header> Profile Saya </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container>
            <div class="d-flex justify-center text-center">
              <v-btn icon style="width: 150px; height: 150px">
                <v-avatar color="brown" size="150">
                  <img v-if="userData.photo != ''" :src="userData.photo" />
                  <span
                    v-if="userData.photo == ''"
                    class="white--text text-h5"
                    style="text-transuserdata: none"
                  >
                    <v-icon>mdi-camera</v-icon>
                  </span>
                </v-avatar>
              </v-btn>
            </div>
            <p>Identitas</p>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  prepend-icon="mdi-account"
                  label="Nama Lengkap"
                  filled
                  readonly
                  v-model="userData.nama"
                  hint="Nama Lengkap Sesuai KTP"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  prepend-icon="mdi-book-account"
                  label="NIK"
                  filled
                  readonly
                  v-model="userData.nik"
                  hint="Nomor Induk Keluarga (16 Digit)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  prepend-icon="mdi-book-open-outline"
                  label="No KK"
                  v-model="userData.id_kk"
                  hint="Nomor Kartu Keluarga (16 Digit)"
                  filled
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  prepend-icon="mdi-phone"
                  type="tel"
                  v-model="userData.nohp"
                  label="Nomor Telepon"
                  hint="Nomor Telepon Aktif"
                  filled
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  prepend-icon="mdi-email"
                  label="Email"
                  filled
                  readonly
                  v-model="userData.email"
                  hint="Email Aktif"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  prepend-icon="mdi-account-group"
                  label="Status Di KK"
                  v-model="userData.status"
                  hint="Status Sesuai Kartu Keluarga"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  prepend-icon="mdi-heart"
                  v-model="userData.status_pernikahan"
                  label="Status Pernikahan"
                  hint="Status Pernikahan Sesuai Di KTP"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Tanggal Lahir"
                  prepend-icon="mdi-calendar"
                  readonly
                  filled
                  :value="computedDateFormatedMomentjs"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  label="Tempat Lahir"
                  v-model="userData.tempatlahir"
                  hint="ex:Balikpapan"
                  filled
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  prepend-icon="mdi-gender-male-female"
                  label="Jenis kelamin"
                  v-model="userData.jk"
                  filled
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  prepend-icon="mdi-account-group"
                  label="Suku"
                  v-model="userData.suku"
                  hint="Suku"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  prepend-icon="mdi-account-group"
                  v-model="userData.agama"
                  label="Agama"
                  hint="Agama Sesuai KTP"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  prepend-icon="mdi-water"
                  v-model="userData.goldarah"
                  label="Golongan Darah"
                  hint="Golongan Darah"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <br />
            <p>Domisili</p>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  label="Alamat KTP"
                  v-model="userData.alamat"
                  hint="Alamat Sesuai KTP"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  label="Alamat Domisili"
                  v-model="userData.alamat_domisili"
                  hint="Alamat Tempat Tinggal Saat Ini"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  v-model="userData.provinsi"
                  label="Provinsi"
                  item-text="provinsi"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  v-model="userData.kabupaten_kota"
                  label="Kabupaten/ Kota"
                  item-text="kabupaten_kota"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  v-model="userData.kecamatan"
                  label="Kecamatan"
                  item-text="kecamatan"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  v-model="userData.kelurahan"
                  label="Kelurahan"
                  item-text="kelurahan"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  label="RT"
                  v-model="userData.id_rt"
                  hint="RT Sesuai Tempat Tinggal Saat Ini"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  label="RW"
                  v-model="userData.id_rw"
                  hint="RW Sesuai Tempat Tinggal Saat Ini"
                  filled
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" style="padding: 0">
                <v-subheader>Pendidikan Umum</v-subheader>
              </v-col>
              <div v-for="(itemUmum, i) in dataPendidikanUmum" :key="i">
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-school"
                    label="Nama Sekolah"
                    :value="itemUmum.nama_sekolah"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-school"
                    label="Tahun Masuk"
                    :value="itemUmum.tahun_masuk"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-school"
                    label="Tahun Selesai"
                    :value="itemUmum.tahun_selesai"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Status Pendidikan"
                    prepend-icon="mdi-school"
                    :value="itemUmum.status_selesai"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <br />
              </div>
              <v-col cols="12" style="padding: 0">
                <v-subheader>Pendidikan Khusus</v-subheader>
              </v-col>
              <div v-for="(itemKhusus, i) in dataPendidikanKhusus" :key="i">
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-school"
                    label="Nama Sekolah"
                    :value="itemKhusus.nama_sekolah"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-school"
                    label="Tahun Masuk"
                    :value="itemKhusus.tahun_masuk"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-school"
                    label="Tahun Selesai"
                    :value="itemKhusus.tahun_selesai"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Status Pendidikan"
                    prepend-icon="mdi-school"
                    :value="itemKhusus.status_selesai"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>
                <br />
              </div>
            </v-row>
            <v-row>
              <v-col cols="6" style="padding: 0">
                <v-subheader>Prestasi</v-subheader>
              </v-col>
              <v-col cols="6" style="padding: 0" class="text-end">
                <TambahDataPrestasiWarga />
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-list three-line>
                  <div v-for="(itemPrestasi, i) in prestasiSaya" :key="i">
                    <v-list-item>
                      <v-list-item-avatar tile>
                        <v-img :src="itemPrestasi.photo"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          v-html="itemPrestasi.judul"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="itemPrestasi.keterangan"
                        ></v-list-item-subtitle>

                        <v-list-item-subtitle
                          v-if="itemPrestasi.status == 'menunggu'"
                          style="color: #ad9c9c"
                        >
                          Menunggu...
                          <v-icon style="color: #ad9c9c"
                            >mdi-clock-outline</v-icon
                          >
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-if="itemPrestasi.status == 'disetujui'"
                          style="color: #4ff54c"
                        >
                          Disetujui...
                          <v-icon style="color: #4ff54c">mdi-check</v-icon>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-if="itemPrestasi.status == 'ditolak'"
                          style="color: red"
                        >
                          Ditolak...
                          <v-icon style="color: red"
                            >mdi-close-circle-outline
                          </v-icon>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <!-- <v-btn icon>
                          <v-icon color="grey lighten-1"
                            >mdi-information</v-icon
                          >
                        </v-btn> -->
                        <div cols="12" sm="3">
                          <DetailDataPrestasiWarga
                            :dataItem="itemPrestasi"
                            status="editable"
                            v-if="itemPrestasi.status == 'menunggu'"
                          />
                          <DetailDataPrestasiWarga
                            :dataItem="itemPrestasi"
                            status="lansia"
                            v-if="itemPrestasi.status != 'menunggu'"
                          />
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </div>
                </v-list>
                <!-- <v-col cols="12" sm="6">
                  <v-text-field
                      prepend-icon="mdi-school"
                      label="Nama Prestasi"
                      :value="itemPrestasi.judul"
                      readonly
                    ></v-text-field>
                  <p>{{ itemPrestasi.judul }}</p>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Status"
                    :value="itemPrestasi.status"
                    readonly
                  ></v-text-field>
                </v-col> -->
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header> Keluarga Saya </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <v-data-table
              class="table-penduduk"
              disable-pagination
              :hide-default-footer="true"
              :headers="headersKeluarga"
              :items="dataKeluarga"
              :total-items="totalNumberOfItems"
              @update:pagination="paginate"
            >
              <template
                v-slot:item.nama="{ item }"
                style="text-transform: capitalize"
              >
                <td class="truncate">{{ item.nama }}</td>
              </template>

              <template v-slot:item.aksi="{ item }">
                <DetailDataPenduduk :dataItem="item" status="lansia" />
              </template>
        
            </v-data-table>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-container style="margin-bottom: 100px" v-else>
      <p style="color: #ff8540; font-size: 25px" class="mt-10">Data Penduduk</p>
      <v-row class="d-flex mt-10">
        <FitureCardVue
          v-show="userData.role != 'warga'"
          icon="mdi-account-group"
          link="/master-penduduk"
          text="Data Penduduk"
          img="false" 
        />

        <FitureCardVue img="false" icon="mdi-heart" link="/perkawinan" text="Perkawinan" />
        <FitureCardVue
          icon="mdi-heart-broken"
          link="/perceraian"
          text="Perceraian"
          img="false" 
        />
        
        <FitureCardVue img="false" 
          icon="mdi-baby-carriage"
          link="/kelahiran"
          text="Kelahiran"
        />
        <FitureCardVue img="false" 
          icon="mdi-human-white-cane"
          link="/lansia"
          text="Lansia"
        />
        <FitureCardVue img="dead-body.png" icon="false" link="/kematian" text="Kematian" />

        <FitureCardVue img="false"  icon="mdi-hand-coin" link="/gakin" text="Gakin" />
        <FitureCardVue img="false" 
          icon="mdi-medal"
          link="/prestasi-warga"
          text="Prestasi Warga"
        />
        <FitureCardVue img="false" 
          icon="mdi-school"
          link="/putus-sekolah"
          text="Putus Sekolah"
          v-show="userData.role != 'warga'"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FitureCardVue from "../components/FitureCard.vue";

import { getData } from "../service/ApiService";
import moment from "moment";
import TambahDataPrestasiWarga from "../components/TambahDataPrestasiWarga.vue";
import DetailDataPrestasiWarga from "../components/DetailDataPrestasiWarga.vue";
import DetailDataPenduduk from "../components/DetailDataPenduduk.vue";

export default {
  name: "Penduduk",
  components: {
    DetailDataPrestasiWarga,
    TambahDataPrestasiWarga,
    DetailDataPenduduk,
    // DataTable,
    // DataTableDisable,
    FitureCardVue,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }

    this.dataPendidikanUmum = JSON.parse(
      localStorage.user_data
    ).pendidikan_umum;

    this.dataPendidikanKhusus = JSON.parse(
      localStorage.user_data
    ).pendidikan_khusus;

    this.getDataPrestasi();
    this.getDataKeluarga();
  },
  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
    }
  },
  computed: {
    computedDateFormatedMomentjs() {
      return this.userData.tgl_lahir
        ? moment(this.userData.tgl_lahir).format("DD, MMMM YYYY")
        : "";
    },
  },
  data: () => ({
    dialogConfirmation: false,
    userData: "",
    token_jwt: "",
    loading: false,
    msg: null,
    news: "",
    totalPages: "",
    dialogProfile: false,
    dataPendidikanUmum: "",
    dataKeluarga: [],
    dataPendidikanKhusus: "",
    page: 1,
    search: null,
    prestasiSaya: null,
    headersKeluarga: [
      {
        text: "NIK",
        align: "start",
        value: "nik",
      },
      {
        text: "No KK",
        value: "id_kk",
      },
      {
        text: "Nama",
        value: "nama",
      },
      {
        text: "Aksi",
        value: "aksi",
        sortable: false,
      },

      //   { text: 'Aksi', value: 'aksi',class: 'my-header-style' },
    ],
    headers: [
      {
        text: "Nama",
        value: "nama",
      },
      {
        text: "Prestasi",
        value: "judul",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Aksi",
        value: "aksi",
        sortable: false,
      },

      //   { text: 'Aksi', value: 'aksi',class: 'my-header-style' },
    ],
  }),
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm");
      }
    },
    getDataPrestasi() {
      const apiLansia =
        "api/prestasi-warga/" +
        this.userData.nik +
        "?page=" +
        this.page +
        "&keyword=" +
        this.search;
      console.log("data mylapor: ", this.userData.nik);

      getData(apiLansia)
        .then((response) => {
          console.log("data :", response);
          this.prestasiSaya = response.prestasi_saya;
          this.totalPages = response.last_page;
          console.log(this.penduduks);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    detailNews(data) {
      this.$router.push({
        name: "DetailNews",
        params: {
          dataNews: data,
        },
      });
    },
    getDataKeluarga() {
      const ApiGetKeluarga = "api/list-kk/" + this.userData.id_kk;

      getData(ApiGetKeluarga)
        .then((response) => {
          console.log("data :", response);
          this.dataKeluarga = response.data;
          console.log(this.dataKeluarga);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    logout() {
      this.$session.destroy();
      this.$router.push("/");
      console.log(localStorage.token_jwt);
      localStorage.token_jwt = null;
      console.log(localStorage.token_jwt);
    },
  },
};
</script>

<style scoped>
.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-content {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>