<template>
  <v-card class="overflow-hidden">
    <v-app-bar app color="#F2F1F1" dense>
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="Lowongan Pekerjaan" icon="mdi-briefcase" />

    <v-container style="height: 100%; margin-bottom: 100px">
      <div v-if="!lokerData.data">
        <v-skeleton-loader class="mx-auto mb-10" max-width="100%" type="card"></v-skeleton-loader>

        <v-skeleton-loader class="mx-auto mb-10" max-width="100%" type="card"></v-skeleton-loader>

        <v-slide-group class="mb-10 mt-10" v-model="model" active-class="success" show-arrows>
          <v-slide-item v-for="n in 15" :key="n">
            <v-skeleton-loader class="mx-auto mr-10" max-width="150px" type="card" style="width: 150px">
            </v-skeleton-loader>
          </v-slide-item>
        </v-slide-group>
      </div>

      <v-row>
        <v-col cols="6">
          <p style="color: #c82424; font-size: 20px">Lowongan Terbuka</p>
        </v-col>
        <v-col cols="6" class="flex justify-end text-right mb-2">
          <v-btn color="#F54C4C" class="button-signup ml-5" type="submit" @click="tambahloker()"
            v-show="userData.role != 'warga'">Buat Loker</v-btn>
          <!-- <TambahLoker  v-show="userData.role != 'warga'" /> -->
        </v-col>
      </v-row>
      <div v-if="lokerData.data">
        <v-expansion-panels v-show="userData.role == 'superadmin' || userData.role == 'admin'">
          <v-expansion-panel>
            <v-expansion-panel-header> Filter </v-expansion-panel-header>
            <v-expansion-panel-content class="text-center">
              <v-autocomplete prepend-icon="mdi-map-marker" label="Kategori" v-model="kategori_name"
                :items="kategoriData" item-text="kategori" required @change="(event) => selectedKategori(event)">
              </v-autocomplete>
              <v-btn color="red" class="button-signup" block v-on:click="getDataLokerFilter()">Submit</v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Cari Vote.." single-line filled outlined
          hide-details @input="SearchbarChange" style="width: 100%">
        </v-text-field>
        <span v-if="kategori_name != ''" style="color: black; margin: 0; font-size: 16px">
          Filter :
        </span>
        <span>
          <v-chip v-if="kategori_name != ''" class="ma-2" close @click:close="
              kategori_name = '';
              kategori = '';
            ">
            {{ kategori_name }}
          </v-chip>
        </span>
        <div v-if="lokerData.data.length == 0">
          <p class="text-center ma-10">
            Tidak ada lowongan pekerjaan saat ini.
          </p>
        </div>

        <v-grid>
          <v-row>
            <v-col v-for="(item, index) in lokerData.data" :key="index" sm="6" cols="12">
              <v-card color="#F2F2F2" class="mt-5" @click="detailLoker(item)">
                <v-list-item :key="item.judul">
                  <v-list-item-avatar tile>
                    <v-img :src="item.photo"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-html="item.judul"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.nama_usaha"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip color="#FF8540" text-color="white">
                      {{ item.kategori }}
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>
                      mdi-map-marker
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.alamat"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>
                      mdi-clock-outline
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title v-text="timeSince(new Date(item.created_at)-aDay)">
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-grid>

        <div class="text-center mt-5">
          <v-pagination  :loading="loading" v-model="page" :length="totalPages" total-visible="7"
            next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="handlePageChange"></v-pagination>
        </div>
      </div>
    </v-container>
    <Footer />
  </v-card>
</template>
<script>
  // import FitureCardVue from '../components/FitureCard.vue';
  // import NewsCardVue from '../components/NewsCard.vue';
  // import Tombol from '../components/Tombol.vue';
  // @ is an alias to /src
  // import axios from "axios";
  import moment from "moment";
  import Footer from "../components/Footer.vue";
  import Header from "../components/Header.vue";
  import {
    getData
  } from "../service/ApiService";
  export default {
    name: "LowonganPekerjaan",
    components: {
      Footer,
      Header,
    },
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push("/");
      }
    },
    data() {
      return {
        model: null,
        kategoriData: null,
        kategori_name: "",
        provinsis: "",
        kabupatens: "",
        kelurahans: "",
        kecamatans: "",
        id_provinsi: "",
        id_kabkot: "",
        id_kelurahan: "",
        id_kecamatan: "",
        search: "",
        token_jwt: "",
        menu: false,
        msg: "",
        loading: false,
        page: 1,
        lokerData: "",
        startTime: "July 7, 2017 12:03:00",
        endTime: "July 9, 2022 14:55:00",
        userData: "",
        times: [{
            id: 0,
            text: "Hari yang lalu",
            time: 1
          },
          {
            id: 1,
            text: "Jam yang lalu",
            time: 1
          },
          {
            id: 2,
            text: "Menit yang lalu",
            time: 1
          },
          {
            id: 3,
            text: "Detik yang lalu",
            time: 1
          },
        ],
        progress: 100,
        aDay: 24 * 60 * 60 * 1000,
        // isActive: false,
        timeinterval: undefined,
      };
    },
    computed: {
      computedDateFormattedMomentjs() {
        return this.dataNews.created_at ?
          moment(this.dataNews.created_at).format("dddd, d MMM YYYY HH:m A") :
          "";
      },
    },
    watch: {
      timerCount: {
        handler(value) {
          if (value > 0) {
            setTimeout(() => {
              this.timerCount--;
            }, 1000);
          }
        },
      },
    },
    mounted() {},
    created() {
      if (localStorage.user_data) {
        this.userData = JSON.parse(localStorage.user_data).data[0];
        console.log("data user: ", this.userData);
      }
      this.getDataVoting();
      this.getListKategori();
    },
    methods: {
      timeSince(date) {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
          return "Dibuat " + Math.floor(interval) + " Tahun yang lalu";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
          return "Dibuat " + Math.floor(interval) + " Bulan yang lalu";
        }
        interval = seconds / 86400;
        if (interval > 1) {
          return "Dibuat " + Math.floor(interval) + " Hari yang lalu";
        }
        interval = seconds / 3600;
        if (interval > 1) {
          return "Dibuat " + Math.floor(interval) + " Jam yang lalu";
        }
        interval = seconds / 60;
        if (interval > 1) {
          return "Dibuat " + Math.floor(interval) + " Menit yang lalu";
        }
        return "Dibuat " + Math.floor(seconds) + " Detik yang lalu";
      },
      getListKategori() {
        const apiListKategori = "api/loker/id/kategori";
        getData(apiListKategori)
          .then((response) => {
            console.log("data kategori :", response);
            this.kategoriData = response.data;
            console.log(this.kategoriData);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      },
      evotingFinish() {
        this.$router.push({
          name: "EVotingFinish",
        });
      },
      handlePageChange(value) {
        this.page = value;
        this.getDataLokerFilter();
      },
      getDataLokerFilter() {
        if (this.search != "") {
          if (this.kategori_name != "" && this.kategori_name != null) {
            this.apiGetFilter =
              "api/loker?page=" +
              this.page +
              "&kategori=" +
              this.kategori_name +
              "&keyword=" +
              this.search;
          } else {
            this.apiGetFilter =
              "api/loker" + "?page=" + this.page + "&keyword=" + this.search;
          }
        } else {
          if (this.kategori_name != "") {
            this.apiGetFilter =
              "api/loker?page=" + this.page + "&kategori=" + this.kategori_name;
          } else {
            this.apiGetFilter = "api/loker" + "?page=" + this.page;
          }
        }

        getData(this.apiGetFilter)
          .then((response) => {
            console.log("data :", response);
            this.lokerData = response.data;
            this.totalPages = response.data.last_page;
            console.log(this.penduduks);
            this.loading = false;
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      },
      selectedKategori(value) {
        this.kategori = value;
        console.log(value);
      },
      SearchbarChange(value) {
        this.search = value;
        this.getDataLokerFilter();
        console.log(value);
      },
      back() {
        this.$router.back();
      },
      getDataVoting() {
        const ApiGetVoting = "api/loker?page=" + this.page;

        getData(ApiGetVoting)
          .then((response) => {
            console.log("data :", response);
            this.lokerData = response.data;

            this.totalPages = response.data.last_page;
            console.log(this.lokerData);
            this.loading = false;
          })
          .catch((error) => {
            console.log("pesan error :", error);
            this.msg = true;
          });
      },
      detailLoker(item) {
        this.$router.push({
          name: "DetailLoker",
          params: {
            dataLoker: item,
          },
        });
      },
      hasilVoting(item) {
        this.$router.push({
          name: "HasilVoting",
          params: {
            items: item.id_evoting,
          },
        });
      },
      tambahloker() {
        this.$router.push({
          name: "TambahLoker",
        });
      },
      format_date(value) {
        if (value) {
          return moment(String(value)).format("DD/MMM/YYYY HH:SS");
        }
      },
    },
  };
</script>

<style scoped>
  .button-signup {
    border-radius: 10px;
    color: white !important;
    text-transform: none;
    text-decoration: none;
  }

  .text-sub {
    color: #c82424;
  }

  .truncate {
    line-height: 1.5em;
    height: 3em;
    /* height is 2x line-height, so two lines will display */
    overflow: hidden;
    /* prevents extra lines from being visible */
  }
</style>