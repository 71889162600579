<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      app
      color="#F2F1F1"
      dense
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="E-Survey" icon="mdi-chart-box-outline" />
    <!-- <p>{{ items }}</p> -->
    <div v-if="surveyData == ''">
      <v-row style="padding: 20px 50px">
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <v-container
      style="height: 100%; margin-bottom: 100px"
      v-if="surveyData != ''"
    >
      <!-- <p>{{ surveyData }}</p> -->
      <p style="color: #c82424; font-size: 20px">
        Ditutup : {{ items[1].tanggal_selesai }}
      </p>
      <p style="font-size: 24px" class="text-center">
        {{ items[1].judul_survey }}
      </p>
      <p style="font-size: 14px" class="text-center">
        {{ items[1].provinsi }}, {{ items[1].kabupaten_kota }},
        {{ items[1].kecamatan }},
        {{ items[1].kelurahan }}
      </p>

      <v-card
        v-for="(item, index) in surveyData.data"
        :key="index"
        class="mb-10"
      >
        <v-card-title style="background-color: #fecbcb">
          {{ item.pertanyaan[0].pertanyaan }}
        </v-card-title>
        <v-container style="background-color: #f2f2f2">
          <v-radio-group
            v-model="responseArrPilihanGanda[index]"
            style="padding-top: 10px"
            v-if="item.pertanyaan[0].jenis == 'pilihan ganda'"
          >
            <v-radio
              v-for="(item, index) in item.pilihan"
              :key="index"
              :label="item.opsi_text"
              :value="item"
            ></v-radio>
          </v-radio-group>
          <div v-if="item.pertanyaan[0].jenis == 'kotak centang'">
            <v-checkbox
              v-for="(item, index) in item.pilihan"
              :key="index"
              v-model="responseArrKotakCentang"
              :label="item.opsi_text"
              :value="item"
            ></v-checkbox>
          </div>
          <v-text-field
            v-if="item.pertanyaan[0].jenis == 'jawaban singkat'"
            label="Jawaban Anda"
            @input="selectedResponse(item.pertanyaan[0], $event)"
            required
          ></v-text-field>
        </v-container>
      </v-card>

      <div class="text-end">
        <v-btn
          color="red"
          class="button-signup ma-10"
          large
          @click="dialogConfirmation = true"
          :loading="dialog"
        >
          Kirim
        </v-btn>
      </div>
      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-card color="red" dark>
          <v-card-title></v-card-title>
          <v-card-text class="text-center">
            Mohon tunggu..
            <v-progress-circular
              indeterminate
              color="white"
              class="ml-6"
            ></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar.visible"
        multi-line="true"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
        centered
        auto-height
      >
        <v-layout align-center pr-4>
          <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
          <v-layout column>
            <div>
              <strong> {{ snackbar.status }}</strong>
            </div>
            <div>{{ msg }}</div>
          </v-layout>
        </v-layout>
        <v-btn
          v-if="snackbar.timeout === 0"
          icon
          @click="snackbar.visible = false"
        >
          <v-icon>clear</v-icon>
        </v-btn>
      </v-snackbar>
      <v-dialog v-model="dialogConfirmation" max-width="290">
        <v-card>
          <v-card-title class="text-h5"> Konfirmasi </v-card-title>

          <v-card-text> Apakah anda yakin dengan jawaban anda? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="postDataSurvey()">
              Ya
            </v-btn>

            <v-btn
              color="red darken-1"
              text
              @click="dialogConfirmation = false"
            >
              Tidak
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import { getData, postData } from "../service/ApiService";
const axios = require("axios");

export default {
  name: "DetailSurvey",
  props: ["items"],
  components: {
    Footer,
    Header,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      responseArrKotakCentang: [],
      responseArrPilihanGanda: [],
      responseArrJawabanSingkat: [],
      responseArrEssay: [],
      responseArrPilihan: [],
      icons: ["mdi-rewind", "mdi-play", "mdi-fast-forward"],
      transparent: "rgba(255, 255, 255, 0)",
      model: null,
      token_jwt: "",
      menu: false,
      msg: "",
      overlay: false,
      selected: null,
      surveyData: "",
      userData: "",
      dialog: false,
      objIndex: null,
      dialogConfirmation: false,
      textResponse: "",
      snackbar: {
        timeout: 3000,
        visible: false,
        color: "",
        icon: "",
        status: "",
      },
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataNews.created_at
        ? moment(this.dataNews.created_at).format("dddd, d MMM YYYY HH:m A")
        : "";
    },
  },
  mounted() {},
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.surveyData = this.items[0];

    console.log(this.surveyData);
    // this.getDataVoting();
  },
  methods: {
    selectedResponse(item, event) {
      console.log(event);
      console.log(item);
      const found = this.responseArrJawabanSingkat.some(
        (el) => el.id_pertanyaan == item.id_pertanyaan
      );
      if (!found) {
        this.responseArrJawabanSingkat.push({
          nik_respon: this.userData.nik,
          id_pertanyaan: item.id_pertanyaan,
          jenis: item.jenis,
          pertanyaan: item.pertanyaan,
          id_survey: this.items[1].id_survey,
          opsi_text: event,
        });
      } else {
        this.responseArrJawabanSingkat.forEach((itemSurvey, index) => {
          if (itemSurvey.id_pertanyaan == item.id_pertanyaan) {
            this.responseArrJawabanSingkat[index].opsi_text = event;
          }
        });
      }
      console.log(this.responseArrJawabanSingkat);
    },
    submitResponse() {
      this.dialog = true;
      console.log("response array :", this.responses);

      let formData = new FormData();
      formData.append("id_evoting", this.items);
      formData.append("id_peserta", this.selected);
      formData.append("nik_pemilih", this.userData.nik);
      const data = {
        api: "api/vote",
        body: formData,
      };

      postData(data)
        .then((response) => {
          this.dialogConfirmation = false;
          console.log("response :", response);
          this.dialog = false;
          this.msg = "Terima kasih telah melakukan voting.";
          this.snackbar.status = "Berhasil";
          this.snackbar.color = "green accent-4";
          this.snackbar.icon = "mdi-check-circle-outline";
          this.snackbar.visible = true;
        })
        .catch((error) => {
          this.dialog = false;
          this.dialogConfirmation = false;
          this.snackbar.color = "red accent-2";
          this.snackbar.icon = "mdi-alert-octagon-outline";
          this.snackbar.status = "Gagal";
          if (error.response) {
            this.snackbar.visible = true;
            console.log(error.response.data.nik_pemilih[0]);
            if (
              error.response.data.nik_pemilih ==
              "nik pemilih anda telah melakukan voting"
            ) {
              this.msg = "Anda telah melakukan voting.";
            } else {
              this.msg = error.response.data;
            }
          }
        });
    },
    pilih(data) {
      if (this.selected != data.id_peserta) {
        this.selected = data.id_peserta;
      } else {
        this.selected = null;
      }
      console.log(this.selected);
    },
    back() {
      this.$router.back();
    },
    getDataVoting() {
      console.log(this.items);
      const ApiGetVoting = "api/survey/ask/" + this.items.id_survey;

      getData(ApiGetVoting)
        .then((response) => {
          console.log("data :", response);
          this.surveyData = response;
          console.log(this.surveyData);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    postDataSurvey() {
      this.dialog = true;
      console.log(
        "Kotak Centang :",
        this.responseArrKotakCentang,
        "Pilihan Ganda :",
        this.responseArrPilihanGanda,
        "Jawaban Singkat :",
        this.responseArrJawabanSingkat
      );

      let formData = new FormData();

      this.responseArrKotakCentang.forEach((item) => {
        this.responseArrPilihan.push({
          item,
        });
      });
      this.responseArrPilihanGanda.forEach((item) => {
        this.responseArrPilihan.push({
          item,
        });
      });
      this.responseArrJawabanSingkat.forEach((item) => {
        this.responseArrEssay.push({
          item,
        });
      });

      console.log(
        "Pilihan",
        this.responseArrPilihan,
        "Essay",
        this.responseArrEssay
      );
      this.responseArrPilihan.forEach((item, index) => {
        formData.append(
          "pilihan[" + [index] + "][nik_respon]",
          this.userData.nik
        );
        formData.append("pilihan[" + [index] + "][id_opsi]", item.item.id_opsi);
      });

      this.responseArrEssay.forEach((item, index) => {
        formData.append(
          "essay[" + [index] + "][nik_respon]",
          this.userData.nik
        );
        formData.append(
          "essay[" + [index] + "][id_survey]",
          item.item.id_survey
        );
        formData.append(
          "essay[" + [index] + "][id_pertanyaan]",
          item.item.id_pertanyaan
        );
        formData.append(
          "essay[" + [index] + "][opsi_text]",
          item.item.opsi_text
        );
      });

      formData.append("nik_respon", this.userData.nik);
      formData.append("id_survey", this.items[1].id_survey);

      const data = {
        api: "api/survy/respon",
        body: formData,
      };

      axios
        .post("https://dev.smartrt.id/" + data.api, data.body, {
          headers: {
            Authorization: `Bearer ${localStorage.token_jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.dialogConfirmation = false;
          console.log("response :", response);
          this.dialog = false;
          this.msg = "Terima kasih telah mengisi survey.";
          this.snackbar.status = "Berhasil";
          this.snackbar.color = "green accent-4";
          this.snackbar.icon = "mdi-check-circle-outline";
          this.snackbar.visible = true;
          this.$router.push("/e-survey");
        })
        .catch((error) => {
          this.dialog = false;
          this.dialogConfirmation = false;
          this.snackbar.color = "red accent-2";
          this.snackbar.icon = "mdi-alert-octagon-outline";
          this.snackbar.status = "Gagal";
          if (error.response) {
            this.snackbar.visible = true;
            console.log(error.response.data.nik_pemilih[0]);
            if (
              error.response.data.nik_pemilih == "Anda telah mengisi survey"
            ) {
              this.msg = "Anda telah mengisi survey.";
            } else {
              this.msg = error.response.data;
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.text-sub {
  text-align: center;
  color: #000000;
  font-size: 20px;
}

.v-alert {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

.truncate {
  line-height: 1.5em;
  height: 3em; /* height is 2x line-height, so two lines will display */
  overflow: hidden; /* prevents extra lines from being visible */
}
</style>