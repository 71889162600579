<template>
  <v-card class="overflow-hidden">
    <v-app-bar color="#F2F1F1" dense>
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="Berita" icon="mdi-newspaper" />

    <v-container style="height: 100%; margin-bottom: 100px">
      <div v-if="!newsData.data.data">
        <v-skeleton-loader
          class="mx-auto mb-10"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>

        <v-skeleton-loader
          class="mx-auto mb-10"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>

        <v-slide-group
          class="mb-10 mt-10"
          v-model="model"
          active-class="success"
          show-arrows
        >
          <v-slide-item v-for="n in 15" :key="n">
            <v-skeleton-loader
              class="mx-auto mr-10"
              max-width="150px"
              type="card"
              style="width: 150px"
            ></v-skeleton-loader>
          </v-slide-item>
        </v-slide-group>
      </div>
      <v-carousel
        style="border-radius: 15px"
        cycle
        height="auto"
        class="responsive-height mb-5"
        hide-delimiter-background
        show-arrows-on-hover
        v-if="newsData.data.data"
      >
        <v-carousel-item v-for="(slide, i) in newsData.data.data" :key="i">
          <v-card height="100%" style="border-radius: 15px">
            <v-img
              class="white--text align-end d-flex responsive-height"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              :src="slide.photo"
            >
              <v-card-subtitle>
                <p style="margin-bottom: 5px; font-size: 20px">
                  {{ slide.judul }}
                </p>
                <p style="color: red; font-size: 18px">
                  {{ slide.created_at }}
                </p>
              </v-card-subtitle>
            </v-img>
          </v-card>
        </v-carousel-item>
      </v-carousel>
      <div v-if="newsData.data.data">
        <v-expansion-panels
          v-show="userData.role == 'superadmin' || userData.role == 'admin'"
        >
          <v-expansion-panel>
            <v-expansion-panel-header> Filter </v-expansion-panel-header>
            <v-expansion-panel-content class="text-center">
              <v-autocomplete
                prepend-icon="mdi-map-marker"
                label="Provinsi"
                v-model="id_provinsi"
                :items="provinsis"
                item-text="provinsi"
                return-object
                required
                @change="(event) => selectedProvinsi(event)"
              ></v-autocomplete>
              <v-autocomplete
                prepend-icon="mdi-map-marker"
                v-model="id_kabkot"
                :items="kabupatens"
                label="Kabupaten/ Kota"
                return-object
                item-text="kabupaten_kota"
                :disabled="kabupatens == ''"
                required
                @change="(event) => selectedKabupaten(event)"
              ></v-autocomplete>
              <v-autocomplete
                prepend-icon="mdi-map-marker"
                v-model="id_kecamatan"
                :items="kecamatans"
                label="Kecamatan"
                item-text="kecamatan"
                return-object
                :disabled="kecamatans == ''"
                required
                @change="(event) => selectedKecamatan(event)"
              ></v-autocomplete>
              <v-autocomplete
                prepend-icon="mdi-map-marker"
                v-model="id_kelurahan"
                :items="kelurahans"
                label="Kelurahan"
                return-object
                :disabled="kelurahans == ''"
                item-text="kelurahan"
                required
                @change="(event) => selectedKelurahan(event)"
              ></v-autocomplete>
              <v-btn
                color="red"
                class="button-signup"
                block
                v-on:click="getDataNewsFilter()"
                >Submit</v-btn
              >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Vote.."
          single-line
          filled
          outlined
          hide-details
          @input="SearchbarChange"
          style="width: 100%"
        >
        </v-text-field>
        <span
          v-if="id_provinsi != ''"
          style="color: black; margin: 0; font-size: 16px"
        >
          Filter :
        </span>
        <span>
          <v-chip
            v-if="id_provinsi != ''"
            class="ma-2"
            close
            @click:close="
              id_provinsi = '';
              id_kabkot = '';
              id_kelurahan = '';
              id_kecamatan = '';
              kabupatens = '';
              kelurahans = '';
              kecamatans = '';
            "
          >
            {{ id_provinsi }}
          </v-chip>
          <v-chip
            v-if="id_kabkot != ''"
            class="ma-2"
            close
            @click:close="
              id_kabkot = '';
              id_kelurahan = '';
              id_kecamatan = '';
              kelurahans = '';
              kecamatans = '';
            "
          >
            {{ id_kabkot }}
          </v-chip>
          <v-chip
            v-if="id_kecamatan != ''"
            class="ma-2"
            close
            @click:close="
              id_kecamatan = '';
              id_kelurahan = '';
              kelurahans = '';
            "
          >
            {{ id_kecamatan }}
          </v-chip>
          <v-chip
            v-if="id_kelurahan != ''"
            class="ma-2"
            close
            @click:close="id_kelurahan = ''"
          >
            {{ id_kelurahan }}
          </v-chip>
        </span>
        <v-row class="mt-5">
          <v-spacer></v-spacer>
          <v-col cols="6" class="flex justify-end text-right mb-2">
            <v-btn
              color="#F54C4C"
              class="button-signup ml-5"
              type="submit"
              to="/tambah-berita"
              v-show="userData.role != 'warga'"
              >Buat Berita</v-btn
            >
          </v-col>
        </v-row>
        <div v-if="newsData != ''">
          <v-row v-for="(item, index) in newsData.data.data" :key="index">
            <v-col cols="5">
              <v-card class="mb-6" @click="detailNews(item)">
                <v-img
                  class="white--text align-end"
                  height="200px"
                  :src="item.photo"
                >
                </v-img>
              </v-card>
            </v-col>
            <v-col cols="7"  @click="detailNews(item)">
              <div class="truncate mb-5" style="font-weight: bold">
                {{ item.judul }}
              </div>
              <div class="truncate-content" v-html="item.konten"></div>
            </v-col>
          </v-row>
        </div>
        <div class="text-center">
          <v-pagination
            :loading="loading"
            v-model="page"
            :length="totalPages"
            total-visible="7"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          ></v-pagination>
        </div>
      </div>
    </v-container>
    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import { getData } from "../service/ApiService";
export default {
  name: "DetailNews",
  components: {
    Footer,
    Header,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      model: null,
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      id_provinsi: "",
      id_kabkot: "",
      id_kelurahan: "",
      id_kecamatan: "",
      search: "",
      token_jwt: "",
      menu: false,
      msg: "",
      loading: false,
      page: 1,
      newsData: "",
      startTime: "July 7, 2017 12:03:00",
      endTime: "July 9, 2022 14:55:00",
      userData: "",
      times: [
        { id: 0, text: "Days", time: 1 },
        { id: 1, text: "Hours", time: 1 },
        { id: 2, text: "Minutes", time: 1 },
        { id: 3, text: "Seconds", time: 1 },
      ],
      progress: 100,
      // isActive: false,
      timeinterval: undefined,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataNews.created_at
        ? moment(this.dataNews.created_at).format("dddd, d MMM YYYY HH:m A")
        : "";
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
    },
  },
  mounted() {},
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.getDataNews();
    this.getListProvinsi();
  },
  methods: {
    detailNews(data) {
      this.$router.push({
        name: "DetailNews",
        params: {
          dataNews: data,
        },
      });
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    enewsFinish() {
      this.$router.push({
        name: "ENewsFinish",
      });
    },
    tambahBerita() {
      this.$router.push({
        name: "tambah-berita",
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.getDataNewsFilter();
    },
    getDataNewsFilter() {
      if (this.search != "") {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/news" +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&kel=" +
            this.id_kelurahan +
            "&keyword=" +
            this.search;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/news" +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&keyword=" +
            this.search;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/news" +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&keyword=" +
            this.search;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/news" +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&keyword=" +
            this.search;
        } else {
          this.apiGetFilter =
            "api/news" + "?page=" + this.page + "&keyword=" + this.search;
        }
      } else {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/news" +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&kel=" +
            this.id_kelurahan;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/news" +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/news" +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/news" + "?page=" + this.page + "&prov=" + this.id_provinsi;
        } else {
          this.apiGetFilter = "api/news" + "?page=" + this.page;
        }
      }

      getData(this.apiGetFilter)
        .then((response) => {
          console.log("data :", response);
          this.newsData = response;
          this.totalPages = response.data_active.last_page;
          console.log(this.penduduks);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value.id;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.id_provinsi = value.provinsi;
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kabkot = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value.id;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.id_kabkot = value.kabupaten_kota;
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value.id;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.id_kecamatan = value.kecamatan;
            this.kelurahans = "";
            this.kelurahans = response;
            this.id_kelurahan = "";

            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.id_kelurahan = value.kelurahan;
      }
    },
    SearchbarChange(value) {
      this.search = value;
      this.getDataNewsFilter();
      console.log(value);
    },
    back() {
      this.$router.back();
    },
    getDataNews() {
      const ApiGetNews = "api/news" + "?page=" + this.page;

      getData(ApiGetNews)
        .then((response) => {
          console.log("data :", response);
          this.newsData = response;

          this.totalPages = response.data.last_page;
          console.log(this.newsData);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    hasilNews(item) {
      this.$router.push({
        name: "HasilNews",
        params: {
          items: item.id_enews,
        },
      });
    },
    tambahVote() {
      this.$router.push({
        name: "TambahVote",
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY HH:SS");
      }
    },
  },
};
</script>

<style scoped>
.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}
.text-sub {
  color: #c82424;
}
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-content {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>