<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      app
      color="#F2F1F1"
      dense
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="E-Vote" icon="mdi-vote" />
    <!-- <p>{{ items }}</p> -->
    <div v-if="votingData == ''">
      <v-row style="padding: 20px 50px">
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <v-container
      style="height: 100%; margin-bottom: 100px"
      v-if="votingData != ''"
    >
      <!-- <p>{{ votingData }}</p> -->
      <p style="color: #c82424; font-size: 20px">
        Ditutup : {{ votingData.data_evoting[0].tanggal_selesai }}
      </p>
      <p style="font-size: 24px" class="text-center">
        {{ votingData.data_evoting[0].judul }}
      </p>
      <p style="font-size: 14px" class="text-center">
        {{ votingData.data_evoting[0].provinsi }},
        {{ votingData.data_evoting[0].kabupaten_kota }},
        {{ votingData.data_evoting[0].kecamatan }},
        {{ votingData.data_evoting[0].kelurahan }}
      </p>

      <v-item-group>
        <v-container>
          <v-row>
            <v-col
              v-for="item in votingData.data_candidat"
              :key="item"
              cols="12"
              md="4"
              @click="pilih(item)"
            >
              <v-item v-slot="{ active, toggle }">
                <v-hover v-slot="{ hover }">
                  <v-card
                    :color="active ? 'red' : '#DFDFDF'"
                    dark
                    height="300"
                    :elevation="hover ? 12 : 2"
                    :class="{ 'on-hover': hover }"
                    @click="toggle"
                  >
                    <v-img
                      :src="item.photo"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="200px"
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                        color="primary"
                      >
                        <v-scale-transition v-if="active">
                          <v-card
                            outlined
                            shaped
                            class="text-h6"
                            style="background-color: red; padding: 10px"
                          >
                            Pilihan Anda
                            <v-icon
                              color="white"
                              size="36"
                              v-text="'mdi-checkbox-marked-circle-outline'"
                            ></v-icon>
                          </v-card>
                        </v-scale-transition>
                      </v-row>
                    </v-img>
                    <div>
                      <v-card-subtitle>
                        <p
                          class="text-sub"
                          v-bind:style="[
                            active ? { color: 'white' } : { color: 'black' },
                          ]"
                        >
                          {{ item.peserta }}
                        </p>
                      </v-card-subtitle>
                    </div>
                  </v-card>
                </v-hover>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>

      <div class="text-end">
        <v-btn
          color="red"
          class="button-signup ma-10"
          large
          @click="dialogConfirmation = true"
          :loading="dialog"
        >
          Kirim
        </v-btn>
      </div>
      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-card color="red" dark>
          <v-card-title></v-card-title>
          <v-card-text class="text-center">
            Mohon tunggu..
            <v-progress-circular
              indeterminate
              color="white"
              class="ml-6"
            ></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar.visible"
        multi-line="true"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
        centered
        auto-height
      >
        <v-layout align-center pr-4>
          <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
          <v-layout column>
            <div>
              <strong> {{ snackbar.status }}</strong>
            </div>
            <div>{{ msg }}</div>
          </v-layout>
        </v-layout>
        <v-btn
          v-if="snackbar.timeout === 0"
          icon
          @click="snackbar.visible = false"
        >
          <v-icon>clear</v-icon>
        </v-btn>
      </v-snackbar>
      <v-dialog v-model="dialogConfirmation" max-width="290">
        <v-card>
          <v-card-title class="text-h5"> Konfirmasi </v-card-title>

          <v-card-text> Apakah anda yakin dengan pilihan anda? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="postDataVoting()">
              Ya
            </v-btn>

            <v-btn
              color="red darken-1"
              text
              @click="dialogConfirmation = false"
            >
              Tidak
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import { postData } from "../service/ApiService";

export default {
  name: "DetailVoting",
  props: ["votingData"],
  components: {
    Footer,
    Header,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      icons: ["mdi-rewind", "mdi-play", "mdi-fast-forward"],
      transparent: "rgba(255, 255, 255, 0)",
      model: null,
      token_jwt: "",
      menu: false,
      msg: "",
      overlay: false,
      selected: null,
      userData: "",
      dialog: false,
      dialogConfirmation: false,
      snackbar: {
        timeout: 3000,
        visible: false,
        color: "",
        icon: "",
        status: "",
      },
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataNews.created_at
        ? moment(this.dataNews.created_at).format("dddd, d MMM YYYY HH:m A")
        : "";
    },
  },
  mounted() {},
  created() {
    console.log(this.votingData);
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
  },
  methods: {
    pilih(data) {
      if (this.selected != data.id_peserta) {
        this.selected = data.id_peserta;
      } else {
        this.selected = null;
      }
      console.log(this.selected);
    },
    back() {
      this.$router.back();
    },
    postDataVoting() {
      this.dialog = true;

      let formData = new FormData();
      formData.append("id_evoting", this.votingData.data_evoting[0].id_evoting);
      formData.append("id_peserta", this.selected);
      formData.append("nik_pemilih", this.userData.nik);
      if (this.selected != "" && this.selected != null) {
        const data = {
          api: "api/voting-respone",
          body: formData,
        };

        postData(data)
          .then((response) => {
            this.dialogConfirmation = false;
            console.log("response :", response);
            this.dialog = false;
            this.msg = "Terima kasih telah melakukan voting.";
            this.snackbar.status = "Berhasil";
            this.snackbar.color = "green accent-4";
            this.snackbar.icon = "mdi-check-circle-outline";
            this.snackbar.visible = true;
            this.$router.go(-1);
          })
          .catch((error) => {
            this.dialog = false;
            this.dialogConfirmation = false;
            this.snackbar.color = "red accent-2";
            this.snackbar.icon = "mdi-alert-octagon-outline";
            this.snackbar.status = "Gagal";
            if (error.response) {
              this.snackbar.visible = true;
              console.log(error.response.data.nik_pemilih[0]);
              if (
                error.response.data.nik_pemilih ==
                "nik pemilih anda telah melakukan voting"
              ) {
                this.msg = "Anda telah melakukan voting.";
              } else {
                this.msg = error.response.data;
              }
            }
          });
      } else {
        this.dialog = false;
        this.dialogConfirmation = false;
        this.snackbar.visible = true;
        this.snackbar.color = "red accent-2";
        this.snackbar.icon = "mdi-alert-octagon-outline";
        this.snackbar.status = "Gagal";
        this.msg = "Anda belum memilih Kandidat.";
      }
    },
  },
};
</script>

<style scoped>
.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.text-sub {
  text-align: center;
  color: #000000;
  font-size: 20px;
}

.v-alert {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

.truncate {
  line-height: 1.5em;
  height: 3em; /* height is 2x line-height, so two lines will display */
  overflow: hidden; /* prevents extra lines from being visible */
}
</style>