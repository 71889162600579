<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      app
      color="#F2F1F1"
      dense
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="E-Survey" icon="mdi-chart-box-outline" />
    <!-- <p>{{ items }}</p> -->
    <div v-if="surveyData == ''">
      <v-row style="padding: 20px 50px">
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <v-container
      style="height: 100%; margin-bottom: 100px"
      v-if="surveyData != ''"
    >
      <p style="font-size: 24px" class="text-center">
        {{ items.judul_survey }}
      </p>
      <p style="font-size: 14px" class="text-center">
        {{ items.provinsi }}, {{ items.kabupaten_kota }}, {{ items.kecamatan }},
        {{ items.kelurahan }}
      </p>
      <p style="font-size: 16px" class="text-end">
        Jumlah Suara :
        <span style="color: #0079d1">
          {{ surveyData.count }}
        </span>
      </p>
      <v-card
        v-for="(item, index) in surveyData.data"
        :key="index"
        class="mb-10"
      >
        <v-card-title style="background-color: #fecbcb">
          {{ item.pertanyaan[0].pertanyaan }}
        </v-card-title>

        <v-container style="background-color: #f2f2f2">
          <div v-if="item.pertanyaan[0].jenis != 'jawaban singkat'">
            <v-row v-if="item.pilihan.length == 0">
              <v-col cols="12">
                <p style="margin: 0">Belum ada jawaban.</p>
              </v-col>
            </v-row>
            <v-row
              v-else
              v-for="(itemSurvey, index) in item.pilihan"
              :key="index"
              style="padding-top: 10px"
            >
              <v-col cols="8">
                <p>{{ itemSurvey.opsi_text }}</p>
              </v-col>
              <v-col cols="4" class="text-center">
                <p style="color: #0079d1">
                  {{ itemSurvey.count }}
                </p>
              </v-col>
            </v-row>

            <pie-chart
              v-if="item.pilihan.length != 0"
              :kandidat_final="setDataSurvey(item)"
              :options="chartOptions"
            ></pie-chart>
          </div>

          <v-row v-if="item.pertanyaan[0].jenis == 'jawaban singkat'">
            <v-col
              cols="12"
              v-for="(itemEssay, index) in item.jawaban"
              :key="index"
            >
              <p style="margin: 0">{{ itemEssay.opsi_text }}</p>
            </v-col>
            <v-col cols="12" v-if="item.jawaban.length == 0">
              <p style="margin: 0">Belum ada jawaban.</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>

    <Footer />
  </v-card>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.9.4/Chart.min.js"></script>
<script src="https://unpkg.com/vue-chartjs/dist/vue-chartjs.min.js"></script>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import { getData, postData } from "../service/ApiService";
import PieChart from "../PieChart.js";

const axios = require("axios");

export default {
  name: "HasilSurvey",
  props: ["items"],
  components: {
    Footer,
    Header,
    PieChart,
  },
  beforeCreate: function () {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      if (this.token_jwt == null) {
        this.$router.push("/");
      }
    }
  },
  data() {
    return {
      dataSurvey: [
        {
          peserta: "Islam",
          suara: 3,
          warna: "#db5669",
        },
        {
          peserta: "Kristen",
          suara: 4,
          warna: "#db9669",
        },
        {
          peserta: "VTuber",
          suara: 5,
          warna: "#gb9669",
        },
      ],
      chartOptions: {
        hoverBorderWidth: 20,
        responsive: true,
        plugins: {
          datalabels: {
            color: "white",
            textAlign: "center",
            font: {
              weight: "bold",
              size: 16,
            },
          },
        },
      },
      chartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: ["Green", "Red", "Blue"],
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#41B883", "#E46651", "#00D8FF"],
            data: [1, 10, 5],
          },
        ],
      },
      mycolor: "#" + ((Math.random() * 0xffffff) << 0).toString(16),
      pertanyaanArr: [],
      responses: [],
      icons: ["mdi-rewind", "mdi-play", "mdi-fast-forward"],
      transparent: "rgba(255, 255, 255, 0)",
      model: null,
      token_jwt: "",
      menu: false,
      msg: "",
      overlay: false,
      selected: null,
      surveyData: "",
      userData: "",
      dialog: false,
      objIndex: null,
      dialogConfirmation: false,
      textResponse: "",
      total_suara: "",
      snackbar: {
        timeout: 3000,
        visible: false,
        color: "",
        icon: "",
        status: "",
      },
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataNews.created_at
        ? moment(this.dataNews.created_at).format("dddd, d MMM YYYY HH:m A")
        : "";
    },
  },
  mounted() {},
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    // this.surveyData = this.items[0];

    console.log(this.items);
    this.getDataHasilSurvey();
  },
  methods: {
    generator() {
      const mycolor = "#" + ((Math.random() * 0xffffff) << 0).toString(16);

      return mycolor;
    },
    setDataSurvey(item) {
      console.log("Item Data Survey : ", item);
      var DataSurvey = [];
      item.pilihan.forEach((itemData, index) => {
        DataSurvey.push({
          peserta: itemData.opsi_text,
          suara: itemData.count,
          warna: this.generator(),
        });
      });

      return DataSurvey;
    },
    selectedResponse(event, data, jenis) {
      console.log(event);
      console.log(data);
      console.log(jenis);
      if (jenis == "ganda") {
        this.responseArr.forEach((item, index) => {
          console.log(this.surveyData[index]);
          console.log("index :", index);
          const found = this.responses.some((el) => el.id_pertanyaan == data);
          if (!found) {
            switch (true) {
              case item == "opsi_1":
                this.responses.push({
                  id_pertanyaan: data,
                  opsi_1: 1,
                  opsi_2: "",
                  opsi_3: "",
                  opsi_4: "",
                  nik_respon: this.userData.nik,
                  id_survey: this.items[1].id_survey,
                  jenis: this.surveyData[index].jenis,
                  text: "",
                });
                break;
              case item == "opsi_2":
                this.responses.push({
                  id_pertanyaan: data,
                  opsi_2: 1,
                  opsi_1: "",
                  opsi_3: "",
                  opsi_4: "",
                  nik_respon: this.userData.nik,
                  id_survey: this.items[1].id_survey,
                  jenis: this.surveyData[index].jenis,
                  text: "",
                });
                break;
              case item == "opsi_3":
                this.responses.push({
                  id_pertanyaan: data,
                  opsi_3: 1,
                  opsi_2: "",
                  opsi_1: "",
                  opsi_4: "",
                  nik_respon: this.userData.nik,
                  id_survey: this.items[1].id_survey,
                  jenis: this.surveyData[index].jenis,
                  text: "",
                });
                break;
              case item == "opsi_4":
                this.responses.push({
                  id_pertanyaan: data,
                  opsi_4: 1,
                  opsi_2: "",
                  opsi_3: "",
                  opsi_1: "",
                  nik_respon: this.userData.nik,
                  id_survey: this.items[1].id_survey,
                  jenis: this.surveyData[index].jenis,
                  text: "",
                });
                break;

              default:
                break;
            }
          } else {
            console.log(index);
            console.log(this.responses);
            console.log(this.responses[index]);
            console.log(item);
            switch (true) {
              case item == "opsi_1":
                this.responses[index].opsi_2 = "";
                this.responses[index].opsi_3 = "";
                this.responses[index].opsi_4 = "";
                this.responses[index].opsi_1 = 1;
                break;
              case item == "opsi_2":
                this.responses[index].opsi_4 = "";
                this.responses[index].opsi_3 = "";
                this.responses[index].opsi_1 = "";
                this.responses[index].opsi_2 = 1;
                break;
              case item == "opsi_3":
                this.responses[index].opsi_2 = "";
                this.responses[index].opsi_4 = "";
                this.responses[index].opsi_1 = "";
                this.responses[index].opsi_3 = 1;
                break;
              case item == "opsi_4":
                this.responses[index].opsi_2 = "";
                this.responses[index].opsi_3 = "";
                this.responses[index].opsi_1 = "";
                this.responses[index].opsi_4 = 1;
                break;

              default:
                break;
            }
          }
          console.log("data foreach : ", item, index, this.responses);
        });
      } else if (jenis == "essay") {
        this.responseArr.forEach((item, index) => {
          console.log(this.responseArr[index]);
          console.log(this.surveyData.data[index]);
          console.log("index :", index);
          const found = this.responses.some((el) => el.id_pertanyaan == data);
          if (!found) {
            this.responses.push({
              id_pertanyaan: data,
              opsi_1: "",
              opsi_2: "",
              opsi_3: "",
              opsi_4: "",
              nik_respon: this.userData.nik,
              id_survey: this.items[1].id_survey,
              jenis: "essay",
              text: this.responseArr[index],
            });
          } else {
            console.log(index);
            console.log(jenis);
            console.log(this.responses);
            console.log(this.responses[index]);
            console.log(this.responses[index].jenis);
            if (this.responses[index].jenis == "essay") {
              this.responses[index].text = this.responseArr[index];
            }
          }
          console.log("data foreach : ", item, index, this.responses);
        });
      }
    },
    submitResponse() {
      this.dialog = true;
      console.log("response array :", this.responses);

      let formData = new FormData();
      formData.append("id_evoting", this.items);
      formData.append("id_peserta", this.selected);
      formData.append("nik_pemilih", this.userData.nik);
      const data = {
        api: "api/vote",
        body: formData,
      };

      postData(data)
        .then((response) => {
          this.dialogConfirmation = false;
          console.log("response :", response);
          this.dialog = false;
          this.msg = "Terima kasih telah melakukan voting.";
          this.snackbar.status = "Berhasil";
          this.snackbar.color = "green accent-4";
          this.snackbar.icon = "mdi-check-circle-outline";
          this.snackbar.visible = true;
        })
        .catch((error) => {
          this.dialog = false;
          this.dialogConfirmation = false;
          this.snackbar.color = "red accent-2";
          this.snackbar.icon = "mdi-alert-octagon-outline";
          this.snackbar.status = "Gagal";
          if (error.response) {
            this.snackbar.visible = true;
            console.log(error.response.data.nik_pemilih[0]);
            if (
              error.response.data.nik_pemilih ==
              "nik pemilih anda telah melakukan voting"
            ) {
              this.msg = "Anda telah melakukan voting.";
            } else {
              this.msg = error.response.data;
            }
          }
        });
    },
    pilih(data) {
      if (this.selected != data.id_peserta) {
        this.selected = data.id_peserta;
      } else {
        this.selected = null;
      }
      console.log(this.selected);
    },
    back() {
      this.$router.back();
    },
    getDataHasilSurvey() {
      console.log(this.items);
      const ApiGetVoting = "api/result/survey/" + this.items.id_survey;

      getData(ApiGetVoting)
        .then((response) => {
          console.log("data :", response);

          this.surveyData = response;
          console.log(this.surveyData);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    groupBy(xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    postDataSurvey() {
      this.dialog = true;

      const data = {
        api: "api/survy/respon",
        body: this.responses,
      };

      axios
        .post("https://dev.smartrt.id/" + data.api, data.body, {
          headers: {
            Authorization: `Bearer ${localStorage.token_jwt}`,
          },
        })
        .then((response) => {
          this.dialogConfirmation = false;
          console.log("response :", response);
          this.dialog = false;
          this.msg = "Terima kasih telah mengisi survey.";
          this.snackbar.status = "Berhasil";
          this.snackbar.color = "green accent-4";
          this.snackbar.icon = "mdi-check-circle-outline";
          this.snackbar.visible = true;
          this.$router.push("/e-survey");
        })
        .catch((error) => {
          this.dialog = false;
          this.dialogConfirmation = false;
          this.snackbar.color = "red accent-2";
          this.snackbar.icon = "mdi-alert-octagon-outline";
          this.snackbar.status = "Gagal";
          if (error.response) {
            this.snackbar.visible = true;
            console.log(error.response.data.nik_pemilih[0]);
            if (
              error.response.data.nik_pemilih == "Anda telah mengisi survey"
            ) {
              this.msg = "Anda telah mengisi survey.";
            } else {
              this.msg = error.response.data;
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.text-sub {
  text-align: center;
  color: #000000;
  font-size: 20px;
}

.v-alert {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

.truncate {
  line-height: 1.5em;
  height: 3em; /* height is 2x line-height, so two lines will display */
  overflow: hidden; /* prevents extra lines from being visible */
}
</style>
