<template>
<v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>{{userData.nama}}</v-list-item-title>

        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
  import {
    getData
  } from "../service/ApiService";
  import moment from "moment";

  // import TimeAgo from "javascript-time-ago";
  // import id from "javascript-time-ago/locale/id.json";

  // TimeAgo.addDefaultLocale(id);

  // import Tombol from '../components/Tombol.vue';
  // @ is an alias to /src
  export default {
    props: ["id"],
    data() {
      return {
        drawer: true,
        items: [ 
          { title: 'Data Penduduk', icon: 'mdi-account-group' },
          { title: 'E-Vote', icon: 'mdi-vote' },
          { title: 'E-Survey', icon: 'mdi-chart-box-outline' },
          { title: 'E-Lapor', icon: 'mdi-alert-octagon-outline' },
          { title: 'E-Bansos', icon: 'mdi-hand-heart-outline' },
          { title: 'Pernikahan', icon: 'mdi-heart' },
          { title: 'Perceraian', icon: 'mdi-heart-broken' },
          { title: 'Kelahiran', icon: 'mdi-baby-carriage' },
          { title: 'Lansia', icon: 'mdi-human-white-cane' },
          { title: 'Kematian/pemakaman', icon: 'mdi-account-group' },
          { title: 'Gakin', icon: 'mdi-hand-coin' },
          { title: 'Pariwisata', icon: 'mdi-earth' },
          { title: 'Berita', icon: 'mdi-newspaper' },
          { title: 'Lowongan Pekerjaan', icon: 'mdi-briefcase' },
          { title: 'Peternakan', icon: 'mdi-barn' },
          { title: 'UMKM', icon: 'mdi-storefront' },
          { title: 'Perikanan', icon: 'mdi-fish' },
          { title: 'Pertanian', icon: 'mdi-tractor-variant' },
          { title: 'Iklan', icon: 'mdi-advertisements' },
          { title: 'Broadcast', icon: 'mdi-broadcast' },
          { title: 'Prestasi Warga', icon: 'mdi-seal' },
          { title: 'Putus Sekolah', icon: 'mdi-school' },
          
        ],
        mini: true,
        activeTab: this.$route.path,
        tabs: [{
            id: 1,
            name: "Beranda",
            route: `/home/beranda`,
            icon: "mdi-home"
          },
          {
            id: 2,
            name: "RT",
            route: `/home/rt`,
            icon: "mdi-account-tie"
          },
          {
            id: 3,
            name: "Penduduk",
            route: `/home/penduduk`,
            icon: "mdi-account-group",
          },
        ],
        dialogConfirmation: false,
        userData: "",
        token_jwt: "",
        loading: false,
        msg: null,
        news: "",
        totalPages: "",
        ads: null,
        dialogProfile: false,
        imgs: [{
            src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
          },
          {
            src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
          },
          {
            src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
          },
          {
            src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
          },
          {
            src: require("../assets/slide-1.png"),
          },
        ],
      };
    },
    name: "Dashboard",
    components: {
    },
    beforeCreate: function () {
      console.log('beranda')
   if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
    },
    created() {
      console.log(this.$route.path);
    },
    mounted() {
      if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
      }
      if (localStorage.user_data) {
        this.userData = JSON.parse(localStorage.user_data).data[0];
        console.log("data user: ", this.userData);
      }
    },
    computed: {
      computedDateFormatedMomentjs() {
        return this.userData.tgl_lahir ?
          moment(this.userData.tgl_lahir).format("DD, MMMM YYYY") :
          "";
      },
    },
    methods: {
      format_date(value) {
        if (value) {
          return moment(String(value)).format("dddd, D MMM YYYY HH:m A");
        }
      },
      // userDataat_news(value){
      //    if (value) {
      //      return value ? timeAgo.userDataat(Date.now() - 60 * 1000);
      //     }
      detailAds(data) {
        window.open(data.link, "_blank");
      },
      // },
      detailNews(data) {
        localStorage.data_news = JSON.stringify(data);
        this.$router.push({
          name: "DetailNews",
          params: {
            dataNews: data,
          },
        });
      },
      changeTab() {
        this.activeTab = this.$route.path;
        console.log(this.activeTab);
      },
      getAds() {
        const ApiGetAds = "api/ads-list";

        getData(ApiGetAds)
          .then((response) => {
            console.log("data :", response);
            this.ads = response;
            console.log(this.ads);
            this.loading = false;
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      },
      getNews() {
        const ApiGetNews = "api/news" + "?page=" + this.page;

        getData(ApiGetNews)
          .then((response) => {
            console.log("data :", response);
            this.news = response.data;
            this.totalPages = response.last_page;
            console.log(this.penduduks);
            this.loading = false;
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      },
      logout() {
        this.$session.destroy();
        this.$router.push("/");
        console.log(localStorage.token_jwt);
        localStorage.token_jwt = null;
        console.log(localStorage.token_jwt);
      },
    },
  };
</script>

<style scoped>
  .text-title {
    text-decoration: none;
    color: black;
    margin: 0 22px 0 0;
    font-size: 18px;
    font-weight: 500;
  }

  .img-logo {
    height: 80%;
    width: auto;
    margin-right: 20px;
  }

  .truncate {

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .truncate-content {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
</style>