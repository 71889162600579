<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil membuat voting.
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="red" dark>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          Mohon tunggu..
          <v-progress-circular
            indeterminate
            color="white"
            class="ml-6"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card style="margin-bottom: 100px; margin-bottom: 100px">
      <v-app-bar app color="#F2F1F1" dense>
        <v-btn color="black" icon @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-app-bar-title>Buat Survey</v-app-bar-title>
      </v-app-bar>
      <Header text="E-Survey" icon="mdi-chart-box-outline" />
      <v-stepper v-model="e1">
        <v-stepper-header>
          <template>
            <v-stepper-step :step="1" editable :complete="e1 > 1">
              Buat e-Voting
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :step="2" editable :complete="e1 > 2">
              Tambah Pertanyaan
            </v-stepper-step>

            <v-divider></v-divider>
            <v-stepper-step :step="3" editable :complete="e1 > 3">
              Tambah Peserta
            </v-stepper-step>

            <v-divider></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content :step="1">
            <v-container>
              <form
                id="myForm"
                name="myForm"
                v-on:submit.prevent="nextStep(1)"
                enctype="multipart/form-data"
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-book-account"
                      label="Judul"
                      required
                      v-model="form.judul"
                      hint="Judul Voting"
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-menu
                      ref="menu"
                      v-model="menu_tanggal_1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      :rules="[rules.required]"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Tanggal Mulai"
                          prepend-icon="mdi-calendar"
                          readonly
                          :rules="[rules.required]"
                          :value="TglMulaiMomentjs"
                          v-bind="attrs"
                          required
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :rules="[rules.required]"
                        v-model="form.tanggal_mulai"
                        :active-picker.sync="activePicker"
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-menu
                      ref="menu"
                      v-model="menu_waktu_1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.waktu_mulai"
                          label="Waktu Mulai"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu_waktu_1"
                        v-model="form.waktu_mulai"
                        full-width
                        @click:minute="$refs.menu.save(time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-menu
                      ref="menu"
                      v-model="menu_tanggal_2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      :rules="[rules.required]"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Tanggal Selesai"
                          prepend-icon="mdi-calendar"
                          readonly
                          :rules="[rules.required]"
                          :value="TglSelesaiMomentjs"
                          v-bind="attrs"
                          required
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        :rules="[rules.required]"
                        v-model="form.tanggal_selesai"
                        :active-picker.sync="activePicker"
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-menu
                      ref="menu"
                      v-model="menu_waktu_2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          required
                          v-model="form.waktu_selesai"
                          label="Waktu Selesai"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu_waktu_2"
                        v-model="form.waktu_selesai"
                        full-width
                        @click:minute="$refs.menu.save(time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="form.id_provinsi"
                      :items="provinsis"
                      label="Provinsi"
                      :rules="[rules.required]"
                      item-value="id"
                      item-text="provinsi"
                      required
                      @change="(event) => selectedProvinsi(event)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="form.id_kabkot"
                      :rules="[rules.required]"
                      :items="kabupatens"
                      label="Kabupaten/ Kota"
                      item-value="id"
                      item-text="kabupaten_kota"
                      :disabled="kabupatens == ''"
                      required
                      @change="(event) => selectedKabupaten(event)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="form.id_kecamatan"
                      :items="kecamatans"
                      label="Kecamatan"
                      :rules="[rules.required]"
                      item-text="kecamatan"
                      item-value="id"
                      :disabled="kecamatans == ''"
                      required
                      @change="(event) => selectedKecamatan(event)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="form.id_kelurahan"
                      :items="kelurahans"
                      :rules="[rules.required]"
                      item-value="id"
                      label="Kelurahan"
                      :disabled="kelurahans == ''"
                      item-text="kelurahan"
                      required
                      @change="(event) => selectedKelurahan(event)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-map-marker"
                      :rules="[rules.required]"
                      label="RW"
                      v-model="form.id_rw"
                      hint="RW Sesuai Tempat Tinggal Saat Ini"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-map-marker"
                      label="RT"
                      v-model="form.id_rt"
                      hint="RT Sesuai Tempat Tinggal Saat Ini"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-btn
                    style="color: white"
                    color="green"
                    type="submit"
                    value="Submit"
                    :loading="loading"
                    block
                  >
                    Simpan
                  </v-btn>
                </v-row>
              </form>
            </v-container>
            <!-- <v-btn color="primary" @click="nextStep(2)"> Continue </v-btn>
          <v-btn text> Cancel </v-btn> -->
          </v-stepper-content>
          <v-stepper-content :step="2">
            <v-container>
              <div
                v-for="(textField, indexTextField) in textFields"
                :key="indexTextField"
                class="text-fields-row mb-10"
                style="
                  border: 2px solid #5f5757;
                  border-radius: 25px;
                  padding: 10px 25px;
                  background-color: #f2f2f2;
                "
              >
                <v-row no-gutters>
                  <v-col cols="6">
                    <p style="color: #ff0000">
                      Pertanyaan {{ indexTextField + 1 }}
                    </p>
                  </v-col>
                  <v-col cols="6" class="text-end">
                    <v-btn
                      icon
                      @click="remove(indexTextField)"
                      class="button-signup"
                      color="red"
                      ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-text-field
                  prepend-icon="mdi-message-question-outline"
                  label="Pertanyaan"
                  v-model="textField.pertanyaan"
                ></v-text-field>
                <v-autocomplete
                  style="text-transform: capitalize"
                  prepend-icon="mdi-pencil-box-outline"
                  v-model="textField.jenis"
                  :items="jenis_pertanyaan"
                  @change="
                    (event) => selectJenisPertanyaan(event, indexTextField)
                  "
                  label="Jenis Pertanyaan"
                  :rules="[rules.required]"
                  required
                ></v-autocomplete>
                <div
                  v-if="
                    textField.jenis == 'pilihan ganda' ||
                    textField.jenis == 'kotak centang'
                  "
                >
                  <div v-for="(textFieldOpsi, i) in textField.opsi" :key="i">
                    <v-row>
                      <v-col cols="11">
                        <v-text-field
                          prepend-icon="mdi-alpha-a-box"
                          label="Opsi"
                          v-model="textFieldOpsi.opsi_text"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1" class="text-end">
                        <v-btn
                          icon
                          @click="removeOpsi(indexTextField, i)"
                          class="button-signup"
                          color="red"
                          ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                  <div class="text-center">
                    <v-btn
                      @click="addOpsi(indexTextField)"
                      class="button-signup"
                      color="red"
                      :loading="loadingAddBtn"
                      >Tambah Opsi +</v-btn
                    >
                  </div>
                </div>
              </div>
            </v-container>
            <v-speed-dial
              v-model="fab"
              absolute
              bottom
              right
              direction="top"
              :open-on-hover="hover"
              :transition="transition"
            >
              <template v-slot:activator>
                <v-btn v-model="fab" color="red darken-2" dark fab>
                  <v-icon v-if="fab"> mdi-close </v-icon>
                  <v-icon v-else> mdi-dots-vertical </v-icon>
                </v-btn>
              </template>
              <v-btn fab dark small color="green" @click="nextStep(2)">
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn fab dark small color="blue" @click="add()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-speed-dial>
          </v-stepper-content>
          <v-stepper-content :step="3">
            <v-container>
              <v-expansion-panels
                v-show="
                  userData.role == 'superadmin' || userData.role == 'admin'
                "
              >
                <v-expansion-panel>
                  <v-expansion-panel-header> Filter </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      label="Provinsi"
                      v-model="id_provinsiFilter"
                      :items="provinsis"
                      item-text="provinsi"
                      return-object
                      required
                      @change="(event) => selectedProvinsiFilter(event)"
                    ></v-autocomplete>
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="id_kabkotFilter"
                      :items="kabupatensFilter"
                      label="Kabupaten/ Kota"
                      item-value="id"
                      item-text="kabupaten_kota"
                      return-object
                      :disabled="kabupatensFilter == ''"
                      required
                      @change="(event) => selectedKabupatenFilter(event)"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="id_kecamatanFilter"
                      :items="kecamatansFilter"
                      label="Kecamatan"
                      item-text="kecamatan"
                      return-object
                      :disabled="kecamatansFilter == ''"
                      required
                      @change="(event) => selectedKecamatanFilter(event)"
                    ></v-autocomplete>
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="id_kelurahanFilter"
                      :items="kelurahansFilter"
                      label="Kelurahan"
                      return-object
                      :disabled="kelurahansFilter == ''"
                      item-text="kelurahan"
                      required
                      @change="(event) => selectedKelurahanFilter(event)"
                    ></v-autocomplete>
                    <v-text-field
                      v-model="id_rw"
                      prepend-icon="mdi-map-marker"
                      label="RW"
                      single-line
                      hide-details
                      style="width: 100%"
                      class="mb-5"
                      type="number"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="id_rt"
                      prepend-icon="mdi-map-marker"
                      label="RT"
                      single-line
                      hide-details
                      type="number"
                      style="width: 100%"
                      class="mb-5"
                    >
                    </v-text-field>
                    <v-btn
                      color="red"
                      class="button-signup"
                      block
                      v-on:click="getDataPenduduk()"
                      >Submit</v-btn
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari Nik, Nama, KK, dsb.."
                single-line
                filled
                outlined
                hide-details
                @input="getDataPenduduk()"
                style="width: 100%"
                class="mb-5"
              >
              </v-text-field>
              <span
                v-if="id_provinsiFilter != ''"
                style="color: black; margin: 0; font-size: 16px"
              >
                Filter :
              </span>
              <span>
                <v-chip
                  v-if="id_provinsiFilter != ''"
                  class="ma-2"
                  close
                  @click:close="
                    id_provinsiFilter = '';
                    id_kabkotFilter = '';
                    id_kelurahanFilter = '';
                    id_kecamatanFilter = '';
                    id_provinsiFilterValue = '';
                    id_kabkotFilterValue = '';
                    id_kelurahanFilterValue = '';
                    id_kecamatanFilterValue = '';
                    kabupatensFilter = '';
                    kelurahansFilter = '';
                    kecamatansFilter = '';
                  "
                >
                  {{ id_provinsiFilter.provinsi }}
                </v-chip>
                <v-chip
                  v-if="id_kabkotFilter != ''"
                  class="ma-2"
                  close
                  @click:close="
                    id_kabkotFilter = '';
                    id_kelurahanFilter = '';
                    id_kecamatanFilter = '';
                    id_kabkotFilterValue = '';
                    id_kelurahanFilterValue = '';
                    id_kecamatanFilterValue = '';
                    kelurahansFilter = '';
                    kecamatansFilter = '';
                  "
                >
                  {{ id_kabkotFilter.kabupaten_kota }}
                </v-chip>
                <v-chip
                  v-if="id_kecamatanFilter != ''"
                  class="ma-2"
                  close
                  @click:close="
                    id_kecamatanFilter = '';
                    id_kelurahanFilter = '';
                    id_kecamatanFilterValue = '';
                    id_kelurahanFilterValue = '';
                    kelurahansFilter = '';
                  "
                >
                  {{ id_kecamatanFilter.kecamatan }}
                </v-chip>
                <v-chip
                  v-if="id_kelurahanFilter != ''"
                  class="ma-2"
                  close
                  @click:close="
                    id_kelurahanFilter = '';
                    id_kelurahanFilterValue = '';
                  "
                >
                  {{ id_kelurahanFilter.kelurahan }}
                </v-chip>
              </span>
              <v-row>
                <v-col cols="12" sm="6">
                  <p>List Penduduk</p>
                  <v-card>
                    <v-list two-line v-if="dataPenduduk.data">
                      <template v-for="(item, index) in dataPenduduk.data">
                        <v-list-item :key="item.title">
                          <template>
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.nama"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-text="item.nik"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                icon
                                @click="updateListPenduduk(item)"
                                :loading="loading"
                              >
                                <v-icon color="red lighten-1"
                                  >mdi-plus-circle-outline</v-icon
                                >
                              </v-btn>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                        <v-divider :key="index"></v-divider>
                      </template>
                    </v-list>
                    <v-container v-else>
                      <p>Data tidak ada.</p>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <p>List Peserta</p>
                  <v-card>
                    <v-list two-line v-if="selected.length > 0">
                      <template v-for="(item, index) in selected">
                        <v-list-item :key="item.item.title">
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.item.nama"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="item.item.nik"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon>
                              <v-icon
                                color="red lighten-1"
                                @click="deleteListPenduduk(index, item)"
                                >mdi-close</v-icon
                              >
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider :key="index"></v-divider>
                      </template>
                    </v-list>
                    <v-container v-else>
                      <p>Data tidak ada.</p>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
              <v-btn
                class="button-signup mt-5"
                color="green"
                @click="submitForm()"
                block
                >Simpan</v-btn
              >
            </v-container>
            <!-- <div style="background-color: gray;">
              <v-chip v-for="(item, i) in friends" :key="i" class="ma-2" close @click:close="chip1 = false">
                <v-avatar left>
                  <v-img :src="item.photo"></v-img>
                </v-avatar>
                {{item.nama }}
              </v-chip>
            </div> -->
            <!-- <v-autocomplete
              v-model="friends"
              :disabled="isUpdating"
              :items="friends"
              filled
              chips
              color="blue-grey lighten-2"
              label="List Peserta Voting"
              item-text="nama"
              item-value="item"
              multiple
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeFriend(data.item)"
                >
                  <v-avatar left>
                    <v-img :src="data.item.photo"></v-img>
                  </v-avatar>
                  {{ data.item.nama }}
                </v-chip>
              </template>
           </v-autocomplete> -->
            <!-- <v-btn color="primary" @click="nextStep(3)"> Continue </v-btn> -->
            <!-- <v-btn text> Cancel </v-btn> -->
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
    <Footer />
  </div>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import { getData, postData } from "../service/ApiService";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "TambahVote",
  components: {
    Header,
    Footer,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      show1: false,
      rules: {
        required: (value) => !!value || "Wajib di isi.",
        min: (v) => v.length >= 8 || "Password minimal 8 digit",
        min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
        emailMatch: () => `The email and password you entered don't match`,
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
        return "Wajib Berupa Angka.";
      },
      snackbar: false,
      loading: false,
      jenis_pertanyaan: ["pilihan ganda", "jawaban singkat", "kotak centang"],
      snackbar_error: false,
      menu_waktu_2: false,
      menu_tanggal_1: false,
      menu_waktu_1: false,
      menu_tanggal_2: false,
      provinsis: "",
      kabupatens: "",
      e1: 1,
      steps: 3,
      textFields: [
        {
          pertanyaan: this.pertanyaanBaru,
          jenis: "pilihan ganda",
          opsi: [
            {
              opsi_text: null,
            },
          ],
        },
      ],
      selected: [],
      dataPenduduk: [],
      kelurahans: "",
      kecamatans: "",
      provinsisFilter: "",
      kabupatensFilter: "",
      kelurahansFilter: "",
      kecamatansFilter: "",
      id_provinsi: "",
      id_kabkot: "",
      id_kecamatan: "",
      id_kelurahan: "",
      id_provinsiFilter: "",
      id_kabkotFilter: "",
      id_kecamatanFilter: "",
      id_kelurahanFilter: "",
      id_provinsiFilterValue: "",
      id_kabkotFilterValue: "",
      id_kecamatanFilterValue: "",
      id_kelurahanFilterValue: "",
      id_rw: "",
      id_rt: "",
      search: "",
      form: {
        judul: null,
        tanggal_mulai: null,
        tanggal_selesai: null,
        waktu_mulai: null,
        waktu_selesai: null,
        status: null,
        nik_pembuat: null,
        id_provinsi: null,
        id_kabkot: null,
        id_kecamatan: null,
        id_kelurahan: null,
        id_rw: null,
        id_rt: null,
        password: null,
      },
      url: "",
      token_jwt: "",
      dialog: false,
      activePicker: null,
      menu: false,
      pertanyaanBaru: null,
    };
  },
  computed: {
    TglMulaiMomentjs() {
      return this.form.tanggal_mulai
        ? moment(this.form.tanggal_mulai).format("DD, MMMM YYYY")
        : "";
    },
    TglSelesaiMomentjs() {
      return this.form.tanggal_selesai
        ? moment(this.form.tanggal_selesai).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    if (this.snackbar) {
      this.hide_alert();
    }

    if (this.snackbar_error) {
      this.hide_alert_error();
    }
  },
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }

    this.form.id_rt = this.userData.id_rt;
    this.form.id_rw = this.userData.id_rw;

    this.getListProvinsi();
    this.getListKabkot();
    this.getListKecamatan();
    this.getListKelurahan();
    this.getDataPenduduk();
  },
  methods: {
    updateListPenduduk(item) {
      this.loading = true;
      this.selected.push({
        item,
      });
      console.log(item);
      console.log(this.selected);
      this.getDataPenduduk();
    },
    deleteListPenduduk(indexItem, item) {
      this.loading = true;
      // const index = this.selected.indexOf(indexItem);
      this.selected.splice(indexItem, 1);
      console.log(item);
      console.log(this.selected);
      this.getDataPenduduk();
    },
    selectJenisPertanyaan(event, indexTextField) {
      if (event == "jawaban singkat") {
        console.log(this.textFields[indexTextField].opsi);
        this.textFields[indexTextField].opsi = [];
        console.log(this.textFields[indexTextField]);
      }
      console.log(event, indexTextField);
    },
    addOpsi(i) {
      this.textFields[i].opsi.push({
        opsi_text: null,
      });
      console.log(i);
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    add() {
      this.textFields.push({
        pertanyaan: this.pertanyaanBaru,
        jenis: "pilihan ganda",
        opsi: [
          {
            opsi_text: null,
          },
        ],
      });
    },
    removeOpsi(indexTextField, i) {
      console.log(this.textFields[indexTextField].opsi);
      this.textFields[indexTextField].opsi.splice(i, 1);
      console.log(this.textFields[indexTextField].opsi);
    },
    remove(index) {
      this.textFields.splice(index, 1);
    },
    hide_alert: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    hide_alert_error: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar_error = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    getDataPenduduk() {
      console.log(this.selected);
      let formData = new FormData();
      this.selected.forEach((item, index) => {
        formData.append("list[" + index + "]", item.item.nik);
      });

      const apiListPenduduk = {
        api:
          "api/search/nik" +
          "?prov=" +
          this.id_provinsiFilterValue +
          "&kab=" +
          this.id_kabkotFilterValue +
          "&kec=" +
          this.id_kecamatanFilterValue +
          "&kel=" +
          this.id_kelurahanFilterValue +
          "&rw=" +
          this.id_rw +
          "&rt=" +
          this.id_rt +
          "&keyword=" +
          this.search,
        body: formData,
      };

      postData(apiListPenduduk)
        .then((response) => {
          console.log("data list penduduk :", response);
          if (response.data == "data tidak ditemukan") {
            this.dataPenduduk = [];
          } else {
            this.dataPenduduk = response;
          }
          this.loading = false;
          // this.provinsis = response;
          // this.form.id_provinsi = this.userData.id_provinsi;
          // console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          this.form.id_provinsi = this.userData.id_provinsi;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getListKabkot() {
      const apiListKabkot = "api/kabkot?keyword=" + this.userData.id_provinsi;

      getData(apiListKabkot)
        .then((response) => {
          console.log("data kabkot :", response);
          this.kabupatens = response;
          console.log(this.kabupatens);
          this.form.id_kabkot = this.userData.id_kabkot;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },
    getListKecamatan() {
      const apiListKecamatan = "api/kec?keyword=" + this.userData.id_kabkot;

      getData(apiListKecamatan)
        .then((response) => {
          console.log("data kecamatan :", response);
          this.kecamatans = response;
          this.form.id_kelurahan = this.userData.id_kelurahan;
          console.log(this.kecamatans);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },
    getListKelurahan() {
      const apiListKelurahan = "api/kel?keyword=" + this.userData.id_kecamatan;
      getData(apiListKelurahan)
        .then((response) => {
          console.log("data kelurahan :", response);
          console.log(this.form.id_kelurahan);
          this.kelurahans = response;
          this.form.id_kecamatan = this.userData.id_kecamatan;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.form.id_provinsi = value;
            this.form.id_kabkot = "";
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.form.id_kabkot = value;
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.form.id_kecamatan = value;
            this.form.id_kelurahan = "";
            this.kelurahans = "";

            this.kelurahans = response;
            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.form.id_kelurahan = value;
      }
    },
    selectedProvinsiFilter(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value.id;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.id_provinsiFilter = value;
            this.id_provinsiFilterValue = value.id;
            this.id_kabkotFilter = "";
            this.id_kelurahanFilter = "";
            this.id_kecamatanFilter = "";
            this.kabupatensFilter = "";
            this.kelurahansFilter = "";
            this.kecamatansFilter = "";

            this.kabupatensFilter = response;
            console.log(this.kabupatensFilter);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupatenFilter(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value.id;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.id_kabkotFilter = value;
            this.id_kabkotFilterValue = value.id;
            this.id_kelurahanFilter = "";
            this.id_kecamatanFilter = "";
            this.kelurahansFilter = "";
            this.kecamatansFilter = "";

            this.kecamatansFilter = response;
            console.log(this.kecamatansFilter);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatanFilter(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value.id;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.id_kecamatanFilter = value;
            this.id_kecamatanFilterValue = value.id;
            this.id_kelurahanFilter = "";
            this.kelurahansFilter = "";

            this.kelurahansFilter = response;
            console.log(this.kelurahansFilter);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahanFilter(value) {
      console.log(value);
      if (value != null) {
        this.id_kelurahanFilter = value;
        this.id_kelurahanFilterValue = value.id;
      }
    },
    submitForm() {
      //   this.loading = true;
      this.loading = true;
      console.log(this.form);
      let formData = new FormData();
      formData.append("judul_survey", this.form.judul);
      formData.append(
        "tanggal_selesai",
        this.form.tanggal_selesai + " " + this.form.waktu_selesai
      );
      formData.append(
        "tanggal_mulai",
        this.form.tanggal_mulai + " " + this.form.waktu_mulai
      );
      formData.append("nik_pembuat", this.userData.nik);
      formData.append("id_provinsi", this.form.id_provinsi);
      formData.append("id_kecamatan", this.form.id_kecamatan);
      formData.append("id_kelurahan", this.form.id_kelurahan);
      formData.append("id_kabkot", this.form.id_kabkot);
      formData.append("id_rt", this.form.id_rt);
      formData.append("id_rw", this.form.id_rw);

      this.textFields.forEach((item, index) => {
        formData.append(
          "pertanyaan[" + index + "][pertanyaan]",
          item.pertanyaan
        );

        formData.append("pertanyaan[" + index + "][jenis]", item.jenis);
        if (item.opsi.length > 0) {
          item.opsi.forEach((itemOpsi, indexOpsi) => {
            formData.append(
              "pertanyaan[" + index + "][opsi][" + indexOpsi + "]",
              itemOpsi.opsi_text
            );
          });
        }
      });
      this.selected.forEach((item, i) => {
        formData.append("list[" + i + "][nik]", item.item.nik);
      });

      console.log(formData.values());
      const data = {
        api: "api/survey",
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log(response);
          this.dialog = false;
          this.loading = false;
          this.snackbar = true;
          this.$router.push({
            name: "ESurvey",
          });
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.loading = false;
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data) {
              this.msg_error = error.response.data;
              this.snackbar_error = true;
            }
          }
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    deletePic() {
      this.url = "";
      this.$refs.file.value = "";
    },
    onFileChange(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.form.photo = e.target.files[0];
      console.log(this.form);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>