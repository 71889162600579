import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "../views/Landing.vue";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import MasterPenduduk from "../views/MasterPenduduk.vue";
import Pariwisata from "../views/Pariwisata.vue";
import Lansia from "../views/Lansia.vue";
import DetailNews from "../views/DetailNews.vue";
import EVoting from "../views/EVoting.vue";
import EVotingFinish from "../views/EVotingFinish.vue";
import DetailVoting from "../views/DetailVoting.vue";
import HasilVoting from "../views/HasilVoting.vue";
import TambahVote from "../views/TambahVote.vue";
import TambahKandidatVote from "../views/TambahKandidatVote.vue";
import TambahPertanyaanSurvey from "../views/TambahPertanyaanSurvey.vue";
import Berita from "../views/Berita.vue";
import ESurvey from "../views/ESurvey.vue";
import ESurveyFinish from "../views/ESurveyFinish.vue";
import TambahSurvey from "../views/TambahSurvey.vue";
import TambahBansos from "../views/TambahBansos.vue";
import TambahBerita from "../views/TambahBerita.vue";
import TambahELapor from "../views/TambahELapor.vue";
import TambahPariwisata from "../views/TambahPariwisata.vue";
import DetailPariwisata from "../views/DetailPariwisata.vue";
import DetailLoker from "../views/DetailLoker.vue";
import DetailBansos from "../views/DetailBansos.vue";
import LowonganPekerjaan from "../views/LowonganPekerjaan.vue";
import Gakin from "../views/Gakin.vue";
import Kelahiran from "../views/Kelahiran.vue";
import EBansos from "../views/EBansos.vue";
import EBansosAksi from "../views/EBansosAksi.vue";
import Perkawinan from "../views/Perkawinan.vue";
import Perceraian from "../views/Perceraian.vue";
import Kematian from "../views/Kematian.vue";
import Pemakaman from "../views/Pemakaman.vue";
import ELapor from "../views/ELapor.vue";
import MyLapor from "../views/MyLapor.vue";
import DetailELapor from "../views/DetailELapor.vue";
import ELaporKategori from "../views/ELaporKategori.vue";
import EditDataPenduduk from "../views/EditDataPenduduk.vue";
import EditDataPernikahan from "../views/EditDataPernikahan.vue";
import EditDataPemakaman from "../views/EditDataPemakaman.vue";
import EditDataPerceraian from "../views/EditDataPerceraian.vue";
import EditDataPariwisata from "../views/EditDataPariwisata.vue";
import PutusSekolah from "../views/PutusSekolah.vue";
import TambahLoker from "../components/TambahLoker.vue";
import DetailSurvey from "../views/DetailSurvey.vue";
import HasilSurvey from "../views/HasilSurvey.vue";
import PrestasiWarga from "../views/PrestasiWarga.vue";
import Beranda from "../views/Beranda.vue";
import RT from "../views/ProfileRT.vue";
import Penduduk from "../views/Penduduk.vue";
import Iklan from "../views/Iklan.vue";
import ResetPassword from "../views/ResetPassword.vue";
import TambahMyLapor from "../components/TambahMyLapor.vue";
import PageNotFound from "../views/Landing.vue";
import GantiPassword from "../views/GantiPassword.vue";
import SmartFarm from "../views/SmartFarm.vue";
import DetailSmartFarm from "../views/DetailSmartFarm.vue";
import Broadcast from "../views/Broadcast.vue";
import DashboardVue from "../views/Dashboard.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  { path: "*", component: PageNotFound },
  {
    path: "/reset-password",
    name: "Resetpassword",
    component: ResetPassword,
  },
  {
    path: "/broadcast",
    name: "Broadcast",
    component: Broadcast,
  },
  {
    path: "/detail-smart-farm",
    name: "DetailSmartFarm",
    component: DetailSmartFarm,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/smart-farm",
    name: "SmartFarm",
    component: SmartFarm,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/ganti-password",
    name: "Gantipassword",
    component: GantiPassword,
  },
  {
    path: "/tambah-laporan",
    name: "TambahMyLapor",
    component: TambahMyLapor,
  },
  {
    path: "/home",
    component: Home,
    props: true,
    children: [
      {
        path: "beranda",
        component: Beranda,
      },
      {
        path: "rt",
        component: RT,
      },
      {
        path: "penduduk",
        component: Penduduk,
      },
    ],
  },
  {
    path: "/berita",
    name: "Berita",
    component: Berita,
  },
  {
    path: "/pemakaman",
    name: "Pemakaman",
    component: Pemakaman,
  },
  {
    path: "/kematian",
    name: "Kematian",
    component: Kematian,
  },
  {
    path: "/perkawinan",
    name: "Perkawinan",
    component: Perkawinan,
  },
  {
    path: "/perceraian",
    name: "Perceraian",
    component: Perceraian,
  },
  {
    path: "/e-bansos",
    name: "EBansos",
    component: EBansos,
  },
  {
    path: "/kelahiran",
    name: "Kelahiran",
    component: Kelahiran,
  },
  {
    path: "/gakin",
    name: "Gakin",
    component: Gakin,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/iklan",
    name: "iklan",
    component: Iklan,
  },
  {
    path: "/lowongan-pekerjaan",
    name: "LowonganPekerjaan",
    component: LowonganPekerjaan,
  },
  {
    path: "/detail-voting",
    name: "DetailVoting",
    component: DetailVoting,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/e-bansos-aksi",
    name: "EBansosAksi",
    component: EBansosAksi,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/beranda",
    name: "Beranda",
    component: Beranda,
  },
  {
    path: "/detail-loker",
    name: "DetailLoker",
    component: DetailLoker,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/detail-e-lapor",
    name: "DetailELapor",
    component: DetailELapor,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/hasil-voting",
    name: "HasilVoting",
    component: HasilVoting,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/tambah-kandidat-voting",
    name: "TambahKandidatVote",
    component: TambahKandidatVote,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/tambah-pertanyaan-survey",
    name: "TambahPertanyaanSurvey",
    component: TambahPertanyaanSurvey,
    props: (route) => ({
      ...route.params,
    }),
  },

  {
    path: "/tambah-vote",
    name: "TambahVote",
    component: TambahVote,
  },
  {
    path: "/tambah-loker",
    name: "TambahLoker",
    component: TambahLoker,
  },
  {
    path: "/tambah-bansos",
    name: "TambahBansos",
    component: TambahBansos,
  },
  {
    path: "/tambah-berita",
    name: "TambahBerita",
    component: TambahBerita,
  },
  {
    path: "/tambah-e-lapor",
    name: "TambahELapor",
    component: TambahELapor,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/tambah-pariwisata",
    name: "TambahPariwisata",
    component: TambahPariwisata,
  },
  {
    path: "/tambah-survey",
    name: "TambahSurvey",
    component: TambahSurvey,
  },
  {
    path: "/master-penduduk",
    name: "MasterPenduduk",
    component: MasterPenduduk,
  },
  {
    path: "/prestasi-warga",
    name: "PrestasiWarga",
    component: PrestasiWarga,
  },
  {
    path: "/lansia",
    name: "Lansia",
    component: Lansia,
  },
  {
    path: "/e-survey",
    name: "ESurvey",
    component: ESurvey,
  },
  {
    path: "/e-survey-finish",
    name: "ESurveyFinish",
    component: ESurveyFinish,
  },
  {
    path: "/e-lapor-kategori",
    name: "ELaporKategori",
    component: ELaporKategori,
  },
  {
    path: "/e-lapor",
    name: "ELapor",
    component: ELapor,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/mylapor",
    name: "MyLapor",
    component: MyLapor,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/e-voting",
    name: "EVoting",
    component: EVoting,
  },
  {
    path: "/e-voting-finish",
    name: "EVotingFinish",
    component: EVotingFinish,
  },
  {
    path: "/edit-penduduk",
    name: "EditDataPenduduk",
    component: EditDataPenduduk,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/edit-pemakaman",
    name: "EditDataPemakaman",
    component: EditDataPemakaman,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/edit-pernikahan",
    name: "EditDataPernikahan",
    component: EditDataPernikahan,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/edit-perceraian",
    name: "EditDataPerceraian",
    component: EditDataPerceraian,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/edit-data-pariwisata",
    name: "EditDataPariwisata",
    component: EditDataPariwisata,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/detail-survey",
    name: "DetailSurvey",
    component: DetailSurvey,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/hasil-survey",
    name: "HasilSurvey",
    component: HasilSurvey,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/detail-news",
    name: "DetailNews",
    component: DetailNews,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/detail-pariwisata",
    name: "DetailPariwisata",
    component: DetailPariwisata,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/detail-bansos",
    name: "DetailBansos",
    component: DetailBansos,
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/pariwisata",
    name: "Pariwisata",
    component: Pariwisata,
  },
  {
    path: "/putus-sekolah",
    name: "PutusSekolah",
    component: PutusSekolah,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardVue,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
