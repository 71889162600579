<template>
  <div style="position: relative">
    <div
      style="position: absolute; width: 100%"
      class="d-flex align-content-center justify-center"
    >
      <img src="../assets/dots.png" class="img-bubble" alt="" srcset="" />
    </div>
    <img src="../assets/header.png" class="background-image" alt="" />
    <v-row
      no-gutters
      justify="center"
      style="position: absolute; width: 100%; top: 0"
    >
      <v-col cols="3" style="text-align: center">
        <div
          class="pt-2 ma-2"
          style="
            background: #ffffff;
            height: 60px;
            width: 60px;
            border-radius: 50px;
            text-align: center;
            color: black;
          "
        >
          <v-icon style="font-size: 45px" v-if="icon != 'dead-body.png'">
            {{ icon }}
          </v-icon>
          <img
            style="height: 45px; filter: invert(100%)"
            v-else
            :src="require('../assets/' + icon + '')"
          />
        </div>
      </v-col>
      <v-col
        cols="6"
        class="d-flex align-content-center flex-wrap justify-center"
      >
        <p class="text-cp">{{ text }}</p>
      </v-col>
      <v-col
        cols="3"
        class="d-flex align-content-center flex-wrap justify-center"
      >
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "Header",
  props: {
    text: String,
    icon: String,
  },
  data() {
    return {
      imageUrl: "",
    };
  },
};
</script>

<style scoped>
@media only screen and (min-width: 599px) {
  .text-cp {
    color: white;
    font-size: 1.3vh;
    margin: 0;
  }

  .img-logo2 {
    height: 70px;
    width: auto;
    margin: 10px 20px 0 50px;
  }

  .img-bubble {
    height: 12vh;
    width: auto;
  }

  .background-image {
    height: 100px;
    width: 100%;
  }
}

@media only screen and (max-width: 1250px) {
  .img-logo2 {
    height: 70px;
    width: auto;
    margin: 10px 20px 0 20px;
  }

  .img-bubble {
    height: 11vh;
    width: auto;
  }
}

@media only screen and (max-width: 970px) {
  .img-logo2 {
    height: 70px;
    width: auto;
    margin: 10px 20px 0 20px;
  }

  .img-bubble {
    height: 11vh;
    width: auto;
  }
}

@media only screen and (max-width: 845px) {
  .img-logo2 {
    height: 70px;
    width: auto;
    margin: 10px 20px 0 20px;
  }

  .img-bubble {
    height: 10vh;
    width: auto;
  }
}

@media only screen and (max-width: 599px) {
  .text-cp {
    color: white;
    font-size: 2vh;
    margin: 0;
  }

  .img-bubble {
    height: 5vh;
    width: auto;
  }

  .img-logo2 {
    height: 40px;
    width: auto;
    margin: 20px 20px 0 10px;
  }

  .background-image {
    height: 80px;
    width: 100%;
  }
}

@media only screen and (min-width: 693px) {
  .text-cp {
    color: white;
    font-size: 5vh;
    margin: 0;
  }
}

@media only screen and (max-width: 693px) {
  .text-cp {
    color: white;
    font-size: 2vh;
    margin: 10px 0 0 0;
  }
}
</style>
