<template class="overflow-hidden">
  <div>
    <v-app-bar app color="white">
      <v-btn
        class="d-flex"
        color="black"
        icon
        v-bind="attrs"
        v-on="on"
        to="/home"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn color="black" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="black" v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link>
            <v-list-item-title v-on:click="logout()">Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-app-bar>
    <HeaderVue text="Perkawinan" icon="mdi-heart" />

    <DataTablePerkawinan />

    <Footer />
  </div>
</template>
<script>
import DataTablePerkawinan from "../components/DataTablePerkawinan.vue";
import Footer from "../components/Footer.vue";
import HeaderVue from "../components/Header.vue";
export default {
  components: { DataTablePerkawinan, Footer, HeaderVue },
  name: "Perkawinan",
  data() {
    return {
      userData: null,
    };
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("data token: ", this.token_jwt);
    }
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
  },
  created() {},
  methods: {
    logout() {
      this.$session.destroy();
      this.$router.push("/");
      console.log(localStorage.token_jwt);
      localStorage.token_jwt = null;
      console.log(localStorage.token_jwt);
    },
  },
};
</script>

<style scoped>
.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>