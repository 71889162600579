<template>
  <v-card>
    <v-app-bar app color="#F2F1F1" dense>
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="E-Lapor" icon="mdi-alert-octagon-outline" />
    <v-tabs class="tabs" centered grow slider-color="red" color="red">
      <v-tab> E-Lapor </v-tab>
      <v-tab> E-Lapor Saya </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-container style="height: 100%; margin-bottom: 500px">
              <div v-if="!DataDinas.data">
                <v-skeleton-loader
                  class="mx-auto mb-10"
                  max-width="100%"
                  type="card"
                ></v-skeleton-loader>

                <v-skeleton-loader
                  class="mx-auto mb-10"
                  max-width="100%"
                  type="card"
                ></v-skeleton-loader>
              </div>

              <v-snackbar
                color="green"
                top
                style="color: white"
                v-model="snackbar"
              >
                Berhasil mendaftarkan kategori
              </v-snackbar>
              <v-snackbar
                color="red"
                top
                style="color: white"
                v-model="snackbar_error"
              >
                {{ this.msg_error }}
              </v-snackbar>

              <div class="text-end">
                <v-dialog v-model="dialog" width="90%">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
                      Tambah Dinas & Kategori
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Tambah Dinas Beserta Kategorinya
                    </v-card-title>

                    <v-container>
                      <v-autocomplete
                        :items="kategoris"
                        label="Kategori yang telah Terdaftar"
                        item-value="kategori"
                        item-text="kategori"
                      ></v-autocomplete>

                      <p>Note: jangan menambahakn dinas yang telah terdaftar</p>
                    </v-container>

                    <form
                      id="myForm"
                      name="myForm"
                      v-on:submit.prevent="submitForm"
                      enctype="multipart/form-data"
                    >
                      <v-col cols="12">
                        <v-combobox
                          prepend-icon="mdi-pencil-box-outline"
                          v-model="form.dinas"
                          :items="dinases"
                          label="Nama DINAS"
                          :rules="[rules.required]"
                          item-value="dinas"
                          item-text="dinas"
                          required
                        ></v-combobox>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          prepend-icon="mdi-pencil-box-outline"
                          label="Nama Kategori"
                          :rules="[rules.required]"
                          required
                          v-model="form.kategori"
                        >
                        </v-text-field>
                      </v-col>
                    </form>

                    <v-divider></v-divider>
                    <v-container class="text-end">
                      <v-btn
                        color="red"
                        class="button-signup mt-10"
                        @click="sendData()"
                        :loading="loading"
                        >Kirim</v-btn
                      >
                    </v-container>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>

              <div v-if="DataDinas">
                <v-container>
                  <div class="text-center">
                    <p v-if="DataDinas.data.length == 0">
                      Data Dinas belum ada.
                    </p>
                  </div>
                  <v-row>
                    <v-col
                      cols="6"
                      sm="3"
                      class="text-center mt-4"
                      v-for="item in ListDataDinas"
                      :key="item"
                    >
                      <v-btn
                        class="white--text"
                        color="pink darken-2"
                        depressed
                        outlined
                        @click="pushELapor(item)"
                      >
                        <v-badge
                          bordered
                          color="error"
                          :content="item.notif"
                          :value="item.notif"
                          overlap
                          v-if="item.notif.length != 0"
                        >
                          <v-icon class="mx-2"> mdi-chat-alert-outline </v-icon>
                        </v-badge>

                        <v-badge
                          bordered
                          color="error"
                          :content="item.notif_proses"
                          :value="item.notif_proses"
                          overlap
                          v-if="item.notif_proses.length != 0"
                        >
                          <v-icon class="mx-2"> mdi-reload-alert</v-icon>
                        </v-badge>

                        <v-badge
                          bordered
                          color="error"
                          :content="item.notif"
                          :value="item.notif"
                          overlap
                          v-else
                        >
                          <v-icon> mdi-check-circle-outline </v-icon>
                        </v-badge>
                      </v-btn>
                      <p>
                        <a
                          class="danger"
                          style="color: #c2185b"
                          @click="pushELapor(item)"
                        >
                          {{ item.dinas }}
                        </a>
                      </p>
                    </v-col>
                  </v-row>
                  <p style="font-size: 10px; margin-top: 20px">
                    <span style="font-size: 16px; margin-top: 20px">
                      Note.
                    </span>
                    <br />
                    <v-icon> mdi-chat-alert-outline </v-icon> ada lapor sedang
                    menunggu
                    <br />
                    <v-icon> mdi-reload-alert</v-icon> ada lapor sedang diproses
                    <br />
                    <v-icon> mdi-check-circle-outline </v-icon> tidak ada
                    laporan atau selesai
                  </p>
                </v-container>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-sheet
              id="scrolling-techniques-7"
              style="overflow: auto"
              max-height="100vh"
            >
              <DataTableMyLapor />
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
// import CardElapor from "../components/CardElapor.vue";
import moment from "moment";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import DataTableMyLapor from "../components/DataTableMyLapor.vue";

import { getData, postData } from "../service/ApiService";
export default {
  name: "ELaporKategori",
  components: {
    // CardElapor,
    DataTableMyLapor,
    Footer,
    Header,
  },

  props: {
    dataLapor: [],
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Wajib di isi.",
      },
      dialog: false,
      model: null,
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      id_provinsi: "",
      id_kabkot: "",
      id_kelurahan: "",
      id_kecamatan: "",
      search: "",
      token_jwt: "",
      menu: false,
      msg: "",
      loading: false,
      page: 1,
      laporData: "",
      startTime: "July 7, 2017 12:03:00",
      endTime: "July 9, 2022 14:55:00",
      userData: "",
      snackbar: false,
      ListDataDinas: [],
      DataDinas: [],
      times: [
        { id: 0, text: "Days", time: 1 },
        { id: 1, text: "Hours", time: 1 },
        { id: 2, text: "Minutes", time: 1 },
        { id: 3, text: "Seconds", time: 1 },
      ],
      progress: 100,
      dinases: [],
      // isActive: false,
      timeinterval: undefined,
      form: {
        dinas: null,
        kategori: null,
      },
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataNews.created_at
        ? moment(this.dataNews.created_at).format("dddd, d MMM YYYY HH:m A")
        : "";
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
    },
  },
  mounted() {
    this.getDinas();
    this.getKategori();
    this.getListDinas();
    // this.getDataLapor();
  },
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.getListProvinsi();
  },
  methods: {
    async sendData() {
      this.loading = true;
      console.log(this.form);
      let formData = new FormData();
      formData.append("dinas", this.form.dinas);
      formData.append("kategori", this.form.kategori);

      console.log(formData.values());
      const data = {
        api: "api/elapor/create-dinas",
        body: formData,
      };

      await postData(data)
        .then((response) => {
          console.log(response);
          this.id_lapor = response.data.id;
          this.dialog = false;
          this.loading = false;
          this.snackbar = true;
        })
        .catch((error) => {
          console.log("finish get 1");
          console.log(error);
          console.log(error.response);
        });
    },
    getKategori() {
      const apiListKategori = "api/elapor-list-kategori";

      getData(apiListKategori)
        .then((response) => {
          // this.form.id_kategori = value;

          this.kategoris = response.data;
          console.log("data response kategori :", this.kategoris);
          // console.log("Data Kategori 2: ",this.kategoris);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getDinas() {
      const apiListDinas = "api/elapor-list-dinas";

      getData(apiListDinas)
        .then((response) => {
          // this.form.id_kategori = value;

          console.log("data response kategori :", response);
          this.dinases = response.data;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    pushELapor(value) {
      localStorage.data_elapor = JSON.stringify(value);
      console.log(JSON.parse(localStorage.data_elapor));
      this.$router.push({
        name: "ELapor",
        params: {
          dataItem: value,
        },
      });
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    eLaporFinish() {
      this.$router.push({
        name: "ELaporFinish",
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.getDataLaporFilter();
    },
    getDataLaporFilter() {
      if (this.search != "") {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/lapor/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi +
            "&keyword=" +
            this.id_kabkot +
            "&keyword=" +
            this.id_kecamatan +
            "&keyword=" +
            this.id_kelurahan +
            "&keyword=" +
            this.search;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/lapor/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi +
            "&keyword=" +
            this.id_kabkot +
            "&keyword=" +
            this.id_kecamatan +
            "&keyword=" +
            this.search;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/lapor/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi +
            "&keyword=" +
            this.id_kabkot +
            "&keyword=" +
            this.search;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/lapor/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi +
            "&keyword=" +
            this.search;
        } else {
          this.apiGetFilter =
            "api/lapor/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.search;
        }
      } else {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/lapor/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi +
            "&keyword=" +
            this.id_kabkot +
            "&keyword=" +
            this.id_kecamatan +
            "&keyword=" +
            this.id_kelurahan;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/lapor/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi +
            "&keyword=" +
            this.id_kabkot +
            "&keyword=" +
            this.id_kecamatan;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/lapor/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi +
            "&keyword=" +
            this.id_kabkot;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/lapor/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi;
        } else {
          this.apiGetFilter =
            "api/lapor/" + this.userData.nik + "?page=" + this.page;
        }
      }

      getData(this.apiGetFilter)
        .then((response) => {
          console.log("data :", response);
          this.laporData = response;
          this.totalPages = response.data_active.last_page;
          console.log(this.penduduks);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value.id;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.id_provinsi = value.provinsi;
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kabkot = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value.id;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.id_kabkot = value.kabupaten_kota;
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value.id;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.id_kecamatan = value.kecamatan;
            this.kelurahans = "";
            this.kelurahans = response;
            this.id_kelurahan = "";

            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.id_kelurahan = value.kelurahan;
      }
    },
    SearchbarChange(value) {
      this.search = value;
      this.getDataLaporFilter();
      console.log(value);
    },
    back() {
      this.$router.back();
    },
    getListDinas() {
      const ApiGetDinas = "api/elapor-list-dinas";
      getData(ApiGetDinas)
        .then((response) => {
          this.DataDinas = response;

          console.log("List Dinas :", this.DataDinas);
          this.DataDinas.data.forEach((item) => {
            this.getDataLapor(item);
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    getDataLapor(value) {
      const ApiGetLapor = "api/elapordinas?dinas=" + value.dinas;

      getData(ApiGetLapor)
        .then((response) => {
          console.log("data :", response);
          this.laporData = response;
          this.ListDataDinas.push({
            dinas: value.dinas,
            notif: response.notif,
            notif_proses: response.notif_proses,
          });

          // this.totalPages = response.data_active.last_page;
          console.log("Notif list :", this.laporData.notif);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    checkLapor(item) {
      this.loading = true;
      console.log(this.items);
      let formData = new FormData();
      formData.append("nik_respon", this.userData.nik);
      const data = {
        api: "api/lapor/ask/" + item.id_lapor,
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log("data :", response);
          const arr = [response, item];
          this.detailLapor(arr);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.loading = false;
          this.snackbar.color = "red accent-2";
          this.snackbar.icon = "mdi-alert-octagon-outline";
          this.snackbar.status = "Gagal";
          if (error.response) {
            this.snackbar.visible = true;
            console.log(error.response.data.alert);
            this.msg = error.response.data.alert;
          }
        });
    },
    detailLapor(item, i) {
      console.log(item, i);
      this.$router.push({
        name: "ELapor",
        params: {
          items: item,
          kategori: i,
        },
      });
    },
    hasilLapor(item) {
      this.$router.push({
        name: "HasilLapor",
        params: {
          items: item,
        },
      });
    },
    tambahLapor() {
      this.$router.push({
        name: "TambahLapor",
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY HH:SS");
      }
    },
  },
};
</script>

<style scoped>
.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}
.text-sub {
  color: #c82424;
}
.truncate {
  line-height: 1.5em;
  height: 3em; /* height is 2x line-height, so two lines will display */
  overflow: hidden; /* prevents extra lines from being visible */
}
</style>