<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil membuat Loker
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
      <v-card>
        <v-app-bar color="#F2F1F1" height="80px" elevate-on-scroll scroll-target="#scrolling-techniques-7">
          <v-btn color="black" icon v-bind="attrs" v-on="on" @click="$router.push('/lowongan-pekerjaan')">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-app-bar-title>Buat Loker</v-app-bar-title>
        </v-app-bar>
        <v-card-text>
          <v-container>
            <form id="myForm" name="myForm" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
              <v-row>
                <v-col cols="12">
                  <v-text-field prepend-icon="mdi-book-account" label="Judul" required v-model="form.judul"
                    hint="Judul Loker" :rules="[rules.required]">
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field prepend-icon="mdi-book-account" label="Nama Usaha/Perusahaan" required
                    v-model="form.nama_usaha" hint="Nama" :rules="[rules.required]">
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete prepend-icon="mdi-map-marker" v-model="form.id_kategori" :items="kategoris"
                    label="Kategori" :rules="[rules.required]" item-value="id_kategori" item-text="kategori" required>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-text-field prepend-icon="mdi-book-account" label="Alamat Perusahaan/Usaha" required
                    v-model="form.alamat" hint="Alamat" :rules="[rules.required]">
                  </v-text-field>
                </v-col>

                <!-- <v-col cols="12">
                  <v-textarea
                    prepend-icon="mdi-book-open-outline"
                    label="Deskripsi Loker"
                    required
                    auto-grow
                    v-model="form.deskripsi"
                    hint="Deskripsi Loker"
                    :rules="[rules.required]"
                  >
                  </v-textarea>
                </v-col> -->
                  <v-col cols="12">
                    <v-file-input label="Logo Perusahaan" truncate-length="15" v-model="form.photo"></v-file-input>
                  </v-col>
                <v-subheader>Deskripsi</v-subheader>
                <v-col cols="12">
                  <ckeditor :editor="editor" v-model="form.deskripsi" :config="editorConfig"></ckeditor>
                </v-col>


                <v-btn style="color: white" color="green" type="submit" value="Submit" :loading="loading" block>
                  Simpan
                </v-btn>
              </v-row>
            </form>
          </v-container>
        </v-card-text>
      </v-card>

  </div>
</template>
<script>
  // import FitureCardVue from '../components/FitureCard.vue';
  // import NewsCardVue from '../components/NewsCard.vue';
  // import Tombol from '../components/Tombol.vue';
  // @ is an alias to /src
  // import axios from "axios";
  import moment from "moment";
  import {
    getData,
    postData
  } from "../service/ApiService";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';

  export default {
    name: "TambahLoker",
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push("/");
      }
    },
    data() {
      return {
        editor: ClassicEditor,
        editorData: "",
        editorConfig:{        
        },
        show1: false,
        rules: {
          required: (value) => !!value || "Wajib di isi.",
          min: (v) => v.length >= 8 || "Password minimal 8 digit",
          min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
          emailMatch: () => `The email and password you entered don't match`,
        },
        numberRule: (v) => {
          if (!v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
          return "Wajib Berupa Angka.";
        },
        snackbar: false,
        loading: false,
        snackbar_error: false,
        goldars: [
          "A",
          "B",
          "AB",
          "O",
          "A+",
          "A-",
          "B+",
          "B-",
          "AB+",
          "AB-",
          "O+",
          "O-",
          "NA",
        ],
        agamas: [
          "islam",
          "kristen",
          "katholik",
          "budha",
          "hindu",
          "konghucu",
          "lainnya",
        ],
        roles: [{
            id: 1,
            nama: "Superadmin",
          },
          {
            id: 2,
            nama: "Admin",
          },
          {
            id: 3,
            nama: "RT",
          },
          {
            id: 4,
            nama: "Warga",
          },
        ],
        status_pernikahans: ["Belum Kawin", "Kawin", "Cerai Hidup", "Cerai Mati"],
        menu_waktu_2: false,
        menu_tanggal_1: false,
        menu_waktu_1: false,
        menu_tanggal_2: false,
        provinsis: "",
        kabupatens: "",
        kelurahans: "",
        kecamatans: "",
        form: {
          time: null,
          judul: null,
          deskripsi: null,
          alamat: null,
          tanggal_mulai: null,
          tanggal_selesai: null,
          waktu_mulai: null,
          waktu_selesai: null,
          status: null,
          nik_pembuat: null,
          id_provinsi: null,
          id_kabkot: null,
          id_kecamatan: null,
          id_kelurahan: null,
          id_rw: null,
          id_rt: null,
          photo: null,
          password: null,
        },
        url: "",
        token_jwt: "",
        dialog: false,
        activePicker: null,
        menu: false,
      };
    },
    computed: {
      computedDateFormattedMomentjs() {
        return this.form.tgl_lahir ?
          moment(this.form.tgl_lahir).format("DD, MMMM YYYY") :
          "";
      },
    },
    mounted() {
      if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        console.log("token jwt :", localStorage.token_jwt);
      }

      if (this.snackbar) {
        this.hide_alert();
      }

      if (this.snackbar_error) {
        this.hide_alert_error();
      }

      this.getListProvinsi();
      this.getKategori();
    },
    created() {
      if (localStorage.user_data) {
        this.userData = JSON.parse(localStorage.user_data).data[0];
        console.log("data user local : ", this.userData.nik);
      }
    },
    methods: {
      getListProvinsi() {
        const apiListProvinsi = "api/provinsi";
        getData(apiListProvinsi)
          .then((response) => {
            console.log("data provinsi :", response);
            this.provinsis = response;
            console.log(this.provinsi);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      },
      hide_alert: function (event) {
        console.log(event);
        // `event` is the native DOM event
        window.setInterval(() => {
          this.snackbar = false;
          console.log("hide alert after 3 seconds");
        }, 3000);
      },
      hide_alert_error: function (event) {
        console.log(event);
        // `event` is the native DOM event
        window.setInterval(() => {
          this.snackbar_error = false;
          console.log("hide alert after 3 seconds");
        }, 3000);
      },
      formatDate(date) {
        if (!date) return null;

        const [year, month, day] = date.split("-");
        return `${month}/${day}/${year}`;
      },
      selectedProvinsi(value) {
        console.log(value);
        if (value != null) {
          const apiListKabupaten = "api/kabkot?keyword=" + value;

          getData(apiListKabupaten)
            .then((response) => {
              console.log("data kabupaten :", response);
              this.form.id_provinsi = value;
              this.form.id_kabkot = "";
              this.form.id_kelurahan = "";
              this.form.id_kecamatan = "";
              this.kabupatens = "";
              this.kelurahans = "";
              this.kecamatans = "";

              this.kabupatens = response;
              console.log(this.kabupatens);
            })
            .catch((error) => {
              console.log("pesan error :", error.response.data);
              this.msg = true;
            });
        }
      },
      selectedKabupaten(value) {
        console.log(value);
        if (value != null) {
          const apiListKecamatan = "api/kec?keyword=" + value;

          getData(apiListKecamatan)
            .then((response) => {
              console.log("data kecamatan :", response);
              this.form.id_kabkot = value;
              this.form.id_kelurahan = "";
              this.form.id_kecamatan = "";
              this.kelurahans = "";
              this.kecamatans = "";

              this.kecamatans = response;
              console.log(this.kecamatans);
            })
            .catch((error) => {
              console.log("pesan error :", error.response.data);
              this.msg = true;
            });
        }
      },
      selectedKecamatan(value) {
        console.log(value);
        if (value != null) {
          const apiListKelurahan = "api/kel?keyword=" + value;

          getData(apiListKelurahan)
            .then((response) => {
              console.log("data kelurahan :", response);
              this.form.id_kecamatan = value;
              this.form.id_kelurahan = "";
              this.kelurahans = "";

              this.kelurahans = response;
              console.log(this.kelurahans);
            })
            .catch((error) => {
              console.log("pesan error :", error.response.data);
              this.msg = true;
            });
        }
      },
      selectedKelurahan(value) {
        console.log(value);
        if (value != null) {
          this.form.id_kelurahan = value;
        }
      },
      getKategori() {
        const apiListKategori = "api/loker/id/kategori";

        getData(apiListKategori)
          .then((response) => {
            console.log("data response kategori :", response);
            // this.form.id_kategori = value;

            this.kategoris = response.data;
            // console.log("Data Kategori 2: ",this.kategoris);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      },
      submitForm() {
        console.log("Data user nik :", this.userData);
        this.loading = true;
        console.log(this.form);
        let formData = new FormData();
        formData.append("judul", this.form.judul);
        formData.append("id_kategori", this.form.id_kategori);
        formData.append("nama_usaha", this.form.nama_usaha);
        formData.append("alamat", this.form.alamat);
        formData.append("deskripsi", this.form.deskripsi);
        formData.append("photo", this.form.photo);
        if (this.userData.role != "superadmin") {
          this.form.id_role = 4;
        }
        formData.append("id_role", this.form.id_role);
        console.log(formData.values());
        const data = {
          api: "api/loker",
          body: formData,
        };
        postData(data)
          .then((response) => {
            console.log(response);
            this.dialog = false;
            this.loading = false;
            this.snackbar = true;
            this.$router.push('/lowongan-pekerjaan');
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response);
            if (error.response) {
              console.log(error.response.data);
              if (error.response.data.nik) {
                this.msg_error = error.response.data.nik[0];
                this.snackbar_error = true;
              } else if (error.response.data.email) {
                this.msg_error = error.response.data.email[0];
                this.snackbar_errror = true;
              } else {
                this.msg_error = error.response.data;
                this.snackbar_errror = true;
              }
            }
          });
      },
      save(date) {
        this.$refs.menu.save(date);
      },
      deletePic() {
        this.url = "";
        this.$refs.file.value = "";
      },
      onFileChange(e) {
        let file = e.target.files[0];
        this.url = URL.createObjectURL(file);
        this.form.photo = e.target.files[0];
        console.log(this.form);
      },
      format_date(value) {
        if (value) {
          return moment(String(value)).format("DD/MMM/YYYY");
        }
      },
    },
  };
</script>

<style scoped>
  #preview {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
  }

  .delete-text {
    color: red;
    cursor: pointer;
  }

  #preview img {
    max-width: 100%;
    max-height: 500px;
  }

  .button-signup {
    border-radius: 10px;
    color: white !important;
    text-transform: none;
    text-decoration: none;
  }

  .v-card__title {
    background: #f54c4c;
  }

  .text-title {
    text-decoration: none;
    color: black;
    margin: 0 22px 0 0;
    font-size: 18px;
    font-weight: 500;
  }

  .img-logo {
    height: 80%;
    width: auto;
    margin-right: 20px;
  }
</style>