<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      app
      color="#F2F1F1"
      dense
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="Hasil Voting" icon="mdi-vote" />
    <!-- <p>{{ items }}</p> -->

    <v-container
      style="height: 100%; margin-bottom: 100px"
      v-if="kandidats_final.length != 0"
    >
      <!-- <p>{{ votingData }}</p> -->
      <!-- <p>{{ dataHasilVote }}</p> -->
      <v-row>
        <v-col cols="12">
          <div>
            <pie-chart
              v-if="kandidats_final.length != 0"
              :kandidat_final="kandidats_final"
              :options="chartOptions"
            ></pie-chart>
          </div>
        </v-col>
        <v-col cols="12">
          <h2 class="mb-2">Hasil Suara</h2>
          <div
            style="
              padding: 20px 20px 10px 20px;
              border-radius: 25px;
              background-color: #dfdfdf;
            "
          >
            <v-row no-gutters v-for="(item, i) in kandidats_final" :key="i">
              <v-col cols="3">
                <p>{{ item.peserta }}</p>
              </v-col>
              <v-col cols="9">
                <v-progress-linear
                  :value="updateMyValue(item, i)"
                  :color="item.warna"
                  height="25"
                >
                  <template>
                    <strong>{{ kandidats_final[i].suara }} Suara</strong>
                  </template>
                </v-progress-linear>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <p style="text-align: right">
        Total
        <span style="color: #3c64ac">{{ kandidats_final[0].total_suara }}</span>
        Suara
      </p>
    </v-container>
    <v-container
      style="height: 100%; margin-bottom: 100px"
      v-if="kandidats_final.length == 0"
    >
      <p class="text-center">Tidak ada suara.</p>
    </v-container>

    <Footer />
  </v-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.9.4/Chart.min.js"></script>
<script src="https://unpkg.com/vue-chartjs/dist/vue-chartjs.min.js"></script>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";

import moment from "moment";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import { getData, postData } from "../service/ApiService";
import PieChart from "../PieChart.js";

export default {
  name: "HasilVoting",
  props: ["items"],
  components: {
    Footer,
    Header,
    PieChart,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      chartOptions: {
        hoverBorderWidth: 20,
      },
      chartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: ["Green", "Red", "Blue"],
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#41B883", "#E46651", "#00D8FF"],
            data: [1, 10, 5],
          },
        ],
      },
      mycolor: "#" + ((Math.random() * 0xffffff) << 0).toString(16),

      model: null,
      token_jwt: "",
      menu: false,
      msg: "",
      kandidat: [
        { nama: "Ujang", suara: "20", color: "rgb(54, 162, 235)" },
        { nama: "Jefri", suara: "30", color: "rgb(255, 99, 132)" },
        { nama: "Rachmad", suara: "10", color: "rgb(255, 205, 86)" },
        { nama: "Guntur", suara: "40", color: "#f87979" },
      ],
      dataHasilVote: [
        {
          peserta: [
            {
              id_peserta: 3,
              peserta: "pak hj 6",
            },
            {
              id_peserta: 4,
              peserta: "pak bj haji",
            },
            {
              id_peserta: 5,
              peserta: "pak bj haji",
            },
            {
              id_peserta: 6,
              peserta: "pak bj haji",
            },
            {
              id_peserta: 7,
              peserta: "pak bj haji",
            },
            {
              id_peserta: 8,
              peserta: "pak bj haji",
            },
          ],
        },
        {
          suara: [
            {
              id_peserta: 3,
            },
            {
              id_peserta: 5,
            },
            {
              id_peserta: 5,
            },
            {
              id_peserta: 5,
            },
          ],
        },
        { Total_suara: 4 },
      ],
      overlay: false,
      selected: null,
      votingData: "",
      userData: "",
      kandidats: "",
      suara_kandidats: "",
      kandidats_final: [],
      dialog: false,
      dialogConfirmation: false,
      snackbar: {
        timeout: 3000,
        visible: false,
      },
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataNews.created_at
        ? moment(this.dataNews.created_at).format("dddd, d MMM YYYY HH:m A")
        : "";
    },
  },
  mounted() {},
  created() {
    this.getDataVoting();

    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }

    console.log("items :", this.items);
  },
  methods: {
    updateMyValue(item, i) {
      console.log("event :", item, i);
      let suara = "";
      suara =
        (this.kandidats_final[i].suara * 100) /
        this.kandidats_final[i].total_suara; // Formatting example
      return suara;
    },
    generator() {
      const mycolor = "#" + ((Math.random() * 0xffffff) << 0).toString(16);

      return mycolor;
    },
    pilih(data) {
      if (this.selected != data.id_peserta) {
        this.selected = data.id_peserta;
      } else {
        this.selected = null;
      }
      console.log(this.selected);
    },
    back() {
      this.$router.back();
    },
    getDataVoting() {
      console.log(this.items);
      const ApiGetVoting = "api/vote/" + this.items;

      getData(ApiGetVoting)
        .then((response) => {
          console.log("data :", response);
          this.dataHasilVote = response;
          this.kandidats = this.dataHasilVote.data.peserta;
          let key = "id_peserta";
          console.log(this.findOcc(this.dataHasilVote.data.suara, key));
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    postDataVoting() {
      this.dialog = true;

      let formData = new FormData();
      formData.append("id_evoting", this.items);
      formData.append("id_peserta", this.selected);
      formData.append("nik_pemilih", this.userData.nik);
      const data = {
        api: "api/vote",
        body: formData,
      };

      postData(data)
        .then((response) => {
          this.dialogConfirmation = false;
          console.log("response :", response);
          this.dialog = false;
          this.msg = "";
        })
        .catch((error) => {
          this.dialog = false;
          this.dialogConfirmation = false;
          if (error.response) {
            this.snackbar.visible = true;
            console.log(error.response.data.nik_pemilih[0]);
            if (
              error.response.data.nik_pemilih ==
              "nik pemilih anda telah melakukan voting"
            ) {
              this.msg = "Anda telah melakukan voting.";
            } else {
              this.msg = error.response.data;
            }
          }
        });
    },
    findOcc(arr, key) {
      this.kandidats_final = [];
      let arr2 = [];

      arr.forEach((x) => {
        // Checking if there is any object in arr2
        // which contains the key value
        if (
          arr2.some((val) => {
            return val[key] == x[key];
          })
        ) {
          // If yes! then increase the occurrence by 1
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["total_suara"]++;
            }
          });
        } else {
          // If not! Then create a new object initialize
          // it with the present iteration key's value and
          // set the occurrence to 1
          let a = {};
          a[key] = x[key];
          a["total_suara"] = 1;
          arr2.push(a);
        }
      });

      this.suara_kandidats = arr2;
      console.log(this.suara_kandidats);
      this.kandidats.forEach((kandidat) => {
        this.suara_kandidats.forEach((suara) => {
          if (suara.id_peserta == kandidat.id_peserta) {
            this.kandidats_final.push({
              id_peserta: kandidat.id_peserta,
              photo: kandidat.photo,
              peserta: kandidat.peserta,
              suara: suara.total_suara,
              total_suara: this.dataHasilVote.data.Total_suara,
              warna: this.generator(),
            });
          }
        });
        this.add(this.kandidats_final, kandidat);
      });
      console.log(this.kandidats_final);
      return this.kandidats_final;
    },
    add(arr, kandidat) {
      const found = arr.some((el) => el.id_peserta == kandidat.id_peserta);
      if (!found)
        this.kandidats_final.push({
          id_peserta: kandidat.id_peserta,
          peserta: kandidat.peserta,
          photo: kandidat.photo,
          suara: 0,
          total_suara: this.dataHasilVote.data.Total_suara,
          warna: this.generator(),
        });
    },
  },
};
</script>



<style scoped>
.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.text-sub {
  text-align: center;
  color: #000000;
  font-size: 20px;
}

.v-alert {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

.truncate {
  line-height: 1.5em;
  height: 3em; /* height is 2x line-height, so two lines will display */
  overflow: hidden; /* prevents extra lines from being visible */
}
</style>