<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil mendaftarkan
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-btn color="red" class="button-signup" @click="dialog = true"
      >Detail</v-btn
    >
    <v-dialog v-if="dialog" v-model="dialog" max-width="600px">
      <v-card>
        <v-app-bar
          color="#F2F1F1"
          height="80px"
          elevate-on-scroll
          scroll-target="#scrolling-techniques-7"
        >
          <v-btn
            color="black"
            icon
            v-bind="attrs"
            v-on="on"
            @click="dialog = false"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card-text>
          <v-container>
            <form
              id="myForm"
              name="myForm"
              v-on:submit.prevent="submitForm"
              enctype="multipart/form-data"
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-book-account"
                    label="Judul"
                    filled
                    readonly
                    v-model="dataLapor.judul"
                    hint="Judul"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    prepend-icon="mdi-book-open-outline"
                    filled
                    v-model="dataLapor.keterangan"
                    readonly
                    label="Keterangan"
                    hint="Keterangan"
                  >
                  </v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-book-open-outline"
                    label="Kategori"
                    v-model="dataLapor.kategori"
                    hint="Nomor Kartu Keluarga (16 Digit)"
                    filled
                    readonly
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-account"
                    label="Waktu Pelaporan"
                    filled
                    readonly
                    :value="format_date_now(dataLapor.tanggal)"
                    hint="Waktu"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-book-open-outline"
                    filled
                    v-model="dataLapor.alamat_lengkap"
                    readonly
                    label="Alamat Lengkap"
                    hint="Alamat"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    v-model="dataLapor.provinsi"
                    label="Provinsi"
                    item-text="provinsi"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    v-model="dataLapor.kabupaten"
                    label="Kabupaten/ Kota"
                    item-text="kabupaten_kota"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    v-model="dataLapor.tanggal_perubahan"
                    label="Waktu Tanggapan"
                    item-text="Tanggapan"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-timeline align-top dense>
                    <div v-for="item in dataItem.proses" :key="item">
                      <v-timeline-item
                        color="blue"
                        small
                        v-if="item.status == 'menunggu'"
                      >
                        <v-row class="pt-1">
                          <v-col cols="3">
                            <strong>{{ item.status }}</strong>
                          </v-col>
                          <v-col>
                            <div class="text-caption" style="color: #ad9c9c">
                              {{ computedDateFormattedMomentjs(item.respone) }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>

                      <v-timeline-item
                        color="green"
                        small
                        v-if="item.status == 'selesai'"
                      >
                        <v-row class="pt-1">
                          <v-col cols="3">
                            <strong>{{ item.status }}</strong>
                          </v-col>
                          <v-col>
                            <div class="text-caption" style="color: #ad9c9c">
                              {{ computedDateFormattedMomentjs(item.respone) }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>

                      <v-timeline-item
                        color="orange"
                        small
                        v-if="item.status == 'proses'"
                      >
                        <v-row class="pt-1">
                          <v-col cols="3">
                            <strong>{{ item.status }}</strong>
                          </v-col>
                          <v-col>
                            <div class="text-caption" style="color: #ad9c9c">
                              {{ computedDateFormattedMomentjs(item.respone) }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </div>
                  </v-timeline>
                </v-col>
                <v-col cols="12">
                  <p
                    v-if="dataLapor.status == 'menunggu'"
                    class="text-sub text-center"
                    style="color: #ad9c9c"
                  >
                    Menunggu...
                    <v-icon style="color: #ad9c9c">mdi-clock-outline</v-icon>
                  </p>
                  <p
                    v-if="dataLapor.status == 'proses'"
                    class="text-sub text-center"
                    style="color: orange"
                  >
                    Diproses...
                    <v-icon style="color: orange">mdi-progress-clock</v-icon>
                  </p>
                  <p
                    v-if="dataLapor.status == 'selesai'"
                    class="text-sub text-center"
                    style="color: #4ff54c"
                  >
                    Selesai... <v-icon style="color: #4ff54c">mdi-check</v-icon>
                  </p>
                </v-col>
              </v-row>
            </form>
          </v-container>
          <v-card>
            <Map
              :lat="dataLapor.long"
              :lng="dataLapor.lat"
              :lokasi="dataLapor.judul"
            />
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import { activatedData, deleteData } from "../service/ApiService";
import Map from "./Map.vue";

export default {
  name: "DetailDataKelahiran",
  components: {
    Map,
  },
  props: {
    dataItem: [],
    status: String,
  },
  beforeCreate: function () {
    this.showDetail = true;
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      rand: 1,
      showDetail: false,
      show1: false,
      rules: {
        required: (value) => !!value || "Wajib di isi.",
        min: (v) => v.length >= 8 || "Password minimal 8 digit",
        min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
        emailMatch: () => `The email and password you entered don't match`,
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
        return "Wajib Berupa Angka.";
      },
      snackbar: false,
      snackbar_error: false,
      goldars: [
        "A",
        "B",
        "AB",
        "O",
        "A+",
        "A-",
        "B+",
        "B-",
        "AB+",
        "AB-",
        "O+",
        "O-",
        "NA",
      ],
      agamas: [
        "islam",
        "kristen",
        "kathloik",
        "budha",
        "hindu",
        "konghucu",
        "lainnya",
      ],
      status_pernikahans: ["Belum Kawin", "Kawin", "Cerai Hidup", "Cerai Mati"],
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      dataLapor: {
        id_role: 4,
        nama: "",
        nik: "",
        id_kk: "",
        email: "",
        password: "",
        status: "",
        status_pernikahan: "",
        nohp: "",
        tgl_lahir: "",
        tempatlahir: "",
        jk: "",
        suku: "",
        agama: "",
        goldarah: "",
        alamat: "",
        alamat_domisili: "",
        id_provinsi: "",
        id_kabkot: "",
        id_kelurahan: "",
        id_kecamatan: "",
        id_rt: "",
        id_rw: "",
        photo: "",
      },
      id_rt: "",
      id_rw: "",
      url: "",
      token_jwt: "",
      dialog: false,
      dialogConfirmation: false,
      activePicker: null,
      menu: false,
      msg_error: null,
    };
  },
  mounted() {
    if (this.dialog) {
      self.rand = Date.now();
    }

    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    if (this.snackbar) {
      this.hide_alert();
    }

    if (this.snackbar_error) {
      this.hide_alert_error();
    }

    this.getListProvinsi();
  },
  created() {
    this.dataLapor = this.dataItem.lapor[0];

    console.log("data item :", this.dataLapor);
  },
  methods: {
    computedDateFormattedMomentjs(value) {
      return value ? moment(value).format("DD, MMMM YYYY") : "";
    },
    format_date_now(value) {
      if (value) {
        return moment(String(value)).format("hh:mm MM-DD-YYYY");
      }
    },
    hide_alert: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    hide_alert_error: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar_error = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    editDataPenduduk() {
      this.$router.push({
        name: "EditDataPenduduk",
        params: {
          items: this.dataLapor,
        },
      });
    },
    aktifUser() {
      if (this.status === "aktif") {
        const data = {
          api: "api/profile/",
          body: this.dataItem.nik,
        };

        deleteData(data)
          .then((response) => {
            console.log(response);
            this.$router.go();
            console.log(this.token_jwt);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              this.msg = error.response.data;
            }
          });
      } else if (this.status === "non-aktif") {
        const data = {
          api: "api/profile/active/",
          body: this.dataItem.nik,
        };
        activatedData(data)
          .then((response) => {
            console.log(response);
            this.$router.go();
            console.log(this.token_jwt);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              this.msg = error.response.data;
            }
          });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>