<template>
  <v-card class="overflow-hidden">
    <v-app-bar app color="#F2F1F1" dense>
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="E-Lapor" icon="mdi-alert-octagon-outline" />
    <v-snackbar
      v-model="snackbar.visible"
      multi-line="true"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      centered
      auto-height
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>
            <strong> {{ snackbar.status }}</strong>
          </div>
          <div>{{ msg }}</div>
        </v-layout>
      </v-layout>
      <v-btn
        v-if="snackbar.timeout === 0"
        icon
        @click="snackbar.visible = false"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-snackbar>
    <v-container style="height: 100%; margin-bottom: 100px">
      <v-row>
        <v-col cols="6" class="flex mb-2">
          <p class="text-title">
            E-Lapor
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small>
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span
                >MyLapor merupakan aplikasi lapor segala <br />
                keperluan pembangunan maupun lingkungan disekitarmu.</span
              >
            </v-tooltip>
          </p>
        </v-col>
        <v-col cols="6" class="text-end">
          <!-- <TambahMyLapor /> -->
        </v-col>
      </v-row>

      <v-card-title>
        <v-text-field
          dark
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Nik, Nama, KK, dsb.."
          single-line
          filled
          outlined
          hide-details
          @input="SearchbarChange"
          style="width: 100%"
        >
        </v-text-field>
      </v-card-title>

      <v-data-table
        class="table-penduduk"
        disable-pagination
        :hide-default-footer="true"
        :headers="headers"
        :items="penduduks"
        :total-items="totalNumberOfItems"
      >
        <template
          v-slot:item.keterangan="{ item }"
          style="text-transform: capitalize"
        >
          <p class="truncate">
            {{ item.keterangan }}
          </p>
        </template>

        <template
          v-slot:item.status="{ item }"
          style="text-transform: capitalize"
        >
          <p
            v-if="item.status == 'menunggu'"
            class="text-sub"
            style="color: #ad9c9c"
          >
            Menunggu...
            <v-icon style="color: #ad9c9c">mdi-clock-outline</v-icon>
          </p>
          <p
            v-if="item.status == 'proses'"
            class="text-sub"
            style="color: orange"
          >
            Diproses...
            <v-icon style="color: orange">mdi-progress-clock</v-icon>
          </p>
          <p
            v-if="item.status == 'selesai'"
            class="text-sub"
            style="color: #4ff54c"
          >
            Selesai... <v-icon style="color: #4ff54c">mdi-check</v-icon>
          </p>
        </template>
        <!-- <template v-slot:item.tempatlahir="{ item }">
        <td class="truncate">{{ item.tempatlahir }}</td>
      </template> -->
        <!-- <template v-slot:item.tgl_lahir="{ item }">
        {{ format_date(item.tgl_lahir) }}
      </template> -->
        <template v-slot:item.aksi="{ item }">
          <DetailELapor :dataItem="item" status="lansia" />
        </template>
      </v-data-table>

      <v-pagination
        :loading="loading"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      >
      </v-pagination>
    </v-container>
    <Footer />
  </v-card>
</template>
<script>
import moment from "moment";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import DetailELapor from "../components/DetailELapor.vue";
import { getData, postData } from "../service/ApiService";
export default {
  name: "ELapor",
  components: {
    Footer,
    Header,
    DetailELapor,
  },
  props: {
    items: [],
    kategori: [],
    dataItem: [],
  },
  beforeCreate: function () {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      if (this.token_jwt == null) {
        this.$router.push("/");
      }
    }
  },
  data() {
    return {
      model: null,
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      id_provinsi: "",
      id_kabkot: "",
      id_kelurahan: "",
      id_kecamatan: "",
      search: "",
      token_jwt: "",
      menu: false,
      msg: "",
      loading: false,
      page: 1,
      totalPages: 1,
      laporData: "",
      startTime: "July 7, 2017 12:03:00",
      endTime: "July 9, 2022 14:55:00",
      userData: "",
      snackbar: {
        timeout: 3000,
        visible: false,
        color: "",
        icon: "",
        status: "",
      },
      headers: [
        {
          text: "Kategori",
          align: "start",
          value: "kategori",
        },
        {
          text: "Judul",
          value: "judul",
        },
        {
          text: "Keterangan",
          value: "keterangan",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Aksi",
          value: "aksi",
          sortable: false,
        },

        //   { text: 'Aksi', value: 'aksi',class: 'my-header-style' },
      ],
      penduduks: [],
      times: [
        { id: 0, text: "Days", time: 1 },
        { id: 1, text: "Hours", time: 1 },
        { id: 2, text: "Minutes", time: 1 },
        { id: 3, text: "Seconds", time: 1 },
      ],
      progress: 100,
      // isActive: false,
      timeinterval: undefined,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataNews.created_at
        ? moment(this.dataNews.created_at).format("dddd, d MMM YYYY HH:m A")
        : "";
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
    },
  },
  mounted() {
    this.getDataLapor();
  },
  created() {
    console.log(this.dataItem);
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    if (localStorage.data_elapor) {
      this.dataItem = JSON.parse(localStorage.data_elapor);
      console.log("data ELapor: ", this.dataItem);
    }
    this.getListProvinsi();
  },
  methods: {
    eLaporFinish() {
      this.$router.push({
        name: "ELaporFinish",
      });
    },
    handlePageChange(value) {
      this.penduduks = [];
      this.page = value;
      this.getDataLaporFilter();
    },
    getDataLaporFilter() {
      this.apiGetFilter =
        "api/elapordinas" +
        "?dinas=" +
        this.dataItem.dinas +
        "&page=" +
        this.page +
        "&prov=" +
        this.id_provinsi +
        "&kab=" +
        this.id_kabkot +
        "&keyword=" +
        this.search;

      getData(this.apiGetFilter)
        .then((response) => {
          this.penduduks = response.data.data;
          console.log("data elapor dinas :", response);
          this.totalPages = response.data.last_page;
          this.laporData = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value.id;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.id_provinsi = value.provinsi;
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kabkot = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value.id;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.id_kabkot = value.kabupaten_kota;
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value.id;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.id_kecamatan = value.kecamatan;
            this.kelurahans = "";
            this.kelurahans = response;
            this.id_kelurahan = "";

            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.id_kelurahan = value.kelurahan;
      }
    },
    SearchbarChange(value) {
      this.search = value;
      this.getDataLaporFilter();
      console.log(value);
    },
    back() {
      this.$router.back();
    },
    getDataLapor() {
      const ApiGetLapor = "api/elapordinas" + "?dinas=" + this.dataItem.dinas;

      getData(ApiGetLapor)
        .then((response) => {
          // console.log("data elapor :", response);
          this.penduduks = response.data.data;
          console.log("data elapor dinas :", response);
          this.totalPages = response.data.last_page;
          this.laporData = response;

          // this.totalPages = response.data_active.last_page;
          console.log("this respons", this.laporData);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    checkLapor(item) {
      this.loading = true;
      console.log(this.items);
      let formData = new FormData();
      formData.append("nik_respon", this.userData.nik);
      const data = {
        api: "api/lapor/ask/" + item.id_lapor,
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log("data :", response);
          const arr = [response, item];
          this.detailLapor(arr);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.loading = false;
          this.snackbar.color = "red accent-2";
          this.snackbar.icon = "mdi-alert-octagon-outline";
          this.snackbar.status = "Gagal";
          if (error.response) {
            this.snackbar.visible = true;
            console.log(error.response.data.alert);
            this.msg = error.response.data.alert;
          }
        });
    },
    detailLapor(item) {
      this.$router.push({
        name: "DetailELapor",
        params: {
          dataLapor: item,
        },
      });
    },
    tambahLapor() {
      this.$router.push({
        name: "TambahELapor",
        params: {
          dataKategori: this.kategori,
        },
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY HH:SS");
      }
    },
  },
};
</script>

<style scoped>
.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}
.text-sub {
  color: #c82424;
}
.truncate {
  line-height: 1.5em;
  height: 3em; /* height is 2x line-height, so two lines will display */
  overflow: hidden; /* prevents extra lines from being visible */
  text-overflow: ellipsis;
}
.v-card__title {
  background: rgb(252, 55, 55);
}
</style>
