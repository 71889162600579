
      <template >
  <v-btn :color="warna" class="button-signup" block>{{ text }}</v-btn>
</template> 

<script>
export default {
  name: "Tombol",
  props: {
    text: String,
    warna: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}
</style>
