<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      app
      color="#F2F1F1"
      dense
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>
    <v-container style="margin-bottom: 100px">
      <v-row>
        <v-col>
          <p style="margin: 0 0 40px 0; font-size: 18px" class="mb-5">
            Di kirim : {{ dataLapor.tanggal }}
          </p>
        </v-col>
        <v-col>
          <p
            v-if="dataLapor.status == 'menunggu'"
            class="text-sub text-end"
            style="padding: 0 5px; margin: 0 5px; color: #ad9c9c"
          >
            Menunggu...
            <v-icon style="color: #ad9c9c">mdi-clock-outline</v-icon>
          </p>
          <p
            v-if="dataLapor.status == 'proses'"
            class="text-sub text-end"
            style="padding: 0 5px; margin: 0 5px; color: orange"
          >
            Diproses...
            <v-icon style="color: orange">mdi-progress-clock</v-icon>
          </p>
          <p
            v-if="dataLapor.status == 'selesai'"
            class="text-sub text-end"
            style="padding: 0 5px; margin: 0 5px; color: #4ff54c"
          >
            Selesai... <v-icon style="color: #4ff54c">mdi-check</v-icon>
          </p>
        </v-col>
      </v-row>
      <v-container>
        <p style="margin: 0 0 40px 0; font-size: 34px" class="text-center">
          {{ dataLapor.judul }}
        </p>
        <p style="margin: 0 0 40px 0; font-size: 18px" class="mb-5">
          {{ dataLapor.keterangan }}
        </p>
        <v-row>
          <v-col cols="12">
            <v-subheader style="color: black; font-size: 18px"
              >Foto</v-subheader
            >
          </v-col>
        </v-row>
        <v-slide-group class="mb-10" v-if="dataLaporPhoto.data">
          <v-slide-item
            v-for="item in dataLaporPhoto.data"
            :key="item"
            class="border-0"
          >
            <v-card
              height="100"
              width="150"
              @click="zoomImage(item.foto)"
              class="border-0 ma-1"
            >
              <v-img
                :src="item.foto"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="100px"
              >
              </v-img>
            </v-card>
          </v-slide-item>
        </v-slide-group>
        <v-row>
          <v-col cols="12">
            <v-subheader style="color: black; font-size: 18px"
              >Lokasi</v-subheader
            >
          </v-col>
        </v-row>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                readonly
                label="Alamat"
                placeholder="Alamat"
                :value="dataLapor.alamat_lengkap"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                label="Kabupaten/ Kota"
                placeholder="Kabupaten/ Kota"
                :value="dataLapor.kabupaten"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                label="Provinsi"
                placeholder="Provinsi"
                :value="dataLapor.provinsi"
              >
              </v-text-field>
            </v-col>
            <!-- <v-col cols="6">
            <v-text-field
              readonly
              label="Kecamatan"
              placeholder="Kecamatan"
              :value="dataLaporDetail.data[0].kecamatan"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              label="Kelurahan"
              placeholder="Kelurahan"
              :value="dataLaporDetail.data[0].kelurahan"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              label="RT"
              placeholder="RT"
              :value="dataLaporDetail.data[0].rt"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              label="RW"
              placeholder="RW"
              :value="dataLaporDetail.data[0].rw"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              readonly
              label="Alamat"
              placeholder="Alamat"
              :value="dataLaporDetail.data[0].alamat"
            ></v-text-field>
          </v-col> -->
          </v-row>

          <v-card>
            <Map
              :lat="parseInt(dataLapor.lat)"
              :lng="parseInt(dataLapor.long)"
              :lokasi="dataLapor.judul"
            />
          </v-card>
        </v-container>
      </v-container>
    </v-container>
    <!-- 
    <v-container style="height: 100%; margin-bottom:100px">

      
      </v-container>
      <v-row>
        <v-col sm="3" cols="5">
          <v-subheader>Pemberi</v-subheader>
        </v-col>
        <v-col sm="9" cols="7">
          <v-text-field
            readonly
            label="Pemberi"
            placeholder="Pemberi"
            :value="dataLaporDetail.data[0].pemberi"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5" sm="3">
          <v-subheader>Jumlah Bantuan</v-subheader>
        </v-col>
        <v-col cols="7" sm="3">
          <v-text-field
            readonly
            label="Jumlah"
            placeholder="Jumlah"
            :value="
              dataLaporDetail.data[0].total +
              ' ' +
              dataLaporDetail.data[0].satuan
            "
          ></v-text-field>
        </v-col>
        <v-col cols="5" sm="3">
          <v-subheader>Jumlah Yang Diberikan</v-subheader>
        </v-col>
        <v-col cols="7" sm="3">
          <v-text-field
            readonly
            label="Jumlah"
            placeholder="Jumlah"
            :value="
              dataLaporDetail.data[0].total -
              dataLaporDetail.data[0].sisa +
              ' ' +
              dataLaporDetail.data[0].satuan
            "
          >
          </v-text-field>
        </v-col>
      </v-row>

      <div style="text-align: end">
        <v-btn
          color="red"
          class="button-signup mr-5"
          @click="laporAksi()"
          v-if="dataLaporDetail.statuspetugas != 0"
        >
          Aksi</v-btn
        >

=
      </div>
    </v-container> -->
    <v-overlay :z-index="zIndex" :value="overlay">
      <v-img
        v-click-outside="onClickOutside"
        @click="overlay = false"
        :src="img"
        style="width: 40vh"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      >
      </v-img>
    </v-overlay>

    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import Footer from "../components/Footer.vue";
import { postData, getData } from "../service/ApiService";
import Map from "../components/Map.vue";

export default {
  name: "DetailELapor",
  props: ["dataLapor"],
  dialog: false,
  dialogConfirmation: false,
  components: {
    Footer,
    Map,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      token_jwt: "",
      menu: false,
      msg: "",
      overlay: false,
      zIndex: 1000,
      userData: "",
      img: null,
      dataLaporPhoto: null,
      dummy:
        "<h3>Tujuan :&nbsp;</h3><p>Floor &amp; Decor Designers provide a seamless start to finish consultative design service that builds lasting relationships and brand loyalty with the customers we serve. At Floor &amp; Decor Designers are responsible for meeting with customers, listening to their project needs, educating them on all that is required for their project, and building a creative design concept that incorporates the customers personal style, lifestyle, and budget</p><h3>Persyaratan Kelayakan :</h3><ul><li>Minimum of One (1) year of design experience&nbsp;</li><li>Design/decorating certification in lieu of 1 year design experience. BA degree in Design is a plus&nbsp;</li><li>Knowledge of hard surface flooring is a plus&nbsp;</li><li>Proficient in basic mathematical skills&nbsp;</li><li>Must be self-motivated and able to multi-task in a fast-paced environment&nbsp;</li><li>Excellent verbal, written, and interpersonal skills&nbsp;</li><li>Basic computer knowledge, including Microsoft Office suite</li></ul><p>&nbsp;</p><h3>Informasi Tambahan</h3><ul><li>Greet store customers in a helpful manner and supports store design walk-in needs&nbsp;</li><li>Schedule design appointments for existing and potential customers, while developing clientele through networking and referrals, as well as leads</li><li>generated within the store&nbsp;</li><li>Providing tile, wood, stone and accessories advice to homeowners, interior designers, and builders for both residential and commercial projects.&nbsp;</li><li>Design and sell complete, functional and aesthetically appealing solutions</li></ul>",
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataLapor.created_at
        ? moment(this.dataLapor.created_at).format("dddd, D MMM YYYY HH:m A")
        : "";
    },
  },
  mounted() {},
  created() {
    console.log(this.dataLapor);
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.getDataLapor();
  },
  methods: {
    laporAksi() {
      this.$router.push({
        name: "ELaporAksi",
        params: {
          dataELaporAksi: this.dataLapor,
        },
      });
    },
    parseInt(value) {
      var val = parseInt(value);
      return val;
    },
    getDataLapor() {
      console.log(this.items);
      const ApiGetData = "api/photo/elapor/" + this.dataLapor.id_lapor;

      getData(ApiGetData)
        .then((response) => {
          console.log("data :", response);
          this.dataLaporPhoto = response;
          console.log(this.dataLaporPhoto);
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    postDataLapor() {
      this.loading = true;
      console.log(this.items);
      let formData = new FormData();
      formData.append("nikstatus", this.userData.nik);
      console.log(this.userData.nik);
      console.log(this.userData);
      const data = {
        api: "api/bantuansosial/" + this.dataLapor.idlapor,
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log("data :", response);
          this.dataLaporPhoto = response;
          console.log(this.dataLaporPhoto);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.loading = false;
          this.snackbar.color = "red accent-2";
          this.snackbar.icon = "mdi-alert-octagon-outline";
          this.snackbar.status = "Gagal";
          if (error.response) {
            this.snackbar.visible = true;
            console.log(error.response.data.alert);
            this.msg = error.response.data.alert;
          }
        });
    },
    onClickOutside() {
      this.overlay = false;
    },
    zoomImage(item) {
      this.img = item;
      this.overlay = true;
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}

@media only screen and (min-width: 599px) {
  .text-cp {
    color: white;
    font-size: 1.3vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 1vh;
    margin: 0;
  }

  .img-bubble {
    height: 20vh;
    width: auto;
  }

  .background-image {
    height: 160px;
    width: 100%;
  }
}

@media only screen and (max-width: 1250px) {
  .img-bubble {
    height: 20vh;
    width: auto;
  }
}

@media only screen and (max-width: 970px) {
  .img-bubble {
    height: 20vh;
    width: auto;
  }
}

@media only screen and (max-width: 845px) {
  .img-bubble {
    height: 20vh;
    width: auto;
  }
}

@media only screen and (max-width: 599px) {
  .text-cp {
    color: white;
    font-size: 3vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 2vh;
    margin: 0;
  }

  .img-bubble {
    height: 10vh;
    width: auto;
  }

  .background-image {
    height: 120px;
    width: 100%;
  }
}

@media only screen and (min-width: 693px) {
  .text-cp {
    color: white;
    font-size: 5vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 2.5vh;
    margin: 0;
  }
}

@media only screen and (max-width: 693px) {
  .text-cp {
    color: white;
    font-size: 2vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 1.5vh;
    margin: 0;
  }
}
</style>