import { render, staticRenderFns } from "./TambahPariwisata.vue?vue&type=template&id=c260c8b8&scoped=true&"
import script from "./TambahPariwisata.vue?vue&type=script&lang=js&"
export * from "./TambahPariwisata.vue?vue&type=script&lang=js&"
import style0 from "./TambahPariwisata.vue?vue&type=style&index=0&id=c260c8b8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c260c8b8",
  null
  
)

export default component.exports