<template>
  <div>
    <v-dialog v-model="dialogConfirmation" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Konfirmasi </v-card-title>

        <v-card-text> Apakah anda yakin ingin keluar? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="logout()"> Ya </v-btn>

          <v-btn color="red darken-1" text @click="dialogConfirmation = false">
            Tidak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProfile" v-if="userData != null" max-width="600px">
      <v-card>
        <v-app-bar color="#F2F1F1" height="80px">
          <v-btn color="black" icon @click="dialogProfile = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="black" dark v-bind="attrs" v-on="on" icon>
                <v-icon> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="gantiPassword()">
                <v-list-item-title>Ganti Password</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
        <v-container>
          <v-row>
            <v-col cols="12" class="d-flex justify-center text-center">
              <v-avatar color="brown" size="150">
                <img v-if="userData.photo != ''" :src="userData.photo" />
                <span
                  v-if="userData.photo == ''"
                  class="white--text text-h5"
                  style="text-transuserdata: none"
                >
                  <v-icon>mdi-camera</v-icon>
                </span>
              </v-avatar>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-book-account"
                label="NIK"
                filled
                readonly
                v-model="userData.nik"
                hint="Nomor Induk Keluarga (16 Digit)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-book-open-outline"
                label="No KK"
                v-model="userData.id_kk"
                hint="Nomor Kartu Keluarga (16 Digit)"
                filled
                readonly
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-account"
                label="Nama Lengkap"
                filled
                readonly
                v-model="userData.nama"
                hint="Nama Lengkap Sesuai KTP"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-email"
                label="Email"
                filled
                readonly
                v-model="userData.email"
                hint="Email Aktif"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-account-group"
                label="Status Di KK"
                v-model="userData.status"
                hint="Status Sesuai Kartu Keluarga"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-heart"
                v-model="userData.status_pernikahan"
                label="Status Pernikahan"
                hint="Status Pernikahan Sesuai Di KTP"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-phone"
                type="tel"
                v-model="userData.nohp"
                label="Nomor Telepon"
                hint="Nomor Telepon Aktif"
                filled
                readonly
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Tanggal Lahir"
                prepend-icon="mdi-calendar"
                readonly
                filled
                :value="computedDateFormatedMomentjs"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-map-marker"
                label="Tempat Lahir"
                v-model="userData.tempatlahir"
                hint="ex:Balikpapan"
                filled
                readonly
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-gender-male-female"
                label="Jenis kelamin"
                v-model="userData.jk"
                filled
                readonly
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-account-group"
                label="Suku"
                v-model="userData.suku"
                hint="Suku"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-account-group"
                v-model="userData.agama"
                label="Agama"
                hint="Agama Sesuai KTP"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-water"
                v-model="userData.goldarah"
                label="Golongan Darah"
                hint="Golongan Darah"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-map-marker"
                label="Alamat KTP"
                v-model="userData.alamat"
                hint="Alamat Sesuai KTP"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-map-marker"
                label="Alamat Domisili"
                v-model="userData.alamat_domisili"
                hint="Alamat Tempat Tinggal Saat Ini"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-map-marker"
                label="RT"
                v-model="userData.id_rt"
                hint="RT Sesuai Tempat Tinggal Saat Ini"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-map-marker"
                label="RW"
                v-model="userData.id_rw"
                hint="RW Sesuai Tempat Tinggal Saat Ini"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-map-marker"
                v-model="userData.provinsi"
                label="Provinsi"
                item-text="provinsi"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-map-marker"
                v-model="userData.kabupaten_kota"
                label="Kabupaten/ Kota"
                item-text="kabupaten_kota"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-map-marker"
                v-model="userData.kecamatan"
                label="Kecamatan"
                item-text="kecamatan"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-map-marker"
                v-model="userData.kelurahan"
                label="Kelurahan"
                item-text="kelurahan"
                filled
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-app-bar
      app
      color="#ffff"
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <img
        style="widht: 50px; height: 50px"
        alt="Smart RT logo"
        src="../assets/smart-rt-logo.png"
      />

      <v-spacer></v-spacer>

      <div class="text-center mr-5">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="black" v-bind="attrs" v-on="on">
              <v-icon>mdi-bell</v-icon>
              <v-badge
                v-if="notifCount != 0"
                color="red"
                :content="notifCount"
              ></v-badge>
            </v-btn>
          </template>
          <v-card flat v-if="notifCount == 0">
            <p v-if="notifCount == 0" class="pt-5 pl-5">
              Tidak ada pemberitahuan.
            </p>
          </v-card>
          <v-card v-else>
            <div v-for="(item, index) in notif.info[0].data" :key="index">
              <v-list>
                <v-list-item link @click="closeMessage(item)">
                  <v-list-item-avatar color="#c82424">
                    <v-icon color="white">mdi-broadcast</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>e-Pesan</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.pengumuman
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>
            </div>
            <div v-for="(item, index) in notif.bansos[0].data" :key="index">
              <v-list>
                <v-list-item link @click="detailBansos(item)">
                  <v-list-item-avatar color="#c82424">
                    <v-icon color="white">mdi-hand-heart-outline</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>e-Bansos</v-list-item-title>
                    <v-list-item-subtitle
                      >Kamu menerima Bantuan sosial!</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>
            </div>
            <div v-for="(item, index) in notif.survey[0].data" :key="index">
              <v-list>
                <v-list-item link @click="checkSurvey(item)">
                  <v-list-item-avatar color="#c82424">
                    <v-icon color="white">mdi-chart-box-outline</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>e-Survey</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.judul_survey
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>
            </div>
            <div v-for="(item, index) in notif.voting[0].data" :key="index">
              <v-list>
                <v-list-item link @click="getDataVotingDetail(item)">
                  <v-list-item-avatar color="#c82424">
                    <v-icon color="white">mdi-vote</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>e-Voting</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.judul
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>
            </div>
            <div v-for="(item, index) in notif.lapor[0].data" :key="index">
              <v-list>
                <v-list-item link @click="notifELaporClick(item)">
                  <v-list-item-avatar color="#c82424">
                    <v-icon color="white"> mdi-help-circle-outline </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>e-Lapor</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.status
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>
            </div>
          </v-card>
        </v-menu>
      </div>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="black" v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link @click="dialogProfile = true">
            <v-list-item-avatar>
              <v-img :src="userData.photo"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="userData.nama"></v-list-item-title>
              <v-list-item-subtitle>Lihat profile anda</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item link @click="dialogProfile = true">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Profile</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item link @click="laporMasalah()">
            <v-list-item-icon>
              <v-icon>mdi-message-alert </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Laporkan Masalah</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="dialogConfirmation = true">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <template v-slot:extension>
        <v-tabs
          class="tabs"
          centered
          grow
          v-model="activeTab"
          slider-color="red"
          color="red"
        >
          <v-tab
            icon
            v-for="tab in tabs"
            :key="tab.id"
            :to="tab.route"
            exact
            @click="changeTab()"
          >
            <v-icon>{{ tab.icon }}</v-icon>
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-banner two-line v-if="this.broadcastMsg != null">
      <v-avatar slot="icon" color="red accent-4" size="40">
        <v-icon icon="mdi-lock" color="white">
          mdi-alert-octagon-outline
        </v-icon>
      </v-avatar>

      {{ this.broadcastMsg }}
      <template v-slot:actions="{ dismiss }">
        <v-btn text color="primary" @click="dismiss"> Tutup </v-btn>
      </template>
    </v-banner>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialogBroadcast"
    >
      <template>
        <v-card>
          <v-toolbar color="red" dark>Pengumuman</v-toolbar>
          <v-card-text>
            <div class="text-h2 pa-12">{{ this.broadcastMsgUrgent }}</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-checkbox
              v-model="checkboxModel"
              label="Jangan tampilkan lagi"
            ></v-checkbox>
            <v-btn text @click="closeDialog()" class="mr-5 ml-5">Tutup</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-speed-dial fixed v-model="fab" bottom left>
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark fab>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-chat-processing </v-icon>
        </v-btn>
      </template>
      <v-btn fab dark color="green" @click="whatsapp()">
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
      <v-btn @click="instagram()" fab dark color="indigo">
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
    </v-speed-dial>
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
// import FitureCardVue from "../components/FitureCard.vue";
import Footer from "../components/Footer.vue";
import { getData, postData } from "../service/ApiService";
import moment from "moment";

// import TimeAgo from "javascript-time-ago";
// import id from "javascript-time-ago/locale/id.json";

// TimeAgo.addDefaultLocale(id);

// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
export default {
  props: ["id"],
  data() {
    return {
      activeTab: "/home/beranda",
      tabs: [
        {
          id: 1,
          name: "Beranda",
          route: `/home/beranda`,
          icon: "mdi-home",
        },
        {
          id: 2,
          name: "RT",
          route: `/home/rt`,
          icon: "mdi-account-tie",
        },
        {
          id: 3,
          name: "Penduduk",
          route: `/home/penduduk`,
          icon: "mdi-account-group",
        },
      ],
      menu: false,
      dialogConfirmation: false,
      checkboxModel: false,
      userData: "",
      token_jwt: "",
      loading: false,
      msg: null,
      news: "",
      fab: false,
      notif: null,
      notifCount: 0,
      totalPages: "",
      dialogProfile: false,
      dialogBroadcast: false,
      broadcastMsg: null,
      broadcastMsgUrgent: null,
      imgs: [
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
        },
        {
          src: require("../assets/slide-1.png"),
        },
      ],
    };
  },
  beforeCreate: function () {
    console.log("home 1");
    console.log(localStorage.token_jwt);
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      if (this.token_jwt == null) {
        this.$router.push("/");
      } else {
        this.$router.push("/home/beranda");
      }
    }
  },
  name: "Home",
  components: {
    // FitureCardVue,
    Footer,
  },
  mounted() {
    console.log("home 2");
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      if (this.token_jwt == null) {
        this.$router.push("/");
      }
    }
    if (localStorage.dialogBroadcast) {
      console.log("local broad : " + localStorage.dialogBroadcast);
      var status = localStorage.dialogBroadcast;

      if (status == "true") {
        console.log("local broad true: " + localStorage.dialogBroadcast);
        this.getBroadcastUrgent();
      } else {
        console.log("local broad false: " + localStorage.dialogBroadcast);
      }
    }

    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.getNotif();
    this.getBroadcast();
  },
  created() {
    console.log(this.$route.path);
    // this.getAds();
  },
  computed: {
    computedDateFormatedMomentjs() {
      return this.userData.tgl_lahir
        ? moment(this.userData.tgl_lahir).format("DD, MMMM YYYY")
        : "";
    },
  },
  methods: {
    laporMasalah() {
      // +62 856-5481-4536
      window.open("https://wa.me/6285654814536?text=!report", "_blank");
    },
    whatsapp() {
      // +62 856-5481-4536
      window.open("https://wa.me/628115917788?text=Halo, Smart RT !", "_blank");
    },
    instagram() {
      // +62 856-5481-4536
      window.open("https://www.instagram.com/um.smartrt/", "_blank");
    },
    gantiPassword() {
      this.$router.push("/ganti-password");
    },
    detailBansos(item) {
      this.$router.push({
        name: "DetailBansos",
        params: {
          dataBansos: item,
        },
      });
    },
    getBroadcast() {
      console.log(this.items);

      const data = "api/broadcast";
      getData(data)
        .then((response) => {
          console.log("data :", response);
          this.broadcastMsg = response.data[0].pengumuman;
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    getBroadcastUrgent() {
      const data = "api/broadcast-mendesak";
      getData(data)
        .then((response) => {
          if (response.data[0].pengumuman) {
            this.broadcastMsgUrgent = response.data[0].pengumuman;
            this.dialogBroadcast = true;
          }
          console.log("data :", response);
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    getDataVotingDetail(value) {
      console.log(this.items);

      let formData = new FormData();
      formData.append("nik", this.userData.nik);
      const data = {
        api: "api/e-voting/" + value.id_evoting,
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log("data :", response);
          this.loading = false;
          if (response.result == "fail") {
            if (response.data[0].status_pilih) {
              this.msg = response.data[0].status_pilih;
              this.snackbar.color = "red accent-2";
              this.snackbar.icon = "mdi-alert-octagon-outline";
              this.snackbar.status = "Gagal";
              this.snackbar.visible = true;
              console.log(response.data);
            }
          } else {
            if (response.data_evoting) {
              this.$router.push({
                name: "DetailVoting",
                params: {
                  votingData: response,
                },
              });
            }
          }
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    notifELaporClick(item) {
      console.log(item);

      let formData = new FormData();
      formData.append("id_list", item.id_list);
      formData.append("id_lapor", item.id_lapor);

      const data = {
        api: "api/notif-lapor",
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log("data :", response);
          this.loading = false;
          this.$router.push("/mylapor");
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    checkSurvey(item) {
      this.loading = true;
      console.log(this.items);
      let formData = new FormData();
      formData.append("nik_respon", this.userData.nik);
      const data = {
        api: "api/survey/ask/" + item.id_survey,
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log("data :", response);
          const arr = [response, item];
          this.detailSurvey(arr);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.loading = false;
          this.snackbar.color = "red accent-2";
          this.snackbar.icon = "mdi-alert-octagon-outline";
          this.snackbar.status = "Gagal";
          if (error.response) {
            this.snackbar.visible = true;
            console.log(error.response.data.alert);
            this.msg = error.response.data.alert;
          }
        });
    },
    detailSurvey(item) {
      this.$router.push({
        name: "DetailSurvey",
        params: {
          items: item,
        },
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm");
      }
    },
    detailNews(data) {
      this.$router.push({
        name: "DetailNews",
        params: {
          dataNews: data,
        },
      });
    },
    changeTab() {
      this.activeTab = this.$route.path;
      console.log(this.activeTab);
    },
    getAds() {
      const ApiGetAds = "api/ads-list";

      getData(ApiGetAds)
        .then((response) => {
          console.log("data :", response);
          this.ads = response.data;
          console.log(this.ads);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getNews() {
      const ApiGetNews = "api/news" + "?page=" + this.page;

      getData(ApiGetNews)
        .then((response) => {
          console.log("data :", response);
          this.news = response.data;
          this.totalPages = response.last_page;
          console.log(this.penduduks);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getNotif() {
      const ApiNotif = "api/notif/" + this.userData.nik;

      getData(ApiNotif)
        .then((response) => {
          console.log("data :", response);
          this.notif = response;
          this.notifCount =
            this.notif.info[0].count +
            this.notif.bansos[0].count +
            this.notif.survey[0].count +
            this.notif.lapor[0].count +
            this.notif.voting[0].count;
          console.log(this.notif);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    closeMessage(item) {
      const ApiNotif = "api/broadcast/" + item;

      getData(ApiNotif)
        .then((response) => {
          console.log("data :", response);
          this.getNotif();
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    closeDialog() {
      this.dialogBroadcast = false;
      if (this.checkboxModel == true) {
        localStorage.dialogBroadcast = false;
        console.log(localStorage.dialogBroadcast);
        this.muteBroadcast();
      }
    },
    muteBroadcast() {
      console.log("mute broadcast");
    },
    logout() {
      this.$session.destroy();
      this.$router.push("/");
      console.log(localStorage.token_jwt);
      localStorage.token_jwt = null;
      console.log(localStorage.token_jwt);
    },
  },
};
</script>

<style scoped>
.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-content {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
