<template>
  <v-card>
    <v-app-bar app color="#F2F1F1" dense>
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card>
      <v-img
        @click="zoomImage(dataPariwisata.photo)"
        :src="dataPariwisata.photo"
        class="white--text align-end"
        height="250"
      >
        <v-card-subtitle>
          <h1 style="margin-bottom: 10px">{{ dataPariwisata.namawisata }}</h1>
          <p style="margin: 0; color: red">{{ dataPariwisata.kota }}</p>
        </v-card-subtitle>
      </v-img>
    </v-card>
    <v-slide-group class="mb-10" v-model="model">
      <v-slide-item
        v-for="item in pariwisataData.data.listphoto"
        :key="item"
        class="border-0"
      >
        <v-card
          height="100"
          width="150"
          @click="zoomImage(item.photowisata)"
          class="border-0 ma-1"
        >
          <v-img
            :src="item.photowisata"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="100px"
          >
          </v-img>
        </v-card>
      </v-slide-item>
    </v-slide-group>

    <v-overlay :z-index="zIndex" :value="overlay">
      <v-img
        v-click-outside="onClickOutside"
        @click="overlay = false"
        :src="img"
        style="width: 40vh"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      >
      </v-img>
    </v-overlay>

    <v-container style="height: 100%; margin-bottom: 100px">
      <div class="ma-6">
        <v-row class="mb-5">
          <v-col sm="4"></v-col>
          <v-col sm="4" cols="12">
            <p
              style="margin: 0; font-size: 25px; color: #ff8540"
              class="text-center"
            >
              Tentang
            </p>
          </v-col>
          <v-col
            sm="4"
            cols="12"
            class="text-end"
            v-if="userData.role != 'warga'"
          >
            <v-btn color="red" class="button-signup" @click="edit()">
              Edit
            </v-btn>
            <v-btn
              v-if="pariwisataData.data.wisata[0].trend != 'Rekomendasi'"
              color="red"
              class="button-signup ml-5"
              @click="rekomendasiPariwisata()"
            >
              Rekomendasikan
            </v-btn>
            <v-btn
              v-else
              color="red"
              class="button-signup ml-5"
              @click="rekomendasiPariwisata()"
            >
              Lepas Rekomendasi
            </v-btn>
          </v-col>
        </v-row>
        <p
          style="margin: 0; font-size: 15px; color: black"
          v-html="pariwisataData.data.wisata[0].desk"
        ></p>
        <v-row class="mb-2">
          <v-col> </v-col>
          <v-col
            ><p
              class="mt-10 text-center"
              style="margin: 0; font-size: 25px; color: #ff8540"
            >
              Lokasi
            </p>
          </v-col>
          <v-col class="text-end">
            <v-btn color="red" class="button-signup mt-10" @click="gmaps()"
              ><v-icon>mdi-map-marker</v-icon> GMAPS
            </v-btn>
          </v-col>
        </v-row>

        <v-card style="margin-bottom: 120px">
          <Map
            :lat="pariwisataData.data.wisata[0].lat"
            :lng="pariwisataData.data.wisata[0].lng"
            :lokasi="dataPariwisata.namawisata"
          />
        </v-card>
      </div>
    </v-container>

    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import Footer from "../components/Footer.vue";
import { postData, getData } from "../service/ApiService";
import Map from "../components/Map.vue";

export default {
  name: "DetailPariwisata",
  props: ["dataPariwisata"],
  components: { Footer, Map },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      overlay: false,
      zIndex: 1000,
      token_jwt: "",
      menu: false,
      msg: "",
      pariwisataData: null,
      img: null,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataPariwisata.created_at
        ? moment(this.dataPariwisata.created_at).format(
            "dddd, D MMM YYYY HH:m A"
          )
        : "";
    },
  },
  mounted() {},
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    if (localStorage.data_pariwisata) {
      this.dataPariwisata = JSON.parse(localStorage.data_pariwisata);
      console.log("data ELapor: ", this.dataPariwisata);
    }
    console.log(this.dataPariwisata);
    this.getDataPariwisata();
  },
  methods: {
    edit() {
      this.$router.push({
        name: "EditDataPariwisata",
        params: {
          dataParawisata: this.pariwisataData,
          idParawisata: this.dataPariwisata.idparawisata,
        },
      });
    },
    gmaps() {
      window.open(
        "http://maps.google.com/maps?z=12&t=m&q=loc:" +
          this.pariwisataData.data.wisata[0].lat +
          "+" +
          this.pariwisataData.data.wisata[0].lng +
          "",
        "_blank"
      );
    },
    innerClick() {
      alert("Click!");
    },
    onClickOutside() {
      this.overlay = false;
    },
    zoomImage(item) {
      this.img = item;
      this.overlay = true;
    },
    back() {
      this.$router.back();
    },
    getDataPariwisata() {
      console.log(this.items);
      const ApiGetPariwisata = "api/wisata/" + this.dataPariwisata.idparawisata;

      getData(ApiGetPariwisata)
        .then((response) => {
          console.log("data :", response);
          this.pariwisataData = response;
          console.log(this.pariwisataData);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    rekomendasiPariwisata() {
      this.loading = true;
      console.log(this.form);
      let formData = new FormData();
      formData.append("trend", "Rekomendasi");
      console.log(formData.values());
      const data = {
        api: "api/rekomendasi/wisata/" + this.dataPariwisata.idparawisata,
        body: formData,
      };

      postData(data)
        .then((response) => {
          console.log(response);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>