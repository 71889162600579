<template>
  <v-card class="overflow-hidden" v-if="dataBansosDetail.data">
    <v-app-bar
      app
      color="#F2F1F1"
      dense
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <v-container style="height: 100%; margin-bottom: 100px">
      <v-container>
        <p style="margin: 0 0 40px 0; font-size: 34px" class="text-center">
          {{ dataBansosDetail.data[0].nama }}
        </p>
        <p style="margin: 0 0 40px 0; font-size: 18px" class="mb-5">
          {{ dataBansosDetail.data[0].keterangan }}
        </p>
      </v-container>

      <v-row>
        <v-col cols="12">
          <v-subheader>Wilayah Tujuan</v-subheader>
        </v-col>
      </v-row>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-text-field
              readonly
              label="Kabupaten/ Kota"
              placeholder="Kabupaten/ Kota"
              :value="dataBansosDetail.data[0].kabupaten"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              label="Provinsi"
              placeholder="Provinsi"
              :value="dataBansosDetail.data[0].provinsi"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              label="Kecamatan"
              placeholder="Kecamatan"
              :value="dataBansosDetail.data[0].kecamatan"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              label="Kelurahan"
              placeholder="Kelurahan"
              :value="dataBansosDetail.data[0].kelurahan"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              label="RT"
              placeholder="RT"
              :value="dataBansosDetail.data[0].rt"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              label="RW"
              placeholder="RW"
              :value="dataBansosDetail.data[0].rw"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              readonly
              label="Alamat"
              placeholder="Alamat"
              :value="dataBansosDetail.data[0].alamat"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-row>
        <v-col sm="3" cols="5">
          <v-subheader>Pemberi</v-subheader>
        </v-col>
        <v-col sm="9" cols="7">
          <v-text-field
            readonly
            label="Pemberi"
            placeholder="Pemberi"
            :value="dataBansosDetail.data[0].pemberi"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5" sm="3">
          <v-subheader>Jumlah Bantuan</v-subheader>
        </v-col>
        <v-col cols="7" sm="3">
          <v-text-field
            readonly
            label="Jumlah"
            placeholder="Jumlah"
            :value="
              dataBansosDetail.data[0].total +
              ' ' +
              dataBansosDetail.data[0].satuan
            "
          ></v-text-field>
        </v-col>
        <v-col cols="5" sm="3">
          <v-subheader>Jumlah Yang Diberikan</v-subheader>
        </v-col>
        <v-col cols="7" sm="3">
          <v-text-field
            readonly
            label="Jumlah"
            placeholder="Jumlah"
            :value="
              dataBansosDetail.data[0].total -
              dataBansosDetail.data[0].sisa +
              ' ' +
              dataBansosDetail.data[0].satuan
            "
          >
          </v-text-field>
        </v-col>
      </v-row>

      <div style="text-align: end">
        <v-btn
          color="red"
          class="button-signup mr-5"
          @click="bansosAksi()"
          v-if="dataBansosDetail.statuspetugas != 0"
        >
          Aksi</v-btn
        >

        <!-- <v-btn
          color="red"
          class="button-signup"
          to="/tambah-bansos"
          v-if="userData.role == 'admin' || userData.role == 'superadmin'"
          >Edit</v-btn
        > -->
      </div>

      <p style="margin: 40px 0; font-size: 24px" class="mb-5">Daftar Petugas</p>
      <div style="text-align: end">
        <TambahDataPetugasBansos
          :dataItem="dataBansosDetail.data[0]"
          v-if="userData.role == 'admin' || userData.role == 'superadmin'"
        />
      </div>
      <v-list three-line>
        <template v-for="item in dataBansosDetail.petugas">
          <v-list-item :key="item.nikpetugas">
            <v-list-item-avatar tile size="80">
              <v-img :src="item.photo"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="item.nama"></v-list-item-title>
              <v-list-item-subtitle
                v-html="item.nikpetugas"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-container>

    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import Footer from "../components/Footer.vue";
import { postData } from "../service/ApiService";
import TambahDataPetugasBansos from "../components/TambahDataPetugasBansos.vue";

export default {
  name: "DetailBansos",
  props: ["dataBansos"],
  components: {
    Footer,
    TambahDataPetugasBansos,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      token_jwt: "",
      menu: false,
      msg: "",
      overlay: false,
      zIndex: 0,
      dataBansosDetail: "",
      userData: "",
      dummy:
        "<h3>Tujuan :&nbsp;</h3><p>Floor &amp; Decor Designers provide a seamless start to finish consultative design service that builds lasting relationships and brand loyalty with the customers we serve. At Floor &amp; Decor Designers are responsible for meeting with customers, listening to their project needs, educating them on all that is required for their project, and building a creative design concept that incorporates the customers personal style, lifestyle, and budget</p><h3>Persyaratan Kelayakan :</h3><ul><li>Minimum of One (1) year of design experience&nbsp;</li><li>Design/decorating certification in lieu of 1 year design experience. BA degree in Design is a plus&nbsp;</li><li>Knowledge of hard surface flooring is a plus&nbsp;</li><li>Proficient in basic mathematical skills&nbsp;</li><li>Must be self-motivated and able to multi-task in a fast-paced environment&nbsp;</li><li>Excellent verbal, written, and interpersonal skills&nbsp;</li><li>Basic computer knowledge, including Microsoft Office suite</li></ul><p>&nbsp;</p><h3>Informasi Tambahan</h3><ul><li>Greet store customers in a helpful manner and supports store design walk-in needs&nbsp;</li><li>Schedule design appointments for existing and potential customers, while developing clientele through networking and referrals, as well as leads</li><li>generated within the store&nbsp;</li><li>Providing tile, wood, stone and accessories advice to homeowners, interior designers, and builders for both residential and commercial projects.&nbsp;</li><li>Design and sell complete, functional and aesthetically appealing solutions</li></ul>",
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataBansos.created_at
        ? moment(this.dataBansos.created_at).format("dddd, D MMM YYYY HH:m A")
        : "";
    },
  },
  mounted() {},
  created() {
    console.log(this.dataBansos);
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
     if (localStorage.data_ebansos) {
      this.dataBansos = JSON.parse(localStorage.data_ebansos);
      console.log("data EBansos: ", this.dataBansos);
    }
    this.postDataBansos();
  },
  methods: {
    bansosAksi() {
      this.$router.push({
        name: "EBansosAksi",
        params: {
          dataEBansosAksi: this.dataBansos,
        },
      });
    },
    getDataBansos() {
      console.log(this.items);
      const ApiGetPariwisata = "api/bansos/" + this.dataBansos.idbansos;

      postData(ApiGetPariwisata)
        .then((response) => {
          console.log("data :", response);
          this.dataBansosDetail = response;
          console.log(this.dataBansosDetail);
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    postDataBansos() {
      this.loading = true;
      console.log(this.items);
      let formData = new FormData();
      formData.append("nikstatus", this.userData.nik);
      console.log(this.userData.nik);
      console.log(this.userData);
      const data = {
        api: "api/bantuansosial/" + this.dataBansos.idbansos,
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log("data :", response);
          this.dataBansosDetail = response;
          console.log(this.dataBansosDetail);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.loading = false;
          this.snackbar.color = "red accent-2";
          this.snackbar.icon = "mdi-alert-octagon-outline";
          this.snackbar.status = "Gagal";
          if (error.response) {
            this.snackbar.visible = true;
            console.log(error.response.data.alert);
            this.msg = error.response.data.alert;
          }
        });
    },
    onClickOutside() {
      this.overlay = false;
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}

@media only screen and (min-width: 599px) {
  .text-cp {
    color: white;
    font-size: 1.3vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 1vh;
    margin: 0;
  }

  .img-bubble {
    height: 20vh;
    width: auto;
  }

  .background-image {
    height: 160px;
    width: 100%;
  }
}

@media only screen and (max-width: 1250px) {
  .img-bubble {
    height: 20vh;
    width: auto;
  }
}

@media only screen and (max-width: 970px) {
  .img-bubble {
    height: 20vh;
    width: auto;
  }
}

@media only screen and (max-width: 845px) {
  .img-bubble {
    height: 20vh;
    width: auto;
  }
}

@media only screen and (max-width: 599px) {
  .text-cp {
    color: white;
    font-size: 3vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 2vh;
    margin: 0;
  }

  .img-bubble {
    height: 10vh;
    width: auto;
  }

  .background-image {
    height: 120px;
    width: 100%;
  }
}

@media only screen and (min-width: 693px) {
  .text-cp {
    color: white;
    font-size: 5vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 2.5vh;
    margin: 0;
  }
}

@media only screen and (max-width: 693px) {
  .text-cp {
    color: white;
    font-size: 2vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 1.5vh;
    margin: 0;
  }
}
</style>