<template>
  <v-card class="overflow-hidden">
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      {{this.msg}}
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-dialog v-model="dialogTambahData" max-width="600px">
      <v-card>
        <v-app-bar color="#F2F1F1" height="80px" elevate-on-scroll scroll-target="#scrolling-techniques-7">
          <v-btn color="black" icon v-bind="attrs" v-on="on" @click="dialogTambahData = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-app-bar-title>Daftar Usaha</v-app-bar-title>
        </v-app-bar>
        <v-card-text>
          <v-container>
            <form id="myForm" name="myForm" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
              <v-row>
                <v-col cols="12">
                  <v-text-field prepend-icon="mdi-book-account" label="Nama Usaha" required v-model="form.nama"
                    hint="Nama" :rules="[rules.required]">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-file-input label="Photo Usaha" truncate-length="15" v-model="form.foto"
                    accept="image/png, image/jpeg, image/bmp" required prepend-icon="mdi-camera"></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-text-field type="number" prepend-icon="mdi-book-account" label="Luas Tanah" required
                    v-model="form.luas_tanah" hint="Luas Tanah Usaha (m²)" suffix="m²" :rules="[rules.required]">
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field prepend-icon="mdi-book-account" label="Deskripsi Usaha" required
                    v-model="form.deskripsi" hint="deskripsi" :rules="[rules.required]">
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete prepend-icon="mdi-filter" v-model="form.kategori" :items="kategoris"
                    label="Jenis Usaha" :rules="[rules.required]" item-value="value" item-text="name" required
                    @change="(event) => selectedJenisKategori(event)"></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="9">
                  <v-autocomplete prepend-icon="mdi-filter" v-model="form.dataJenis" :items="dataJenisKategori"
                    label="Kategori" :rules="[rules.required]" item-value="id_jenis" item-text="nama"
                    :disabled="dataJenisKategori == ''" auto-select-first chips clearable deletable-chips multiple
                    small-chips>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-btn color="red" dark @click="dialogTambahJenisKategori = true">
                    Tambah+
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete prepend-icon="mdi-map-marker" v-model="form.id_provinsi"
                    :items="list_provinsi_register" label="Provinsi" :rules="[rules.required]" return-object
                    item-text="provinsi" required @change="(event) => selectedProvinsiRegister(event)"></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete prepend-icon="mdi-map-marker" v-model="form.id_kabkot" :rules="[rules.required]"
                    :items="list_kabupaten_register" label="Kabupaten/ Kota" return-object item-text="kabupaten_kota"
                    :disabled="list_kabupaten_register == ''" required
                    @change="(event) => selectedKabupatenRegister(event)">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete prepend-icon="mdi-map-marker" v-model="form.id_kecamatan"
                    :items="list_kecamatan_register" label="Kecamatan" :rules="[rules.required]" item-text="kecamatan"
                    return-object :disabled="list_kecamatan_register == ''" required
                    @change="(event) => selectedKecamatanRegister(event)">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete prepend-icon="mdi-map-marker" v-model="form.id_kelurahan"
                    :items="list_kelurahan_register" :rules="[rules.required]" return-object label="Kelurahan"
                    :disabled="list_kelurahan_register == ''" item-text="kelurahan" required
                    @change="(event) => selectedKelurahanRegister(event)"></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field prepend-icon="mdi-map-marker" :rules="[rules.required]" label="RW" v-model="form.id_rw"
                    hint="RW Sesuai Tempat Tinggal Saat Ini" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field prepend-icon="mdi-map-marker" label="RT" v-model="form.id_rt"
                    hint="RT Sesuai Tempat Tinggal Saat Ini" required></v-text-field>
                </v-col>

                <v-col cols="12" style="padding: 0">
                  <v-subheader>Titik Lokasi</v-subheader>
                </v-col>

                <div style="width: 100%; height: 500px" v-if="position.lat || userLocation.lat || defaultLocation.lat">
                  <l-map @dblclick="onMapClick($event, myobj)" ref="map" :zoom="zoom" :center="[
                      position.lat || userLocation.lat || defaultLocation.lat,
                      position.lng || userLocation.lng || defaultLocation.lng,
                    ]">
                    <l-tile-layer :url="tileProvider.url" :attribution="tileProvider.attribution" />
                    <l-geosearch :options="geoSearchOptions"></l-geosearch>
                    <l-marker v-if="position.lat && position.lng" visible draggable :icon="icon"
                      :lat-lng.sync="position" @dragstart="dragging = true" @dragend="dragging = false">
                      <l-tooltip :content="tooltipContent" :options="{ permanent: true }" />
                    </l-marker>
                  </l-map>
                </div>

                <v-btn style="color: white" color="green" type="submit" value="Submit" :loading="loading" block>
                  Simpan
                </v-btn>
                <!-- <v-btn @click="submitForm()" block> Simpan </v-btn> -->
              </v-row>
            </form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTambahJenisKategori" max-width="600px">
      <v-card>
        <v-app-bar color="#F2F1F1" height="80px" elevate-on-scroll scroll-target="#scrolling-techniques-7">
          <v-btn color="black" icon v-bind="attrs" v-on="on" @click="dialogTambahJenisKategori = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-app-bar-title>Tambah Jenis Kategori</v-app-bar-title>
        </v-app-bar>
        <v-card-text>
          <v-container>
            <form id="myForm" name="myForm" v-on:submit.prevent="submitFormJenisKategori" enctype="multipart/form-data">
              <v-row>


                <v-col cols="12">
                  <v-autocomplete prepend-icon="mdi-filter" v-model="formJenisKategori.kategori" :items="kategoris"
                    label="Jenis Usaha" :rules="[rules.required]" item-value="value" item-text="name" required>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-text-field prepend-icon="mdi-book-account" label="Nama" required v-model="formJenisKategori.nama"
                    hint="Nama Jenis Kategori" :rules="[rules.required]">
                  </v-text-field>
                </v-col>
                <v-btn style="color: white" color="green" type="submit" value="Submit" :loading="loading" block>
                  Simpan
                </v-btn>
                <!-- <v-btn @click="submitForm()" block> Simpan </v-btn> -->
              </v-row>
            </form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-app-bar app color="#F2F1F1" dense>
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="Smart Farm" icon="mdi-barn" />

    <v-container style="height: 10 0%; margin-bottom: 100px">
      <div v-if="!farmData.data">
        <v-skeleton-loader class="mx-auto mb-10" max-width="100%" type="card"></v-skeleton-loader>

        <v-skeleton-loader class="mx-auto mb-10" max-width="100%" type="card"></v-skeleton-loader>

        <v-slide-group class="mb-10 mt-10" v-model="model" active-class="success" show-arrows>
          <v-slide-item v-for="n in 15" :key="n">
            <v-skeleton-loader class="mx-auto mr-10" max-width="150px" type="card" style="width: 150px">
            </v-skeleton-loader>
          </v-slide-item>
        </v-slide-group>
      </div>

      <v-row>
        <v-col cols="6">
          <p style="color: #c82424; font-size: 20px" v-if="userData.role != 'superadmin'">
            {{ userData.provinsi }}, {{ userData.kabupaten_kota }}
          </p>
          <p style="color: #c82424; font-size: 20px" v-else>Semua Wilayah</p>
        </v-col>
        <v-col cols="6" class="flex justify-end text-right mb-2">
          <v-btn v-if="userData.role == 'superadmin'" @click="tambahData()" color="red" dark> Tambah Data </v-btn>
        </v-col>
      </v-row>
      <div v-if="farmData.data">
        <v-expansion-panels v-show="userData.role == 'superadmin'">
          <v-expansion-panel>
            <v-expansion-panel-header> Filter </v-expansion-panel-header>
            <v-expansion-panel-content class="text-center">
              <v-autocomplete prepend-icon="mdi-map-marker" label="Provinsi" v-model="data_provinsi"
                :items="list_provinsi" item-text="provinsi" return-object required
                @change="(event) => selectedProvinsi(event)"></v-autocomplete>
              <v-autocomplete prepend-icon="mdi-map-marker" v-model="data_kabkot" :items="list_kabupaten"
                label="Kabupaten/ Kota" return-object item-text="kabupaten_kota" :disabled="list_kabupaten == ''"
                required @change="(event) => selectedKabupaten(event)"></v-autocomplete>
              <v-autocomplete prepend-icon="mdi-map-marker" v-model="data_kecamatan" :items="list_kecamatan"
                label="Kecamatan" return-object item-text="kecamatan" :disabled="list_kecamatan == ''" required
                @change="(event) => selectedKecamatan(event)"></v-autocomplete>
              <v-autocomplete prepend-icon="mdi-map-marker" v-model="data_kelurahan" :items="list_kelurahan"
                label="Kelurahan" return-object item-text="kelurahan" :disabled="list_kelurahan == ''" required
                @change="(event) => selectedKelurahan(event)"></v-autocomplete>
              <v-autocomplete prepend-icon="mdi-filter" label="Kategori" v-model="kategori" :items="tags"
                item-text="name" item-value="value" required @change="(event) => selectedKategori(event)">
              </v-autocomplete>
              <v-btn color="red" class="button-signup" block v-on:click="getDataFarm(kategori)">Submit</v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Cari Vote.." single-line filled outlined
          hide-details @input="getDataFarm(kategori)" style="width: 100%">
        </v-text-field>
        <span v-if="
            (userData.role == 'superadmin' && data_provinsi.provinsi != '') ||
            (userData.role == 'superadmin' && kategori != '')
          " style="color: black; margin: 0; font-size: 16px">
          Filter :
        </span>
        <span v-show="userData.role == 'superadmin'">
          <v-chip v-if="kategori != ''" class="ma-2" close @click:close="
              kategori = '';
              kategori = '';
            ">
            {{ kategori }}
          </v-chip>
          <v-chip v-if="data_provinsi.provinsi != ''" class="ma-2" close @click:close="
              data_provinsi.provinsi = '';
              data_provinsi.id = '';
              data_kabkot.kabupaten_kota = '';
              data_kabkot.id = '';
              data_kecamatan.kecamatan = '';
              data_kecamatan.id = '';
              data_kelurahan.kelurahan = '';
              data_kelurahan.id = '';
              list_kabupaten = '';
              list_kecamatan = '';
              list_kelurahan = '';
            ">
            {{ data_provinsi.provinsi }}
          </v-chip>
          <v-chip v-if="data_kabkot.kabupaten_kota != ''" class="ma-2" close @click:close="
              data_kabkot.kabupaten_kota = '';
              data_kabkot.id = '';
              data_kecamatan.kecamatan = '';
              data_kecamatan.id = '';
              list_kecamatan = '';
              data_kelurahan.kelurahan = '';
              data_kelurahan.id = '';
              list_kelurahan = '';
            ">
            {{ data_kabkot.kabupaten_kota }}
          </v-chip>
          <v-chip v-if="data_kecamatan.kecamatan != ''" class="ma-2" close @click:close="
              data_kelurahan.kelurahan = '';
              data_kelurahan.id = '';
              data_kecamatan.kecamatan = '';
              data_kecamatan.id = '';
              list_kelurahan = '';
            ">
            {{ data_kecamatan.kecamatan }}
          </v-chip>
          <v-chip v-if="data_kelurahan.kelurahan != ''" class="ma-2" close @click:close="
              data_kelurahan.kelurahan = '';
              data_kelurahan.id = '';
            ">
            {{ data_kelurahan.kelurahan }}
          </v-chip>
        </span>
        <v-chip-group mandatory v-if="userData.role != 'superadmin'" v-model="kategori">
          <v-chip v-for="tag in tags" :key="tag" :value="tag.value" color="#FF8540" text-color="white"
            active-class="white--text red" @click="getDataFarm(tag.value)">
            {{ tag.name }}
          </v-chip>
        </v-chip-group>

        <div v-if="farmData.data.length == 0">
          <p class="text-center ma-10">
            Tidak menemukan data usaha.
          </p>
        </div>
        <v-card color="#F2F2F2" class="mb-10 mt-5" v-for="(item, index) in farmData.data" :key="index"
          @click="detailSmartFarm(item)">
          <v-img :src="item.foto" class="white--text align-end d-sm-none d-flex"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px">
            <v-card-title>
              {{ item.nama }}
            </v-card-title>
          </v-img>

          <div class="d-sm-none d-flex">
            <v-card-subtitle>
              <div class="truncate">
                <p>
                  {{ item.deskripsi }}
                </p>
              </div>

              <v-chip color="#FF8540" text-color="white">
                {{ item.kategori }}
              </v-chip>
            </v-card-subtitle>
          </div>

          <div class="d-none d-sm-flex">
            <v-avatar tile height="100%" width="200px">
              <v-img :src="item.foto" class="white--text align-end d-none d-sm-flex"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="170px">
              </v-img>
            </v-avatar>
            <v-card-subtitle>
              <p style="font-size: 24px" class="d-none d-sm-flex">
                {{ item.nama }}
              </p>

              <div class="truncate">
                <p>
                  {{ item.deskripsi }}
                </p>
              </div>

              <v-chip color="#FF8540" text-color="white">
                {{ item.kategori }}
              </v-chip>
            </v-card-subtitle>
          </div>
        </v-card>

        <div class="text-center">
          <v-pagination :loading="loading" v-model="page" :length="totalPages" total-visible="7"
            next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="handlePageChange"></v-pagination>
        </div>
      </div>
    </v-container>
    <Footer />
  </v-card>
</template>
<script>
  // import FitureCardVue from '../components/FitureCard.vue';
  // import NewsCardVue from '../components/NewsCard.vue';
  // import Tombol from '../components/Tombol.vue';
  // @ is an alias to /src
  // import axios from "axios";
  import moment from "moment";
  import Footer from "../components/Footer.vue";
  import Header from "../components/Header.vue";
  import {
    getData,
    postData
  } from "../service/ApiService";

  import {
    LMap,
    LMarker,
    LTileLayer,
    LTooltip
  } from "vue2-leaflet";
  import {
    OpenStreetMapProvider
  } from "leaflet-geosearch";
  import LGeosearch from "vue2-leaflet-geosearch";
  import {
    icon
  } from "leaflet";

  export default {
    name: "SmartFarm",
    components: {
      Footer,
      Header,
      LMap,
      LTileLayer,
      LMarker,
      LTooltip,
      LGeosearch,
    },
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push("/");
      }
    },
    data() {
      return {
        value: {
          type: 1,
          required: true,
        },
        defaultLocation: {
          type: {},
          default: () => ({
            lat: 8.9806,
            lng: 38.7578,
          }),
        },
        loading: false,
        geoSearchOptions: {
          provider: new OpenStreetMapProvider(),
          showMarker: false,
          autoClose: true,
        },
        userLocation: {},
        icon: icon({
          iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
          iconUrl: require("leaflet/dist/images/marker-icon.png"),
          shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        }),
        position: {},
        address: "",
        tileProvider: {
          attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
        zoom: 18,
        dragging: false,
        model: null,
        kategoriData: null,
        kategori: "",
        list_provinsi: "",
        list_kabupaten: "",
        list_kelurahan: "",
        list_kecamatan: "",
        list_provinsi_register: "",
        list_kabupaten_register: "",
        list_kelurahan_register: "",
        list_kecamatan_register: "",
        id_provinsi: "",
        id_kabkot: "",
        id_kelurahan: "",
        id_kecamatan: "",
        data_provinsi: null,
        data_kabkot: null,
        dataJenisKategori: "",
        data_kelurahan: null,
        data_kecamatan: null,
        dialogTambahData: false,
        dialogTambahJenisKategori: false,
        snackbar: false,
        snackbar_error: false,
        search: "",
        token_jwt: "",
        menu: false,
        msg: "",
        page: 1,
        farmData: "",
        startTime: "July 7, 2017 12:03:00",
        endTime: "July 9, 2022 14:55:00",
        userData: "",
        rules: {
          required: (value) => !!value || "Wajib di isi.",
        },
        form: {
          nama: null,
          luas_tanah: null,
          deskripsi: null,
          kategori: null,
          id_provinsi: null,
          id_kabkot: null,
          id_kecamatan: null,
          id_kelurahan: null,
          id_rw: null,
          id_rt: null,
          lat: null,
          lng: null,
          dataJenis: null,
          foto: null,
        },
        formJenisKategori: {
          kategori: null,
          nama: null
        },
        tags: [{
            name: "Semua",
            value: "",
          },
          {
            name: "Pertanian",
            value: "Pertanian",
          },
          {
            name: "Perkebunan",
            value: "Perkebunan",
          },
          {
            name: "Perternakan",
            value: "Perternakan",
          },
          {
            name: "Perikanan",
            value: "Perikanan",
          },
        ],
        kategoris: [{
            name: "Pertanian",
            value: "pertanian",
          },
          {
            name: "Perkebunan",
            value: "perkebunan",
          },
          {
            name: "Perternakan",
            value: "perternakan",
          },
          {
            name: "Perikanan",
            value: "perikanan",
          },
        ],
        times: [{
            id: 0,
            text: "Days",
            time: 1,
          },
          {
            id: 1,
            text: "Hours",
            time: 1,
          },
          {
            id: 2,
            text: "Minutes",
            time: 1,
          },
          {
            id: 3,
            text: "Seconds",
            time: 1,
          },
        ],
        progress: 100,
        // isActive: false,
        timeinterval: undefined,
      };
    },
    watch: {
      position: {
        deep: true,
        async handler(value) {
          this.address = await this.getAddress();
          this.$emit("input", {
            position: value,
            address: this.address,
          });
        },
      },
    },
    computed: {
      tooltipContent() {
        if (this.dragging) return "...";
        if (this.loading) return "Loading...";
        return `<strong>${this.address.replace(
        ",",
        "<br/>"
      )}</strong> <hr/><strong>lat:</strong> ${
        this.position.lat
      }<br/> <strong>lng:</strong> ${this.position.lng}`;
      },
    },
    created() {
      console.log(this.data_provinsi);
      if (localStorage.user_data) {
        this.userData = JSON.parse(localStorage.user_data).data[0];
        console.log("data user: ", this.userData);
      }

      this.data_provinsi = {
        provinsi: this.userData.provinsi,
        id: this.userData.id_provinsi,
      };
      this.data_kabkot = {
        kabupaten_kota: this.userData.kabupaten_kota,
        id: this.userData.id_kabkot,
      };
      this.data_kecamatan = {
        kecamatan: this.userData.kecamatan,
        id: this.userData.id_kecamatan,
      };
      this.data_kelurahan = {
        kelurahan: this.userData.kelurahan,
        id: this.userData.id_kelurahan,
      };

      this.form.id_provinsi = {
        provinsi: this.userData.provinsi,
        id: this.userData.id_provinsi,
      };
      this.form.id_kabkot = {
        kabupaten_kota: this.userData.kabupaten_kota,
        id: this.userData.id_kabkot,
      };
      this.form.id_kecamatan = {
        kecamatan: this.userData.kecamatan,
        id: this.userData.id_kecamatan,
      };
      this.form.id_kelurahan = {
        kelurahan: this.userData.kelurahan,
        id: this.userData.id_kelurahan,
      };

      this.getDataFarm("");
      this.getListProvinsi();
    },
    methods: {
      submitForm() {
        this.loading = true;
        console.log(this.form);
        let formData = new FormData();
        formData.append("nama", this.form.nama);
        formData.append("luas_tanah", this.form.luas_tanah);
        formData.append("deskripsi", this.form.deskripsi);
        formData.append("kategori", this.form.kategori);
        formData.append("id_provinsi", this.form.id_provinsi.id);
        formData.append("id_kabkot", this.form.id_kabkot.id);
        formData.append("id_kecamatan", this.form.id_kecamatan.id);
        formData.append("id_kelurahan", this.form.id_kelurahan.id);
        formData.append("id_rw", this.form.id_rw);
        formData.append("id_rt", this.form.id_rt);
        formData.append("lat", this.form.lat);
        formData.append("lng", this.form.lng);
        formData.append("foto", this.form.foto);

        this.form.dataJenis.forEach((item, i) => {
          formData.append("id_jenis[" + i + "]", item);
        });

        console.log(formData.values());
        const data = {
          api: "api/create-farm",
          body: formData,
        };
        postData(data)
          .then((response) => {
            console.log(response);
            this.msg = "Berhasil mendaftarkan usaha.";
            this.snackbar = true;
            this.dialogTambahData = false;
            this.loading = false;
            this.getDataFarm();
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response);
            this.loading = false;
            if (error.response) {
              console.log(error.response.data);
              if (error.response.data) {
                this.msg_error = error.response.data;
                this.snackbar_error = true;
              }
            }
          });
      },
      submitFormJenisKategori() {
        this.loading = true;
        console.log(this.form);
        let formData = new FormData();
        formData.append("nama", this.formJenisKategori.nama);
        formData.append("kategori", this.formJenisKategori.kategori);

        console.log(formData.values());
        const data = {
          api: "api/create-farm-jenis",
          body: formData,
        };
        postData(data)
          .then((response) => {
            console.log(response);
            this.msg = "Berhasil menambahkan jenis kategori.";
            this.snackbar = true;
            this.dialogTambahJenisKategori = false;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response);
            this.loading = false;
            if (error.response) {
              console.log(error.response.data);
              if (error.response.data) {
                this.msg_error = error.response.data;
                this.snackbar_error = true;
              }
            }
          });
      },
      tambahData() {
        this.dialogTambahData = true;
        this.getUserPosition();
        this.$refs.map.mapObject.on("geosearch/showlocation", this.onSearch);
      },
      async getAddress() {
        this.loading = true;
        let address = "Unresolved address";
        try {
          const {
            lat,
            lng
          } = this.position;
          const result = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
          );
          if (result.status === 200) {
            const body = await result.json();
            address = body.display_name;
          }
        } catch (e) {
          console.error("Reverse Geocode Error->", e);
        }
        this.loading = false;
        console.log(address);
        return address;
      },
      async onMapClick(value) {
        // place the marker on the clicked spot
        console.log(value.latlng);
        this.position = value.latlng;
        this.form.lat = this.position.lat;
        this.form.lng = this.position.lng;
      },
      onSearch(value) {
        const loc = value.location;
        this.position = {
          lat: loc.y,
          lng: loc.x,
        };
        this.form.lat = this.position.lat;
        this.form.lng = this.position.lng;
        console.log(this.position);
      },
      async getUserPosition() {
        if (navigator.geolocation) {
          // get GPS position
          navigator.geolocation.getCurrentPosition((pos) => {
            // set the user location
            this.userLocation = {
              lat: pos.coords.latitude,
              lng: pos.coords.longitude,
            };
            console.log(this.userLocation);
            this.form.lat = this.userLocation.lat;
            this.form.lng = this.userLocation.lng;
            this.position = this.userLocation;
          });
        }
      },
      getListProvinsi() {
        const apiListProvinsi = "api/provinsi";
        getData(apiListProvinsi)
          .then((response) => {
            console.log("data provinsi :", response);
            this.list_provinsi = response;
            this.list_provinsi_register = response;
            console.log(this.provinsi);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      },
      selectedJenisKategori(value) {
        console.log(value);
        if (value != null) {
          const api = "api/farm-jenis?kategori=" + value;

          getData(api)
            .then((response) => {
              console.log("data jenis kategori :", response);

              this.dataJenisKategori = response.data;
              console.log(this.dataJenisKategori);
            })
            .catch((error) => {
              console.log("pesan error :", error.response.data);
              this.msg = true;
            });
        }
      },
      selectedProvinsi(value) {
        console.log(value);
        if (value != null) {
          const apiListKabupaten = "api/kabkot?keyword=" + value.id;

          getData(apiListKabupaten)
            .then((response) => {
              console.log("data kabupaten :", response);
              this.data_provinsi = value;
              this.data_kabkot.kabupaten_kota = "";
              this.data_kabkot.id = "";
              this.data_kelurahan.kelurahan = "";
              this.data_kelurahan.id = "";
              this.data_kecamatan.kecamatan = "";
              this.data_kecamatan.id = "";
              this.list_kabupaten = "";
              this.list_kelurahan = "";
              this.list_kecamatan = "";

              this.list_kabupaten = response;
              console.log(this.list_kabupaten);
            })
            .catch((error) => {
              console.log("pesan error :", error.response.data);
              this.msg = true;
            });
        }
      },
      selectedKabupaten(value) {
        console.log(value);
        if (value != null) {
          const apiListKecamatan = "api/kec?keyword=" + value.id;

          getData(apiListKecamatan)
            .then((response) => {
              console.log("data kecamatan :", response);
              this.data_kabkot = value;
              this.data_kelurahan.kelurahan = "";
              this.data_kelurahan.id = "";
              this.data_kecamatan.kecamatan = "";
              this.data_kecamatan.id = "";
              this.list_kelurahan = "";
              this.list_kecamatan = "";

              this.list_kecamatan = response;
              console.log(this.list_kecamatan);
            })
            .catch((error) => {
              console.log("pesan error :", error.response.data);
              this.msg = true;
            });
        }
      },
      selectedKecamatan(value) {
        console.log(value);
        if (value != null) {
          const apiListKelurahan = "api/kel?keyword=" + value.id;

          getData(apiListKelurahan)
            .then((response) => {
              console.log("data kelurahan :", response);
              this.data_kecamatan = value;
              this.data_kelurahan.kelurahan = "";
              this.data_kelurahan.id = "";
              this.list_kelurahan = "";
              this.list_kelurahan = response;

              console.log(this.list_kelurahan);
            })
            .catch((error) => {
              console.log("pesan error :", error.response.data);
              this.msg = true;
            });
        }
      },
      selectedKelurahan(value) {
        console.log(value);
        if (value != null) {
          this.data_kelurahan = value;
        }
      },
      selectedProvinsiRegister(value) {
        console.log(value);
        if (value != null) {
          const apiListKabupaten = "api/kabkot?keyword=" + value.id;

          getData(apiListKabupaten)
            .then((response) => {
              console.log("data kabupaten :", response);
              this.form.id_provinsi = value;
              this.form.id_kabkot.kabupaten_kota = "";
              this.form.id_kabkot.id = "";
              this.form.id_kelurahan.kelurahan = "";
              this.form.id_kelurahan.id = "";
              this.form.id_kecamatan.kecamatan = "";
              this.form.id_kecamatan.id = "";
              this.list_kabupaten_register = "";
              this.list_kelurahan_register = "";
              this.list_kecamatan_register = "";

              this.list_kabupaten_register = response;
              console.log(this.list_kabupaten_register);
            })
            .catch((error) => {
              console.log("pesan error :", error.response.data);
              this.msg = true;
            });
        }
      },
      selectedKabupatenRegister(value) {
        console.log(value);
        if (value != null) {
          const apiListKecamatan = "api/kec?keyword=" + value.id;

          getData(apiListKecamatan)
            .then((response) => {
              console.log("data kecamatan :", response);
              this.form.id_kabkot = value;
              this.form.id_kelurahan.kelurahan = "";
              this.form.id_kelurahan.id = "";
              this.form.id_kecamatan.kecamatan = "";
              this.form.id_kecamatan.id = "";
              this.list_kelurahan_register = "";
              this.list_kecamatan_register = "";

              this.list_kecamatan_register = response;
              console.log(this.list_kecamatan_register);
            })
            .catch((error) => {
              console.log("pesan error :", error.response.data);
              this.msg = true;
            });
        }
      },
      selectedKecamatanRegister(value) {
        console.log(value);
        if (value != null) {
          const apiListKelurahan = "api/kel?keyword=" + value.id;

          getData(apiListKelurahan)
            .then((response) => {
              console.log("data kelurahan :", response);
              this.form.id_kecamatan = value;
              this.form.id_kelurahan.kelurahan = "";
              this.form.id_kelurahan.id = "";
              this.list_kelurahan_register = "";
              this.list_kelurahan_register = response;

              console.log(this.list_kelurahan_register);
            })
            .catch((error) => {
              console.log("pesan error :", error.response.data);
              this.msg = true;
            });
        }
      },
      selectedKelurahanRegister(value) {
        console.log(value);
        if (value != null) {
          this.form.id_kelurahan = value;
        }
      },
      getListKategori() {
        const apiListKategori = "api/loker/id/kategori";
        getData(apiListKategori)
          .then((response) => {
            console.log("data kategori :", response);
            this.kategoriData = response.data;
            console.log(this.kategoriData);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      },
      evotingFinish() {
        this.$router.push({
          name: "EVotingFinish",
        });
      },
      handlePageChange(value) {
        this.page = value;
        this.getDataLokerFilter();
      },
      getDataLokerFilter() {
        this.apiGetFilter =
          "api/farm?prov=" +
          "?page=" +
          this.page +
          "&prov=" +
          this.data_provinsi.id +
          "&kab=" +
          this.data_kabkot.id +
          "&kec=" +
          this.data_kecamatan.id +
          "&kel=" +
          this.data_kelurahan.id +
          "&kategori=" +
          this.kategori +
          "&keyword=" +
          this.search;
        getData(this.apiGetFilter)
          .then((response) => {
            console.log("data :", response);
            this.farmData = response.data;
            this.totalPages = response.data.last_page;
            console.log(this.penduduks);
            this.loading = false;
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      },
      selectedKategori(value) {
        this.kategori = value;
        console.log(value);
      },
      SearchbarChange(value) {
        this.search = value;
        this.getDataLokerFilter();
        console.log(value);
      },
      back() {
        this.$router.back();
      },
      getDataFarm(value) {
        const apiGetVoting =
          "api/farm" +
          "?page=" +
          this.page +
          "&prov=" +
          this.data_provinsi.id +
          "&kab=" +
          this.data_kabkot.id +
          "&kec=" +
          this.data_kecamatan.id +
          "&kel=" +
          this.data_kelurahan.id +
          "&kategori=" +
          value +
          "&keyword=" +
          this.search;

        getData(apiGetVoting)
          .then((response) => {
            console.log("data :", response);
            this.farmData = response.data;

            this.totalPages = response.data.last_page;
            console.log(this.farmData);
            this.loading = false;
          })
          .catch((error) => {
            console.log("pesan error :", error);
            this.msg = true;
          });
      },
      getDataKategoriFarm() {
        const apiGetVoting = "api/farm-jenis?kategori=" + this.form.kategori;

        getData(apiGetVoting)
          .then((response) => {
            console.log("data :", response);
            this.dataJenisKategori = response.data;

            console.log(this.dataJenisKategori);
            this.loading = false;
          })
          .catch((error) => {
            console.log("pesan error :", error);
            this.msg = true;
          });
      },
      detailSmartFarm(item) {
        console.log(item);
        this.$router.push({
          name: "DetailSmartFarm",
          params: {
            item: item,
          },
        });
      },
      hasilVoting(item) {
        this.$router.push({
          name: "HasilVoting",
          params: {
            items: item.id_evoting,
          },
        });
      },
      tambahloker() {
        this.$router.push({
          name: "TambahLoker",
        });
      },
      format_date(value) {
        if (value) {
          return moment(String(value)).format("DD/MMM/YYYY HH:SS");
        }
      },
    },
  };
</script>

<style scoped>
  .button-signup {
    border-radius: 10px;
    color: white !important;
    text-transform: none;
    text-decoration: none;
  }

  .text-sub {
    color: #c82424;
  }

  .truncate {
    line-height: 1.5em;
    height: 3em;
    /* height is 2x line-height, so two lines will display */
    overflow: hidden;
    /* prevents extra lines from being visible */
  }
</style>