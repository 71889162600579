<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil mendaftarkan akun petugas
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="600px">
      <template
        v-slot:activator="{ on, attrs }"
        class="flex justify-end text-right"
      >
        <v-btn color="red" class="button-signup" v-bind="attrs" v-on="on"
          >Tambah Petugas</v-btn
        >
      </template>
      <v-card>
        <v-app-bar
          color="#F2F1F1"
          height="80px"
          elevate-on-scroll
          scroll-target="#scrolling-techniques-7"
        >
          <v-btn
            color="black"
            icon
            v-bind="attrs"
            v-on="on"
            @click="dialog = false"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="9">
                <v-text-field
                  v-model="model"
                  :loading="isLoading"
                  item-text="Description"
                  item-value="API"
                  label="NIK Petugas"
                  placeholder="Cari NIK .."
                  prepend-icon="mdi-database-search"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn color="red" class="button-signup" @click="searchNIK()"
                  >Cek</v-btn
                >
              </v-col>
            </v-row>
            <v-text-field
              filled
              readonly
              :value="nama"
              label="Nama Petugas"
              prepend-icon="mdi-account"
            ></v-text-field>
            <v-btn color="red" class="button-signup" @click="submitForm()"
              >Submit</v-btn
            >
            <!-- <form
              id="myForm"
              name="myForm"
              v-on:submit.prevent="submitForm"
              enctype="multipart/form-data"
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-book-account"
                    label="NIK"
                    required
                    counter="16"
                    v-model="form.nik"
                    hint="Nomor Induk Keluarga (16 Digit)"
                    :rules="[rules.required, rules.min_16]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-book-open-outline"
                    label="No KK"
                    required
                    counter="16"
                    v-model="form.id_kk"
                    hint="Nomor Kartu Keluarga (16 Digit)"
                    :rules="[rules.required, rules.min_16]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-account"
                    label="Nama Lengkap"
                    required
                    :rules="[rules.required, rules.min_16]"
                    v-model="form.nama"
                    hint="Nama Lengkap Sesuai KTP"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-email"
                    label="Email"
                    :rules="[rules.required]"
                    required
                    v-model="form.email"
                    hint="Email Aktif"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="userData.role == 'superadmin'">
                  <v-autocomplete
                    prepend-icon="mdi-account"
                    v-model="form.id_role"
                    :items="roles"
                    :rules="[rules.required]"
                    item-value="id"
                    item-text="nama"
                    label="Role"
                    hint="Role"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    counter
                    @click:append="show1 = !show1"
                    prepend-icon="mdi-lock"
                    label="Password"
                    required
                    v-model="form.password"
                    hint="Password Minimal 8 Digit"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-account-group"
                    label="Status Di KK"
                    v-model="form.status"
                    hint="Status Sesuai Kartu Keluarga"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    prepend-icon="mdi-heart"
                    v-model="form.status_pernikahan"
                    :items="status_pernikahans"
                    :rules="[rules.required]"
                    label="Status Pernikahan"
                    hint="Status Pernikahan Sesuai Di KTP"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-phone"
                    type="tel"
                    v-model="form.nohp"
                    label="Nomor Telepon"
                    hint="Nomor Telepon Aktif"
                    :rules="[numberRule, rules.required]"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    :rules="[rules.required]"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Tanggal Lahir"
                        prepend-icon="mdi-calendar"
                        readonly
                        :rules="[rules.required]"
                        :value="computedDateFormattedMomentjs"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :rules="[rules.required]"
                      v-model="form.tgl_lahir"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :rules="[rules.required]"
                    prepend-icon="mdi-map-marker"
                    label="Tempat Lahir"
                    v-model="form.tempatlahir"
                    hint="ex:Balikpapan"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-radio-group
                    required
                    :rules="[rules.required]"
                    label="Jenis Kelamin"
                    v-model="form.jk"
                    prepend-icon="mdi-gender-male-female"
                  >
                    <v-radio label="Pria" value="pria"></v-radio>
                    <v-radio label="Wanita" value="wanita"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-account-group"
                    label="Suku"
                    :rules="[rules.required]"
                    v-model="form.suku"
                    hint="Suku"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    prepend-icon="mdi-account-group"
                    v-model="form.agama"
                    :items="agamas"
                    :rules="[rules.required]"
                    label="Agama"
                    hint="Agama Sesuai KTP"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    prepend-icon="mdi-water"
                    v-model="form.goldarah"
                    :rules="[rules.required]"
                    :items="goldars"
                    label="Golongan Darah"
                    hint="Golongan Darah"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    label="Alamat KTP"
                    v-model="form.alamat"
                    :rules="[rules.required]"
                    hint="Alamat Sesuai KTP"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    label="Alamat Domisili"
                    :rules="[rules.required]"
                    v-model="form.alamat_domisili"
                    hint="Alamat Tempat Tinggal Saat Ini"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    label="RT"
                    v-model="form.id_rt"
                    hint="RT Sesuai Tempat Tinggal Saat Ini"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    :rules="[rules.required]"
                    label="RW"
                    v-model="form.id_rw"
                    hint="RW Sesuai Tempat Tinggal Saat Ini"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="form.id_provinsi"
                    :items="provinsis"
                    label="Provinsi"
                    :rules="[rules.required]"
                    item-value="id"
                    item-text="provinsi"
                    required
                    @change="(event) => selectedProvinsi(event)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="form.id_kabkot"
                    :rules="[rules.required]"
                    :items="kabupatens"
                    label="Kabupaten/ Kota"
                    item-value="id"
                    item-text="kabupaten_kota"
                    :disabled="kabupatens == ''"
                    required
                    @change="(event) => selectedKabupaten(event)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="form.id_kecamatan"
                    :items="kecamatans"
                    label="Kecamatan"
                    :rules="[rules.required]"
                    item-text="kecamatan"
                    item-value="id"
                    :disabled="kecamatans == ''"
                    required
                    @change="(event) => selectedKecamatan(event)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="form.id_kelurahan"
                    :items="kelurahans"
                    :rules="[rules.required]"
                    item-value="id"
                    label="Kelurahan"
                    :disabled="kelurahans == ''"
                    item-text="kelurahan"
                    required
                    @change="(event) => selectedKelurahan(event)"
                  ></v-autocomplete>
                </v-col>
                <v-btn
                  style="color: white"
                  color="green"
                  type="submit"
                  value="Submit"
                  :loading="loading"
                  block
                >
                  Simpan
                </v-btn>
              </v-row>
            </form> -->
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import { getData, postDataString } from "../service/ApiService";

export default {
  name: "TambahDataPetugasBansos",
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  props: {
    dataItem: [],
  },
  data() {
    return {
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: "",
      nama: "",
      nikpetugas: "",
      search: null,
      show1: false,
      snackbar: false,
      loading: false,
      snackbar_error: false,
      msg_error: "",
      url: "",
      token_jwt: "",
      dialog: false,
      activePicker: null,
      menu: false,
      items: "",
      userData: "",
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.form.tgl_lahir
        ? moment(this.form.tgl_lahir).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    if (this.snackbar) {
      this.hide_alert();
    }

    if (this.snackbar_error) {
      this.hide_alert_error();
    }

    this.getListProvinsi();
  },
  created() {
    console.log("data item :", this.dataItem);
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
  },
  methods: {
    searchNIK() {
      console.log(this.model);
      this.isLoading = true;
      const apiListData = "api/pencarian/" + this.model;

      getData(apiListData)
        .then((response) => {
          console.log("items :", response);
          this.nama = response.data[0].nama;
          this.nikpetugas = this.model;
          console.log(this.nama);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.nama = "NIK Tidak Ditemukan.";
          console.log("pesan error :", error);
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    hide_alert: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    hide_alert_error: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar_error = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.form.id_provinsi = value;
            this.form.id_kabkot = "";
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.form.id_kabkot = value;
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.form.id_kecamatan = value;
            this.form.id_kelurahan = "";
            this.kelurahans = "";

            this.kelurahans = response;
            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.form.id_kelurahan = value;
      }
    },
    submitForm() {
      this.loading = true;
      // let formData = new FormData();
      const dataArrayPetugas = [
        {
          nikpetugas: this.nikpetugas,
          idbansos: this.dataItem.idbansos,
        },
      ];
      const dataArrayStatus = [this.userData.nik];
      // formData.append("data", dataArrayPetugas);
      // formData.append("nikstatus", dataArrayStatus);
      console.log(
        "data :",
        dataArrayPetugas,
        "===========",
        "nikstatus :",
        dataArrayStatus
      );
      const data = {
        api: "api/petugas/bansos",
        body: {
          data: dataArrayPetugas,
          nikstatus: dataArrayStatus,
        },
      };
      console.log(data);
      postDataString(data)
        .then((response) => {
          console.log(response);
          this.dialog = false;
          this.loading = false;
          this.snackbar = true;
          this.dataItem = response.data[0];
          this.$router.push("e-bansos");
        })
        .catch((error) => {
          this.snackbar_error = true;
          console.log(error);
          console.log(error.response.data["data.0.nikpetugas"], "wew");
          this.msg_error = error.response.data["data.0.nikpetugas"][0];
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    deletePic() {
      this.url = "";
      this.$refs.file.value = "";
    },
    onFileChange(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.form.photo = e.target.files[0];
      console.log(this.form);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>