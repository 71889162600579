<template>
  <v-card class="overflow-hidden">
    <v-app-bar app color="#F2F1F1" dense>
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="E-Survey" icon="mdi-chart-box-outline" />
    <v-snackbar
      v-model="snackbar.visible"
      multi-line
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      centered
      auto-height
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>
            <strong> {{ snackbar.status }}</strong>
          </div>
          <div>{{ msg }}</div>
        </v-layout>
      </v-layout>
      <v-btn
        v-if="snackbar.timeout === 0"
        icon
        @click="snackbar.visible = false"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-snackbar>
    <v-container style="height: 100%; margin-bottom: 100px">
      <v-row>
        <v-col cols="6">
          <p style="color: #c82424; font-size: 20px">Selesai</p>
        </v-col>
        <v-col cols="6" class="flex justify-end text-right mb-2">
          <v-btn
            color="#F54C4C"
            class="button-signup ml-5"
            type="submit"
            @click="tambahSurvey()"
            v-show="userData.role != 'warga'"
            >Buat Survey</v-btn
          >
        </v-col>
      </v-row>

      <v-expansion-panels
        v-show="userData.role == 'superadmin' || userData.role == 'admin'"
      >
        <v-expansion-panel>
          <v-expansion-panel-header> Filter </v-expansion-panel-header>
          <v-expansion-panel-content class="text-center">
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              label="Provinsi"
              v-model="id_provinsi"
              :items="provinsis"
              item-text="provinsi"
              return-object
              required
              @change="(event) => selectedProvinsi(event)"
            ></v-autocomplete>
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              v-model="id_kabkot"
              :items="kabupatens"
              label="Kabupaten/ Kota"
              return-object
              item-text="kabupaten_kota"
              :disabled="kabupatens == ''"
              required
              @change="(event) => selectedKabupaten(event)"
            ></v-autocomplete>
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              v-model="id_kecamatan"
              :items="kecamatans"
              label="Kecamatan"
              item-text="kecamatan"
              return-object
              :disabled="kecamatans == ''"
              required
              @change="(event) => selectedKecamatan(event)"
            ></v-autocomplete>
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              v-model="id_kelurahan"
              :items="kelurahans"
              label="Kelurahan"
              return-object
              :disabled="kelurahans == ''"
              item-text="kelurahan"
              required
              @change="(event) => selectedKelurahan(event)"
            ></v-autocomplete>
            <v-btn
              color="red"
              class="button-signup"
              block
              v-on:click="getDataSurveyFilter()"
              >Submit</v-btn
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Cari Vote.."
        single-line
        filled
        outlined
        hide-details
        @input="SearchbarChange"
        style="width: 100%"
        class="mb-5"
      >
      </v-text-field>
      <span
        v-if="id_provinsi != ''"
        style="color: black; margin: 0; font-size: 16px"
      >
        Filter :
      </span>
      <span>
        <v-chip
          v-if="id_provinsi != ''"
          class="ma-2"
          close
          @click:close="
            id_provinsi = '';
            id_kabkot = '';
            id_kelurahan = '';
            id_kecamatan = '';
            kabupatens = '';
            kelurahans = '';
            kecamatans = '';
          "
        >
          {{ id_provinsi }}
        </v-chip>
        <v-chip
          v-if="id_kabkot != ''"
          class="ma-2"
          close
          @click:close="
            id_kabkot = '';
            id_kelurahan = '';
            id_kecamatan = '';
            kelurahans = '';
            kecamatans = '';
          "
        >
          {{ id_kabkot }}
        </v-chip>
        <v-chip
          v-if="id_kecamatan != ''"
          class="ma-2"
          close
          @click:close="
            id_kecamatan = '';
            id_kelurahan = '';
            kelurahans = '';
          "
        >
          {{ id_kecamatan }}
        </v-chip>
        <v-chip
          v-if="id_kelurahan != ''"
          class="ma-2"
          close
          @click:close="id_kelurahan = ''"
        >
          {{ id_kelurahan }}
        </v-chip>
      </span>
      <div v-if="!surveyData.data_finish">
        <v-skeleton-loader
          class="mx-auto mb-10"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>

        <v-skeleton-loader
          class="mx-auto mb-10"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>

        <v-slide-group
          class="mb-10 mt-10"
          v-model="model"
          active-class="success"
          show-arrows
        >
          <v-slide-item v-for="n in 15" :key="n">
            <v-skeleton-loader
              class="mx-auto mr-10"
              max-width="150px"
              type="card"
              style="width: 150px"
            ></v-skeleton-loader>
          </v-slide-item>
        </v-slide-group>
      </div>
      <div v-else>
        <div v-if="surveyData.data_finish.length == 0">
          <p class="text-center">Tidak ada survey yang sedang berjalan.</p>
        </div>
        <div v-if="surveyData.data_finish.length != 0">
          <v-card
            color="#F2F2F2"
            class="mb-10 d-sm-flex"
            v-for="(item, index) in surveyData.data_finish.data"
            :key="index"
          >
            <div>
              <v-card-title>
                <p>
                  {{ item.judul_survey }}
                </p>
              </v-card-title>

              <v-card-subtitle>
                <p class="text-sub">
                  {{ item.provinsi }}/{{ item.kabupaten_kota }}/{{
                    item.kecamatan
                  }}/{{ item.kelurahan }}/RW {{ item.id_rw }}/RT
                  {{ item.id_rt }}
                </p>
                <p>by : {{ item.nama }}</p>
              </v-card-subtitle>
            </div>

            <v-card-actions class="ml-2 d-sm-none d-flex justify-end">
              <v-btn
                color="#F54C4C"
                class="button-signup"
                type="submit"
                @click="hasilSurvey(item)"
                >Hasil</v-btn
              >
            </v-card-actions>

            <v-card-actions class="ml-15 d-none d-sm-flex">
              <v-btn
                color="#F54C4C"
                class="button-signup"
                type="submit"
                @click="hasilSurvey(item)"
                >Hasil</v-btn
              >
            </v-card-actions>
          </v-card>

          <div class="text-center mb-6">
            <v-pagination
              :loading="loading"
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
          </div>
        </div>
      </div>
    </v-container>
    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import { getData, postData } from "../service/ApiService";
export default {
  name: "ESurveyFinish",
  components: {
    Footer,
    Header,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      model: null,
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      id_provinsi: "",
      id_kabkot: "",
      id_kelurahan: "",
      id_kecamatan: "",
      search: "",
      token_jwt: "",
      menu: false,
      msg: "",
      loading: false,
      page: 1,
      surveyData: [],
      startTime: "July 7, 2017 12:03:00",
      endTime: "July 9, 2022 14:55:00",
      userData: "",
      snackbar: {
        timeout: 3000,
        visible: false,
        color: "",
        icon: "",
        status: "",
      },
      times: [
        { id: 0, text: "Days", time: 1 },
        { id: 1, text: "Hours", time: 1 },
        { id: 2, text: "Minutes", time: 1 },
        { id: 3, text: "Seconds", time: 1 },
      ],
      progress: 100,
      // isActive: false,
      timeinterval: undefined,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataNews.created_at
        ? moment(this.dataNews.created_at).format("dddd, d MMM YYYY HH:m A")
        : "";
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
    },
  },
  mounted() {},
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.getDataSurvey();
    this.getListProvinsi();
  },
  methods: {
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    eSurveyFinish() {
      this.$router.push({
        name: "ESurveyFinish",
      });
    },
    hasilSurvey(value) {
      this.$router.push({
        name: "HasilSurvey",
        params: {
          items: value,
        },
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.getDataSurveyFilter();
    },
    getDataSurveyFilter() {
      if (this.search != "") {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/survey/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov_finish=" +
            this.id_provinsi +
            "&kab_finish=" +
            this.id_kabkot +
            "&kec_finish=" +
            this.id_kecamatan +
            "&kel_finish=" +
            this.id_kelurahan +
            "&keyword_finish=" +
            this.search;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/survey/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov_finish=" +
            this.id_provinsi +
            "&kab_finish=" +
            this.id_kabkot +
            "&kec_finish=" +
            this.id_kecamatan +
            "&keyword_finish=" +
            this.search;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/survey/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov_finish=" +
            this.id_provinsi +
            "&kab_finish=" +
            this.id_kabkot +
            "&keyword_finish=" +
            this.search;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/survey/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov_finish=" +
            this.id_provinsi +
            "&keyword_finish=" +
            this.search;
        } else {
          this.apiGetFilter =
            "api/survey/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword_finish=" +
            this.search;
        }
      } else {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/survey/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov_finish=" +
            this.id_provinsi +
            "&kab_finish=" +
            this.id_kabkot +
            "&kec_finish=" +
            this.id_kecamatan;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/survey/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov_finish=" +
            this.id_provinsi +
            "&kab_finish=" +
            this.id_kabkot +
            "&kec_finish=" +
            this.id_kecamatan;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/survey/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov_finish=" +
            this.id_provinsi +
            "&kab_finish=" +
            this.id_kabkot;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/survey/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov_finish=" +
            this.id_provinsi;
        } else {
          this.apiGetFilter =
            "api/survey/" + this.userData.nik + "?page=" + this.page;
        }
      }

      getData(this.apiGetFilter)
        .then((response) => {
          console.log("data :", response);
          this.surveyData = response;
          this.totalPages = response.data_active.last_page;
          console.log(this.penduduks);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value.id;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.id_provinsi = value.provinsi;
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kabkot = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value.id;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.id_kabkot = value.kabupaten_kota;
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value.id;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.id_kecamatan = value.kecamatan;
            this.kelurahans = "";
            this.kelurahans = response;
            this.id_kelurahan = "";

            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.id_kelurahan = value.kelurahan;
      }
    },
    SearchbarChange(value) {
      this.search = value;
      this.getDataSurveyFilter();
      console.log(value);
    },
    back() {
      this.$router.back();
    },
    getDataSurvey() {
      const ApiGetSurvey =
        "api/survey/" + this.userData.nik + "?page=" + this.page;

      getData(ApiGetSurvey)
        .then((response) => {
          console.log("data :", response);
          this.surveyData = response;
          console.log("data survey :", this.surveyData);

          console.log(this.surveyData.data_finish.last_page);
          this.totalPages = response.data_finish.last_page;
          console.log(this.surveyData);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    checkSurvey(item) {
      this.loading = true;
      console.log(this.items);
      let formData = new FormData();
      formData.append("nik_respon", this.userData.nik);
      const data = {
        api: "api/survey/ask/" + item.id_survey,
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log("data :", response);
          const arr = [response, item];
          this.detailSurvey(arr);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.loading = false;
          this.snackbar.color = "red accent-2";
          this.snackbar.icon = "mdi-alert-octagon-outline";
          this.snackbar.status = "Gagal";
          if (error.response) {
            this.snackbar.visible = true;
            console.log(error.response.data.alert);
            this.msg = error.response.data.alert;
          }
        });
    },
    detailSurvey(item) {
      this.$router.push({
        name: "DetailSurvey",
        params: {
          items: item,
        },
      });
    },
    tambahSurvey() {
      this.$router.push({
        name: "TambahSurvey",
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY HH:SS");
      }
    },
  },
};
</script>

<style scoped>
.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}
.text-sub {
  color: #c82424;
}
.truncate {
  line-height: 1.5em;
  height: 3em; /* height is 2x line-height, so two lines will display */
  overflow: hidden; /* prevents extra lines from being visible */
}
</style>