<template>
  <v-card class="overflow-hidden">
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      {{this.msg}}
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-dialog v-model="dialogTambahData" max-width="600px">
      <v-card>
        <v-app-bar color="#F2F1F1" height="80px" elevate-on-scroll scroll-target="#scrolling-techniques-7">
          <v-btn color="black" icon @click="dialogTambahData = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-app-bar-title>Buat Broadcast/ Multicast</v-app-bar-title>
        </v-app-bar>
        <v-card-text>
          <v-container>
            <form id="myForm" name="myForm" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
              <v-row>

                <v-col cols="12">
                  <v-autocomplete prepend-icon="mdi-filter" v-model="form.tipe" :items="types" label="Jenis Pengumuman"
                    :rules="[rules.required]" item-value="value" item-text="name" required>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" v-if="form.tipe == 'Broadcast' ">
                  <v-autocomplete prepend-icon="mdi-filter" v-model="form.status" :items="statuses"
                    label="Status Pengumuman" :rules="[rules.required]" item-value="value" item-text="name" required>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" v-if="form.tipe == 'Broadcast' || form.tipe == 'Multicast'">
                  <v-textarea prepend-icon="mdi-book-account" label="Isi Pengumuman" required v-model="form.pengumuman"
                    hint="Isi Pengumuman" :rules="[rules.required]">
                  </v-textarea>
                </v-col>

                <v-col cols="12" v-if="form.tipe == 'Broadcast' || form.tipe == 'Multicast'">
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="form.batas_waktu" label="Batas Waktu Berakhir" prepend-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="form.batas_waktu" :active-picker.sync="activePicker"
                      
                      :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)" @change="save"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" style="padding: 0" v-if="form.tipe == 'Broadcast'">
                  <v-subheader>Lokasi</v-subheader>
                </v-col>

                <v-col cols="12" v-if="form.tipe == 'Broadcast'|| form.tipe == 'Multicast'">
                  <v-autocomplete prepend-icon="mdi-map-marker" v-model="form.id_provinsi"
                    :items="list_provinsi_register" label="Provinsi" :rules="[rules.required]" return-object
                    item-text="provinsi" required @change="(event) => selectedProvinsiRegister(event)"></v-autocomplete>
                </v-col>

                <v-col cols="12" v-if="form.tipe == 'Broadcast'|| form.tipe == 'Multicast'">
                  <v-autocomplete prepend-icon="mdi-map-marker" v-model="form.id_kabkot" :rules="[rules.required]"
                    :items="list_kabupaten_register" label="Kabupaten/ Kota" return-object item-text="kabupaten_kota"
                    :disabled="list_kabupaten_register == ''" required
                    @change="(event) => selectedKabupatenRegister(event)">
                  </v-autocomplete>
                </v-col>

                <v-container v-if="form.tipe == 'Multicast'">
                  <v-subheader>List Penerima</v-subheader>
                  <v-expansion-panels v-show="userData.role == 'superadmin' || userData.role == 'admin'">
                    <v-expansion-panel>
                      <v-expansion-panel-header> Filter </v-expansion-panel-header>
                      <v-expansion-panel-content class="text-center">
                        <v-autocomplete prepend-icon="mdi-map-marker" label="Provinsi" v-model="id_provinsiFilter"
                          :items="provinsis" item-text="provinsi" return-object required
                          @change="(event) => selectedProvinsiFilter(event)"></v-autocomplete>
                        <v-autocomplete prepend-icon="mdi-map-marker" v-model="id_kabkotFilter"
                          :items="kabupatensFilter" label="Kabupaten/ Kota" item-value="id" item-text="kabupaten_kota"
                          return-object :disabled="kabupatensFilter == ''" required
                          @change="(event) => selectedKabupatenFilter(event)">
                        </v-autocomplete>
                        <v-autocomplete prepend-icon="mdi-map-marker" v-model="id_kecamatanFilter"
                          :items="kecamatansFilter" label="Kecamatan" item-text="kecamatan" return-object
                          :disabled="kecamatansFilter == ''" required
                          @change="(event) => selectedKecamatanFilter(event)"></v-autocomplete>
                        <v-autocomplete prepend-icon="mdi-map-marker" v-model="id_kelurahanFilter"
                          :items="kelurahansFilter" label="Kelurahan" return-object :disabled="kelurahansFilter == ''"
                          item-text="kelurahan" required @change="(event) => selectedKelurahanFilter(event)">
                        </v-autocomplete>
                        <v-text-field v-model="id_rw" prepend-icon="mdi-map-marker" label="RW" single-line hide-details
                          style="width: 100%" class="mb-5" type="number">
                        </v-text-field>
                        <v-text-field v-model="id_rt" prepend-icon="mdi-map-marker" label="RT" single-line hide-details
                          type="number" style="width: 100%" class="mb-5">
                        </v-text-field>
                        <v-btn color="red" class="button-signup" block v-on:click="getDataPenduduk()">Submit</v-btn>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="Cari Nik, Nama, KK, dsb.." single-line
                    filled outlined hide-details @input="getDataPenduduk()" style="width: 100%" class="mb-5">
                  </v-text-field>
                  <span v-if="id_provinsiFilter != ''" style="color: black; margin: 0; font-size: 16px">
                    Filter :
                  </span>
                  <span>
                    <v-chip v-if="id_provinsiFilter != ''" class="ma-2" close @click:close="
                  id_provinsiFilter = '';
                  id_kabkotFilter = '';
                  id_kelurahanFilter = '';
                  id_kecamatanFilter = '';
                  id_provinsiFilterValue = '';
                  id_kabkotFilterValue = '';
                  id_kelurahanFilterValue = '';
                  id_kecamatanFilterValue = '';
                  kabupatensFilter = '';
                  kelurahansFilter = '';
                  kecamatansFilter = '';
                ">
                      {{ id_provinsiFilter.provinsi }}
                    </v-chip>
                    <v-chip v-if="id_kabkotFilter != ''" class="ma-2" close @click:close="
                  id_kabkotFilter = '';
                  id_kelurahanFilter = '';
                  id_kecamatanFilter = '';
                  id_kabkotFilterValue = '';
                  id_kelurahanFilterValue = '';
                  id_kecamatanFilterValue = '';
                  kelurahansFilter = '';
                  kecamatansFilter = '';
                ">
                      {{ id_kabkotFilter.kabupaten_kota }}
                    </v-chip>
                    <v-chip v-if="id_kecamatanFilter != ''" class="ma-2" close @click:close="
                  id_kecamatanFilter = '';
                  id_kelurahanFilter = '';
                  id_kecamatanFilterValue = '';
                  id_kelurahanFilterValue = '';
                  kelurahansFilter = '';
                ">
                      {{ id_kecamatanFilter.kecamatan }}
                    </v-chip>
                    <v-chip v-if="id_kelurahanFilter != ''" class="ma-2" close @click:close="
                  id_kelurahanFilter = '';
                  id_kelurahanFilterValue = '';
                ">
                      {{ id_kelurahanFilter.kelurahan }}
                    </v-chip>
                  </span>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <p>List Penduduk</p>
                      <v-card>
                        <v-list two-line v-if="dataPenduduk.data">
                          <template v-for="(item, index) in dataPenduduk.data">
                            <v-list-item :key="item.title">
                              <template>
                                <v-list-item-content>
                                  <v-list-item-title v-text="item.nama"></v-list-item-title>
                                  <v-list-item-subtitle v-text="item.nik"></v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-btn icon @click="updateListPenduduk(item)" :loading="loading">
                                    <v-icon color="red lighten-1">mdi-plus-circle-outline</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </template>
                            </v-list-item>
                            <v-divider :key="index"></v-divider>
                          </template>
                        </v-list>
                        <v-container v-else>
                          <p>Data tidak ada.</p>
                        </v-container>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <p>List Peserta</p>
                      <v-card>
                        <v-list two-line v-if="selected.length > 0">
                          <template v-for="(item, index) in selected">
                            <v-list-item :key="item.item.title">
                              <v-list-item-content>
                                <v-list-item-title v-text="item.item.nama"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.item.nik"></v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-btn icon>
                                  <v-icon color="red lighten-1" @click="deleteListPenduduk(index, item)">mdi-close
                                  </v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                            <v-divider :key="index"></v-divider>
                          </template>
                        </v-list>
                        <v-container v-else>
                          <p>Data tidak ada.</p>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
              <v-btn class="button-signup mt-5" color="green" @click="submitForm()" block>Simpan</v-btn>

            </form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-app-bar app color="#F2F1F1" dense>
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="Broadcast/ Multicast" icon="mdi-broadcast" />

    <v-container style="height: 100%; margin-bottom: 100px">
      <div v-if="!broadcastData.data">
        <v-skeleton-loader class="mx-auto mb-10" max-width="100%" type="card"></v-skeleton-loader>
        <v-skeleton-loader class="mx-auto mb-10" max-width="100%" type="card"></v-skeleton-loader>
        <v-slide-group class="mb-10 mt-10" v-model="model" active-class="success" show-arrows>
          <v-slide-item v-for="n in 15" :key="n">
            <v-skeleton-loader class="mx-auto mr-10" max-width="150px" type="card" style="width: 150px">
            </v-skeleton-loader>
          </v-slide-item>
        </v-slide-group>
      </div>

      <v-row>
        <v-col cols="6">
          <p style="color: #c82424; font-size: 20px" v-if="userData.role != 'superadmin'">
            {{ userData.provinsi }}, {{ userData.kabupaten_kota }}
          </p>
          <p style="color: #c82424; font-size: 20px" v-else>Semua Wilayah</p>
        </v-col>
        <v-col cols="6" class="flex justify-end text-right mb-2">
          <v-btn v-if="userData.role == 'superadmin'" @click="tambahData()" color="red" dark> Tambah Data </v-btn>
        </v-col>
      </v-row>

      <div v-if="broadcastData.data">
        <v-expansion-panels v-show="userData.role == 'superadmin'">
          <v-expansion-panel>
            <v-expansion-panel-header> Filter </v-expansion-panel-header>
            <v-expansion-panel-content class="text-center">
              <v-autocomplete prepend-icon="mdi-map-marker" label="Provinsi" v-model="data_provinsi"
                :items="list_provinsi" item-text="provinsi" return-object required
                @change="(event) => selectedProvinsi(event)"></v-autocomplete>
              <v-autocomplete prepend-icon="mdi-map-marker" v-model="data_kabkot" :items="list_kabupaten"
                label="Kabupaten/ Kota" return-object item-text="kabupaten_kota" :disabled="list_kabupaten == ''"
                required @change="(event) => selectedKabupaten(event)"></v-autocomplete>

              <v-autocomplete prepend-icon="mdi-filter" label="Kategori" v-model="kategori" :items="kategoris"
                item-text="name" item-value="value" required @change="(event) => selectedKategori(event)">
              </v-autocomplete>
              <v-btn color="red" class="button-signup" block v-on:click="getDataBroadcastFilter(kategori)">Submit</v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Cari Vote.." single-line filled outlined
          hide-details @input="getDataBroadcastFilter(kategori)" style="width: 100%">
        </v-text-field>
        <span v-if="
            (userData.role == 'superadmin' && data_provinsi != '') ||
            (userData.role == 'superadmin' && kategori != '')
          " style="color: black; margin: 0; font-size: 16px">
          Filter :
        </span>
        <span v-show="userData.role == 'superadmin'">
          <v-chip v-if="kategori != ''" class="ma-2" close @click:close="
              kategori = '';
              kategori = '';
            ">
            {{ kategori }}
          </v-chip>
          <v-chip v-if="data_provinsi != ''" class="ma-2" close @click:close="
              data_provinsi = '';
              data_kabkot = '';
              list_kabupaten = '';

            ">
            {{ data_provinsi.provinsi }}
          </v-chip>
          <v-chip v-if="data_kabkot != ''" class="ma-2" close @click:close="
              data_kabkot = '';
              data_kabkot = '';
            ">
            {{ data_kabkot.kabupaten_kota }}
          </v-chip>

        </span>
        <v-chip-group mandatory v-if="userData.role != 'superadmin'" v-model="kategori">
          <v-chip v-for="tag in tags" :key="tag" :value="tag.value" color="#FF8540" text-color="white"
            active-class="white--text red" @click="getDataBroadcast(tag.value)">
            {{ tag.name }}
          </v-chip>
        </v-chip-group>

        <div v-if="broadcastData.data.length == 0">
          <p class="text-center ma-10">
            Tidak menemukan pesan.
          </p>
        </div>

        <div v-else>
          <v-card color="#F2F2F2" class="mt-10" v-for="(item, index) in broadcastData.data" :key="index">
            <v-row class="pl-5 pr-">
              <v-col cols="12" sm="9">
                <v-row>
                  <v-col sm="12" cols="12">
                    
                    <v-chip color="#FF8540" text-color="white" class="mb-5" v-if="item.tipe != 'Multicast'">
                      {{ item.tipe }} - {{ item.status }}
                    </v-chip>
                    <v-chip color="#FF8540" text-color="white" class="mb-5" v-else>
                      {{ item.tipe }} 
                    </v-chip>
                     <p>
                      {{ item.pengumuman }}
                    </p>
             
                    <p class="text-sub">
                      {{ item.provinsi }}/{{ item.kabupaten_kota }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="3" class="d-flex justify-end pt-10">
                <v-row class="d-flex justify-end">
                  <v-col cols="12">
                    <!-- <v-btn color="#F54C4C" class="button-signup mr-5" :loading="loading" @click="editBroadcast(item)">Edit
                    </v-btn> -->
                    <v-btn color="#F54C4C" class="button-signup" :loading="loading" @click="deleteBroadcast(item.id)">Hapus
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <p class="text-sub">
                      Berakhir pada : {{ item.batas_waktu }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>

          <div class="text-center mt-10">
            <v-pagination :loading="loading" v-model="page" :length="totalPages" total-visible="7"
              next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="handlePageChange"></v-pagination>
          </div>

        </div>

      </div>
    </v-container>
    <Footer />
  </v-card>
</template>
<script>
  // import FitureCardVue from '../components/FitureCard.vue';
  // import NewsCardVue from '../components/NewsCard.vue';
  // import Tombol from '../components/Tombol.vue';
  // @ is an alias to /src
  // import axios from "axios";
  import moment from "moment";
  import Footer from "../components/Footer.vue";
  import Header from "../components/Header.vue";
  import {
deleteData,
    getData,
    postData
  } from "../service/ApiService";

  export default {
    name: "Broadcast",
    components: {
      Footer,
      Header,
    },
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push("/");
      }
    },
    data() {
      return {
        loading: false,
        kategoriData: null,
        kategori: "",
        list_provinsi: "",
        list_kabupaten: "",
        list_kelurahan: "",
        list_provinsi_register: "",
        list_kabupaten_register: "",
        list_kelurahan_register: "",
        id_provinsiFilter: "",
        id_kabkotFilter: "",
        id_kecamatanFilter: "",
        id_kelurahanFilter: "",
        id_provinsiFilterValue: "",
        id_kabkotFilterValue: "",
        id_kecamatanFilterValue: "",
        id_kelurahanFilterValue: "",
        id_provinsi: "",
        id_kabkot: "",
        id_kelurahan: "",
        id_rw: "",
        id_rt: "",
        data_provinsi: "",
        data_kabkot: "",
        dataJenisKategori: "",
        dialogTambahData: false,
        dialogTambahJenisKategori: false,
        snackbar: false,
        snackbar_error: false,
        search: "",
        token_jwt: "",
        menu: false,
        msg_error: '',
        msg: "",
        page: 1,
        broadcastData: "",
        startTime: "July 7, 2017 12:03:00",
        endTime: "July 9, 2022 14:55:00",
        userData: "",
        rules: {
          required: (value) => !!value || "Wajib di isi.",
        },
        dataPenduduk: [],
        selected: [],
        form: {
          pengumuman: null,
          tipe: null,
          status: null,
          kategori: null,
          id_provinsi: "",
          id_kabkot: "",
          batas_waktu: null,
          invited: [],
        },
        formJenisKategori: {
          kategori: null,
          nama: null
        },
        statuses: [{
          name: "Biasa",
          value: "biasa",
        }, {
          name: "Mendesak",
          value: "mendesak",
        }, ],
        kategoris: [{
            name: "Semua",
            value: "",
          }, {
            name: "Broadcast",
            value: "Broadcast",
          },
          {
            name: "Multicast",
            value: "Multicast",
          },
        ],
        types: [{
            name: "Broadcast",
            value: "Broadcast",
          },
          {
            name: "Multicast",
            value: "Multicast",
          },
        ],
      };
    },
    watch: {
      position: {
        deep: true,
        async handler(value) {
          this.address = await this.getAddress();
          this.$emit("input", {
            position: value,
            address: this.address,
          });
        },
      },
    },
    computed: {
      tooltipContent() {
        if (this.dragging) return "...";
        if (this.loading) return "Loading...";
        return `<strong>${this.address.replace(
        ",",
        "<br/>"
      )}</strong> <hr/><strong>lat:</strong> ${
        this.position.lat
      }<br/> <strong>lng:</strong> ${this.position.lng}`;
      },
    },
    created() {
      console.log(this.data_provinsi);
      if (localStorage.user_data) {
        this.userData = JSON.parse(localStorage.user_data).data[0];
        console.log("data user: ", this.userData);
      }

      this.getDataBroadcast('');
      this.getListProvinsi();
      this.getDataPenduduk();
    },
    methods: {
      updateListPenduduk(item) {
        this.loading = true;
        this.selected.push({
          item,
        });
        console.log(item);
        console.log(this.selected);
        this.getDataPenduduk();
      },
      deleteListPenduduk(indexItem, item) {
        this.loading = true;
        // const index = this.selected.indexOf(indexItem);
        this.selected.splice(indexItem, 1);
        console.log(item);
        console.log(this.selected);
        this.getDataPenduduk();
      },
      removeFriend(item) {
        const index = this.friends.indexOf(item.nik);
        if (index >= 0) this.friends.splice(index, 1);
      },
      add() {
        this.textFields.push({
          peserta: null,
          photo: null,
        });
      },
      remove(index) {
        this.textFields.splice(index, 1);
      },
      getDataPenduduk() {
        console.log(this.selected);
        let formData = new FormData();
        this.selected.forEach((item, index) => {
          formData.append("list[" + index + "]", item.item.nik);
        });

        const apiListPenduduk = {
          api: "api/search/nik" +
            "?prov=" +
            this.id_provinsiFilterValue +
            "&kab=" +
            this.id_kabkotFilterValue +
            "&kec=" +
            this.id_kecamatanFilterValue +
            "&kel=" +
            this.id_kelurahanFilterValue +
            "&rw=" +
            this.id_rw +
            "&rt=" +
            this.id_rt +
            "&keyword=" +
            this.search,
          body: formData,
        };

        postData(apiListPenduduk)
          .then((response) => {
            console.log("data list penduduk :", response);
            if (response.data == "data tidak ditemukan") {
              this.dataPenduduk = [];
            } else {
              this.dataPenduduk = response;
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      },
      submitForm() {
        this.loading = true;
        console.log(this.form);
        let formData = new FormData();

        formData.append("tipe", this.form.tipe);
        
        if (this.form.tipe == 'Broadcast' ) {
          formData.append("status", this.form.status) ;
        }

        formData.append("pengumuman", this.form.pengumuman);
        formData.append("id_prov", this.form.id_provinsi.id);
        formData.append("id_kabkot", this.form.id_kabkot.id);
        formData.append("batas_waktu", this.form.batas_waktu);

        if (this.form.tipe == 'Multicast' ) {
          this.selected.forEach((item, i) => {
              formData.append("invite[" + i + "][nik]", item.item.nik);
          });
        }

        console.log(formData.values());
        const data = {
          api: "api/create-broadcast",
          body: formData,
        };

        postData(data)
          .then((response) => {
            console.log(response);
            this.msg = "Berhasil membuat broadcast.";
            this.snackbar = true;
            this.dialogTambahData = false;
            this.loading = false;
            this.getDataBroadcast('');
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response);
            this.loading = false;
            if (error.response) {
              console.log(error.response.data);
              if (error.response.data) {
                this.msg_error = error.response.data;
                this.snackbar_error = true;
              }
            }
          });
      },
      tambahData() {
        this.dialogTambahData = true;
      },
      getListProvinsi() {
        const apiListProvinsi = "api/provinsi";
        getData(apiListProvinsi)
          .then((response) => {
            console.log("data provinsi :", response);
            this.list_provinsi = response;
            this.list_provinsi_register = response;
            console.log(this.provinsi);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      },
      selectedJenisKategori(value) {
        console.log(value);
        if (value != null) {
          const api = "api/farm-jenis?kategori=" + value;

          getData(api)
            .then((response) => {
              console.log("data jenis kategori :", response);

              this.dataJenisKategori = response.data;
              console.log(this.dataJenisKategori);
            })
            .catch((error) => {
              console.log("pesan error :", error.response.data);
              this.msg = true;
            });
        }
      },
      selectedProvinsi(value) {
        console.log(value);
        if (value != null) {
          const apiListKabupaten = "api/kabkot?keyword=" + value.id;

          getData(apiListKabupaten)
            .then((response) => {
              console.log("data kabupaten :", response);
              this.data_provinsi = value;
              this.list_kabupaten = "";
              this.data_kabkot = "";

              this.list_kabupaten = response;
              console.log(this.list_kabupaten);
            })
            .catch((error) => {
              console.log("pesan error :", error.response.data);
              this.msg = true;
            });
        }
      },
      selectedKabupaten(value) {
        console.log(value);
        if (value != null) {
          const apiListKecamatan = "api/kec?keyword=" + value.id;

          getData(apiListKecamatan)
            .then((response) => {
              console.log("data kecamatan :", response);
              this.data_kabkot = value;
            })
            .catch((error) => {
              console.log("pesan error :", error.response.data);
              this.msg = true;
            });
        }
      },
      selectedKelurahan(value) {
        console.log(value);
        if (value != null) {
          this.data_kelurahan = value;
        }
      },
      selectedProvinsiRegister(value) {
        console.log(value);
        if (value != null) {
          const apiListKabupaten = "api/kabkot?keyword=" + value.id;

          getData(apiListKabupaten)
            .then((response) => {
              console.log("data kabupaten :", response);
              this.list_kabupaten_register = "";
              this.list_kabupaten_register = response;
              console.log(this.list_kabupaten_register);
              this.form.id_provinsi = value;
              this.form.id_kabkot = "";
              // this.form.id_kabkot.kabupaten_kota = "";
              // this.form.id_kabkot.id = "";
             
            })
            .catch((error) => {
              console.log("pesan error :", error);
              this.msg = true;
            });
        }
      },
      selectedKabupatenRegister(value) {
        console.log(value);
        if (value != null) {
          const apiListKecamatan = "api/kec?keyword=" + value.id;

          getData(apiListKecamatan)
            .then((response) => {
              console.log("data kecamatan :", response);
              this.form.id_kabkot = value;
            })
            .catch((error) => {
              console.log("pesan error :", error.response.data);
              this.msg = true;
            });
        }
      },
     
      getListKategori() {
        const apiListKategori = "api/loker/id/kategori";
        getData(apiListKategori)
          .then((response) => {
            console.log("data kategori :", response);
            this.kategoriData = response.data;
            console.log(this.kategoriData);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      },
      evotingFinish() {
        this.$router.push({
          name: "EVotingFinish",
        });
      },
      handlePageChange(value) {
        this.page = value;
        this.getDataLokerFilter();
      },
      selectedKategori(value) {
        this.kategori = value;
        console.log(value);
      },
      SearchbarChange(value) {
        this.search = value;
        this.getDataLokerFilter();
        console.log(value);
      },
      back() {
        this.$router.back();
      },
      getDataBroadcast(value) {
        const apiGetData =
          "api/broadcast-all" +
          "?page=" +
          this.page +
          "&prov=" +
          this.data_provinsi +
          "&kab=" +
         this.data_kabkot +
          "&kategori=" +
          value +
          "&keyword=" +
          this.search;

        getData(apiGetData)
          .then((response) => {
            console.log("data :", response);
            this.broadcastData = response.data;
            this.totalPages = response.data.last_page;
            console.log(this.broadcastData);
            this.loading = false;
          })
          .catch((error) => {
            console.log("pesan error :", error);
            this.msg = true;
          });
      },
      deleteBroadcast(value) {
        this.loading = true;
        const apiGetData = {'api':"api/broadcast-delete/" + value};
        console.log(apiGetData);      

        deleteData(apiGetData)
          .then((response) => {
            console.log("data :", response);
            this.getDataBroadcast('');
          })
          .catch((error) => {
            console.log("pesan error :", error);
            this.msg = true;
          });
      },
       getDataBroadcastFilter(value) {

        var page_filter = this.page;
        var prov_filter;
        var kab_filter;
        if (this.data_provinsi != '') {
         prov_filter = this.data_provinsi.id;
        } else {
         prov_filter = "";
        }

        if (this.data_kabkot != '') {
           kab_filter = this.data_kabkot.id;
        } else {
           kab_filter = "";
        }

        var kategori_filter = value;
        var search_filter = this.search;

        const apiGetData =
          "api/broadcast-all" +
          "?page=" +
          page_filter +
          "&prov=" +
          prov_filter +
          "&kab=" +
          kab_filter +
          "&kategori=" +
          kategori_filter +
          "&keyword=" +
          search_filter

        getData(apiGetData)
          .then((response) => {
            console.log("data :", response);
            this.broadcastData = response.data;
            this.totalPages = response.data.last_page;
            console.log(this.broadcastData);
            this.loading = false;
          })
          .catch((error) => {
            console.log("pesan error :", error);
            this.msg = true;
          });
      },
      hasilVoting(item) {
        this.$router.push({
          name: "HasilVoting",
          params: {
            items: item.id_evoting,
          },
        });
      },
      tambahloker() {
        this.$router.push({
          name: "TambahLoker",
        });
      },
      format_date(value) {
        if (value) {
          return moment(String(value)).format("DD/MMM/YYYY HH:SS");
        }
      },
    },
  };
</script>

<style scoped>
  .button-signup {
    border-radius: 10px;
    color: white !important;
    text-transform: none;
    text-decoration: none;
  }

  .text-sub {
    color: #c82424;
  }

  .truncate {
    line-height: 1.5em;
    height: 3em;
    /* height is 2x line-height, so two lines will display */
    overflow: hidden;
    /* prevents extra lines from being visible */
  }
</style>