<template>
  <div>
    <v-snackbar v-model="snackbar" multi-line centered color="green">
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="white" size="50"> mdi-check-circle-outline </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title style="font-size: 2rem">Email Terkirim</v-list-item-title>
          <v-list-item-subtitle style="font-size: 1rem">Periksa email anda dan klik tautan untuk
            melanjutkan</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <template v-slot:action="{ attrs }">
        <v-btn @click="snackbar = false" icon v-bind="attrs">
          <v-icon color="white"> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar color="red" centered style="color: white" v-model="snackbar_error">
      Email yang dimasukan tidak benar.
    </v-snackbar>
    <p class="forgot-text" @click="dialog = true;">lupa password?</p>
    <v-dialog v-model="dialog" max-width="600px" style="border-radius: 50px">
      <v-card style="border-radius: 20px">
        <v-card-text>
          <v-container>
            <form id="myForm" name="myForm" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
              <h1 class="text-center mb-5 mt-10">Masukan Email</h1>
              <p class="text-center mb-5">
                Masukan Email anda dan kami akan mengirimkan tautan untuk <br />
                mengganti password
              </p>
              <v-text-field label="Email" outlined required v-model="email">
              </v-text-field>
              <v-btn style="color: white" color="red" type="submit" value="Submit" :loading="loading" block>
                Kirim
              </v-btn>
            </form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  // import FitureCardVue from '../components/FitureCard.vue';
  // import NewsCardVue from '../components/NewsCard.vue';
  // import Tombol from '../components/Tombol.vue';
  // @ is an alias to /src
  // import axios from "axios";
  import {
    postDataTanpaToken
  } from "../service/ApiService";

  export default {
    name: "ResetPasswordEmail",
    props: {},
    data() {
      return {
        show1: false,
        rules: {
          required: (value) => !!value || "Wajib di isi.",
          min: (v) => v.length >= 8 || "Password minimal 8 digit",
          min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
          emailMatch: () => `The email and password you entered don't match`,
        },
        numberRule: (v) => {
          if (!v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
          return "Wajib Berupa Angka.";
        },
        snackbar: false,
        loading: false,
        snackbar_error: false,
        token_jwt: "",
        dialog: false,
        activePicker: null,
        menu: false,
        email: null,
      };
    },
    computed: {},
    mounted() {
      if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        console.log("token jwt :", localStorage.token_jwt);
      }

      if (this.snackbar) {
        this.hide_alert();
      }

      if (this.snackbar_error) {
        this.hide_alert_error();
      }
    },
    created() {
      console.log("data vote :", this.dataVote);
      if (localStorage.user_data) {
        this.userData = JSON.parse(localStorage.user_data).data[0];
        console.log("data user: ", this.userData);
      }
    },
    methods: {
      hide_alert: function (event) {
        console.log(event);
        // `event` is the native DOM event
        window.setInterval(() => {
          this.snackbar = false;
          console.log("hide alert after 3 seconds");
        }, 3000);
      },
      hide_alert_error: function (event) {
        console.log(event);
        // `event` is the native DOM event
        window.setInterval(() => {
          this.snackbar_error = false;
          console.log("hide alert after 3 seconds");
        }, 3000);
      },
      checkSurvey() {
        this.loading = true;
        console.log(this.items);
        let formData = new FormData();
        formData.append("nik", this.userData.nik);
        const data = {
          api: "api/e-voting/" + this.dataVote.id_evoting,
          body: formData,
        };
        postDataTanpaToken(data)
          .then((response) => {
            console.log("data :", response);

            this.detailVoting(response);
            this.loading = false;
            this.dialog = false;
          })
          .catch((error) => {
            this.msg_error = "Anda sudah melakukan voting.";
            this.snackbar_error = true;
            console.log("error nih bos", error);
            console.log(error.response);
            this.loading = false;
          });
      },
      detailVoting(item) {
        this.dialog = false;
        this.$router.push({
          name: "DetailVoting",
          params: {
            votingData: item,
          },
        });
      },
      submitForm() {
        this.loading = true;
        console.log(this.form);
        let formData = new FormData();

        formData.append("email", this.email);
        console.log(formData.values());
        const data = {
          api: "api/send-token-reset",
          body: formData,
        };
        postDataTanpaToken(data)
          .then((response) => {
            this.snackbar = true;
            this.dialog = false;
            this.loading = false;
            console.log(response);
          })
          .catch((error) => {
            console.log("error nih bos", error);
            console.log(error.response);
            this.msg_error = error;
            this.snackbar_error = true;
            this.loading = false;
            if (error.response) {
              console.log(error.response.data);
              if (error.response.data.password) {
                this.dialog = false;
              }
            }
          });
      },
      save(date) {
        this.$refs.menu.save(date);
      },
    },
  };
</script>

<style scoped>
.forgot-text{
  font-family: 'Alata';
font-weight: 400;
font-size: 14px;
cursor: pointer;
color: #0079D1;
}
  #preview {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
  }

  .delete-text {
    color: red;
    cursor: pointer;
  }

  #preview img {
    max-width: 100%;
    max-height: 500px;
  }

  .button-signup {
    border-radius: 10px;
    color: white !important;
    text-transform: none;
    text-decoration: none;
  }

  .v-card__title {
    background: #f54c4c;
  }

  .text-title {
    text-decoration: none;
    color: black;
    margin: 0 22px 0 0;
    font-size: 18px;
    font-weight: 500;
  }

  .img-logo {
    height: 80%;
    width: auto;
    margin-right: 20px;
  }
</style>