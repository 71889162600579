<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil membuat voting.
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="red" dark>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          Mohon tunggu..
          <v-progress-circular
            indeterminate
            color="white"
            class="ml-6"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-app-bar app color="#F2F1F1" dense>
      <v-app-bar-title>Buat Survey</v-app-bar-title>
    </v-app-bar>
    <Header text="E-Survey" img="e-vote.png" />

    <!-- <div class="text-center"> -->
    <!-- <div
      class="text-fields-row ml-5 mr-5"
      style="
        border: 2px solid #5f5757;
        border-radius: 25px;
        padding: 10px 25px;
        background-color: #f2f2f2;
      "
    >
      <v-row no-gutters>
        <v-col cols="7" class="mr-5">
          <v-text-field
            label="Pertanyaan"
            v-model="pertanyaanBaru"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            outlined
            style="text-transform: capitalize"
            :items="jenis_pertanyaan"
            @change="(event) => selectJenisPertanyaanBaru(event)"
            placeholder="Jenis Pertanyaan"
            :rules="[rules.required]"
            required
          ></v-autocomplete>
        </v-col>
        <v-col cols="3" class="text-end pt-5">
          <v-btn @click="add()">+</v-btn>
        </v-col>
      </v-row>
    </div> -->
    <!-- <v-btn
              @click="add"
              class="button-signup"
              color="red"
              :loading="loadingAddBtn"
              >Tambah +</v-btn
            > -->

    <!-- <v-btn @click="submitForm()" class="button-signup" color="red" block
          >Simpan</v-btn
        > -->
    <!-- </div> -->

    <Footer />
  </div>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import { getData, postData } from "../service/ApiService";
import Header from "../components/Header.vue";

export default {
  name: "TambahPertanyaanSurvey",
  props: ["dataSurvey"],
  components: {
    Header,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      show1: false,
      fab: false,
      rules: {
        required: (value) => !!value || "Wajib di isi.",
        min: (v) => v.length >= 8 || "Password minimal 8 digit",
        min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
        emailMatch: () => `The email and password you entered don't match`,
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
        return "Wajib Berupa Angka.";
      },
      snackbar: false,
      loading: false,
      snackbar_error: false,
      goldars: [
        "A",
        "B",
        "AB",
        "O",
        "A+",
        "A-",
        "B+",
        "B-",
        "AB+",
        "AB-",
        "O+",
        "O-",
        "NA",
      ],
      agamas: [
        "islam",
        "kristen",
        "katholik",
        "budha",
        "hindu",
        "konghucu",
        "lainnya",
      ],
      roles: [
        {
          id: 1,
          nama: "Superadmin",
        },
        {
          id: 2,
          nama: "Admin",
        },
        {
          id: 3,
          nama: "RT",
        },
        {
          id: 4,
          nama: "Warga",
        },
      ],
      jenis_pertanyaan: ["pilihan ganda", "jawaban singkat", "kotak centang"],
      menu_waktu_2: false,
      menu_tanggal_1: false,
      menu_waktu_1: false,
      menu_tanggal_2: false,
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      count: 0,
      form: {
        judul: null,
        deskripsi: null,
        tanggal_mulai: null,
        tanggal_selesai: null,
        waktu_mulai: null,
        waktu_selesai: null,
        status: null,
        nik_pembuat: null,
        id_provinsi: null,
        id_kabkot: null,
        id_kecamatan: null,
        id_kelurahan: null,
        id_rw: null,
        id_rt: null,
        photo: null,
        password: null,
      },
      url: "",
      token_jwt: "",
      dialog: false,
      loadingAddBtn: false,
      activePicker: null,
      menu: false,
      pertanyaanBaru: null,
    };
  },
  computed: {
    TglMulaiMomentjs() {
      return this.form.tanggal_mulai
        ? moment(this.form.tanggal_mulai).format("DD, MMMM YYYY")
        : "";
    },
    TglSelesaiMomentjs() {
      return this.form.tanggal_selesai
        ? moment(this.form.tanggal_selesai).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    if (this.snackbar) {
      this.hide_alert();
    }

    if (this.snackbar_error) {
      this.hide_alert_error();
    }

    this.getListProvinsi();
  },
  created() {
    // this.dataSurvey.data.id = 5;

    // this.dataSurvey = dataSurvey;
    console.log("data survey :", this.dataSurvey.data.id);
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.add();
  },
  methods: {
    selectJenisPertanyaan(event, indexTextField) {
      if (event == "jawaban singkat") {
        console.log(this.textFields[indexTextField].opsi);
        this.textFields[indexTextField].opsi = [];
        console.log(this.textFields[indexTextField]);
      }
      console.log(event, indexTextField);
    },
    selectJenisPertanyaanBaru(event) {
      // this.textFields[i].opsi.push({
      //   id_pertanyaan: null,
      //   opsi_text: null,
      // });

      let formData = new FormData();
      formData.append("id_survey", this.dataSurvey.data.id);
      formData.append("pertanyaan", this.pertanyaanBaru);
      formData.append("jenis", event);

      const data = {
        api: "api/survey/make/ask",
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log(response);
          if (event != "jawaban singkat") {
            this.textFields.push({
              id_survey: this.dataSurvey.data.id,
              id_pertanyaan: response.data.id,
              pertanyaan: this.pertanyaanBaru,
              jenis: event,
              opsi: [],
            });
          } else {
            this.textFields.push({
              id_survey: this.dataSurvey.data.id,
              id_pertanyaan: response.data.id,
              pertanyaan: this.pertanyaanBaru,
              jenis: event,
              opsi: [
                {
                  id_pertanyaan: response.data.id,
                  opsi_text: null,
                },
              ],
            });
          }
          this.loadingAddBtn = false;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.loadingAddBtn = false;
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data) {
              this.msg_error = error.response.data;
              this.snackbar_error = true;
            }
          }
        });

      console.log(event);
    },
    addOpsi(data, i) {
      this.textFields[i].opsi.push({
        id_pertanyaan: data,
        opsi_text: null,
      });
      console.log(i);
    },
    add() {
      this.loadingAddBtn = true;

      let formData = new FormData();
      formData.append("id_survey", this.dataSurvey.data.id);
      formData.append("pertanyaan", this.pertanyaanBaru);
      formData.append("jenis", "pilihan ganda");

      const data = {
        api: "api/survey/make/ask",
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log(response);
          this.textFields.push({
            id_survey: this.dataSurvey.data.id,
            id_pertanyaan: response.data.id,
            pertanyaan: this.pertanyaanBaru,
            jenis: "pilihan ganda",
            opsi: [
              {
                id_pertanyaan: response.data.id,
                opsi_text: null,
              },
            ],
          });
          this.loadingAddBtn = false;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.loadingAddBtn = false;
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data) {
              this.msg_error = error.response.data;
              this.snackbar_error = true;
            }
          }
        });
    },
    removeOpsi(indexTextField, i) {
      console.log(this.textFields[indexTextField].opsi);
      this.textFields[indexTextField].opsi.splice(i, 1);
      console.log(this.textFields[indexTextField].opsi);
    },
    remove(index) {
      this.textFields.splice(index, 1);
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    hide_alert: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    hide_alert_error: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar_error = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.form.id_provinsi = value;
            this.form.id_kabkot = "";
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";
            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.form.id_kabkot = value;
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.form.id_kecamatan = value;
            this.form.id_kelurahan = "";
            this.kelurahans = "";

            this.kelurahans = response;
            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.form.id_kelurahan = value;
      }
    },
    submitForm() {
      console.log(this.textFields);

      this.loading = true;
      let formData = new FormData();
      this.textFields.forEach((item, index) => {
        formData.append(index + "[id_pertanyaan]", item.id_pertanyaan);
        formData.append(index + "[pertanyaan]", item.pertanyaan);
        formData.append(index + "[jenis]", item.jenis);
      });
      const data = {
        api: "api/survey/make/ask-update",
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.loading = false;
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data) {
              this.msg_error = error.response.data;
              this.snackbar_error = true;
            }
          }
        });
      console.log(this.form);

      var arrOpsi = [];

      let formDataOpsi = new FormData();
      this.textFields.forEach((item) => {
        if (item.opsi.length != 0) {
          item.opsi.forEach((item) => {
            arrOpsi.push({ item });
            // formDataOpsi.append(index + "[id_pertanyaan]", item.id_pertanyaan);
            // formDataOpsi.append(index + "[opsi_text]", item.opsi_text);
          });
        }
      });
      console.log(arrOpsi);

      arrOpsi.forEach((item, index) => {
        formDataOpsi.append(index + "[id_pertanyaan]", item.item.id_pertanyaan);
        formDataOpsi.append(index + "[opsi_text]", item.item.opsi_text);
      });

      console.log(formData.values());
      const dataOpsi = {
        api: "api/survey-opsi",
        body: formDataOpsi,
      };
      postData(dataOpsi)
        .then((response) => {
          console.log(response);
          this.dialog = false;
          this.loading = false;
          this.snackbar = true;
          this.$router.push("e-survey");
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.loading = false;
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data) {
              this.msg_error = error.response.data;
              this.snackbar_error = true;
            }
          }
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    deletePic() {
      this.url = "";
      this.$refs.file.value = "";
    },
    onFileChange(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.form.photo = e.target.files[0];
      console.log(this.form);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>