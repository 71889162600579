<template>
  <div>
    <v-snackbar v-model="snackbar" multi-line centered color="green">
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="white" size="50"> mdi-check-circle-outline </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title style="font-size: 2rem"
            >Password Telah Diubah</v-list-item-title
          >
          <v-list-item-subtitle style="font-size: 1rem"
            >Silahkan login kembali</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <template v-slot:action="{ attrs }">
        <v-btn @click="snackbar = false" icon v-bind="attrs">
          <v-icon color="white"> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar color="red" centered style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-app-bar app elevation="4" color="white">
      <div class="text-center pa-5">
        <h4>Smart RT</h4>
        <p style="font-size: 12px; margin: 0">By Universitas Mulia</p>
      </div>
    </v-app-bar>
    <div style="background: #efeeee; height: 1000px">
      <v-container class="pt-10">
        <v-card style="border-radius: 20px">
          <v-card-text>
            <v-container class="pa-10">
              <form
                id="myForm"
                name="myForm"
                v-on:submit.prevent="submitForm"
                enctype="multipart/form-data"
              >
                <h1 class="text-center mb-10">Password Baru</h1>
                <v-text-field
                  v-model="password1"
                  required
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  label="Ketik password baru"
                  placeholder="Password Baru"
                  hint="Minimal 8 karakter"
                  outlined
                  counter
                  @click:append="show1 = !show1"
                >
                </v-text-field>
                <v-text-field
                  v-model="password2"
                  required
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show2 ? 'text' : 'password'"
                  label="Ketik ulang password baru"
                  placeholder="Konfirmasi Password Baru"
                  hint="Minimal 8 karakter"
                  outlined
                  counter
                  @click:append="show2 = !show2"
                ></v-text-field>
                <v-btn
                  style="color: white"
                  color="red"
                  type="submit"
                  value="Submit"
                  :loading="loading"
                  block
                >
                  Kirim
                </v-btn>
              </form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-container>
    </div>
  </div>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import { postDataTanpaToken, getDataTanpaToken } from "../service/ApiService";

export default {
  name: "ResetPassword",
  props: {},
  data() {
    return {
      show1: false,
      show2: false,
      rules: {
        required: (value) => !!value || "Wajib di isi.",
        min: (v) => v.length >= 8 || "Password minimal 8 digit",
        min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
        emailMatch: () => `The email and password you entered don't match`,
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
        return "Wajib Berupa Angka.";
      },
      snackbar: false,
      loading: false,
      snackbar_error: false,
      token_jwt: "",
      dialog: false,
      activePicker: null,
      menu: false,
      email: null,
      password1: null,
      password2: null,
      token_reset: null,
      token_security: null,
    };
  },
  computed: {},

  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    if (this.snackbar) {
      this.hide_alert();
    }

    if (this.snackbar_error) {
      this.hide_alert_error();
    }
  },
  created() {
    this.token_reset = new URL(location.href).searchParams.get("token-reset");
    console.log(this.token_reset);
    this.getToken();
    console.log("data vote :", this.dataVote);
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
  },
  methods: {
    getToken() {
      this.loading = true;

      const ApiGetNews = "api/profile/reset/" + this.token_reset;

      getDataTanpaToken(ApiGetNews)
        .then((response) => {
          console.log("data :", response);
          this.token_security = response.token;
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg_error = "Token sudah expired, mohon melakukan reset password kembali.";
          this.snackbar_error = true;
          setTimeout(() => {
            this.$router.push('/login')
          }, 5000);
        });
    },
    hide_alert: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    hide_alert_error: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar_error = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    checkSurvey() {
      this.loading = true;
      console.log(this.items);
      let formData = new FormData();
      formData.append("nik", this.userData.nik);
      const data = {
        api: "api/e-voting/" + this.dataVote.id_evoting,
        body: formData,
      };
      postDataTanpaToken(data)
        .then((response) => {
          console.log("data :", response);
          this.detailVoting(response);
          this.loading = false;
          this.dialog = false;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          if (error.response) {
            this.loading = false;
            this.msg_error = "Anda sudah melakukan voting.";
            this.snackbar_error = true;
          }
        });
    },
    detailVoting(item) {
      this.dialog = false;
      this.$router.push({
        name: "DetailVoting",
        params: {
          votingData: item,
        },
      });
    },
    submitForm() {
      this.loading = true;
      console.log(this.form);
      let formData = new FormData();

      formData.append("password", this.password1);
      formData.append("password_confirmation", this.password2);
      console.log(formData.values());
      const data = {
        api: "api/profile/reset/" + this.token_security,
        body: formData,
      };
      postDataTanpaToken(data)
        .then((response) => {
          this.snackbar = true;
          this.loading = false;
          this.$router.push("/login?success=true");
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.password) {
              this.msg_error = error.response.data.password;
              this.snackbar_error = true;
              this.dialog = false;
              this.loading = false;
            }
          }
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>