<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil mendaftarkan akun, silahkan cek email anda untuk mendapatkan
      password untuk login.
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="600px">
      <template
        v-slot:activator="{ on, attrs }"
        class="flex justify-end text-right"
      >
        <v-btn
          color="#FB9B65"
          class="button-signup"
          block
          v-bind="attrs"
          v-on="on"
          >{{ text }}</v-btn
        >
      </template>
      <v-card>
        <v-app-bar
          color="#F2F1F1"
          height="80px"
          elevate-on-scroll
          scroll-target="#scrolling-techniques-7"
        >
          <v-btn color="black" icon @click="dialog = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-app-bar-title>
            {{ text }}
          </v-app-bar-title>
        </v-app-bar>
        <v-card-text>
          <v-container>
            <form
              id="myForm"
              name="myForm"
              v-on:submit.prevent="submitForm"
              enctype="multipart/form-data"
            >
              <v-row>
                <v-col cols="12" class="d-flex justify-center text-center">
                  <v-file-input
                    ref="file"
                    style="display: none"
                    @change="onFileChange"
                    accept="image/x-png,image/jpeg"
                    required
                  ></v-file-input>
                  <v-btn
                    icon
                    style="width: 150px; height: 150px"
                    @click="$refs.file.$refs.input.click()"
                  >
                    <v-avatar color="brown" size="150">
                      <div v-if="this.url != ''" id="preview">
                        <img v-if="url" :src="url" />
                      </div>
                      <span
                        v-if="this.url == ''"
                        class="white--text text-h5"
                        style="text-transform: none"
                      >
                        <v-icon>mdi-camera</v-icon>
                      </span>
                    </v-avatar>
                  </v-btn>
                </v-col>

                <v-col cols="12" class="text-center" v-if="this.url != ''">
                  <p class="delete-text" v-on:click="deletePic()">
                    <v-icon style="color: red">mdi-delete</v-icon> Hapus Foto
                  </p>
                </v-col>
                <v-col cols="12" class="text-center" v-if="imgError">
                  <p style="color: red">
                    File yang dipilih berukuran lebih dari 4 MB
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-book-account"
                    label="NIK"
                    required
                    counter="16"
                    v-model="form.nik"
                    hint="Nomor Induk Keluarga (16 Digit)"
                    :rules="[rules.required, rules.min_16]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-book-open-outline"
                    label="No KK"
                    required
                    counter="16"
                    v-model="form.id_kk"
                    hint="Nomor Kartu Keluarga (16 Digit)"
                    :rules="[rules.required, rules.min_16]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-account"
                    label="Nama Lengkap"
                    required
                    :rules="[rules.required]"
                    v-model="form.nama"
                    hint="Nama Lengkap Sesuai KTP"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-email"
                    label="Email"
                    :rules="[rules.required]"
                    required
                    v-model="form.email"
                    hint="Email Aktif"
                  >
                  </v-text-field>
                </v-col>
                
                  <v-col cols="12">
                    <v-autocomplete
                      prepend-icon="mdi-account-group"
                      :items="status_kk"
                      :rules="[rules.required]"
                      label="Status Di KK"
                      v-model="form.status"
                      hint="Status Sesuai Kartu Keluarga" required>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                      prepend-icon="mdi-account-group"
                      :items="status_pernikahans"
                      :rules="[rules.required]"
                      label="Status Pernikahan"
                      v-model="form.status_pernikahan"
                      hint="Status Sesuai Kartu Keluarga" required>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-account"
                    label="Pekerjaan"
                    :rules="[rules.required]"
                    required
                    v-model="form.pekerjaan"
                    hint="Pekerjaan"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-phone"
                    type="tel"
                    v-model="form.nohp"
                    label="Nomor Telepon"
                    hint="Nomor Telepon Aktif"
                    :rules="[numberRule, rules.required]"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    :rules="[rules.required]"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Tanggal Lahir"
                        prepend-icon="mdi-calendar"
                        readonly
                        :rules="[rules.required]"
                        :value="computedDateFormattedMomentjs"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :rules="[rules.required]"
                      v-model="form.tgl_lahir"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :rules="[rules.required]"
                    prepend-icon="mdi-map-marker"
                    label="Tempat Lahir"
                    v-model="form.tempatlahir"
                    hint="ex:Balikpapan"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-radio-group
                    required
                    :rules="[rules.required]"
                    label="Jenis Kelamin"
                    v-model="form.jk"
                    prepend-icon="mdi-gender-male-female"
                  >
                    <v-radio label="Pria" value="pria"></v-radio>
                    <v-radio label="Wanita" value="wanita"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-account-group"
                    label="Suku"
                    :rules="[rules.required]"
                    v-model="form.suku"
                    hint="Suku"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    prepend-icon="mdi-account-group"
                    v-model="form.agama"
                    :items="agamas"
                    :rules="[rules.required]"
                    label="Agama"
                    hint="Agama Sesuai KTP"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    prepend-icon="mdi-water"
                    v-model="form.goldarah"
                    :rules="[rules.required]"
                    :items="goldars"
                    label="Golongan Darah"
                    hint="Golongan Darah"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    label="Alamat KTP"
                    v-model="form.alamat"
                    :rules="[rules.required]"
                    hint="Alamat Sesuai KTP"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    label="Alamat Domisili"
                    :rules="[rules.required]"
                    v-model="form.alamat_domisili"
                    hint="Alamat Tempat Tinggal Saat Ini"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    label="RT"
                    v-model="form.id_rt"
                    hint="RT Sesuai Tempat Tinggal Saat Ini"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    :rules="[rules.required]"
                    label="RW"
                    v-model="form.id_rw"
                    hint="RW Sesuai Tempat Tinggal Saat Ini"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="form.id_provinsi"
                    :items="provinsis"
                    label="Provinsi"
                    :rules="[rules.required]"
                    item-value="id"
                    item-text="provinsi"
                    required
                    @change="(event) => selectedProvinsi(event)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="form.id_kabkot"
                    :rules="[rules.required]"
                    :items="kabupatens"
                    label="Kabupaten/ Kota"
                    item-value="id"
                    item-text="kabupaten_kota"
                    :disabled="kabupatens.length == 0"
                    required
                    @change="(event) => selectedKabupaten(event)"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="form.id_kecamatan"
                    :items="kecamatans"
                    label="Kecamatan"
                    :rules="[rules.required]"
                    item-text="kecamatan"
                    item-value="id"
                    :disabled="kecamatans.length == 0"
                    required
                    @change="(event) => selectedKecamatan(event)"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="form.id_kelurahan"
                    :items="kelurahans"
                    :rules="[rules.required]"
                    item-value="id"
                    label="Kelurahan"
                    :disabled="kelurahans.length == 0"
                    item-text="kelurahan"
                    required
                    @change="(event) => selectedKelurahan(event)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-subheader>Pendidikan</v-subheader>
                </v-col>
                <v-col cols="12" v-if="textFieldsPenididikanWajib != []">
                  <div
                    v-for="(
                      textFieldsPenididikanWajib, i
                    ) in textFieldsPenididikanWajib"
                    :key="i"
                  >
                    
                    <v-row>
                      <v-col cols="10">
                        <v-text-field
                          prepend-icon="mdi-school"
                          label="Nama Sekolah"
                          v-model="textFieldsPenididikanWajib.nama_sekolah"
                        ></v-text-field>
                        <v-text-field
                          prepend-icon="mdi-school"
                          label="Tahun Masuk"
                          v-model="textFieldsPenididikanWajib.tahun_masuk"
                        ></v-text-field>
                        <v-text-field
                          prepend-icon="mdi-school"
                          label="Tahun Selesai"
                          v-model="textFieldsPenididikanWajib.tahun_selesai"
                        ></v-text-field>
                        <v-autocomplete
                          label="Status Pendidikan"
                          prepend-icon="mdi-school"
                          v-model="textFieldsPenididikanWajib.status_selesai"
                          :items="status_pendidikan"
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                        <div class="text-end">
                          <v-btn
                            @click="removePendidikanWajib(i)"
                            icon
                            color="error"
                          >
                            <v-icon>mdi-close-box</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    @click="addPendidikanWajib()"
                    color="red"
                    class="button-signup"
                    block
                    >Tambah Pendidikan Umum +
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="textFieldsPenididikanLanjut != []">
                  <div
                    v-for="(
                      textFieldsPenididikanLanjut, i
                    ) in textFieldsPenididikanLanjut"
                    :key="i"
                  >
                    <div class="text-end">
                      <v-btn
                        @click="removePendidikanLanjut(i)"
                        icon
                        color="error"
                      >
                        <v-icon>mdi-close-box</v-icon>
                      </v-btn>
                    </div>

                    <v-text-field
                      prepend-icon="mdi-school"
                      label="Nama Sekolah"
                      v-model="textFieldsPenididikanLanjut.nama_sekolah"
                    ></v-text-field>
                    <v-text-field
                      prepend-icon="mdi-school"
                      label="Tahun Masuk"
                      v-model="textFieldsPenididikanLanjut.tahun_masuk"
                    ></v-text-field>
                    <v-text-field
                      prepend-icon="mdi-school"
                      label="Tahun Selesai"
                      v-model="textFieldsPenididikanLanjut.tahun_selesai"
                    ></v-text-field>
                    <v-autocomplete
                      label="Status Pendidikan"
                      prepend-icon="mdi-school"
                      v-model="textFieldsPenididikanLanjut.status_selesai"
                      :items="status_pendidikan"
                      required
                    ></v-autocomplete>
                    <v-text-field
                      prepend-icon="mdi-school"
                      label="Gelar"
                      v-model="textFieldsPenididikanLanjut.gelar"
                    ></v-text-field>
                
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    @click="addPendidikanLanjut()"
                    class="button-signup"
                    color="red"
                    block
                    >Tambah Pendidikan Lanjut +
                  </v-btn>
                </v-col>
                <v-btn
                  style="color: white"
                  color="green"
                  type="submit"
                  value="Submit"
                  :loading="loading"
                  block
                >
                  Simpan
                </v-btn>
              </v-row>
            </form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import { getData, postDataTanpaToken } from "../service/ApiService";

export default {
  name: "Daftar",
  props: {
    text: String,
  },

  data() {
    return {
      imgError: false,
      userData: null,
      msg_error: "",
      show1: false,
      rulesImg: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      rules: {
        required: (value) => !!value || "Wajib di isi.",
        min: (v) => v.length >= 8 || "Password minimal 8 digit",
        min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
        return "Wajib Berupa Angka.";
      },
      snackbar: false,
      loading: false,
      textFieldsPenididikanWajib: [
        // {
        //   nama_sekolah: null,
        //   tahun_masuk: null,
        //   tahun_selesai: null,
        //   status_selesai: null,
        // },
      ],
      status_pernikahans: ["Belum Kawin", "Kawin", "Cerai Hidup", "Cerai Mati"],
      status_kk: ["Suami", "Istri", "Anak", "Ponakan", "Cucu", "Anak Tiri"],
      textFieldsPenididikanLanjut: [],
      snackbar_error: false,
      goldars: [
        "A",
        "B",
        "AB",
        "O",
        "A+",
        "A-",
        "B+",
        "B-",
        "AB+",
        "AB-",
        "O+",
        "O-",
        "NA",
      ],
      agamas: [
        "islam",
        "kristen",
        "katholik",
        "budha",
        "hindu",
        "konghucu",
        "lainnya",
      ],
      roles: [
        {
          id: 2,
          nama: "Admin",
        },
        {
          id: 3,
          nama: "RT",
        },
        {
          id: 4,
          nama: "Warga",
        },
      ],
      status_pendidikan: ["Lulus", "Tidak Lulus"],
      provinsis: [],
      kabupatens: [],
      kelurahans: [],
      kecamatans: [],
      form: {
        pekerjaan: "",
        id_role: "",
        nama: "",
        nik: "",
        id_kk: "",
        email: "",
        status: "",
        status_pernikahan: "",
        nohp: "",
        tgl_lahir: "",
        tempatlahir: "",
        jk: "",
        suku: "",
        agama: "",
        goldarah: "",
        alamat: "",
        alamat_domisili: "",
        id_provinsi: "",
        id_kabkot: "",
        id_kelurahan: "",
        id_kecamatan: "",
        id_rt: "",
        id_rw: "",
        photo: "",
      },
      url: "",
      token_jwt: "",
      dialog: false,
      activePicker: null,
      menu: false,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.form.tgl_lahir
        ? moment(this.form.tgl_lahir).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    this.getListProvinsi();
  },
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
  },
  methods: {
    snackbarAlert(msg) {
      this.msg_error = msg;
      this.snackbar_error = true;
      setTimeout(() => {
        this.snackbar_error = false;
      }, 2000);
    },
    addPendidikanWajib() {
      this.textFieldsPenididikanWajib.push({
        nama_sekolah: null,
        tahun_masuk: null,
        tahun_selesai: null,
        status_selesai: null,
      });
    },
    removePendidikanWajib(index) {
      this.textFieldsPenididikanWajib.splice(index, 1);
    },
    addPendidikanLanjut() {
      this.textFieldsPenididikanLanjut.push({
        nama_sekolah: null,
        tahun_masuk: null,
        tahun_selesai: null,
        status_selesai: null,
        gelar: null,
      });
    },
    removePendidikanLanjut(index) {
      this.textFieldsPenididikanLanjut.splice(index, 1);
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.form.id_provinsi = value;
            this.form.id_kabkot = "";
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kabupatens = [];
            this.kelurahans = [];
            this.kecamatans = [];

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.form.id_kabkot = value;
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kelurahans = [];
            this.kecamatans = [];

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.form.id_kecamatan = value;
            this.form.id_kelurahan = "";
            this.kelurahans = [];

            this.kelurahans = response;
            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.form.id_kelurahan = value;
      }
    },
    submitForm() {
      console.log(this.form);
      this.loading = true;
      let formData = new FormData();

      formData.append("data_penduduk[nama]", this.form.nama);
      formData.append("data_penduduk[nik]", this.form.nik);
      formData.append("data_penduduk[email]", this.form.email);

      //hidden
      // formData.append("data_penduduk[id_provinsi]", 23);
      // formData.append("data_penduduk[id_kecamatan]", 5068);
      // formData.append("data_penduduk[id_kelurahan]", 62138);
      // formData.append("data_penduduk[id_kabkot]", 364);
      // formData.append("data_penduduk[id_rt]", "35");
      // formData.append("data_penduduk[id_kk]", "7234567890123456");
      // formData.append("data_penduduk[status]", "Suami");
      // formData.append("data_penduduk[status_pernikahan]", "Menikah");
      // formData.append("data_penduduk[pekerjaan]", "Karyawan");
      // formData.append("data_penduduk[nohp]", "081234567890");
      // formData.append("data_penduduk[tgl_lahir]", "1958-05-07");
      // formData.append("data_penduduk[tempatlahir]", "Balikpapan");
      // formData.append("data_penduduk[jk]", "pria");
      // formData.append("data_penduduk[suku]", "Jawa");
      // formData.append("data_penduduk[agama]", "Islam");
      // formData.append("data_penduduk[goldarah]", "AB");
      // formData.append("data_penduduk[alamat]", "Jalan Damai");
      // formData.append("data_penduduk[alamat_domisili]", "Jalan Damai");
      // formData.append("data_penduduk[id_rw]", "00");
      // formData.append("data_penduduk[id_role]", 4);

      
      formData.append("data_penduduk[id_provinsi]", this.form.id_provinsi);
      formData.append("data_penduduk[id_kecamatan]", this.form.id_kecamatan);
      formData.append("data_penduduk[id_kelurahan]", this.form.id_kelurahan);
      formData.append("data_penduduk[id_kabkot]", this.form.id_kabkot);
      formData.append("data_penduduk[id_rt]", this.form.id_rt);
      formData.append("data_penduduk[id_kk]", this.form.id_kk);
      formData.append("data_penduduk[status]", this.form.status);
      formData.append(
        "data_penduduk[status_pernikahan]",
        this.form.status_pernikahan
      );
      formData.append("data_penduduk[pekerjaan]", this.form.pekerjaan);
      formData.append("data_penduduk[nohp]", this.form.nohp);
      formData.append("data_penduduk[tgl_lahir]", this.form.tgl_lahir);
      formData.append("data_penduduk[tempatlahir]", this.form.tempatlahir);
      formData.append("data_penduduk[jk]", this.form.jk);
      formData.append("data_penduduk[suku]", this.form.suku);
      formData.append("data_penduduk[agama]", this.form.agama);
      formData.append("data_penduduk[goldarah]", this.form.goldarah);
      formData.append("data_penduduk[alamat]", this.form.alamat);
      formData.append(
        "data_penduduk[alamat_domisili]",
        this.form.alamat_domisili
      );
      formData.append("data_penduduk[id_rw]", this.form.id_rw);
      this.form.id_role = 4;
      formData.append("data_penduduk[id_role]", this.form.id_role);

      formData.append("data_penduduk[photo]", this.form.photo);
      formData.append("umum", this.textFieldsPenididikanWajib);
      formData.append("khusus", this.textFieldsPenididikanLanjut);

      this.textFieldsPenididikanWajib.forEach((item, index) => {
        formData.append(
          "umum[" + index + "][nama_sekolah] ",
          item.nama_sekolah
        );
        formData.append("umum[" + index + "][tahun_masuk] ", item.tahun_masuk);
        formData.append(
          "umum[" + index + "][tahun_selesai] ",
          item.tahun_selesai
        );
        formData.append(
          "umum[" + index + "][status_selesai] ",
          item.status_selesai
        );
      });

      this.textFieldsPenididikanLanjut.forEach((item, index) => {
        formData.append(
          "khusus[" + index + "][nama_sekolah] ",
          item.nama_sekolah
        );
        formData.append(
          "khusus[" + index + "][tahun_masuk] ",
          item.tahun_masuk
        );
        formData.append(
          "khusus[" + index + "][tahun_selesai] ",
          item.tahun_selesai
        );
        formData.append(
          "khusus[" + index + "][status_selesai] ",
          item.status_selesai
        );
        formData.append("khusus[" + index + "][gelar] ", item.gelar);
      });

      console.log(formData.values());
      const data = {
        api: "auth/register",
        body: formData,
      };

      postDataTanpaToken(data)
        .then((response) => {
          console.log(response);
          this.snackbar = true;
          this.dialog = false;
          this.loading = false;
          // this.$router.reload();
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.loading = false;
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.nik) {
              this.msg_error = error.response.data.nik;
              this.snackbar_error = true;
            } else if (error.response.data.email) {
              this.msg_error = error.response.data.email;
              this.snackbar_error = true;
            } else {
              this.msg_error = error.response.data;
              this.snackbar_error = true;
            }
            console.log(formData.values());
          }
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    deletePic() {
      this.url = "";
      this.$refs.file.value = "";
    },
    onFileChange(e) {
      console.log(e);
      if (e.size > 4072000) {
        this.snackbarAlert("Ukuran foto maksimal 4 MB!");
        if (this.url == "") {
          this.imgError = true;
        }
        console.log("Pilih gambar gagal");
      } else {
        let file = e;
        this.url = URL.createObjectURL(file);
        this.form.photo = e;
        this.imgError = false;
        console.log("Pilih gambar berhasil");
      }
      // console.log(this.form);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  width: 200px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>
