<template>
  <v-footer padless absolute>
    <div
      style="position: absolute; width: 100%"
      class="d-flex align-content-center justify-center"
    >
      <img
        src="../assets/bubble-footer.png"
        class="img-bubble"
        alt=""
        srcset=""
      />
    </div>
    <img src="../assets/footer.png" class="background-image" alt="" />
    <v-row no-gutters justify="center" style="position: absolute; width: 100%">
      <v-col cols="4">
        <img
          class="img-logo2"
          alt="Smart RT logo"
          src="../assets/smart-rt-logo.png"
        />
      </v-col>
      <v-col
        cols="4"
        class="d-flex align-content-center flex-wrap justify-center"
      >
        <p class="text-cp">© Hak Cipta 2020 - Universitas Mulia</p>
      </v-col>
      <v-col
        cols="4"
        class="d-flex align-content-center flex-wrap justify-center"
      >
        <img src="../assets/logo_um.png" width="80%" alt="" />
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
@media only screen and (min-width: 599px) {
  .text-cp {
    color: white;
    font-size: 1.3vh;
    margin: 0;
  }

  .img-logo2 {
    height: 80px;
    width: auto;
    margin: 10px 20px 0 50px;
  }

  .img-bubble {
    height: 12vh;
    width: auto;
  }

  .background-image {
    height: 100px;
    width: 100%;
  }
}

@media only screen and (max-width: 1250px) {
  .img-logo2 {
    height: 70px;
    width: auto;
    margin: 10px 20px 0 20px;
  }

  .img-bubble {
    height: 11vh;
    width: auto;
  }
}

@media only screen and (max-width: 970px) {
  .img-logo2 {
    height: 70px;
    width: auto;
    margin: 10px 20px 0 20px;
  }

  .img-bubble {
    height: 11vh;
    width: auto;
  }
}

@media only screen and (max-width: 845px) {
  .img-logo2 {
    height: 70px;
    width: auto;
    margin: 10px 20px 0 20px;
  }

  .img-bubble {
    height: 10vh;
    width: auto;
  }
}

@media only screen and (max-width: 599px) {
  .text-cp {
    color: white;
    font-size: 0.8vh;
    margin: 0;
  }

  .img-bubble {
    height: 5vh;
    width: auto;
  }

  .img-logo2 {
    height: 40px;
    width: auto;
    margin: 20px 20px 0 10px;
  }

  .background-image {
    height: 80px;
    width: 100%;
  }
}

@media only screen and (min-width: 693px) {
  .text-cp {
    color: white;
    font-size: 2vh;
    margin: 0;
  }
}

@media only screen and (max-width: 693px) {
  .text-cp {
    color: white;
    font-size: 1.5vh;
    margin: 0;
  }
}
</style>
