<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      {{ this.msg }}
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>

    <v-card>
      <v-app-bar
        app
        color="#F2F1F1"
        height="80px"
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-btn color="black" icon @click="back()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-container>
          <form
            id="myForm"
            name="myForm"
            v-on:submit.prevent="submitForm"
            enctype="multipart/form-data"
          >
            <v-row>
              <v-col cols="12" class="d-flex justify-center text-center">
                <input
                  name="photo"
                  type="file"
                  ref="file"
                  :rules="[rules.required]"
                  style="display: none"
                  @change="onFileChange"
                  accept="image/x-png,image/gif,image/jpeg"
                />
                <v-btn
                  icon
                  style="width: 150px; height: 150px"
                  @click="$refs.file.click()"
                >
                  <v-avatar color="brown" size="150">
                    <div v-if="form.photo != '' && url == ''" id="preview">
                      <img v-if="form.photo" :src="form.photo" />
                    </div>
                    <div v-if="url != ''" id="preview">
                      <img v-if="url" :src="url" />
                    </div>
                    <span
                      v-if="form.photo == null && this.url == ''"
                      class="white--text text-h5"
                      style="text-transform: none"
                    >
                      <v-icon>mdi-camera</v-icon>
                    </span>
                  </v-avatar>
                </v-btn>
              </v-col>
              <v-col cols="12" class="text-center" v-if="this.url != ''">
                <p class="delete-text" v-on:click="deletePic()">
                  <v-icon style="color: red">mdi-delete</v-icon> Hapus Foto
                </p>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="nik"
                  prepend-icon="mdi-book-account"
                  label="NIK"
                  required
                  counter="16"
                  v-model="form.nik"
                  hint="Nomor Induk Keluarga (16 Digit)"
                  :rules="[rules.required, rules.min_16]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="no_kk"
                  prepend-icon="mdi-book-open-outline"
                  label="No KK"
                  required
                  counter="16"
                  v-model="form.id_kk"
                  hint="Nomor Kartu Keluarga (16 Digit)"
                  :rules="[rules.required, rules.min_16]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="nama_lengkap"
                  prepend-icon="mdi-account"
                  label="Nama Lengkap"
                  required
                  :rules="[rules.required, rules.min_16]"
                  v-model="form.nama"
                  hint="Nama Lengkap Sesuai KTP"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="email"
                  prepend-icon="mdi-email"
                  label="Email"
                  :rules="[rules.required]"
                  required
                  v-model="form.email"
                  hint="Email Aktif"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="userData.role == 'superadmin'">
                <v-autocomplete
                  name="id_role"
                  prepend-icon="mdi-account"
                  v-model="form.id_role"
                  :items="roles"
                  :rules="[rules.required]"
                  item-value="id"
                  item-text="nama"
                  label="Role"
                  hint="Role"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="status_kk"
                  prepend-icon="mdi-account-group"
                  label="Status Di KK"
                  v-model="form.status"
                  hint="Status Sesuai Kartu Keluarga"
                  required
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  name="status_pernikahan"
                  prepend-icon="mdi-heart"
                  v-model="form.status_pernikahan"
                  :items="status_pernikahans"
                  :rules="[rules.required]"
                  label="Status Pernikahan"
                  hint="Status Pernikahan Sesuai Di KTP"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-account"
                  label="Pekerjaan"
                  :rules="[rules.required]"
                  required
                  v-model="form.pekerjaan"
                  hint="Pekerjaan"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="no_telp"
                  prepend-icon="mdi-phone"
                  type="tel"
                  v-model="form.nohp"
                  label="Nomor Telepon"
                  hint="Nomor Telepon Aktif"
                  :rules="[numberRule, rules.required]"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  :rules="[rules.required]"
                  min-width="auto"
                >
                  <template>
                    <v-text-field
                      name="tgl_lahir"
                      label="Tanggal Lahir"
                      prepend-icon="mdi-calendar"
                      readonly
                      :rules="[rules.required]"
                      :value="computedDateFormattedMomentjs"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :rules="[rules.required]"
                    v-model="form.tgl_lahir"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    min="1950-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="tmpt_lhr"
                  :rules="[rules.required]"
                  prepend-icon="mdi-map-marker"
                  label="Tempat Lahir"
                  v-model="form.tempatlahir"
                  hint="ex:Balikpapan"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-radio-group
                  name="jk"
                  required
                  :rules="[rules.required]"
                  label="Jenis Kelamin"
                  v-model="form.jk"
                  prepend-icon="mdi-gender-male-female"
                >
                  <v-radio label="Pria" value="pria"></v-radio>
                  <v-radio label="Wanita" value="wanita"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="suku"
                  prepend-icon="mdi-account-group"
                  label="Suku"
                  :rules="[rules.required]"
                  v-model="form.suku"
                  hint="Suku"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  name="agama"
                  prepend-icon="mdi-account-group"
                  v-model="form.agama"
                  :items="agamas"
                  :rules="[rules.required]"
                  label="Agama"
                  hint="Agama Sesuai KTP"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  name="goldar"
                  prepend-icon="mdi-water"
                  v-model="form.goldarah"
                  :rules="[rules.required]"
                  :items="goldars"
                  label="Golongan Darah"
                  hint="Golongan Darah"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="alamat_ktp"
                  prepend-icon="mdi-map-marker"
                  label="Alamat KTP"
                  v-model="form.alamat"
                  :rules="[rules.required]"
                  hint="Alamat Sesuai KTP"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="alamat_domisili"
                  prepend-icon="mdi-map-marker"
                  label="Alamat Domisili"
                  :rules="[rules.required]"
                  v-model="form.alamat_domisili"
                  hint="Alamat Tempat Tinggal Saat Ini"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="rt"
                  prepend-icon="mdi-map-marker"
                  label="RT"
                  v-model="form.id_rt"
                  hint="RT Sesuai Tempat Tinggal Saat Ini"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="rw"
                  prepend-icon="mdi-map-marker"
                  :rules="[rules.required]"
                  label="RW"
                  v-model="form.id_rw"
                  hint="RW Sesuai Tempat Tinggal Saat Ini"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  name="provinsi"
                  prepend-icon="mdi-map-marker"
                  v-model="form.id_provinsi"
                  :items="provinsis"
                  label="Provinsi"
                  :rules="[rules.required]"
                  item-value="id"
                  item-text="provinsi"
                  required
                  @change="(event) => selectedProvinsi(event)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  name="kabkot"
                  prepend-icon="mdi-map-marker"
                  v-model="form.id_kabkot"
                  :rules="[rules.required]"
                  :items="kabupatens"
                  label="Kabupaten/ Kota"
                  item-value="id"
                  item-text="kabupaten_kota"
                  :disabled="kabupatens == ''"
                  required
                  @change="(event) => selectedKabupaten(event)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  name="kecamatan"
                  prepend-icon="mdi-map-marker"
                  v-model="form.id_kecamatan"
                  :items="kecamatans"
                  label="Kecamatan"
                  :rules="[rules.required]"
                  item-text="kecamatan"
                  item-value="id"
                  :disabled="kecamatans == ''"
                  required
                  @change="(event) => selectedKecamatan(event)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  name="kelurahan"
                  prepend-icon="mdi-map-marker"
                  v-model="form.id_kelurahan"
                  :items="kelurahans"
                  :rules="[rules.required]"
                  item-value="id"
                  label="Kelurahan"
                  :disabled="kelurahans == ''"
                  item-text="kelurahan"
                  required
                  @change="(event) => selectedKelurahan(event)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-subheader>Pendidikan</v-subheader>
              </v-col>
              <v-col cols="12">
                <div
                  v-for="(dataPendidikanUmum, i) in dataPendidikanUmum"
                  :key="i"
                >
                  <v-row>
                    <v-col cols="10">
                      <v-text-field
                        prepend-icon="mdi-school"
                        label="Nama Sekolah"
                        v-model="dataPendidikanUmum.nama_sekolah"
                      ></v-text-field>
                      <v-text-field
                        prepend-icon="mdi-school"
                        label="Tahun Masuk"
                        v-model="dataPendidikanUmum.tahun_masuk"
                      ></v-text-field>
                      <v-text-field
                        prepend-icon="mdi-school"
                        label="Tahun Selesai"
                        v-model="dataPendidikanUmum.tahun_selesai"
                      ></v-text-field>
                      <v-autocomplete
                        label="Status Pendidikan"
                        prepend-icon="mdi-school"
                        v-model="dataPendidikanUmum.status_selesai"
                        :items="status_pendidikan"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                      <div class="text-end">
                        <v-btn
                          @click="removePendidikanWajib(i, dataPendidikanUmum)"
                          icon
                          color="error"
                        >
                          <v-icon>mdi-close-box</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" v-if="createDataPendidikanUmum != ''">
                <div
                  v-for="(dataPendidikanUmum, i) in createDataPendidikanUmum"
                  :key="i"
                >
                  <!-- <v-col cols="12">
                      <p style="color: #ff0000">Peserta {{ i + 1 }}</p>
                    </v-col> -->
                  <v-row>
                    <v-col cols="10">
                      <v-text-field
                        prepend-icon="mdi-school"
                        label="Nama Sekolah"
                        v-model="dataPendidikanUmum.nama_sekolah"
                      ></v-text-field>
                      <v-text-field
                        prepend-icon="mdi-school"
                        label="Tahun Masuk"
                        v-model="dataPendidikanUmum.tahun_masuk"
                      ></v-text-field>
                      <v-text-field
                        prepend-icon="mdi-school"
                        label="Tahun Selesai"
                        v-model="dataPendidikanUmum.tahun_selesai"
                      ></v-text-field>
                      <v-autocomplete
                        label="Status Pendidikan"
                        prepend-icon="mdi-school"
                        v-model="dataPendidikanUmum.status_selesai"
                        :items="status_pendidikan"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                      <div class="text-end">
                        <v-btn
                          @click="removePendidikanWajibCreate(i)"
                          icon
                          color="error"
                        >
                          <v-icon>mdi-close-box</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12">
                <v-btn
                  @click="addPendidikanWajib()"
                  color="red"
                  class="button-signup"
                  >Tambah Pendidikan Umum +
                </v-btn>
              </v-col>
              <v-col cols="12">
                <div
                  v-for="(dataPendidikanKhusus, i) in dataPendidikanKhusus"
                  :key="i"
                >
                  <!-- <p style="color: #ff0000">Peserta {{ i + 1 }}</p> -->
                  <div class="text-end">
                    <v-btn
                      @click="removePendidikanLanjut(i, dataPendidikanKhusus)"
                      icon
                      color="error"
                    >
                      <v-icon>mdi-close-box</v-icon>
                    </v-btn>
                  </div>

                  <v-text-field
                    prepend-icon="mdi-school"
                    label="Nama Sekolah"
                    v-model="dataPendidikanKhusus.nama_sekolah"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-school"
                    label="Tahun Masuk"
                    v-model="dataPendidikanKhusus.tahun_masuk"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-school"
                    label="Tahun Selesai"
                    v-model="dataPendidikanKhusus.tahun_selesai"
                  ></v-text-field>
                  <v-autocomplete
                    label="Status Pendidikan"
                    prepend-icon="mdi-school"
                    v-model="dataPendidikanKhusus.status_selesai"
                    :items="status_pendidikan"
                    required
                  ></v-autocomplete>
                  <v-text-field
                    prepend-icon="mdi-school"
                    label="Gelar"
                    v-model="dataPendidikanKhusus.gelar"
                  ></v-text-field>
                  <!-- <v-autocomplete
                      label="Gelar"
                      prepend-icon="mdi-school"
                      v-model="textFieldsPenididikanLanjut.gelar"
                      :items="gelar"
                      required
                    ></v-autocomplete> -->
                </div>
              </v-col>
              <v-col cols="12" v-if="createDataPendidikanKhusus != ''">
                <div
                  v-for="(
                    dataPendidikanKhusus, i
                  ) in createDataPendidikanKhusus"
                  :key="i"
                >
                  <!-- <p style="color: #ff0000">Peserta {{ i + 1 }}</p> -->
                  <div class="text-end">
                    <v-btn
                      @click="removePendidikanLanjutCreate(i)"
                      icon
                      color="error"
                    >
                      <v-icon>mdi-close-box</v-icon>
                    </v-btn>
                  </div>

                  <v-text-field
                    prepend-icon="mdi-school"
                    label="Nama Sekolah"
                    v-model="dataPendidikanKhusus.nama_sekolah"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-school"
                    label="Tahun Masuk"
                    v-model="dataPendidikanKhusus.tahun_masuk"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-school"
                    label="Tahun Selesai"
                    v-model="dataPendidikanKhusus.tahun_selesai"
                  ></v-text-field>
                  <v-autocomplete
                    label="Status Pendidikan"
                    prepend-icon="mdi-school"
                    v-model="dataPendidikanKhusus.status_selesai"
                    :items="status_pendidikan"
                    required
                  ></v-autocomplete>
                  <v-text-field
                    prepend-icon="mdi-school"
                    label="Gelar"
                    v-model="dataPendidikanKhusus.gelar"
                  ></v-text-field>
                  <!-- <v-autocomplete
                      label="Gelar"
                      prepend-icon="mdi-school"
                      v-model="textFieldsPenididikanLanjut.gelar"
                      :items="gelar"
                      required
                    ></v-autocomplete> -->
                </div>
              </v-col>
              <v-col cols="12">
                <v-btn
                  @click="addPendidikanLanjut()"
                  class="button-signup"
                  color="red"
                  >Tambah Pendidikan Lanjut +
                </v-btn>
              </v-col>
              <v-btn
                style="color: white"
                color="green"
                type="submit"
                value="Submit"
                block
              >
                Simpan
              </v-btn>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import { deleteData, getData, postData } from "../service/ApiService";

export default {
  name: "EditDataPenduduk",
  props: ["items"],
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      show1: false,
      rules: {
        required: (value) => !!value || "Wajib di isi.",
        min: (v) => v.length >= 8 || "Password minimal 8 digit",
        min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
        return "Wajib Berupa Angka.";
      },
      snackbar: false,
      snackbar_error: false,
      goldars: [
        "A",
        "B",
        "AB",
        "O",
        "A+",
        "A-",
        "B+",
        "B-",
        "AB+",
        "AB-",
        "O+",
        "O-",
        "NA",
      ],
      agamas: [
        "islam",
        "kristen",
        "katholik",
        "budha",
        "hindu",
        "konghucu",
        "lainnya",
      ],
      roles: [
        { id: 1, nama: "Superadmin" },
        { id: 2, nama: "Admin" },
        { id: 3, nama: "RT" },
        { id: 4, nama: "Warga" },
      ],
      status_pendidikan: ["Lulus", "Tidak Lulus"],
      status_pernikahans: ["Belum Kawin", "Kawin", "Cerai Hidup", "Cerai Mati"],
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      form: {
        id_role: "",
        nama: "",
        nik: "",
        id_kk: "",
        email: "",
        status: "",
        status_pernikahan: "",
        nohp: "",
        tgl_lahir: "",
        tempatlahir: "",
        jk: "",
        suku: "",
        agama: "",
        goldarah: "",
        alamat: "",
        alamat_domisili: "",
        id_provinsi: "",
        id_kabkot: "",
        id_kelurahan: "",
        id_kecamatan: "",
        id_rt: "",
        id_rw: "",
        photo: "",
      },
      url: "",
      dataDeletePendidikanKhusus: [],
      dataDeletePendidikanUmum: [],
      dataPendidikanKhusus: [],
      dataPendidikanUmum: [],
      createDataPendidikanKhusus: [],
      createDataPendidikanUmum: [],
      token_jwt: "",
      dialog: false,
      activePicker: null,
      menu: false,
      msg: "",
      msg_error: "",
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.form.tgl_lahir
        ? moment(this.form.tgl_lahir).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    this.getDataPenduduk();

    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    if (this.snackbar) {
      this.hide_alert();
    }

    if (this.snackbar_error) {
      this.hide_alert_error();
    }
  },
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    addPendidikanWajib() {
      this.createDataPendidikanUmum.push({
        nama_sekolah: null,
        tahun_masuk: null,
        tahun_selesai: null,
        status_selesai: null,
      });
    },
    removePendidikanWajibCreate(index) {
      this.createDataPendidikanUmum.splice(index, 1);
    },
    removePendidikanWajib(index, data) {
      this.dataPendidikanUmum.splice(index, 1);
      this.dataDeletePendidikanUmum.push({ id_pendidikan: data.id_pendidikan });
    },
    removePendidikanLanjut(index, data) {
      this.dataPendidikanKhusus.splice(index, 1);
      this.dataDeletePendidikanKhusus.push({
        id_pendidikan: data.id_pendidikan,
      });
      console.log(this.dataDeletePendidikanKhusus);
    },
    addPendidikanLanjut() {
      this.createDataPendidikanKhusus.push({
        nama_sekolah: null,
        tahun_masuk: null,
        tahun_selesai: null,
        status_selesai: null,
        gelar: null,
      });
    },
    removePendidikanLanjutCreate(index) {
      this.createDataPendidikanKhusus.splice(index, 1);
    },
    getDataPenduduk() {
      const apiMasterPenduduk = "api/profile/" + this.items.nik;

      getData(apiMasterPenduduk)
        .then((response) => {
          console.log("data :", response);
          this.form = response.data[0];
          this.dataPendidikanUmum = response.pendidikan_umum;
          console.log(this.dataPendidikanUmum);
          this.dataPendidikanKhusus = response.pendidikan_khusus;
          //        let file = this.form.photo;
          // this.url = file;
          this.getListProvinsi();
          this.getListKabkot();
          this.getListKecamatan();
          this.getListKelurahan();
          // this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },
    getListKabkot() {
      const apiListKabkot = "api/kabkot?keyword=" + this.form.id_provinsi;

      getData(apiListKabkot)
        .then((response) => {
          console.log("data kabkot :", response);
          this.kabupatens = response;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },
    getListKecamatan() {
      const apiListKecamatan = "api/kec?keyword=" + this.form.id_kabkot;

      getData(apiListKecamatan)
        .then((response) => {
          console.log("data kecamatan :", response);
          this.kecamatans = response;
          console.log(this.kecamatans);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },
    getListKelurahan() {
      const apiListKelurahan = "api/kel?keyword=" + this.form.id_kecamatan;
      getData(apiListKelurahan)
        .then((response) => {
          console.log("data kelurahan :", response);
          this.kelurahans = response;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },
    hide_alert: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    hide_alert_error: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar_error = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.form.id_provinsi = value;
            this.form.id_kabkot = "";
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.form.id_kabkot = value;
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.form.id_kecamatan = value;
            this.form.id_kelurahan = "";
            this.kelurahans = "";

            this.kelurahans = response;
            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.form.id_kelurahan = value;
      }
    },
    submitPhoto() {
      console.log(this.form);
      let formDataPhoto = new FormData();
      formDataPhoto.append("photo", this.form.photo);
      console.log(formDataPhoto.values());

      const data = {
        api: "api/profile/photo/" + this.form.nik,
        body: formDataPhoto,
      };
      postData(data)
        .then((response) => {
          console.log(response);
          this.snackbar = true;
          this.msg = "Berhasil mengganti photo";
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.nik) {
              this.msg_error = error.response.data.nik[0];
              this.snackbar_error = true;
            } else if (error.response.data.email) {
              this.msg_error = error.response.data.email[0];
              this.snackbar_errror = true;
            } else {
              this.msg_error = error.response.data;
              this.snackbar_errror = true;
            }
          }
        });
    },
    submitForm() {
      if (
        this.dataDeletePendidikanUmum != "" &&
        this.dataDeletePendidikanUmum != []
      ) {
        this.dataDeletePendidikanUmum.forEach((item) => {
          const data = {
            api: "api/sekolah/" + item.id_pendidikan,
          };
          deleteData(data)
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
              console.log(error.response);
            });
        });
      }

      if (
        this.dataDeletePendidikanKhusus != "" &&
        this.dataDeletePendidikanKhusus != []
      ) {
        this.dataDeletePendidikanKhusus.forEach((item) => {
          const data = {
            api: "api/sekolah/" + item.id_pendidikan,
          };
          console.log(data);
          deleteData(data)
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
              console.log(error.response);
            });
        });
      }

      console.log(this.form);
      let formData = new FormData();
      formData.append("data_penduduk[nama]", this.form.nama);
      formData.append("data_penduduk[nik]", this.form.nik);
      formData.append("data_penduduk[id_kk]", this.form.id_kk);
      formData.append("data_penduduk[email]", this.form.email);
      formData.append("data_penduduk[password]", this.form.password);
      formData.append("data_penduduk[status]", this.form.status);
      formData.append(
        "data_penduduk[status_pernikahan]",
        this.form.status_pernikahan
      );
      formData.append("data_penduduk[pekerjaan]", this.form.pekerjaan);
      formData.append("data_penduduk[nohp]", this.form.nohp);
      formData.append("data_penduduk[tgl_lahir]", this.form.tgl_lahir);
      formData.append("data_penduduk[tempatlahir]", this.form.tempatlahir);
      formData.append("data_penduduk[jk]", this.form.jk);
      formData.append("data_penduduk[suku]", this.form.suku);
      formData.append("data_penduduk[agama]", this.form.agama);
      formData.append("data_penduduk[goldarah]", this.form.goldarah);
      formData.append("data_penduduk[alamat]", this.form.alamat);
      formData.append(
        "data_penduduk[alamat_domisili]",
        this.form.alamat_domisili
      );
      formData.append("data_penduduk[id_provinsi]", this.form.id_provinsi);
      formData.append("data_penduduk[id_kecamatan]", this.form.id_kecamatan);
      formData.append("data_penduduk[id_kelurahan]", this.form.id_kelurahan);
      formData.append("data_penduduk[id_kabkot]", this.form.id_kabkot);
      formData.append("data_penduduk[id_rt]", this.form.id_rt);
      formData.append("data_penduduk[id_rw]", this.form.id_rw);
      if (this.userData.role != "superadmin") {
        this.form.id_role = 4;
      }
      formData.append("data_penduduk[id_role]", this.form.id_role);
      // formData.append("umum", this.textFieldsPenididikanWajib);
      // formData.append("khusus", this.textFieldsPenididikanLanjut);
      this.dataPendidikanUmum.forEach((item, index) => {
        formData.append(
          "umum[" + index + "][nama_sekolah] ",
          item.nama_sekolah
        );
        formData.append(
          "umum[" + index + "][id_pendidikan] ",
          item.id_pendidikan
        );
        formData.append("umum[" + index + "][tahun_masuk] ", item.tahun_masuk);
        formData.append(
          "umum[" + index + "][tahun_selesai] ",
          item.tahun_selesai
        );
        formData.append(
          "umum[" + index + "][status_selesai] ",
          item.status_selesai
        );
      });

      this.dataPendidikanKhusus.forEach((item, index) => {
        formData.append(
          "khusus[" + index + "][nama_sekolah] ",
          item.nama_sekolah
        );
        formData.append(
          "khusus[" + index + "][id_pendidikan] ",
          item.id_pendidikan
        );
        formData.append(
          "khusus[" + index + "][tahun_masuk] ",
          item.tahun_masuk
        );
        formData.append(
          "khusus[" + index + "][tahun_selesai] ",
          item.tahun_selesai
        );
        formData.append(
          "khusus[" + index + "][status_selesai] ",
          item.status_selesai
        );
        formData.append("khusus[" + index + "][gelar] ", item.gelar);
      });

      if (this.createDataPendidikanKhusus != "") {
        this.createDataPendidikanKhusus.forEach((item, index) => {
          formData.append(
            "create_khusus[" + index + "][nama_sekolah] ",
            item.nama_sekolah
          );
          formData.append(
            "create_khusus[" + index + "][tahun_masuk] ",
            item.tahun_masuk
          );
          formData.append(
            "create_khusus[" + index + "][tahun_selesai] ",
            item.tahun_selesai
          );
          formData.append(
            "create_khusus[" + index + "][status_selesai] ",
            item.status_selesai
          );
          formData.append("create_khusus[" + index + "][gelar] ", item.gelar);
        });
      }

      if (this.createDataPendidikanUmum != "") {
        this.createDataPendidikanUmum.forEach((item, index) => {
          formData.append(
            "create_umum[" + index + "][nama_sekolah] ",
            item.nama_sekolah
          );
          formData.append(
            "create_umum[" + index + "][tahun_masuk] ",
            item.tahun_masuk
          );
          formData.append(
            "create_umum[" + index + "][tahun_selesai] ",
            item.tahun_selesai
          );
          formData.append(
            "create_umum[" + index + "][status_selesai] ",
            item.status_selesai
          );
        });
      }

      if (this.url) {
        formData.append("data_penduduk[photo]", this.form.photo);
      }

      console.log(formData.values());
      const dataEdit = {
        api: "api/profile/" + this.form.nik,
        body: formData,
      };
      postData(dataEdit)
        .then((response) => {
          console.log(response);
          this.dialog = false;
          this.msg = "Berhasil mengedit akun";
          this.snackbar = true;
          this.$router.push("/master-penduduk");
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.nik) {
              this.msg_error = error.response.data.nik[0];
              this.snackbar_error = true;
            } else if (error.response.data.email) {
              this.msg_error = error.response.data.email[0];
              this.snackbar_errror = true;
            } else {
              this.msg_error = error.response.data;
              this.snackbar_errror = true;
            }
          }
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    deletePic() {
      this.url = "";
      this.$refs.file.value = "";
    },
    onFileChange(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.form.photo = e.target.files[0];
      console.log(this.form);
      this.submitPhoto();
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>