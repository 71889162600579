<template>
  <div style="margin-bottom: 1000px">
    <v-dialog v-model="dialogPost" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5" style="color: red">Posting Pengumuman</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  color="green accent-4"
                  label="Judul"
                  v-model="judul"
                  required
                  outlined
                  prepend-icon="mdi-format-title"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-select
                  color="green accent-4"
                  :items="['pengumuman', 'aktivitas']"
                  label="Kategori"
                  v-model="kategori"
                  required
                  outlined
                  prepend-icon="mdi-shape"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12">
                <v-file-input
                  accept="image/png, image/jpeg, image/bmp"
                  v-model="files"
                  color="green accent-4"
                  counter
                  label="Foto"
                  multiple
                  placeholder="Pilih foto"
                  prepend-icon="mdi-camera"
                  outlined
                  :show-size="1000"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 2"
                      color="green accent-4"
                      dark
                      label
                      small
                    >
                      {{ text }}
                    </v-chip>

                    <span
                      v-else-if="index === 2"
                      class="text-overline grey--text text--darken-3 mx-2"
                    >
                      +{{ files.length - 2 }} Foto
                    </span>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialogPost = false">
            Tutup
          </v-btn>
          <v-btn
            color="green darken-1"
            :loading="loading"
            text
            @click="postPengumuman()"
          >
            Kirim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card height="100%" v-if="dataRT.profile">
      <v-img
        class="white--text align-end d-flex responsive-height"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        :src="dataRT.profile[0].photo"
      >
        <v-card-subtitle>
          <p style="margin-bottom: 5px; font-size: 20px">
            RT {{ userData.id_rt }}
          </p>
          <p style="color: red; font-size: 18px">
            {{ dataRT.profile[0].provinsi }},
            {{ dataRT.profile[0].kabupaten_kota }}, {{ userData.kecamatan }},
            {{ userData.kelurahan }}
          </p>
        </v-card-subtitle>
      </v-img>
    </v-card>
    <div class="">
      <v-tabs :vertical="false" grow slider-color="red" color="red">
        <v-tab> Semua </v-tab>
        <v-tab> Pengumuman </v-tab>
        <v-tab> Aktivitas </v-tab>

        <v-tab-item>
          <v-btn
            rounded
            color="red"
            dark
            class="mt-5 ml-5"
            @click="dialogPost = true"
            v-show="userData.role == 'rt'"
          >
            Post
            <v-icon right> mdi-pencil </v-icon>
          </v-btn>
          <v-card
            flat
            v-if="dataRT.posting_all.data"
            background-color="transparent"
          >
            <v-card-text>
              <v-card
                v-for="item in dataRT.posting_all.data"
                :key="item"
                outlined
                class="mb-5"
              >
                <v-list-item>
                  <v-list-item-content>
                    <div
                      class="text-overline mb-4"
                      v-if="item.data[0].kategori"
                    >
                      {{ item.data[0].kategori }}
                    </div>
                    <v-list-item-title>
                      {{ item.data[0].post }}
                    </v-list-item-title>
                <div style="padding: 0 ">
                  <img
                    width="80"
                    height="80"
                    :src="itemPhoto.lampiran"
                    alt=""
                    style="margin: 5px 5px 5px 0"
                    v-for="itemPhoto in item.photo"
                    :key="itemPhoto"
                    color="grey"
                  />
                </div>
                  <v-list-item-subtitle style="color: red">
                    {{ format_date(item.data[0].created_at) }}
                    </v-list-item-subtitle>
               
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-card
                v-for="item in dataRT.posting_pengumuman.data"
                :key="item"
                outlined
                class="mb-5"
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title >
                      {{ item.data[0].post }}
                    </v-list-item-title>
                <div style="padding: 0">
                  <img
                    width="80"
                    height="80"
                    :src="itemPhoto.lampiran"
                    alt=""
                    style="margin: 5px 5px 5px 0"
                    v-for="itemPhoto in item.photo"
                    :key="itemPhoto"
                    color="grey"
                  />
                </div>
                  <v-list-item-subtitle style="color: red">
                    {{ format_date(item.data[0].created_at) }}
                  </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-card
                v-for="item in dataRT.posting_aktivitas.data"
                :key="item"
                outlined
                class="mb-5"
              >
                <v-list-item>
                  <v-list-item-content>
                  
                    <v-list-item-title >
                      {{ item.data[0].post }}
                    </v-list-item-title>
                <div style="padding: 0">
                  <img
                    width="80"
                    height="80"
                    :src="itemPhoto.lampiran"
                    alt=""
                    style="margin: 5px 5px 5px 0"
                    v-for="itemPhoto in item.photo"
                    :key="itemPhoto"
                    color="grey"
                  />
                </div>
                  <v-list-item-subtitle style="color: red">
                    {{ format_date(item.data[0].created_at) }}
                  </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { postData, getData } from "../service/ApiService";
import moment from "moment";

// import TimeAgo from "javascript-time-ago";
// import id from "javascript-time-ago/locale/id.json";

// TimeAgo.addDefaultLocale(id);

// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
export default {
  name: "ProfileRT",
  components: {},
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data: () => ({
    dialogConfirmation: false,
    userData: "",
    files: [],
    dataRT: [],
    token_jwt: "",
    loading: false,
    judul: null,
    kategori: null,
    msg: null,
    news: "",
    totalPages: "",
    dialogProfile: false,
    dialogPost: false,
    imgs: [
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
      },
      {
        src: require("../assets/slide-1.png"),
      },
    ],
  }),
  created() {},
  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
    }
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.getDataRT();
  },
  computed: {
    computedDateFormatedMomentjs() {
      return this.userData.tgl_lahir
        ? moment(this.userData.tgl_lahir).format("DD, MMMM YYYY")
        : "";
    },
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm");
      }
    },
    // userDataat_news(value){
    //    if (value) {
    //      return value ? timeAgo.userDataat(Date.now() - 60 * 1000);
    //     }
    // },
    detailNews(data) {
      this.$router.push({
        name: "DetailNews",
        params: {
          dataNews: data,
        },
      });
    },
    getNews() {
      const ApiGetNews = "api/news" + "?page=" + this.page;

      getData(ApiGetNews)
        .then((response) => {
          console.log("data :", response);
          this.news = response.data;
          this.totalPages = response.last_page;
          console.log(this.penduduks);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getDataRT() {
      console.log("get data rt");
      let formData = new FormData();
      formData.append("prov", this.userData.id_provinsi);
      formData.append("kab", this.userData.id_kabkot);
      formData.append("kec", this.userData.id_kecamatan);
      formData.append("kel", this.userData.id_kelurahan);
      formData.append("rt", this.userData.id_rt);
      formData.append("rw", this.userData.id_rw);

      const apiListPenduduk = {
        api: "api/myrt",
        body: formData,
      };

      postData(apiListPenduduk)
        .then((response) => {
          console.log("data list data rt :", response);
          this.dataRT = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    postPengumuman() {
      console.log("get data rt");
      this.loading = true;
      let formData = new FormData();
      formData.append("uurt", this.dataRT.profile[0].uurt);
      formData.append("kategori", this.kategori);
      formData.append("post", this.judul);
      this.files.forEach((item, index) => {
        formData.append("lampiran[" + index + "]", item);
      });

      const apiPostPengumuman = {
        api: "api/mypost-create",
        body: formData,
      };

      postData(apiPostPengumuman)
        .then((response) => {
          console.log("data response :", response);
          this.dataRT = response;
          this.loading = false;
          this.dialogPost = false;
          // this.$router.go(-1);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    logout() {
      this.$session.destroy();
      this.$router.push("/");
      console.log(localStorage.token_jwt);
      localStorage.token_jwt = null;
      console.log(localStorage.token_jwt);
    },
  },
};
</script>

<style scoped>
#custom-tab-items {
  background-color: transparent !important;
}
.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-content {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>