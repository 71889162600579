<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      app
      color="#F2F1F1"
      dense
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="Berita" icon="mdi-newspaper" />

    <v-container style="height: 100%; margin-bottom: 100px">
      <v-text-field
        label="Judul Berita"
        v-model="judul"
        :rules="[rules.required]"
      >
      </v-text-field>
      <v-row dense>
        <v-col cols="12">
          <p style="color: black">by : {{ this.userData.nama }}</p>
          <v-card>
            <input
              type="file"
              ref="file"
              style="display: none"
              @change="onFileChange"
              accept="image/x-png,image/gif,image/jpeg"
            />
            <v-img
              v-if="this.url == ''"
              @click="$refs.file.click()"
              src="https://via.placeholder.com/720x400.png?text=Pilih+Gambar"
              class="white--text align-end mb-6"
              height="400px"
            >
            </v-img>
            <v-img
              v-if="this.url != ''"
              @click="$refs.file.click()"
              :src="url"
              class="white--text align-end mb-6"
              height="400px"
            >
            </v-img>
          </v-card>
          <ckeditor
            :editor="editor"
            v-model="editorData"
            :config="editorConfig"
          ></ckeditor>
        </v-col>
      </v-row>
      <div class="text-end">
        <v-btn
          color="red"
          class="button-signup mt-10"
          @click="sendData()"
          :loading="loading"
          >Kirim</v-btn
        >
      </div>
    </v-container>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil membuat voting.
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="red" dark>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          Mohon tunggu..
          <v-progress-circular
            indeterminate
            color="white"
            class="ml-6"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { postData } from "../service/ApiService";

export default {
  name: "TambahBerita",
  components: { Footer, Header },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Wajib di isi.",
      },
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
     
      },
      token_jwt: "",
      menu: false,
      msg: "",
      url: "",
      photo: "",
      judul: "",
      id_author: "",
      loading: false,
      snackbar: false,
      snackbar_error: false,
    };
  },
  computed: {},
  mounted() {},
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
  },
  methods: {

    deletePic() {
      this.url = "";
      this.$refs.file.value = "";
    },
    onFileChange(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.photo = e.target.files[0];
      console.log(this.photo);
    },
    back() {
      this.$router.back();
    },
    sendData() {
      this.loading = true;
      console.log(this.form);
      let formData = new FormData();
      formData.append("judul", this.judul);
      formData.append("konten", this.editorData);
      formData.append("id_author", this.userData.nik);
      formData.append("photo", this.photo);
      console.log(formData.values());
      const data = {
        api: "api/news",
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log(response);
          this.dialog = false;
          this.loading = false;
          this.snackbar = true;
          this.$router.push("/");
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.nik) {
              this.msg_error = error.response.data.nik[0];
              this.snackbar_error = true;
            } else if (error.response.data.email) {
              this.msg_error = error.response.data.email[0];
              this.snackbar_error = true;
            } else {
              this.msg_error = error.response.data;
              this.snackbar_error = true;
            }
          }
        });
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>