<template>
  <v-card class="mx-auto overflow-hidden" height="100%">
    <v-app-bar
      app
      color="#F2F1F1"
      dark
      dense
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-app-bar-nav-icon
        class="d-flex d-sm-none"
        color="black"
        @click="drawer = true"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="d-none d-sm-flex"
        ><a href="/" class="text-title">Fitur</a>
      </v-toolbar-title>
      <v-toolbar-title class="d-none d-sm-flex"
        ><a href="/" class="text-title">Tentang</a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <a href="/login" style="text-decoration: none">
        <Tombol text="Masuk" warna="red" class="d-none d-sm-flex" />
      </a>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      style="padding: 30px 0px 100px 0 !important"
    >
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-dots-grid</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Fitur</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tentang</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <a href="/login" style="text-decoration: none">
            <v-btn color="red" class="button-signup" block>Masuk</v-btn>
          </a>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main class="body">
      <!-- Logo Mobile -->
      <v-row
        no-gutters
        justify="center"
        style="position: absolute; width: 100%"
        class="d-flex d-sm-none"
      >
        <v-col cols="4" style="text-align: center">
          <img
            class="img-logo"
            alt="Smart RT logo"
            src="../assets/smart-rt-logo.png"
          />
        </v-col>
        <v-col cols="12" style="margin-top: 100px">
          <img
            class="img-bubble"
            alt="Bubble Patern"
            src="../assets/bubble.png"
          />
        </v-col>
      </v-row>

      <!-- Welcome Text Mobile -->
      <v-row
        no-gutters
        justify="center"
        style="margin-top: 200px; width: 100%"
        class="d-flex d-sm-none"
      >
        <v-col cols="12">
          <div class="text-content">
            <p class="text-welcome">Selamat Datang</p>
            <p class="text-desc">
              Smart RT merupakan sebuah aplikasi terpadu <br />
              yang mengsinergikan peran masyarakat,<br />
              RT dan pemerintah dalam memberikan pelayanan <br />
              publik yang efektif dan efisien.
            </p>
            <div class="button-area">
              <v-row no-gutters justify="center" class="d-flex d-sm-none">
                <v-col cols="6" style="margin-top: 5px"> Ayo mulai!! </v-col>
                <v-col cols="6">
                  <a href="/login" style="text-decoration: none">
                    <Tombol text="Masuk" warna="red" />
                  </a>
                     <!-- <a href="/login" style="text-decoration: none">
                    <Tombol text="Daftar" warna="red" />
                  </a> -->
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- BG Desktop -->
      <v-row
        no-gutters
        justify="center"
        style="position: absolute; width: 100%"
        class="d-none d-sm-flex"
      >
        <v-col cols="4">
          <img
            class="img-logo"
            alt="Smart RT logo"
            src="../assets/smart-rt-logo.png"
          />
        </v-col>
        <v-col cols="2"> </v-col>
        <v-col cols="6">
          <img
            class="img-bubble"
            alt="Bubble Patern"
            src="../assets/bubble.png"
          />
        </v-col>
      </v-row>

      <!-- Welcome Text Desktop -->
      <v-row
        no-gutters
        justify="center"
        style="margin-top: 80px; position: absolute; width: 100%"
        class="d-none d-sm-flex"
      >
        <!-- <v-col cols="4">
    </v-col>
    <v-col cols="2">
    </v-col> -->
        <v-col cols="6">
          <div class="text-content">
            <p class="text-welcome">Selamat Datang</p>
            <p class="text-desc">
              Smart RT merupakan sebuah aplikasi terpadu <br />
              yang mengsinergikan peran masyarakat,<br />
              RT dan pemerintah dalam memberikan pelayanan <br />
              publik yang efektif dan efisien.
            </p>
            <div class="button-area">
              <v-row no-gutters justify="center" class="d-none d-sm-flex">
                <v-col cols="6" class="text-desc-button"> Ayo mulai!! </v-col>
                <v-col cols="6">
                  <a href="/login" style="text-decoration: none">
                    <Tombol text="Masuk" warna="red" />
                  </a>
             
                  <!-- <TambahDataPendudukVue text="Daftar"/> -->
            
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-main>
  </v-card>
</template>


<script>
import Tombol from "@/components/Tombol.vue";
// import TambahDataPendudukVue from '../components/TambahDataPenduduk.vue';

export default {
  name: "Landing",
  beforeCreate: function () {
    if (this.$session.exists()) {
      // this.$router.push("/dashboard");
      this.$router.push("/home/beranda");
    }
  },
  data: () => ({
    drawer: false,
    group: null,
  }),
  components: {
    Tombol,
    // TambahDataPendudukVue
  },
    methods: {
    daftar(){
      this.$router.push('/daftar')
    }
  },
};
</script>

<style scoped>
.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
}

@media only screen and (min-width: 599px) {
  .body {
    height: 100%;
    width: 100%;
    background-image: url("../assets/bg-slash.png");
    background-repeat: no-repeat;
    background-attachment: absolute;
    background-size: cover;
    background-position: center;
  }

  .img-logo {
    height: auto;
    width: 100%;
    margin: 60px 0 0 40px;
  }

  .text-welcome {
    font-family: "Oswald", sans-serif;
    font-size: 6vw;
    text-shadow: 1px 1px 3px #000000;
  }

  .text-desc {
    font-size: 1.5vw;
    color: black;
  }
}

.img-bubble {
  height: auto;
  width: 100%;
}

@media only screen and (max-width: 599px) {
  .body {
    height: 100%;
    width: 100%;
    background-image: url("../assets/bg-slash-mb.png");
    background-repeat: no-repeat;
    background-attachment: absolute;
    background-size: cover;
    background-position: center;
  }

  .img-logo {
    height: auto;
    width: 100%;
    margin: auto;
  }

  .text-welcome {
    font-family: "Oswald", sans-serif;
    font-size: 10vw;
    text-shadow: 1px 1px 3px #000000;
  }

  .text-desc {
    font-size: 3vw;
    color: black;
  }
}

.text-content {
  color: white;
  text-align: center;
  position: absolute;
  width: 100%;
  /* position: absolute;
  z-index: 1000; */
}

.button-area {
  padding: 50px 0 0 0;
  margin: auto;
  text-align: center;
  width: 250px;
}

* {
  font-family: "Alata", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Alata&family=Oswald&display=swap");
</style>