<template>
  <v-card class="overflow-hidden">
    <v-app-bar app color="#F2F1F1" dense>
      <v-btn color="black" icon @click="$router.push('/')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="E-Vote" icon="mdi-vote" />
    <v-snackbar
      v-model="snackbar.visible"
      multi-line
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      centered
      auto-height
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>
            <strong> {{ snackbar.status }}</strong>
          </div>
          <div>{{ msg }}</div>
        </v-layout>
      </v-layout>
      <v-btn
        v-if="snackbar.timeout === 0"
        icon
        @click="snackbar.visible = false"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-snackbar>
    <v-tabs
    v-if="userData.role == 'superadmin'"
      class="tabs"
      centered
      grow
      v-model="activeTab"
      slider-color="red"
      color="red"
    >
      <v-tab> E-Vote </v-tab>
      <v-tab> E-Vote Saya </v-tab>

      <v-tab-item>
        <v-container style="height: 100%; margin-bottom: 100px">
          <div v-if="!votingData">
            <v-skeleton-loader
              class="mx-auto mb-10"
              max-width="100%"
              type="card"
            ></v-skeleton-loader>

            <v-skeleton-loader
              class="mx-auto mb-10"
              max-width="100%"
              type="card"
            ></v-skeleton-loader>

            <v-slide-group class="mb-10 mt-10" v-model="model" show-arrows>
              <v-slide-item v-for="n in 15" :key="n">
                <v-skeleton-loader
                  class="mx-auto mr-10"
                  max-width="150px"
                  type="card"
                  style="width: 150px"
                ></v-skeleton-loader>
              </v-slide-item>
            </v-slide-group>
          </div>

          <v-row>
            <v-col cols="6">
              <p style="color: #c82424; font-size: 20px">Sedang Berjalan</p>
            </v-col>
          </v-row>
          <div v-if="votingData.data_active">
            <v-expansion-panels v-show="userData.role == 'superadmin'">
              <v-expansion-panel>
                <v-expansion-panel-header> Filter </v-expansion-panel-header>
                <v-expansion-panel-content class="text-center">
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    label="Provinsi"
                    v-model="id_provinsi"
                    :items="provinsis"
                    item-text="provinsi"
                    return-object
                    required
                    @change="(event) => selectedProvinsi(event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kabkot"
                    :items="kabupatens"
                    label="Kabupaten/ Kota"
                    return-object
                    item-text="kabupaten_kota"
                    :disabled="kabupatens == ''"
                    required
                    @change="(event) => selectedKabupaten(event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kecamatan"
                    :items="kecamatans"
                    label="Kecamatan"
                    item-text="kecamatan"
                    return-object
                    :disabled="kecamatans == ''"
                    required
                    @change="(event) => selectedKecamatan(event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kelurahan"
                    :items="kelurahans"
                    label="Kelurahan"
                    return-object
                    :disabled="kelurahans == ''"
                    item-text="kelurahan"
                    required
                    @change="(event) => selectedKelurahan(event)"
                  ></v-autocomplete>
                  <v-btn
                    color="red"
                    class="button-signup"
                    block
                    v-on:click="getDataVotingFilter()"
                    >Submit</v-btn
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Vote.."
              single-line
              filled
              outlined
              hide-details
              @input="SearchbarChange"
              style="width: 100%"
            >
            </v-text-field>
            <span
              v-if="id_provinsi != ''"
              style="color: black; margin: 0; font-size: 16px"
            >
              Filter :
            </span>
            <span>
              <v-chip
                v-if="id_provinsi != ''"
                class="ma-2"
                close
                @click:close="
                  id_provinsi = '';
                  id_kabkot = '';
                  id_kelurahan = '';
                  id_kecamatan = '';
                  kabupatens = '';
                  kelurahans = '';
                  kecamatans = '';
                "
              >
                {{ id_provinsi }}
              </v-chip>
              <v-chip
                v-if="id_kabkot != ''"
                class="ma-2"
                close
                @click:close="
                  id_kabkot = '';
                  id_kelurahan = '';
                  id_kecamatan = '';
                  kelurahans = '';
                  kecamatans = '';
                "
              >
                {{ id_kabkot }}
              </v-chip>
              <v-chip
                v-if="id_kecamatan != ''"
                class="ma-2"
                close
                @click:close="
                  id_kecamatan = '';
                  id_kelurahan = '';
                  kelurahans = '';
                "
              >
                {{ id_kecamatan }}
              </v-chip>
              <v-chip
                v-if="id_kelurahan != ''"
                class="ma-2"
                close
                @click:close="id_kelurahan = ''"
              >
                {{ id_kelurahan }}
              </v-chip>
            </span>
            <div v-if="votingData.data_active.length == 0">
              <p class="text-center ma-10">
                Tidak ada voting yang sedang berjalan.
              </p>
            </div>
            <div v-if="votingData.data_active.length != 0">
              <v-card
                color="#F2F2F2"
                class="mb-10 mt-5"
                v-for="(item, index) in votingData.data_active"
                :key="index"
              >
                <v-img
                  :src="item.photo"
                  class="white--text align-end d-sm-none d-flex"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="200px"
                >
                  <v-card-title>
                    <p>{{ item.judul }}</p>
                  </v-card-title>
                </v-img>

                <div class="d-sm-none d-flex">
                  <v-card-subtitle>
                    <!-- <v-chip
                class="mb-2"
                color="green"
                text-color="white"
                v-if="item.status_pilih != null"
              >
                Sudah Voting
              </v-chip>
              <v-chip
                class="mb-2"
                color="red"
                text-color="white"
                v-if="item.status_pilih == null"
              >
                Belum Voting
              </v-chip> -->
                    <div class="truncate">
                      <p>
                        {{ item.deskripsi }}
                      </p>
                    </div>

                    <p class="text-sub">
                      {{ item.provinsi }}/{{ item.kabupaten_kota }}/{{
                        item.kecamatan
                      }}/{{ item.kelurahan }}/RW {{ item.id_rw }}/RT
                      {{ item.id_rt }}
                    </p>
                    <p class="text-sub">
                      Dibuka : {{ format_date(item.tanggal_mulai) }}
                      <br />
                      Ditutup : {{ format_date(item.tanggal_selesai) }}
                    </p>
                  </v-card-subtitle>
                </div>

                <v-card-actions class="d-sm-none d-flex">
                  <!-- <VotePasswordForm
                :dataVote="item"
                v-if="item.status == 'khusus'"
              /> -->
                  <v-btn
                    color="#F54C4C"
                    class="button-signup"
                    type="submit"
                    @click="getDataVotingDetail(item)"
                    >Vote</v-btn
                  >
                  <!-- <v-btn
                color="#F54C4C"
                class="button-signup ml-5"
                type="submit"
                @click="hasilVoting(item)"
                >Hasil</v-btn
              > -->
                </v-card-actions>

                <div class="d-none d-sm-flex">
                  <v-avatar tile height="100%" width="200px">
                    <v-img
                      :src="item.photo"
                      class="white--text align-end d-none d-sm-flex"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="270px"
                    >
                    </v-img>
                  </v-avatar>
                  <v-card-subtitle>
                    <p class="d-none d-sm-flex" v-text="item.judul"></p>

                    <div class="truncate">
                      <p>
                        {{ item.deskripsi }}
                      </p>
                    </div>

                    <p class="text-sub mt-5">
                      {{ item.provinsi }}/{{ item.kabupaten_kota }}/{{
                        item.kecamatan
                      }}/{{ item.kelurahan }}/RW {{ item.id_rw }}/RT
                      {{ item.id_rt }}
                    </p>
                    <p class="text-sub">
                      Dibuka : {{ format_date(item.tanggal_mulai) }}
                      <br />
                      Ditutup : {{ format_date(item.tanggal_selesai) }}
                    </p>
                  </v-card-subtitle>
                  <v-card-actions class="ml-15">
                    <!-- <VotePasswordForm
                  :dataVote="item"
                  v-if="item.status == 'khusus'"
                /> -->
                    <v-btn
                      color="#F54C4C"
                      class="button-signup"
                      type="submit"
                      @click="getDataVotingDetail(item)"
                      >Vote</v-btn
                    >

                    <!-- <v-btn
                  color="#F54C4C"
                  class="button-signup ml-5"
                  type="submit"
                  @click="hasilVoting(item)"
                  >Hasil</v-btn
                > -->
                  </v-card-actions>
                </div>
              </v-card>
            </div>
            <div class="text-center">
              <v-pagination
                :loading="loading"
                v-model="page"
                :length="totalPages"
                total-visible="7"
                next-icon="mdi-menu-right"
                prev-icon="mdi-menu-left"
                @input="handlePageChange"
              ></v-pagination>
            </div>

            <div class="d-flex flex-no-wrap justify-space-between mt-15">
              <p style="color: #c82424; font-size: 20px;cursor:pointer;" @click="evotingFinish()">E-Voting Selesai</p>
              <p style="font-size: 20px;cursor:pointer;" @click="evotingFinish()">
                Selengkapnya
                <v-icon style="font-size: 20px">mdi-chevron-right</v-icon>
              </p>
            </div>

            <v-slide-group class="mb-10" v-model="model" show-arrows>
              <v-slide-item v-for="n in votingData.data_finish.data" :key="n">
                <v-card
                  img=""
                  class="ma-4"
                  height="200"
                  width="150"
                  @click="hasilVoting(n)"
                >
                  <v-img
                    :src="n.photo"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="200px"
                  >
                    <v-card-title>
                      <h6>
                        {{ n.judul }}
                      </h6>
                    </v-card-title>
                  </v-img>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </div>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container style="height: 100%; margin-bottom: 100px">
          <div v-if="!votingData">
            <v-skeleton-loader
              class="mx-auto mb-10"
              max-width="100%"
              type="card"
            ></v-skeleton-loader>

            <v-skeleton-loader
              class="mx-auto mb-10"
              max-width="100%"
              type="card"
            ></v-skeleton-loader>

            <v-slide-group class="mb-10 mt-10" v-model="model" show-arrows>
              <v-slide-item v-for="n in 15" :key="n">
                <v-skeleton-loader
                  class="mx-auto mr-10"
                  max-width="150px"
                  type="card"
                  style="width: 150px"
                ></v-skeleton-loader>
              </v-slide-item>
            </v-slide-group>
          </div>

          <v-row>
            <v-col cols="6">
              <p style="color: #c82424; font-size: 20px">Sedang Berjalan</p>
            </v-col>
            <v-col cols="6" class="flex justify-end text-right mb-2">
              <v-btn
                color="#F54C4C"
                class="button-signup ml-5"
                type="submit"
                @click="tambahVote()"
                >Buat Voting</v-btn
              >
            </v-col>
          </v-row>
          <div v-if="votingData.data_created.data">
            <v-expansion-panels v-show="userData.role == 'superadmin'">
              <v-expansion-panel>
                <v-expansion-panel-header> Filter </v-expansion-panel-header>
                <v-expansion-panel-content class="text-center">
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    label="Provinsi"
                    v-model="id_provinsi"
                    :items="provinsis"
                    item-text="provinsi"
                    return-object
                    required
                    @change="(event) => selectedProvinsi(event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kabkot"
                    :items="kabupatens"
                    label="Kabupaten/ Kota"
                    return-object
                    item-text="kabupaten_kota"
                    :disabled="kabupatens == ''"
                    required
                    @change="(event) => selectedKabupaten(event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kecamatan"
                    :items="kecamatans"
                    label="Kecamatan"
                    item-text="kecamatan"
                    return-object
                    :disabled="kecamatans == ''"
                    required
                    @change="(event) => selectedKecamatan(event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kelurahan"
                    :items="kelurahans"
                    label="Kelurahan"
                    return-object
                    :disabled="kelurahans == ''"
                    item-text="kelurahan"
                    required
                    @change="(event) => selectedKelurahan(event)"
                  ></v-autocomplete>
                  <v-btn
                    color="red"
                    class="button-signup"
                    block
                    v-on:click="getDataVotingFilter()"
                    >Submit</v-btn
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-text-field
              v-model="searchCreated"
              append-icon="mdi-magnify"
              label="Cari Vote.."
              single-line
              filled
              outlined
              hide-details
              @input="SearchbarChangeCreated"
              style="width: 100%"
            >
            </v-text-field>
            <span
              v-if="id_provinsi != ''"
              style="color: black; margin: 0; font-size: 16px"
            >
              Filter :
            </span>
            <span>
              <v-chip
                v-if="id_provinsi != ''"
                class="ma-2"
                close
                @click:close="
                  id_provinsi = '';
                  id_kabkot = '';
                  id_kelurahan = '';
                  id_kecamatan = '';
                  kabupatens = '';
                  kelurahans = '';
                  kecamatans = '';
                "
              >
                {{ id_provinsi }}
              </v-chip>
              <v-chip
                v-if="id_kabkot != ''"
                class="ma-2"
                close
                @click:close="
                  id_kabkot = '';
                  id_kelurahan = '';
                  id_kecamatan = '';
                  kelurahans = '';
                  kecamatans = '';
                "
              >
                {{ id_kabkot }}
              </v-chip>
              <v-chip
                v-if="id_kecamatan != ''"
                class="ma-2"
                close
                @click:close="
                  id_kecamatan = '';
                  id_kelurahan = '';
                  kelurahans = '';
                "
              >
                {{ id_kecamatan }}
              </v-chip>
              <v-chip
                v-if="id_kelurahan != ''"
                class="ma-2"
                close
                @click:close="id_kelurahan = ''"
              >
                {{ id_kelurahan }}
              </v-chip>
            </span>
            <div v-if="votingData.data_created.length == 0">
              <p class="text-center ma-10">
                Tidak ada voting yang sedang berjalan.
              </p>
            </div>
            <div v-if="votingData.data_created.length != 0">
              <v-card
                color="#F2F2F2"
                class="mb-10 mt-5"
                v-for="(item, index) in votingData.data_created.data"
                :key="index"
              >
                <v-img
                  :src="item.photo"
                  class="white--text align-end d-sm-none d-flex"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="200px"
                >
                  <v-card-title>
                    <p>{{ item.judul }}</p>
                  </v-card-title>
                </v-img>

                <div class="d-sm-none d-flex">
                  <v-card-subtitle>
                    <div class="truncate">
                      <p>
                        {{ item.deskripsi }}
                      </p>
                    </div>

                    <p class="text-sub">
                      {{ item.provinsi }}/{{ item.kabupaten_kota }}/{{
                        item.kecamatan
                      }}/{{ item.kelurahan }}/RW {{ item.id_rw }}/RT
                      {{ item.id_rt }}
                    </p>
                    <p class="text-sub">
                      Dibuka : {{ format_date(item.tanggal_mulai) }}
                      <br />
                      Ditutup : {{ format_date(item.tanggal_selesai) }}
                    </p>
                  </v-card-subtitle>
                </div>

                <v-card-actions class="d-sm-none d-flex">
                  <!-- <v-btn
                    color="#F54C4C"
                    class="button-signup"
                    type="submit"
                    @click="getDataVotingDetail(item)"
                    >Vote</v-btn
                  > -->
                  <v-btn
                    color="#F54C4C"
                    class="button-signup ml-5"
                    type="submit"
                    @click="hasilVoting(item)"
                    >Hasil</v-btn
                  >
                </v-card-actions>

                <div class="d-none d-sm-flex">
                  <v-avatar tile height="100%" width="200px">
                    <v-img
                      :src="item.photo"
                      class="white--text align-end d-none d-sm-flex"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="270px"
                    >
                    </v-img>
                  </v-avatar>
                  <v-card-subtitle>
                    <p class="d-none d-sm-flex" v-text="item.judul"></p>

                    <div class="truncate">
                      <p>
                        {{ item.deskripsi }}
                      </p>
                    </div>

                    <p class="text-sub mt-5">
                      {{ item.provinsi }}/{{ item.kabupaten_kota }}/{{
                        item.kecamatan
                      }}/{{ item.kelurahan }}/RW {{ item.id_rw }}/RT
                      {{ item.id_rt }}
                    </p>
                    <p class="text-sub">
                      Dibuka : {{ format_date(item.tanggal_mulai) }}
                      <br />
                      Ditutup : {{ format_date(item.tanggal_selesai) }}
                    </p>
                  </v-card-subtitle>
                  <v-card-actions class="ml-15">
                    <!-- <v-btn
                      color="#F54C4C"
                      class="button-signup"
                      type="submit"
                      @click="getDataVotingDetail(item)"
                      >Vote</v-btn
                    > -->

                    <v-btn
                      color="#F54C4C"
                      class="button-signup ml-5"
                      type="submit"
                      @click="hasilVoting(item)"
                      >Hasil</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </div>
            <div class="text-center">
              <v-pagination
                :loading="loading"
                v-model="pageCreated"
                :length="totalPagesCreated"
                total-visible="7"
                next-icon="mdi-menu-right"
                prev-icon="mdi-menu-left"
                @input="handlePageCreatedChange"
              ></v-pagination>
            </div>

            <div class="d-flex flex-no-wrap justify-space-between mt-15">
              <p style="color: #c82424; font-size: 20px;cursor:pointer;" @click="evotingFinish()">E-Voting Selesai</p>
              <p style="font-size: 20px;cursor:pointer;" @click="evotingFinish()">
                Selengkapnya
                <v-icon style="font-size: 20px">mdi-chevron-right</v-icon>
              </p>
            </div>

            <v-slide-group class="mb-10" v-model="model" show-arrows>
              <v-slide-item v-for="n in votingData.data_finish.data" :key="n">
                <v-card
                  img=""
                  class="ma-4"
                  height="200"
                  width="150"
                  @click="hasilVoting(n)"
                >
                  <v-img
                    :src="n.photo"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="200px"
                  >
                    <v-card-title>
                      <h6>
                        {{ n.judul }}
                      </h6>
                    </v-card-title>
                  </v-img>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </div>
        </v-container>
      </v-tab-item>
    </v-tabs>
           <v-container v-else style="height: 100%; margin-bottom: 100px">
          <div v-if="!votingData">
            <v-skeleton-loader
              class="mx-auto mb-10"
              max-width="100%"
              type="card"
            ></v-skeleton-loader>

            <v-skeleton-loader
              class="mx-auto mb-10"
              max-width="100%"
              type="card"
            ></v-skeleton-loader>

            <v-slide-group class="mb-10 mt-10" v-model="model" show-arrows>
              <v-slide-item v-for="n in 15" :key="n">
                <v-skeleton-loader
                  class="mx-auto mr-10"
                  max-width="150px"
                  type="card"
                  style="width: 150px"
                ></v-skeleton-loader>
              </v-slide-item>
            </v-slide-group>
          </div>

          <v-row>
            <v-col cols="6">
              <p style="color: #c82424; font-size: 20px">Sedang Berjalan</p>
            </v-col>
          </v-row>
          <div v-if="votingData.data_active">
            <v-expansion-panels v-show="userData.role == 'superadmin'">
              <v-expansion-panel>
                <v-expansion-panel-header> Filter </v-expansion-panel-header>
                <v-expansion-panel-content class="text-center">
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    label="Provinsi"
                    v-model="id_provinsi"
                    :items="provinsis"
                    item-text="provinsi"
                    return-object
                    required
                    @change="(event) => selectedProvinsi(event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kabkot"
                    :items="kabupatens"
                    label="Kabupaten/ Kota"
                    return-object
                    item-text="kabupaten_kota"
                    :disabled="kabupatens == ''"
                    required
                    @change="(event) => selectedKabupaten(event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kecamatan"
                    :items="kecamatans"
                    label="Kecamatan"
                    item-text="kecamatan"
                    return-object
                    :disabled="kecamatans == ''"
                    required
                    @change="(event) => selectedKecamatan(event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kelurahan"
                    :items="kelurahans"
                    label="Kelurahan"
                    return-object
                    :disabled="kelurahans == ''"
                    item-text="kelurahan"
                    required
                    @change="(event) => selectedKelurahan(event)"
                  ></v-autocomplete>
                  <v-btn
                    color="red"
                    class="button-signup"
                    block
                    v-on:click="getDataVotingFilter()"
                    >Submit</v-btn
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Vote.."
              single-line
              filled
              outlined
              hide-details
              @input="SearchbarChange"
              style="width: 100%"
            >
            </v-text-field>
            <span
              v-if="id_provinsi != ''"
              style="color: black; margin: 0; font-size: 16px"
            >
              Filter :
            </span>
            <span>
              <v-chip
                v-if="id_provinsi != ''"
                class="ma-2"
                close
                @click:close="
                  id_provinsi = '';
                  id_kabkot = '';
                  id_kelurahan = '';
                  id_kecamatan = '';
                  kabupatens = '';
                  kelurahans = '';
                  kecamatans = '';
                "
              >
                {{ id_provinsi }}
              </v-chip>
              <v-chip
                v-if="id_kabkot != ''"
                class="ma-2"
                close
                @click:close="
                  id_kabkot = '';
                  id_kelurahan = '';
                  id_kecamatan = '';
                  kelurahans = '';
                  kecamatans = '';
                "
              >
                {{ id_kabkot }}
              </v-chip>
              <v-chip
                v-if="id_kecamatan != ''"
                class="ma-2"
                close
                @click:close="
                  id_kecamatan = '';
                  id_kelurahan = '';
                  kelurahans = '';
                "
              >
                {{ id_kecamatan }}
              </v-chip>
              <v-chip
                v-if="id_kelurahan != ''"
                class="ma-2"
                close
                @click:close="id_kelurahan = ''"
              >
                {{ id_kelurahan }}
              </v-chip>
            </span>
            <div v-if="votingData.data_active.length == 0">
              <p class="text-center ma-10">
                Tidak ada voting yang sedang berjalan.
              </p>
            </div>
            <div v-if="votingData.data_active.length != 0">
              <v-card
                color="#F2F2F2"
                class="mb-10 mt-5"
                v-for="(item, index) in votingData.data_active"
                :key="index"
              >
                <v-img
                  :src="item.photo"
                  class="white--text align-end d-sm-none d-flex"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="200px"
                >
                  <v-card-title>
                    <p>{{ item.judul }}</p>
                  </v-card-title>
                </v-img>

                <div class="d-sm-none d-flex">
                  <v-card-subtitle>
                    <!-- <v-chip
                class="mb-2"
                color="green"
                text-color="white"
                v-if="item.status_pilih != null"
              >
                Sudah Voting
              </v-chip>
              <v-chip
                class="mb-2"
                color="red"
                text-color="white"
                v-if="item.status_pilih == null"
              >
                Belum Voting
              </v-chip> -->
                    <div class="truncate">
                      <p>
                        {{ item.deskripsi }}
                      </p>
                    </div>

                    <p class="text-sub">
                      {{ item.provinsi }}/{{ item.kabupaten_kota }}/{{
                        item.kecamatan
                      }}/{{ item.kelurahan }}/RW {{ item.id_rw }}/RT
                      {{ item.id_rt }}
                    </p>
                    <p class="text-sub">
                      Dibuka : {{ format_date(item.tanggal_mulai) }}
                      <br />
                      Ditutup : {{ format_date(item.tanggal_selesai) }}
                    </p>
                  </v-card-subtitle>
                </div>

                <v-card-actions class="d-sm-none d-flex">
                  <!-- <VotePasswordForm
                :dataVote="item"
                v-if="item.status == 'khusus'"
              /> -->
                  <v-btn
                    color="#F54C4C"
                    class="button-signup"
                    type="submit"
                    @click="getDataVotingDetail(item)"
                    >Vote</v-btn
                  >
                  <!-- <v-btn
                color="#F54C4C"
                class="button-signup ml-5"
                type="submit"
                @click="hasilVoting(item)"
                >Hasil</v-btn
              > -->
                </v-card-actions>

                <div class="d-none d-sm-flex">
                  <v-avatar tile height="100%" width="200px">
                    <v-img
                      :src="item.photo"
                      class="white--text align-end d-none d-sm-flex"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="270px"
                    >
                    </v-img>
                  </v-avatar>
                  <v-card-subtitle>
                    <p class="d-none d-sm-flex" v-text="item.judul"></p>

                    <div class="truncate">
                      <p>
                        {{ item.deskripsi }}
                      </p>
                    </div>

                    <p class="text-sub mt-5">
                      {{ item.provinsi }}/{{ item.kabupaten_kota }}/{{
                        item.kecamatan
                      }}/{{ item.kelurahan }}/RW {{ item.id_rw }}/RT
                      {{ item.id_rt }}
                    </p>
                    <p class="text-sub">
                      Dibuka : {{ format_date(item.tanggal_mulai) }}
                      <br />
                      Ditutup : {{ format_date(item.tanggal_selesai) }}
                    </p>
                  </v-card-subtitle>
                  <v-card-actions class="ml-15">
                    <!-- <VotePasswordForm
                  :dataVote="item"
                  v-if="item.status == 'khusus'"
                /> -->
                    <v-btn
                      color="#F54C4C"
                      class="button-signup"
                      type="submit"
                      @click="getDataVotingDetail(item)"
                      >Vote</v-btn
                    >

                    <!-- <v-btn
                  color="#F54C4C"
                  class="button-signup ml-5"
                  type="submit"
                  @click="hasilVoting(item)"
                  >Hasil</v-btn
                > -->
                  </v-card-actions>
                </div>
              </v-card>
            </div>
            <div class="text-center">
              <v-pagination
                :loading="loading"
                v-model="page"
                :length="totalPages"
                total-visible="7"
                next-icon="mdi-menu-right"
                prev-icon="mdi-menu-left"
                @input="handlePageChange"
              ></v-pagination>
            </div>

            <div class="d-flex flex-no-wrap justify-space-between mt-15">
              <p style="color: #c82424; font-size: 20px;cursor:pointer;" @click="evotingFinish()">E-Voting Selesai</p>
              <p style="font-size: 20px;cursor:pointer;" @click="evotingFinish()">
                Selengkapnya
                <v-icon style="font-size: 20px">mdi-chevron-right</v-icon>
              </p>
            </div>

            <v-slide-group class="mb-10" v-model="model" show-arrows>
              <v-slide-item v-for="n in votingData.data_finish.data" :key="n">
                <v-card
                  img=""
                  class="ma-4"
                  height="200"
                  width="150"
                  @click="hasilVoting(n)"
                >
                  <v-img
                    :src="n.photo"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="200px"
                  >
                    <v-card-title>
                      <h6>
                        {{ n.judul }}
                      </h6>
                    </v-card-title>
                  </v-img>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </div>
        </v-container>

    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
// import VotePasswordForm from "../components/VotePasswordForm.vue";
import { getData, postData } from "../service/ApiService";
export default {
  name: "EVoting",
  components: {
    Footer,
    Header,
    // VotePasswordForm,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      snackbar: {
        timeout: 3000,
        visible: false,
        color: "",
        icon: "",
        status: "",
      },
      model: null,
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      id_provinsi: "",
      id_kabkot: "",
      id_kelurahan: "",
      id_kecamatan: "",
      search: "",
      searchCreated: "",
      token_jwt: "",
      menu: false,
      msg: "",
      loading: false,
      page: 1,
      pageCreated: 1,
      votingData: [],
      startTime: "July 7, 2017 12:03:00",
      endTime: "July 9, 2022 14:55:00",
      userData: "",
      times: [
        { id: 0, text: "Days", time: 1 },
        { id: 1, text: "Hours", time: 1 },
        { id: 2, text: "Minutes", time: 1 },
        { id: 3, text: "Seconds", time: 1 },
      ],
      progress: 100,
      // isActive: false,
      timeinterval: undefined,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataNews.created_at
        ? moment(this.dataNews.created_at).format("dddd, d MMM YYYY HH:m A")
        : "";
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
    },
  },
  mounted() {},
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.getDataVoting();
    this.getListProvinsi();
  },
  methods: {
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    evotingFinish() {
      this.$router.push({
        name: "EVotingFinish",
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.getDataVotingFilter();
    },
    handlePageCreatedChange(value) {
      this.pageCreated = value;
      this.getDataVotingCreatedFilter();
    },
    getDataVotingFilter() {
      if (this.search != "") {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&kel=" +
            this.id_kelurahan +
            "&keyword=" +
            this.search;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&keyword=" +
            this.search;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&keyword=" +
            this.search;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&keyword=" +
            this.search;
        } else {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.search;
        }
      } else {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/evoting" +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&kel=" +
            this.id_kelurahan;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi;
        } else {
          this.apiGetFilter =
            "api/evoting/" + this.userData.nik + "?page=" + this.page;
        }
      }

      getData(this.apiGetFilter)
        .then((response) => {
          console.log("data :", response);
          this.votingData = response;
          if (response.data_active.last_page) {
            this.totalPages = response.data_active.last_page;
          } else {
            this.totalPages = 1;
          }
          this.totalPagesCreated = response.data_created.last_page;
          console.log(this.penduduks);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getDataVotingCreatedFilter() {
      if (this.searchCreated != "") {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.pageCreated +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&kel=" +
            this.id_kelurahan +
            "&keyword_finish=" +
            this.searchCreated;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.pageCreated +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&keyword_finish=" +
            this.searchCreated;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.pageCreated +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&keyword_finish=" +
            this.searchCreated;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.pageCreated +
            "&prov=" +
            this.id_provinsi +
            "&keyword_finish=" +
            this.searchCreated;
        } else {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.pageCreated +
            "&keyword_finish=" +
            this.searchCreated;
        }
      } else {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/evoting" +
            "?page=" +
            this.pageCreated +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&kel=" +
            this.id_kelurahan;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.pageCreated +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.pageCreated +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/evoting/" +
            this.userData.nik +
            "?page=" +
            this.pageCreated +
            "&prov=" +
            this.id_provinsi;
        } else {
          this.apiGetFilter =
            "api/evoting/" + this.userData.nik + "?page=" + this.pageCreated;
        }
      }

      getData(this.apiGetFilter)
        .then((response) => {
          console.log("data :", response);
          this.votingData = response;
          if (response.data_active.last_page) {
            this.totalPages = response.data_active.last_page;
          } else {
            this.totalPages = 1;
          }
          this.totalPagesCreated = response.data_created.last_page;
          console.log(this.penduduks);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value.id;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.id_provinsi = value.provinsi;
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kabkot = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value.id;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.id_kabkot = value.kabupaten_kota;
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value.id;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.id_kecamatan = value.kecamatan;
            this.kelurahans = "";
            this.kelurahans = response;
            this.id_kelurahan = "";

            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.id_kelurahan = value.kelurahan;
      }
    },
    SearchbarChange(value) {
      this.search = value;
      this.getDataVotingFilter();
      console.log(value);
    },
    SearchbarChangeCreated(value) {
      this.searchCreated = value;
      this.getDataVotingCreatedFilter();
      console.log(value);
    },
    back() {
      this.$router.back();
    },
    getDataVotingDetail(value) {
      console.log(this.items);

      let formData = new FormData();
      formData.append("nik", this.userData.nik);
      const data = {
        api: "api/e-voting/" + value.id_evoting,
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log("data :", response);
          this.loading = false;
          if (response.result == "fail") {
            if (response.data[0].status_pilih) {
              this.msg = response.data[0].status_pilih;
              this.snackbar.color = "red accent-2";
              this.snackbar.icon = "mdi-alert-octagon-outline";
              this.snackbar.status = "Gagal";
              this.snackbar.visible = true;
              console.log(response.data);
            }
          } else {
            if (response.data_evoting) {
              this.$router.push({
                name: "DetailVoting",
                params: {
                  votingData: response,
                },
              });
            }
          }
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    getDataVoting() {
      const ApiGetVoting =
        "api/evoting/" + this.userData.nik + "?page=" + this.page;

      getData(ApiGetVoting)
        .then((response) => {
          console.log("data :", response);
          this.votingData = response;
          if (response.data_active.last_page) {
            this.totalPages = response.data_active.last_page;
          } else {
            this.totalPages = 1;
          }
          this.totalPagesCreated = response.data_created.last_page;

          console.log(this.votingData);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    hasilVoting(item) {
      this.$router.push({
        name: "HasilVoting",
        params: {
          items: item.id_evoting,
        },
      });
    },
    tambahVote() {
      this.$router.push({
        name: "TambahVote",
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY HH:SS");
      }
    },
  },
};
</script>

<style scoped>
.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}
.text-sub {
  color: #c82424;
}
.truncate {
  line-height: 1.5em;
  height: 3em; /* height is 2x line-height, so two lines will display */
  overflow: hidden; /* prevents extra lines from being visible */
}
</style>