var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"overflow-hidden"},[_c('v-app-bar',{attrs:{"app":"","color":"#F2F1F1","dense":""}},[_c('v-btn',{attrs:{"color":"black","icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1),_c('Header',{attrs:{"text":"E-Lapor","icon":"mdi-alert-octagon-outline"}}),_c('v-snackbar',{attrs:{"multi-line":"true","color":_vm.snackbar.color,"timeout":_vm.snackbar.timeout,"centered":"","auto-height":""},model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_c('v-layout',{attrs:{"align-center":"","pr-4":""}},[_c('v-icon',{staticClass:"pr-3",attrs:{"dark":"","large":""}},[_vm._v(_vm._s(_vm.snackbar.icon))]),_c('v-layout',{attrs:{"column":""}},[_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.snackbar.status))])]),_c('div',[_vm._v(_vm._s(_vm.msg))])])],1),(_vm.snackbar.timeout === 0)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.snackbar.visible = false}}},[_c('v-icon',[_vm._v("clear")])],1):_vm._e()],1),_c('v-container',{staticStyle:{"height":"100%","margin-bottom":"100px"}},[_c('v-row',[_c('v-col',{staticClass:"flex mb-2",attrs:{"cols":"6"}},[_c('p',{staticClass:"text-title"},[_vm._v(" E-Lapor "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v("MyLapor merupakan aplikasi lapor segala "),_c('br'),_vm._v(" keperluan pembangunan maupun lingkungan disekitarmu.")])])],1)]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"6"}})],1),_c('v-card-title',[_c('v-text-field',{staticStyle:{"width":"100%"},attrs:{"dark":"","append-icon":"mdi-magnify","label":"Cari Nik, Nama, KK, dsb..","single-line":"","filled":"","outlined":"","hide-details":""},on:{"input":_vm.SearchbarChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"table-penduduk",attrs:{"disable-pagination":"","hide-default-footer":true,"headers":_vm.headers,"items":_vm.penduduks,"total-items":_vm.totalNumberOfItems},scopedSlots:_vm._u([{key:"item.keterangan",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"truncate"},[_vm._v(" "+_vm._s(item.keterangan)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'menunggu')?_c('p',{staticClass:"text-sub",staticStyle:{"color":"#ad9c9c"}},[_vm._v(" Menunggu... "),_c('v-icon',{staticStyle:{"color":"#ad9c9c"}},[_vm._v("mdi-clock-outline")])],1):_vm._e(),(item.status == 'proses')?_c('p',{staticClass:"text-sub",staticStyle:{"color":"orange"}},[_vm._v(" Diproses... "),_c('v-icon',{staticStyle:{"color":"orange"}},[_vm._v("mdi-progress-clock")])],1):_vm._e(),(item.status == 'selesai')?_c('p',{staticClass:"text-sub",staticStyle:{"color":"#4ff54c"}},[_vm._v(" Selesai... "),_c('v-icon',{staticStyle:{"color":"#4ff54c"}},[_vm._v("mdi-check")])],1):_vm._e()]}},{key:"item.aksi",fn:function(ref){
var item = ref.item;
return [_c('DetailELapor',{attrs:{"dataItem":item,"status":"lansia"}})]}}])}),_c('v-pagination',{attrs:{"loading":_vm.loading,"length":_vm.totalPages,"total-visible":"7","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left"},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }