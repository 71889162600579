<template>
  <div>
    <v-btn color="red" class="button-signup" @click="dialog = true"
      >Detail</v-btn
    >
    <v-dialog v-if="dialog" v-model="dialog" max-width="600px">
      <v-card>
        <v-app-bar
          color="#F2F1F1"
          height="80px"
          elevate-on-scroll
          scroll-target="#scrolling-techniques-7"
        >
          <v-btn
            color="black"
            icon
            v-bind="attrs"
            v-on="on"
            @click="dialog = false"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-tooltip bottom v-if="status == 'editable'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="black"
                icon
                v-bind="attrs"
                v-on="on"
                @click="editDataPenduduk()"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Akun</span>
          </v-tooltip>
          <div v-if="status == 'menunggu'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-5"
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    dialogConfirmation = true;
                    statusKonfirmasi = 'setuju';
                  "
                  style="color: white"
                >
                  Setuju
                </v-btn>
              </template>
              <span>Setujui Prestasi</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    dialogConfirmation = true;
                    statusKonfirmasi = 'tolak';
                  "
                  style="color: white"
                >
                  Tolak
                </v-btn>
              </template>
              <span>Tolak prestasi</span>
            </v-tooltip>
          </div>

          <v-dialog v-model="dialogConfirmation" max-width="290">
            <v-card>
              <v-card-title class="text-h5"> Konfirmasi </v-card-title>

              <v-card-text v-if="statusKonfirmasi == 'setuju'">
                Apakah anda yakin untuk menyetujui prestasi warga ini?
              </v-card-text>
              <v-card-text v-if="statusKonfirmasi == 'tolak'">
                Apakah anda yakin untuk menolak prestasi warga ini?
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="green darken-1"
                  text
                  @click="konfirmasiPrestasi()"
                >
                  Ya
                </v-btn>

                <v-btn
                  color="red darken-1"
                  text
                  @click="dialogConfirmation = false"
                >
                  Tidak
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-app-bar>
        <v-card-text>
          <v-container>
            <form
              id="myForm"
              name="myForm"
              v-on:submit.prevent="submitForm"
              enctype="multipart/form-data"
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-book-account"
                    label="nama"
                    filled
                    readonly
                    v-model="dataPrestasi.nama"
                    hint="Nama Lengkap"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-book-account"
                    label="NIK"
                    filled
                    readonly
                    v-model="dataPrestasi.nik"
                    hint="Nomor Induk Keluarga"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-book-account"
                    label="Judul"
                    filled
                    readonly
                    v-model="dataPrestasi.judul"
                    hint="Judul"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    prepend-icon="mdi-book-open-outline"
                    filled
                    v-model="dataPrestasi.keterangan"
                    readonly
                    label="Keterangan"
                    hint="Keterangan"
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="12" class="pl-10">
                  <p>Dokumentasi</p>
                  <img
                    prepend-icon="mdi-book-open-outline"
                    :src="dataPrestasi.photo"
                    alt=""
                    srcset=""
                    width="100%"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    v-model="dataPrestasi.provinsi"
                    label="Provinsi"
                    item-text="provinsi"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    v-model="dataPrestasi.kabupaten_kota"
                    label="Kabupaten/ Kota"
                    item-text="kabupaten_kota"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    v-model="dataPrestasi.kecamatan"
                    label="Kecamatan"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    v-model="dataPrestasi.kelurahan"
                    label="Kelurahan"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <p
                    v-if="dataPrestasi.status == 'menunggu'"
                    class="text-sub text-center"
                    style="color: #ad9c9c"
                  >
                    Menunggu...
                    <v-icon style="color: #ad9c9c">mdi-clock-outline</v-icon>
                  </p>
                  <p
                    v-if="dataPrestasi.status == 'ditolak'"
                    class="text-sub text-center"
                    style="color: red"
                  >
                    Ditolak...
                    <v-icon style="color: red"
                      >mdi-close-circle-outline
                    </v-icon>
                  </p>
                  <p
                    v-if="dataPrestasi.status == 'disetujui'"
                    class="text-sub text-center"
                    style="color: #4ff54c"
                  >
                    Disetujui...
                    <v-icon style="color: #4ff54c">mdi-check</v-icon>
                  </p>
                </v-col>
              </v-row>
            </form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import { postData } from "../service/ApiService";

export default {
  name: "DetailDataPrestasiWarga",
  components: {},
  props: {
    dataItem: [],
    status: String,
  },
  beforeCreate: function () {
    this.showDetail = true;
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      rand: 1,
      showDetail: false,
      show1: false,
      rules: {
        required: (value) => !!value || "Wajib di isi.",
        min: (v) => v.length >= 8 || "Password minimal 8 digit",
        min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
        emailMatch: () => `The email and password you entered don't match`,
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
        return "Wajib Berupa Angka.";
      },
      snackbar: false,
      snackbar_error: false,
      goldars: [
        "A",
        "B",
        "AB",
        "O",
        "A+",
        "A-",
        "B+",
        "B-",
        "AB+",
        "AB-",
        "O+",
        "O-",
        "NA",
      ],
      agamas: [
        "islam",
        "kristen",
        "kathloik",
        "budha",
        "hindu",
        "konghucu",
        "lainnya",
      ],
      status_pernikahans: ["Belum Kawin", "Kawin", "Cerai Hidup", "Cerai Mati"],
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      dataPrestasi: {
        id_role: 4,
        nama: "",
        nik: "",
        id_kk: "",
        email: "",
        password: "",
        status: "",
        status_pernikahan: "",
        nohp: "",
        tgl_lahir: "",
        tempatlahir: "",
        jk: "",
        suku: "",
        agama: "",
        goldarah: "",
        alamat: "",
        alamat_domisili: "",
        id_provinsi: "",
        id_kabkot: "",
        id_kelurahan: "",
        id_kecamatan: "",
        id_rt: "",
        id_rw: "",
        photo: "",
      },
      id_rt: "",
      statusKonfirmasi: "",
      id_rw: "",
      url: "",
      token_jwt: "",
      dialog: false,
      dialogConfirmation: false,
      activePicker: null,
      menu: false,
      msg_error: null,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataPrestasi.tgl_lahir
        ? moment(this.dataPrestasi.tgl_lahir).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    if (this.dialog) {
      self.rand = Date.now();
    }

    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    if (this.snackbar) {
      this.hide_alert();
    }

    if (this.snackbar_error) {
      this.hide_alert_error();
    }

    // this.getListProvinsi();
  },
  created() {
    this.dataPrestasi = this.dataItem;

    console.log("data item :", this.dataPrestasi);
  },
  methods: {
    format_date_now(value) {
      if (value) {
        return moment(String(value)).format("hh:mm MM-DD-YYYY");
      }
    },
    hide_alert: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    hide_alert_error: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar_error = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    editDataPenduduk() {
      this.$router.push({
        name: "EditDataPenduduk",
        params: {
          items: this.dataPrestasi,
        },
      });
    },
    konfirmasiPrestasi() {
      if (this.statusKonfirmasi == "setuju") {
        let formData = new FormData();
        formData.append("status", "disetujui");

        const data = {
          api: "api/prestasi-progres/" + this.dataItem.id_prestasi,
          body: formData,
        };

        postData(data)
          .then((response) => {
            console.log(response);
            this.$router.go();
            console.log(this.token_jwt);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              this.msg = error.response.data;
            }
          });
      } else if (this.statusKonfirmasi == "tolak") {
        let formData = new FormData();
        formData.append("status", "ditolak");

        const data = {
          api: "api/prestasi-progres/" + this.dataItem.id_prestasi,
          body: formData,
        };

        postData(data)
          .then((response) => {
            console.log(response);
            this.$router.go();
            console.log(this.token_jwt);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              this.msg = error.response.data;
            }
          });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>