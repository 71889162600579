<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      app
      color="#F2F1F1"
      dense
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <div style="position: relative">
      <div
        style="position: absolute; width: 100%"
        class="d-flex align-content-center justify-center"
      >
        <img
          src="../assets/bubble-loker.png"
          class="img-bubble"
          alt=""
          srcset=""
        />
      </div>
      <img src="../assets/bg-loker.png" class="background-image" alt="" />
      <v-row
        no-gutters
        justify="center"
        style="position: absolute; width: 100%; top: 0"
      >
        <v-col cols="3" class="d-flex justify-center mt-5">
          <v-avatar size="80" class="d-flex d-sm-none ml-4">
            <v-img
              alt="Smart RT logo"
              style="position: absolute"
              :src="dataLoker.photo"
              @click="overlay = !overlay"
            ></v-img>
          </v-avatar>
          <v-avatar size="120" class="d-none d-sm-flex d-md-none ml-4">
            <v-img
              alt="Smart RT logo"
              style="position: absolute"
              :src="dataLoker.photo"
              @click="overlay = !overlay"
            ></v-img>
          </v-avatar>
          <v-avatar size="120" class="d-none d-md-flex d-lg-none ml-4">
            <v-img
              alt="Smart RT logo"
              style="position: absolute"
              :src="dataLoker.photo"
              @click="overlay = !overlay"
            ></v-img>
          </v-avatar>
          <v-avatar size="120" class="d-none d-lg-flex d-xl-none ml-4">
            <v-img
              alt="Smart RT logo"
              style="position: absolute"
              :src="dataLoker.photo"
              @click="overlay = !overlay"
            ></v-img>
          </v-avatar>
          <v-avatar size="120" class="d-none d-xl-flex ml-4">
            <v-img
              alt="Smart RT logo"
              style="position: absolute"
              :src="dataLoker.photo"
              @click="overlay = !overlay"
            ></v-img>
          </v-avatar>
        </v-col>
        <v-col
          cols="6"
          class="
            text-center
            align-content-center
            flex-wrap
            justify-center
            pt-3
            pl-1
          "
        >
          <p class="text-cp">{{ dataLoker.judul }}</p>
          <p class="text-cp2">{{ dataLoker.nama_usaha }}</p>
          <p class="text-cp2">{{ dataLoker.alamat }}</p>
        </v-col>
        <v-col
          cols="3"
          class="d-flex align-content-center flex-wrap justify-center"
        >
        </v-col>
      </v-row>
    </div>

    <v-container style="height: 100%; margin-bottom: 100px">
      <p style="margin: 0; font-size: 25px; color: #ff8540" class="text-center">
        Informasi Umum
      </p>
      <p style="color: black" v-html="dataLoker.deskripsi"></p>
      <!-- <v-row dense>
        <v-col cols="12">
          <h1 style="color: black" class="mb-6">{{ dataLoker.judul }}</h1>
          <p style="color: black; margin-bottom: -1px">
          </p>
          <p style="color: gray">{{ computedDateFormattedMomentjs }}</p>
          <v-card>
            <v-img
              style="cursor: zoom-in"
              :src="dataLoker.photo"
              @click="overlay = !overlay"
              class="white--text align-end mb-6"
              height="400px"
            >
            </v-img>
          </v-card>
        </v-col>
      </v-row> -->
    </v-container>
    <v-overlay :z-index="zIndex" :value="overlay">
      <v-img
        v-click-outside="onClickOutside"
        @click="overlay = false"
        :src="dataLoker.photo"
        style="width: 100%; height: auto"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      >
      </v-img>
    </v-overlay>
    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import Footer from "../components/Footer.vue";
export default {
  name: "DetailLoker",
  props: ["dataLoker"],
  components: { Footer },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      token_jwt: "",
      menu: false,
      msg: "",
      overlay: false,
      zIndex: 0,
      dummy:
        "<h3>Tujuan :&nbsp;</h3><p>Floor &amp; Decor Designers provide a seamless start to finish consultative design service that builds lasting relationships and brand loyalty with the customers we serve. At Floor &amp; Decor Designers are responsible for meeting with customers, listening to their project needs, educating them on all that is required for their project, and building a creative design concept that incorporates the customers personal style, lifestyle, and budget</p><h3>Persyaratan Kelayakan :</h3><ul><li>Minimum of One (1) year of design experience&nbsp;</li><li>Design/decorating certification in lieu of 1 year design experience. BA degree in Design is a plus&nbsp;</li><li>Knowledge of hard surface flooring is a plus&nbsp;</li><li>Proficient in basic mathematical skills&nbsp;</li><li>Must be self-motivated and able to multi-task in a fast-paced environment&nbsp;</li><li>Excellent verbal, written, and interpersonal skills&nbsp;</li><li>Basic computer knowledge, including Microsoft Office suite</li></ul><p>&nbsp;</p><h3>Informasi Tambahan</h3><ul><li>Greet store customers in a helpful manner and supports store design walk-in needs&nbsp;</li><li>Schedule design appointments for existing and potential customers, while developing clientele through networking and referrals, as well as leads</li><li>generated within the store&nbsp;</li><li>Providing tile, wood, stone and accessories advice to homeowners, interior designers, and builders for both residential and commercial projects.&nbsp;</li><li>Design and sell complete, functional and aesthetically appealing solutions</li></ul>",
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataLoker.created_at
        ? moment(this.dataLoker.created_at).format("dddd, D MMM YYYY HH:m A")
        : "";
    },
  },
  mounted() {},
  created() {
    console.log(this.dataLoker);
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
  },
  methods: {
    onClickOutside() {
      this.overlay = false;
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}

@media only screen and (min-width: 599px) {
  .text-cp {
    color: white;
    font-size: 1.3vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 1vh;
    margin: 0;
  }

  .img-bubble {
    height: 20vh;
    width: auto;
  }

  .background-image {
    height: 160px;
    width: 100%;
  }
}

@media only screen and (max-width: 1250px) {
  .img-bubble {
    height: 20vh;
    width: auto;
  }
}

@media only screen and (max-width: 970px) {
  .img-bubble {
    height: 20vh;
    width: auto;
  }
}

@media only screen and (max-width: 845px) {
  .img-bubble {
    height: 20vh;
    width: auto;
  }
}

@media only screen and (max-width: 599px) {
  .text-cp {
    color: white;
    font-size: 3vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 2vh;
    margin: 0;
  }

  .img-bubble {
    height: 10vh;
    width: auto;
  }

  .background-image {
    height: 120px;
    width: 100%;
  }
}

@media only screen and (min-width: 693px) {
  .text-cp {
    color: white;
    font-size: 5vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 2.5vh;
    margin: 0;
  }
}

@media only screen and (max-width: 693px) {
  .text-cp {
    color: white;
    font-size: 2vh;
    margin: 0;
  }

  .text-cp2 {
    color: white;
    font-size: 1.5vh;
    margin: 0;
  }
}
</style>