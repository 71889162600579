import { render, staticRenderFns } from "./TambahGakin.vue?vue&type=template&id=33becd62&scoped=true&"
import script from "./TambahGakin.vue?vue&type=script&lang=js&"
export * from "./TambahGakin.vue?vue&type=script&lang=js&"
import style0 from "./TambahGakin.vue?vue&type=style&index=0&id=33becd62&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33becd62",
  null
  
)

export default component.exports