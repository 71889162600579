<template>
  <div class="map">
    <div style="height: 400px; width: 100%">
      <l-map :zoom="zoom" :center="center">
        <l-marker :lat-lng="center">
          <l-popup>
            <div>{{ lokasi }}</div>
          </l-popup>
        </l-marker>
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      </l-map>
    </div>
  </div>
</template>

<script>
export default {
  name: "Map",
  props: {
    lat: String,
    lng: String,
    lokasi: String,
  },
  data() {
    return {
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 25,
      center: [this.lat, this.lng],
      bounds: null,
    };
  },
  created() {
    console.log(this.lng, this.lat);
  },
};
</script>

<style scoped>
</style>