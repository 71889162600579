import VueCharts from "vue-chartjs";

export default {
    extends: VueCharts.Pie,
    props:  ["kandidat_final", "options"],
    data() {
      return {
      };
    },
    mounted() {

        console.log("kandidat final :", this.kandidat_final);
        console.log("kandidat final :", this.options);
        // Create new arrays to store the data

        var length = this.kandidat_final.length;
        var array = this.kandidat_final;

        var ratioArray = [];
        var labelsArray = [];
        var colorArray = [];

        for ( var i = 0; i < length; i++ ) {
          // Then push our data to the new arrays
          labelsArray.push(array[i] ? array[i].peserta : '');
          ratioArray.push(array[i] ? array[i].suara : '');
          colorArray.push(array[i] ? array[i].warna : '');
        }
    
  
        this.renderChart(
          { hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labelsArray,
        datasets: [
          {
            label: "Data One",
            backgroundColor: colorArray,
            data: ratioArray,
          },
        ],
      },  {
        legend: {
          position: "right",
          align: "center",
        },
        responsive: true,
        maintainAspectRatio: false,
      }, {
          borderWidth: "10px",
          hoverBackgroundColor: "red",
          hoverBorderWidth: "10px"
        });

    }}