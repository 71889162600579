<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil mendaftarkan sang buah hati
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-container class="text-end">
      <v-dialog v-model="dialog" max-width="600px">
        <template
          v-slot:activator="{ on, attrs }"
          class="flex justify-end text-right"
        >
          <v-btn color="red" class="button-signup" v-bind="attrs" v-on="on"
            >Tambah Data</v-btn
          >
        </template>
        <v-card>
          <v-app-bar
            color="#F2F1F1"
            height="80px"
            elevate-on-scroll
            scroll-target="#scrolling-techniques-7"
          >
            <v-btn
              color="black"
              icon
              v-bind="attrs"
              v-on="on"
              @click="dialog = false"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-flex class="mx-4"> Daftar Kelahiran </v-flex>
          </v-app-bar>
          <v-card-text>
            <v-container>
              <form
                id="myForm"
                name="myForm"
                v-on:submit.prevent="submitForm"
                enctype="multipart/form-data"
              >
                <v-row>
                  <v-col cols="12" class="d-flex justify-center text-center">
                    <input
                      type="file"
                      ref="file"
                      required
                      :rules="[rules.required]"
                      style="display: none"
                      @change="onFileChange"
                      accept="image/x-png,image/gif,image/jpeg"
                    />
                    <v-btn
                      icon
                      v-on="on"
                      style="width: 150px; height: 150px"
                      @click="$refs.file.click()"
                    >
                      <v-avatar color="brown" size="150">
                        <div v-if="this.url != ''" id="preview">
                          <img v-if="url" :src="url" />
                        </div>
                        <span
                          v-if="this.url == ''"
                          class="white--text text-h5"
                          style="text-transform: none"
                        >
                          <v-icon>mdi-camera</v-icon>
                        </span>
                      </v-avatar>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="text-center" v-if="this.url != ''">
                    <p class="delete-text" v-on:click="deletePic()">
                      <v-icon style="color: red">mdi-delete</v-icon> Hapus Foto
                    </p>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-account"
                      label="Nama Lengkap"
                      required
                      :rules="[rules.required]"
                      v-model="form.nama"
                      hint="Nama Lengkap Sesuai Akta"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-book-open-outline"
                      label="No Kartu Keluarga"
                      required
                      counter="16"
                      v-model="form.id_kk"
                      hint="Nomor Kartu Keluarga (16 Digit)"
                      :rules="[rules.required, rules.min_16]"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-book-account"
                      label="NIK Anak"
                      required
                      counter="16"
                      v-model="form.nik_anak"
                      hint="Nomor Induk Keluarga (16 Digit)"
                      :rules="[rules.required, rules.min_16]"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      :rules="[rules.required]"
                      prepend-icon="mdi-map-marker"
                      label="Tempat Lahir"
                      v-model="form.tempatlahir"
                      hint="ex:Balikpapan"
                      required
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      :rules="[rules.required]"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Tanggal Lahir"
                          prepend-icon="mdi-calendar"
                          readonly
                          :rules="[rules.required]"
                          :value="computedDateFormattedMomentjs"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :rules="[rules.required]"
                        v-model="form.tgl_lahir"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="6">
                    <v-radio-group
                      required
                      :rules="[rules.required]"
                      label="Jenis Kelamin"
                      v-model="form.jk"
                      prepend-icon="mdi-gender-male-female"
                    >
                      <v-radio label="Pria" value="pria"></v-radio>
                      <v-radio label="Wanita" value="wanita"></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="6">
                    <v-autocomplete
                      prepend-icon="mdi-water"
                      v-model="form.goldarah"
                      :rules="[rules.required]"
                      :items="goldars"
                      label="Golongan Darah"
                      hint="Golongan Darah"
                      required
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-account-arrow-up"
                      label="Tinggi Anak"
                      :rules="[rules.required]"
                      required
                      v-model="form.tinggi_bayi"
                      hint="Angka"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <div class="mt-5">CM</div>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-account-plus"
                      label="Berat Anak"
                      :rules="[rules.required]"
                      required
                      v-model="form.berat_bayi"
                      hint="Angka"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <div class="mt-5">Kg</div>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      prepend-icon="mdi-account-child-outline"
                      label="Anak Ke"
                      :rules="[rules.required]"
                      required
                      v-model="form.kelahiran_ke"
                      hint="Angka"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-autocomplete
                      prepend-icon="mdi-account-group"
                      v-model="form.agama"
                      :items="agamas"
                      :rules="[rules.required]"
                      label="Agama"
                      hint="Agama Sesuai akta"
                      required
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      prepend-icon="mdi-account-child-outline"
                      label="Suku"
                      :rules="[rules.required]"
                      required
                      v-model="form.suku"
                      hint="suku"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-file-input
                      prepend-icon="mdi-file-upload"
                      label="File Akta PDF "
                      required
                      accept=".pdf"
                      v-model="form.akta"
                      hint="file berupa pdf"
                      chips
                      small-chips
                      truncate-length="15"
                    >
                    </v-file-input>
                  </v-col>

                  <v-app-bar
                    app
                    color="#F2F1F1"
                    height="80px"
                    elevate-on-scroll
                    scroll-target="#scrolling-techniques-7"
                  >
                    <v-flex class="mx-4 fs-2"> Tempat Kelahiran </v-flex>
                  </v-app-bar>

                  <v-col cols="12">
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="form.id_provinsi"
                      :items="provinsis"
                      label="Provinsi"
                      :rules="[rules.required]"
                      item-value="id"
                      item-text="provinsi"
                      required
                      @change="(event) => selectedProvinsi(event)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="form.id_kabkot"
                      :rules="[rules.required]"
                      :items="kabupatens"
                      label="Kabupaten/ Kota"
                      item-value="id"
                      item-text="kabupaten_kota"
                      :disabled="kabupatens == ''"
                      required
                      @change="(event) => selectedKabupaten(event)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="form.id_kecamatan"
                      :items="kecamatans"
                      label="Kecamatan"
                      :rules="[rules.required]"
                      item-text="kecamatan"
                      item-value="id"
                      :disabled="kecamatans == ''"
                      required
                      @change="(event) => selectedKecamatan(event)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="form.id_kelurahan"
                      :items="kelurahans"
                      :rules="[rules.required]"
                      item-value="id"
                      label="Kelurahan"
                      :disabled="kelurahans == ''"
                      item-text="kelurahan"
                      required
                      @change="(event) => selectedKelurahan(event)"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-map-marker"
                      label="Alamat Akta"
                      v-model="form.alamat"
                      :rules="[rules.required]"
                      hint="Alamat Sesuai Akta"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-map-marker"
                      label="Alamat Domisili"
                      :rules="[rules.required]"
                      v-model="form.alamat_domisili"
                      hint="Alamat Tempat Tinggal Saat Ini"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      prepend-icon="mdi-map-marker"
                      label="RT"
                      v-model="form.id_rt"
                      hint="RT Sesuai Tempat Tinggal Saat Ini"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      prepend-icon="mdi-map-marker"
                      :rules="[rules.required]"
                      label="RW"
                      v-model="form.id_rw"
                      hint="RW Sesuai Tempat Tinggal Saat Ini"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-btn
                    style="color: white"
                    color="red"
                    type="submit"
                    value="Submit"
                    :loading="loading"
                    block
                  >
                    Simpan
                  </v-btn>
                </v-row>
              </form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { getData, postData } from "../service/ApiService";

export default {
  name: "TambahKelahiran",
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      show1: false,
      rules: {
        required: (value) => !!value || "Wajib di isi.",
        min: (v) => v.length >= 8 || "Password minimal 8 digit",
        min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
        return "Wajib Berupa Angka.";
      },
      snackbar: false,
      loading: false,
      snackbar_error: false,
      goldars: [
        "A",
        "B",
        "AB",
        "O",
        "A+",
        "A-",
        "B+",
        "B-",
        "AB+",
        "AB-",
        "O+",
        "O-",
        "NA",
      ],
      agamas: [
        "islam",
        "kristen",
        "katholik",
        "budha",
        "hindu",
        "konghucu",
        "lainnya",
      ],
      roles: [
        { id: 2, nama: "Admin" },
        { id: 3, nama: "RT" },
        { id: 4, nama: "Warga" },
      ],
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      form: {
        tinggi: "",
        nama: "",
        nik_anak: "",
        id_kk: "",
        email: "",
        password: "",
        tgl_lahir: "",
        tempatlahir: "",
        jk: "",
        suku: "",
        agama: "",
        goldarah: "",
        alamat: "",
        alamat_domisili: "",
        id_provinsi: "",
        id_kabkot: "",
        id_kelurahan: "",
        id_kecamatan: "",
        id_rt: "",
        id_rw: "",
        photo: "",
      },
      url: "",
      token_jwt: "",
      dialog: false,
      activePicker: null,
      menu: false,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.form.tgl_lahir
        ? moment(this.form.tgl_lahir).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    if (this.snackbar) {
      this.hide_alert();
    }

    if (this.snackbar_error) {
      this.hide_alert_error();
    }

    this.getListProvinsi();
  },
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
  },
  methods: {
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    hide_alert: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    hide_alert_error: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar_error = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.form.id_provinsi = value;
            this.form.id_kabkot = "";
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.form.id_kabkot = value;
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.form.id_kecamatan = value;
            this.form.id_kelurahan = "";
            this.kelurahans = "";

            this.kelurahans = response;
            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.form.id_kelurahan = value;
      }
    },
    submitForm() {
      this.loading = true;
      console.log(this.form);
      let formData = new FormData();
      formData.append("tinggi_bayi", this.form.tinggi_bayi);
      formData.append("kelahiran_ke", this.form.kelahiran_ke);
      formData.append("akta", this.form.akta);
      formData.append("berat_bayi", this.form.berat_bayi);
      formData.append("nama", this.form.nama);
      formData.append("nik_anak", this.form.nik_anak);
      formData.append("id_kk", this.form.id_kk);
      formData.append("email", this.form.email);
      formData.append("password", this.form.password);
      formData.append("status", this.form.status);
      formData.append("status_pernikahan", this.form.status_pernikahan);
      formData.append("nohp", this.form.nohp);
      formData.append("tgl_lahir", this.form.tgl_lahir);
      formData.append("tempatlahir", this.form.tempatlahir);
      formData.append("jk", this.form.jk);
      formData.append("suku", this.form.suku);
      formData.append("agama", this.form.agama);
      formData.append("goldarah", this.form.goldarah);
      formData.append("alamat", this.form.alamat);
      formData.append("alamat_domisili", this.form.alamat_domisili);
      formData.append("id_provinsi", this.form.id_provinsi);
      formData.append("id_kecamatan", this.form.id_kecamatan);
      formData.append("id_kelurahan", this.form.id_kelurahan);
      formData.append("id_kabkot", this.form.id_kabkot);
      formData.append("id_rt", this.form.id_rt);
      formData.append("id_rw", this.form.id_rw);
      formData.append("photo", this.form.photo);
      if (this.userData.role != "superadmin") {
        this.form.id_role = 4;
      }
      formData.append("id_role", this.form.id_role);
      console.log(formData.values());
      const data = {
        api: "api/lahir",
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log(response);
          this.dialog = false;
          this.loading = false;
          this.snackbar = true;
          this.$router.reload();
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.nik_anak) {
              this.msg_error = error.response.data.nik_anak[0];
              this.snackbar_error = true;
            } else if (error.response.data.email) {
              this.msg_error = error.response.data.email[0];
              this.snackbar_errror = true;
            } else {
              this.msg_error = error.response.data;
              this.snackbar_errror = true;
            }
          }
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    deletePic() {
      this.url = "";
      this.$refs.file.value = "";
    },
    onFileChange(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.form.photo = e.target.files[0];
      console.log(this.form);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>