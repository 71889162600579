<template>
  <v-card height="100%" style="margin-bottom: 100px">
    <v-container>
      <v-row>
        <v-col cols="6" class="flex mb-2">
          <p class="text-title">
            My Lapor
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small>
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span
                >MyLapor merupakan aplikasi lapor segala <br />
                keperluan pembangunan maupun lingkungan disekitarmu.</span
              >
            </v-tooltip>
          </p>
        </v-col>
        <v-col cols="6" class="text-end mb-3">
          <v-btn
            color="red"
            class="button-signup"
            @click="$router.push('/tambah-laporan')"
            >Buat Laporan</v-btn
          >
        </v-col>
      </v-row>

      <v-card-title>
        <v-text-field
          dark
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Nik, Nama, KK, dsb.."
          single-line
          filled
          outlined
          hide-details
          @input="SearchbarChange"
          style="width: 100%"
        >
        </v-text-field>
      </v-card-title>

      <v-data-table
        class="table-penduduk"
        disable-pagination
        :hide-default-footer="true"
        :headers="headers"
        :items="penduduks"
        :total-items="totalNumberOfItems"
        @update:pagination="paginate"
      >
        <template
          v-slot:item.lapor[0].keterangan="{ item }"
          style="text-transform: capitalize"
        >
          <p class="truncate">
            {{ item.keterangan }}
          </p>
        </template>
        <template
          v-slot:item.lapor[0].status="{ item }"
          style="text-transform: capitalize"
        >
          <p
            v-if="item.lapor[0].status == 'menunggu'"
            class="text-sub"
            style="color: #ad9c9c"
          >
            Menunggu...
            <v-icon style="color: #ad9c9c">mdi-clock-outline</v-icon>
          </p>
          <p
            v-if="item.lapor[0].status == 'proses'"
            class="text-sub"
            style="color: orange"
          >
            Diproses...
            <v-icon style="color: orange">mdi-progress-clock</v-icon>
          </p>
          <p
            v-if="item.lapor[0].status == 'selesai'"
            class="text-sub"
            style="color: #4ff54c"
          >
            Selesai... <v-icon style="color: #4ff54c">mdi-check</v-icon>
          </p>
        </template>
        <!-- <template v-slot:item.tempatlahir="{ item }">
        <td class="truncate">{{ item.tempatlahir }}</td>
      </template> -->
        <!-- <template v-slot:item.tgl_lahir="{ item }">
        {{ format_date(item.tgl_lahir) }}
      </template> -->

        <template v-slot:item.aksi="{ item }">
          <DetailDataMyLapor :dataItem="item" status="lansia" />
        </template>
      </v-data-table>

      <v-pagination
        :loading="loading"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      >
      </v-pagination>
    </v-container>
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import { getData } from "../service/ApiService";
import DetailDataMyLapor from "./DetailDataMyLapor.vue";

export default {
  name: "DataTableMylapor",
  components: {
    DetailDataMyLapor,
  },
  beforeCreate: function () {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      if (this.token_jwt == null) {
        this.$router.push("/");
      }
    }
  },
  data() {
    return {
      panel: 0,
      userData: "",
      hasSaved: false,
      isEditing: false,
      dialog: false,
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      id_provinsi: "",
      id_kabkot: "",
      id_kelurahan: "",
      id_kecamatan: "",
      page: 1,
      totalPages: 0,
      apiGetFilter: "",
      pageSize: 3,
      pageSizes: [3, 6, 9],
      search: "",
      loading: true,
      headers: [
        {
          text: "Kategori",
          align: "start",
          value: "lapor[0].kategori",
        },
        {
          text: "Judul",
          value: "lapor[0].judul",
        },
        {
          text: "Keterangan",
          value: "lapor[0].keterangan",
        },
        {
          text: "Status",
          value: "lapor[0].status",
        },
        {
          text: "Aksi",
          value: "aksi",
          sortable: false,
        },

        //   { text: 'Aksi', value: 'aksi',class: 'my-header-style' },
      ],
      penduduks: [],
      activePicker: null,
      menu: false,
    };
  },
  watch: {
    isEditing() {
      if (this.isEditing) {
        this.hasSaved = true;
      }
      console.log("Foo Changed!");
    },
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.form.tgl_lahir
        ? moment(this.form.tgl_lahir).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.getDataPenduduk();
    this.getListProvinsi();
  },
  created() {},
  methods: {
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getDataPenduduk() {
      const apiLansia =
        "api/elapor/warga/" +
        this.userData.nik +
        "?page=" +
        this.page +
        "&keyword=" +
        this.search;
      console.log("data mylapor: ", this.userData.nik);

      getData(apiLansia)
        .then((response) => {
          console.log("data :", response);
          this.penduduks = response.data.data;
          this.totalPages = response.last_page;
          console.log(this.penduduks);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getDataPendudukFilter() {
      console.log("pesan filter :", this.search);
      if (this.search != "") {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/elapor/warga/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&kel=" +
            this.id_kelurahan +
            "&keyword=" +
            this.search;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/elapor/warga/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&keyword=" +
            this.search;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/elapor/warga/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&keyword=" +
            this.search;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/elapor/warga/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&keyword=" +
            this.search;
        } else {
          this.apiGetFilter =
            "api/elapor/warga/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.search;
        }
      } else {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/elapor/warga/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&kel=" +
            this.id_kelurahan;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/elapor/warga/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/elapor/warga/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/elapor/warga/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi;
        } else {
          this.apiGetFilter =
            "api/elapor/warga/" + this.userData.nik + "?page=" + this.page;
        }
      }

      getData(this.apiGetFilter)
        .then((response) => {
          console.log("data :", response);
          this.penduduks = response.data.data;
          this.totalPages = response.last_page;
          console.log(this.penduduks);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value.id;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.id_provinsi = value.provinsi;
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kabkot = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value.id;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.id_kabkot = value.kabupaten_kota;
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value.id;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.id_kecamatan = value.kecamatan;
            this.kelurahans = "";
            this.kelurahans = response;
            this.id_kelurahan = "";

            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.id_kelurahan = value.kelurahan;
      }
    },
    handlePageChange(value) {
      this.page = value;
      this.getDataPenduduk();
    },
    SearchbarChange(value) {
      this.search = value;
      this.penduduks = [];

      this.getDataPendudukFilter();
      console.log(value);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.truncate {
  line-height: 1.5em;
  height: 3em; /* height is 2x line-height, so two lines will display */
  overflow: hidden; /* prevents extra lines from being visible */
  text-overflow: ellipsis;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.hidden-sm-and-down .v-icon {
  color: red !important;
}

.v-card__title {
  background: rgb(252, 55, 55);
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>
