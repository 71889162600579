<template>
  <v-card class="overflow-hidden">
    <v-app-bar app color="#F2F1F1" dense>
      <v-btn color="black" icon to="/e-bansos">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- <Header text="E-Bansos" img="e-survey.png" /> -->
    <v-snackbar
      v-model="snackbar.visible"
      multi-line="true"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      centered
      auto-height
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>
            <strong> {{ snackbar.status }}</strong>
          </div>
          <div>{{ msg }}</div>
        </v-layout>
      </v-layout>
      <v-btn
        v-if="snackbar.timeout === 0"
        icon
        @click="snackbar.visible = false"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-snackbar>
    <v-container style="height: 100%; margin-bottom: 100px">
      <div v-if="!bansosData.data">
        <v-skeleton-loader
          class="mx-auto mb-10"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>

        <v-skeleton-loader
          class="mx-auto mb-10"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>

        <v-slide-group
          class="mb-10 mt-10"
          v-model="model"
          active-class="success"
          show-arrows
        >
          <v-slide-item v-for="n in 15" :key="n">
            <v-skeleton-loader
              class="mx-auto mr-10"
              max-width="150px"
              type="card"
              style="width: 150px"
            ></v-skeleton-loader>
          </v-slide-item>
        </v-slide-group>
      </div>
      <p style="margin: 0 0 40px 0; font-size: 34px" class="text-center">
        {{ dataEBansosAksi.nama }}
      </p>
      <!-- <v-row>
        <v-col cols="6">
        </v-col>
        <v-col cols="6" class="flex justify-end text-right mb-2">
          <TambahEBansosAksi
            v-show="userData.role != 'warga'"
            :dataItem="dataEBansosAksi"
          />
        </v-col>
      </v-row> -->
      <!-- <v-expansion-panels
        v-show="userData.role == 'superadmin' || userData.role == 'admin'"
      >
        <v-expansion-panel>
          <v-expansion-panel-header> Filter </v-expansion-panel-header>
          <v-expansion-panel-content class="text-center">
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              label="Provinsi"
              v-model="id_provinsi"
              :items="provinsis"
              item-text="provinsi"
              return-object
              required
              @change="(event) => selectedProvinsi(event)"
            ></v-autocomplete>
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              v-model="id_kabkot"
              :items="kabupatens"
              label="Kabupaten/ Kota"
              return-object
              item-text="kabupaten_kota"
              :disabled="kabupatens == ''"
              required
              @change="(event) => selectedKabupaten(event)"
            ></v-autocomplete>
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              v-model="id_kecamatan"
              :items="kecamatans"
              label="Kecamatan"
              item-text="kecamatan"
              return-object
              :disabled="kecamatans == ''"
              required
              @change="(event) => selectedKecamatan(event)"
            ></v-autocomplete>
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              v-model="id_kelurahan"
              :items="kelurahans"
              label="Kelurahan"
              return-object
              :disabled="kelurahans == ''"
              item-text="kelurahan"
              required
              @change="(event) => selectedKelurahan(event)"
            ></v-autocomplete>
            <v-btn
              color="red"
              class="button-signup"
              block
              v-on:click="getDataBansosAksiFilter()"
              >Submit</v-btn
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Cari Vote.."
        single-line
        filled
        outlined
        hide-details
        @input="SearchbarChange"
        style="width: 100%"
        class="mb-5"
      >
      </v-text-field> -->
      <div v-if="bansosData.data">
        <v-card
          color="#F2F2F2"
          class="mb-5"
          v-for="(item, index) in bansosData.data"
          :key="index"
        >
          <v-row no-gutters>
            <v-col>
              <v-list three-line color="#F2F2F2">
                <template>
                  <v-list-item :key="item.nama">
                    <v-list-item-avatar tile size="80">
                      <v-img :src="item.photo"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-html="item.nama"></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="item.nikpenerima"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        >Jumlah : {{ item.terima }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle>
                        Di kirim :
                        {{
                          computedDateFormattedMomentjs(item.created_at)
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-col>
            <!-- <v-col>
              <p>
                Di kirim : {{ computedDateFormattedMomentjs(item.created_at) }}
              </p>
            </v-col> -->
          </v-row>
        </v-card>
      </div>
    </v-container>
    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import Footer from "../components/Footer.vue";
// import Header from "../components/Header.vue";
import { getData, postData } from "../service/ApiService";
// import TambahEBansosAksi from "../components/TambahEBansosAksi.vue";
export default {
  name: "EBansosAksi",
  props: ["dataEBansosAksi"],
  components: {
    Footer,
    // TambahEBansosAksi,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      model: null,
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      id_provinsi: "",
      id_kabkot: "",
      id_kelurahan: "",
      id_kecamatan: "",
      search: "",
      token_jwt: "",
      menu: false,
      msg: "",
      loading: false,
      page: 1,
      bansosData: "",
      startTime: "July 7, 2017 12:03:00",
      endTime: "July 9, 2022 14:55:00",
      userData: "",
      snackbar: {
        timeout: 3000,
        visible: false,
        color: "",
        icon: "",
        status: "",
      },
      times: [
        { id: 0, text: "Days", time: 1 },
        { id: 1, text: "Hours", time: 1 },
        { id: 2, text: "Minutes", time: 1 },
        { id: 3, text: "Seconds", time: 1 },
      ],
      progress: 100,
      // isActive: false,
      timeinterval: undefined,
    };
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
    },
  },
  mounted() {},
  created() {
    console.log(this.dataEBansosAksi);
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.getDataBansosAksi();
    this.getListProvinsi();
  },
  methods: {
    computedDateFormattedMomentjs(value) {
      return value ? moment(value).format("DD / MM / YYYY") : "";
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    eSurveyFinish() {
      this.$router.push({
        name: "ESurveyFinish",
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.getDataBansosAksiFilter();
    },
    getDataBansosAksiFilter() {
      if (this.search != "") {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/bansos/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi +
            "&keyword=" +
            this.id_kabkot +
            "&keyword=" +
            this.id_kecamatan +
            "&keyword=" +
            this.id_kelurahan +
            "&keyword=" +
            this.search;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/bansos/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi +
            "&keyword=" +
            this.id_kabkot +
            "&keyword=" +
            this.id_kecamatan +
            "&keyword=" +
            this.search;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/bansos/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi +
            "&keyword=" +
            this.id_kabkot +
            "&keyword=" +
            this.search;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/bansos/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi +
            "&keyword=" +
            this.search;
        } else {
          this.apiGetFilter =
            "api/bansos/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.search;
        }
      } else {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/bansos/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi +
            "&keyword=" +
            this.id_kabkot +
            "&keyword=" +
            this.id_kecamatan +
            "&keyword=" +
            this.id_kelurahan;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/bansos/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi +
            "&keyword=" +
            this.id_kabkot +
            "&keyword=" +
            this.id_kecamatan;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/bansos/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi +
            "&keyword=" +
            this.id_kabkot;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/bansos/" +
            this.userData.nik +
            "?page=" +
            this.page +
            "&keyword=" +
            this.id_provinsi;
        } else {
          this.apiGetFilter =
            "api/bansos/" + this.userData.nik + "?page=" + this.page;
        }
      }

      getData(this.apiGetFilter)
        .then((response) => {
          console.log("data :", response);
          this.bansosData = response;
          console.log(this.bansosData);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value.id;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.id_provinsi = value.provinsi;
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kabkot = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value.id;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.id_kabkot = value.kabupaten_kota;
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value.id;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.id_kecamatan = value.kecamatan;
            this.kelurahans = "";
            this.kelurahans = response;
            this.id_kelurahan = "";

            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.id_kelurahan = value.kelurahan;
      }
    },
    SearchbarChange(value) {
      this.search = value;
      this.getDataBansosAksiFilter();
      console.log(value);
    },
    back() {
      this.$router.back();
    },
    getDataBansosAksi() {
      const ApiGetAksi = "api/aksi/bansos/" + this.dataEBansosAksi.idbansos;

      getData(ApiGetAksi)
        .then((response) => {
          console.log("data :", response);
          this.bansosData = response;
          console.log(this.bansosData);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
          this.loading = false;
        });
    },
    checkSurvey(item) {
      this.loading = true;
      console.log(this.items);
      let formData = new FormData();
      formData.append("nik_respon", this.userData.nik);
      const data = {
        api: "api/bansos/ask/" + item.id_survey,
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log("data :", response);
          const arr = [response, item];
          this.detailSurvey(arr);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.loading = false;
          this.snackbar.color = "red accent-2";
          this.snackbar.icon = "mdi-alert-octagon-outline";
          this.snackbar.status = "Gagal";
          if (error.response) {
            this.snackbar.visible = true;
            console.log(error.response.data.alert);
            this.msg = error.response.data.alert;
          }
        });
    },
    detailSurvey(item) {
      this.$router.push({
        name: "DetailSurvey",
        params: {
          items: item,
        },
      });
    },
    detailBansos(item) {
      this.$router.push({
        name: "DetailBansos",
        params: {
          dataBansos: item,
        },
      });
    },
    tambahBansos() {
      this.$router.push({
        name: "tambahBansos",
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY HH:SS");
      }
    },
  },
};
</script>

<style scoped>
.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}
.text-sub {
  color: #c82424;
}
.truncate {
  line-height: 1.5em;
  height: 3em; /* height is 2x line-height, so two lines will display */
  overflow: hidden; /* prevents extra lines from being visible */
}
</style>