<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      app
      color="#F2F1F1"
      dense
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="Berita" icon="mdi-newspaper" />

    <v-container style="height: 100%; margin-bottom: 100px">
      <v-row dense>
        <v-col cols="12">
          <h2 style="color: black" class="mb-2">{{ dataNews.judul }}</h2>
          <p style="color: black; margin-bottom: -1px">
            by : {{ dataNews.nama }}
          </p>
          <p style="color: gray">{{ computedDateFormattedMomentjs }}</p>
          <v-card>
            <v-img
              style="cursor: zoom-in"
              :src="dataNews.photo"
              @click="overlay = !overlay"
              class="white--text align-end mb-6"
              width="100%"
            >
            </v-img>
          </v-card>
          <p style="color: black" v-html="dataNews.konten"></p>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay :z-index="zIndex" :value="overlay">
      <v-img
        v-click-outside="onClickOutside"
        @click="overlay = false"
        :src="dataNews.photo"
        style="width: 100%; height: auto"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      >
      </v-img>
    </v-overlay>
    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
export default {
  name: "DetailNews",
  props: ["dataNews"],
  components: { Footer, Header },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      token_jwt: "",
      menu: false,
      msg: "",
      overlay: false,
      zIndex: 0,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataNews.created_at
        ? moment(this.dataNews.created_at).format("dddd, D MMM YYYY HH:m A")
        : "";
    },
  },
  mounted() {},
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    if (localStorage.data_news) {
      this.dataNews = JSON.parse(localStorage.data_news);
      console.log("data user: ", this.dataNews);
    }
  },
  methods: {
    onClickOutside() {
      this.overlay = false;
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>