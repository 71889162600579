<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      app
      color="#F2F1F1"
      dense
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="Pariwisata" icon="mdi-earth" />

    <v-container style="height: 100%; margin-bottom: 100px">
      <v-row v-if="pariwisataDataRekomendasi.length != 0" class="mb-5">
        <v-col cols="12" sm="8">
          <v-carousel
            style="border-radius: 15px"
            cycle
            height="auto"
            class="responsive-height"
            hide-delimiter-background
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(slide, i) in pariwisataDataRekomendasi"
              :key="i"
            >
              <v-card height="100%" style="border-radius: 15px">
                <v-img
                  @click="detailPariwisata(slide)"
                  class="white--text align-end d-flex responsive-height"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  :src="slide.photo"
                >
                  <v-card-subtitle>
                    <p style="margin-bottom: 5px; font-size: 20px">
                      {{ slide.namawisata }}
                    </p>
                    <p style="color: red; font-size: 18px">{{ slide.kota }}</p>
                  </v-card-subtitle>
                </v-img>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" sm="4">
          <v-row>
            <v-col cols="6" sm="12">
              <v-card
                height="100%"
                style="border-radius: 10px"
                @click="detailPariwisata(pariwisataDataRekomendasi[1])"
              >
                <v-img
                  class="white--text align-end d-flex"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="135"
                  :src="pariwisataDataRekomendasi[1].photo"
                >
                  <v-card-subtitle>
                    {{ pariwisataDataRekomendasi[1].namawisata }} <br />
                    <p style="color: red; margin-bottom: 0">
                      {{ pariwisataDataRekomendasi[1].kota }}
                    </p>
                  </v-card-subtitle>
                </v-img>
              </v-card>
            </v-col>
            <v-col cols="6" sm="12">
              <v-card
                height="100%"
                style="border-radius: 10px"
                @click="detailPariwisata(pariwisataDataRekomendasi[2])"
              >
                <v-img
                  class="white--text align-end d-flex"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="135"
                  :src="pariwisataDataRekomendasi[2].photo"
                >
                  <v-card-subtitle>
                    {{ pariwisataDataRekomendasi[2].namawisata }} <br />
                    <p style="color: red; margin-bottom: 0">
                      {{ pariwisataDataRekomendasi[2].kota }}
                    </p>
                  </v-card-subtitle>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-expansion-panels
        class="mt-10"
        v-show="userData.role == 'superadmin' || userData.role == 'admin'"
      >
        <v-expansion-panel>
          <v-expansion-panel-header> Filter </v-expansion-panel-header>
          <v-expansion-panel-content class="text-center">
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              label="Provinsi"
              v-model="id_provinsi"
              :items="provinsis"
              item-text="provinsi"
              return-object
              required
              @change="(event) => selectedProvinsi(event)"
            ></v-autocomplete>
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              v-model="id_kabkot"
              :items="kabupatens"
              label="Kabupaten/ Kota"
              return-object
              item-text="kabupaten_kota"
              :disabled="kabupatens == ''"
              required
              @change="(event) => selectedKabupaten(event)"
            ></v-autocomplete>
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              v-model="id_kecamatan"
              :items="kecamatans"
              label="Kecamatan"
              item-text="kecamatan"
              return-object
              :disabled="kecamatans == ''"
              required
              @change="(event) => selectedKecamatan(event)"
            ></v-autocomplete>
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              v-model="id_kelurahan"
              :items="kelurahans"
              label="Kelurahan"
              return-object
              :disabled="kelurahans == ''"
              item-text="kelurahan"
              required
              @change="(event) => selectedKelurahan(event)"
            ></v-autocomplete>
             <v-autocomplete
              prepend-icon="mdi-filter"
              v-model="kategori_filter"
              :items="dataKategoriPariwisata"
              label="Kategori"
              return-object
              :disabled="dataKategoriPariwisata == ''"
              item-text="kategori"
              required
            ></v-autocomplete>
            <v-btn
              color="red"
              class="button-signup"
              block
              v-on:click="getDataPariwisataFilter()"
              >Submit</v-btn
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Cari Wisata.."
        single-line
        filled
        outlined
        hide-details
        @input="SearchbarChange"
        style="width: 100%"
        class="mb-5"
      >
      </v-text-field>
      <v-row>
        <v-col cols="6" sm="8">
          <span
            v-if="id_provinsi != ''"
            style="color: black; margin: 0; font-size: 16px"
          >
            Filter :
          </span>
          <span>
            <v-chip
              v-if="id_provinsi != ''"
              class="ma-2"
              close
              @click:close="
                id_provinsi = '';
                id_kabkot = '';
                id_kelurahan = '';
                id_kecamatan = '';
              "
            >
              {{ id_provinsi }}
            </v-chip>
            <v-chip
              v-if="id_kabkot != ''"
              class="ma-2"
              close
              @click:close="
                id_kabkot = '';
                id_kelurahan = '';
                id_kecamatan = '';
              "
            >
              {{ id_kabkot }}
            </v-chip>
            <v-chip
              v-if="id_kelurahan != ''"
              class="ma-2"
              close
              @click:close="
                id_kelurahan = '';
                id_kecamatan = '';
              "
            >
              {{ id_kelurahan }}
            </v-chip>
            <v-chip
              v-if="id_kecamatan != ''"
              class="ma-2"
              close
              @click:close="id_kecamatan = ''"
            >
              {{ id_kecamatan }}
            </v-chip>
               <v-chip
              v-if="kategori_filter != ''"
              class="ma-2"
              close
              @click:close="kategori_filter = ''"
            >
              {{ kategori_filter.kategori }}
            </v-chip>
          </span>
        </v-col>
        <v-col cols="6" sm="4">
          <div class="d-flex justify-end">
            <v-btn
              color="#F54C4C"
              class="button-signup"
              type="submit"
              @click="tambahPariwisata()"
              v-show="userData.role != 'warga'"
              >Tambah Pariwisata</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <div class="mt-10" v-if="pariwisataData != null">
        <p style="margin: 0; font-size: 25px; color: #ff8540">
          {{ userData.provinsi }}
        </p>

        <div v-if="pariwisataData.by_wilayah.data.length != 0">
          <v-slide-group
            class="mb-10"
            active-class="success"
            show-arrows
            v-if="pariwisataData.by_wilayah.data"
          >
            <v-slide-item
              v-for="item in pariwisataData.by_wilayah.data"
              :key="item"
            >
              <v-card
                class="ma-4 rounded-lg"
                height="200"
                width="150"
                @click="detailPariwisata(item)"
              >
                <v-img
                  :src="item.photo"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="200px"
                >
                  <v-card-subtitle>
                    <h5>{{ item.namawisata }}</h5>
                  </v-card-subtitle>
                </v-img>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </div>
        <p v-else>Tidak ada data.</p>
      </div>
      <div class="mt-10" v-if="pariwisataData != null">
        <p style="margin: 0; font-size: 25px; color: #ff8540">Semua Wisata</p>
        <div v-if="pariwisataData.all_data.data.length != 0">
          <v-slide-group
            class="mb-10"
            active-class="success"
            show-arrows
            v-if="pariwisataData.all_data.data"
          >
            <v-slide-item
              v-for="item in pariwisataData.all_data.data"
              :key="item"
            >
              <v-card
                class="ma-4 rounded-lg"
                height="200"
                width="150"
                @click="detailPariwisata(item)"
              >
                <v-img
                  :src="item.photo"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="200px"
                >
                  <v-card-subtitle>
                    <h5>{{ item.namawisata }}</h5>
                  </v-card-subtitle>
                </v-img>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </div>
        <p v-else>Tidak ada data.</p>
      </div>
      <div class="mt-10" v-if="pariwisataDataSearch != null">
        <p style="margin: 0; font-size: 25px; color: #ff8540">Semua Wisata</p>
        <div v-if="ariwisataDataSearch.all_data.data.length != 0">
          <v-slide-group
            class="mb-10"
            active-class="success"
            show-arrows
            v-if="pariwisataDataSearch.all_data.data"
          >
            <v-slide-item
              v-for="item in pariwisataDataSearch.all_data.data"
              :key="item"
            >
              <v-card
                class="ma-4 rounded-lg"
                height="200"
                width="150"
                @click="detailPariwisata(item)"
              >
                <v-img
                  :src="item.photo"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="200px"
                >
                  <v-card-subtitle>
                    <h5>{{ item.namawisata }}</h5>
                  </v-card-subtitle>
                </v-img>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </div>
        <p v-else>Tidak ada data.</p>
      </div>

      <v-pagination
        :loading="loading"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      >
      </v-pagination>
    </v-container>
    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import { getData } from "../service/ApiService";

export default {
  name: "Pariwisata",
  components: { Footer, Header },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: [
        { text: "First", color: "indigo" },
        { text: "Second", color: "gray" },
        { text: "Third", color: "pink" },
      ],
      token_jwt: "",
      menu: false,
      msg: "",
      page: 1,
      pariwisataData: null,
      pariwisataDataSearch: null,
      pariwisataDataRekomendasi: "",
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      id_provinsi: "",
      id_kabkot: "",
      id_kelurahan: "",
      id_kecamatan: "",
      kategori_filter: "",
      search: "",
      userData: null,
      totalPages: null,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataNews.created_at
        ? moment(this.dataNews.created_at).format("dddd, D MMM YYYY HH:m A")
        : "";
    },
  },
  mounted() {},
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.getListProvinsi();
    this.getDataKategoriPariwisata();
    this.getDataPariwisata();
    this.getDataPariwisataRekomendasi();
  },
  methods: {
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    handlePageChange(value) {
      this.page = value;
      this.getDataPariwisataFilter();
    },
    getDataPariwisataFilter() {
 
          this.apiGetFilter =
            "api/pariwisata/" +
            this.userData.id_provinsi +
            "?page=" +
            this.page +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&kel=" +
            this.id_kelurahan +
            "&keyword=" +
            this.search + "&kategori=" +
            this.kategori_filter;
     

      getData(this.apiGetFilter)
        .then((response) => {
          console.log("data :", response);
          this.pariwisataDataSearch = response;
          this.pariwisataData = null;
          this.totalPages = response.data_active.last_page;
          console.log(this.pariwisataDataSearch);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value.id;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.id_provinsi = value.provinsi;
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kabkot = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value.id;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.id_kabkot = value.kabupaten_kota;
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value.id;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.id_kecamatan = value.kecamatan;
            this.kelurahans = "";
            this.kelurahans = response;
            this.id_kelurahan = "";

            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.id_kelurahan = value.kelurahan;
      }
    },
    SearchbarChange(value) {
      this.search = value;
      if (this.search == "") {
        this.getDataPariwisata();
      } else {
        this.getDataPariwisataFilter();
      }
      console.log(value);
    },
    getDataPariwisata() {
      const ApiGetParawisata =
        "api/pariwisata/" + this.userData.id_provinsi + "?page=" + this.page;

      getData(ApiGetParawisata)
        .then((response) => {
          console.log("data :", response);
          this.pariwisataData = response;
          this.pariwisataDataSearch = null;
          this.totalPages = response.all_data.last_page;
          console.log(this.pariwisataData);
          // this.totalPages = response.data_active.last_page;
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    getDataPariwisataRekomendasi() {
      const ApiGetParawisataRekomendasi = "api/rekomendasi/wisata";

      getData(ApiGetParawisataRekomendasi)
        .then((response) => {
          console.log("data :", response);
          this.pariwisataDataRekomendasi = response.data;

          console.log(this.pariwisataDataRekomendasi);
          // this.totalPages = response.data_active.last_page;
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
     getDataKategoriPariwisata() {
      const ApiGetKategoriPariwisata = "api/kategori/wisata";

      getData(ApiGetKategoriPariwisata)
        .then((response) => {
          console.log("data kategori:", response);
          this.dataKategoriPariwisata = response.data;

          console.log(this.dataKategoriPariwisata);
          // this.totalPages = response.data_active.last_page;
          // this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    back() {
      this.$router.back();
    },
    detailPariwisata(item) {
      localStorage.data_pariwisata = JSON.stringify(item);
      this.$router.push({
        name: "DetailPariwisata",
        params: {
          dataPariwisata: item,
        },
      });
    },
    tambahPariwisata() {
      this.$router.push({
        name: "TambahPariwisata",
      });
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 599px) {
  .responsive-height {
    height: 300px;
  }
}

@media only screen and (max-width: 599px) {
  .responsive-height {
    height: 200px;
  }
}

#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>