<template>
  <v-card class="overflow-hidden">
    <v-dialog v-model="dialogConfirmation" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Konfirmasi </v-card-title>
        <div v-if="dialogType == 'status'">
          <v-card-text v-if="statusIklan === 0">
            Apakah anda yakin untuk menonaktifkan iklan ini?
          </v-card-text>
          <v-card-text v-else>
            Apakah anda yakin untuk mengaktifkan iklan ini?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              color="green darken-1"
              text
              @click="updateStatus()"
            >
              Ya
            </v-btn>

            <v-btn
              color="red darken-1"
              text
              @click="dialogConfirmation = false"
            >
              Tidak
            </v-btn>
          </v-card-actions>
        </div>
        <div v-else>
          <v-card-text>
            Apakah anda yakin untuk menghapus iklan ini?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              color="green darken-1"
              text
              @click="deleteIklan()"
            >
              Ya
            </v-btn>

            <v-btn
              color="red darken-1"
              text
              @click="dialogConfirmation = false"
            >
              Tidak
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <v-app-bar app color="#F2F1F1" dense>
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <Header text="Iklan" icon="mdi-advertisements" />

    <v-container style="height: 100%; margin-bottom: 100px">
      <div v-if="!adsData.data">
        <v-skeleton-loader
          class="mx-auto mb-10"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>

        <v-skeleton-loader
          class="mx-auto mb-10"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>

        <v-slide-group
          class="mb-10 mt-10"
          v-model="model"
          active-class="success"
          show-arrows
        >
          <v-slide-item v-for="n in 15" :key="n">
            <v-skeleton-loader
              class="mx-auto mr-10"
              max-width="150px"
              type="card"
              style="width: 150px"
            ></v-skeleton-loader>
          </v-slide-item>
        </v-slide-group>
      </div>

      <v-row>
        <v-col cols="6">
          <p style="color: #c82424; font-size: 20px">Iklan Berjalan</p>
        </v-col>
        <v-col cols="6" class="flex justify-end text-right mb-2">
          <TambahIklan />
        </v-col>
      </v-row>
      <div v-if="adsData.data">
        <!-- <v-expansion-panels
          v-show="userData.role == 'superadmin' || userData.role == 'admin'"
        >
          <v-expansion-panel>
            <v-expansion-panel-header> Filter </v-expansion-panel-header>
            <v-expansion-panel-content class="text-center">
              <v-autocomplete
                prepend-icon="mdi-map-marker"
                label="Kategori"
                v-model="kategori_name"
                :items="kategoriData"
                item-text="kategori"
                required
                @change="(event) => selectedKategori(event)"
              ></v-autocomplete>
              <v-btn
                color="red"
                class="button-signup"
                block
                v-on:click="getDataLokerFilter()"
                >Submit</v-btn
              >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari iklan.."
          single-line
          filled
          outlined
          hide-details
          @input="SearchbarChange"
          style="width: 100%"
        >
        </v-text-field>
        <span
          v-if="kategori_name != ''"
          style="color: black; margin: 0; font-size: 16px"
        >
          Filter :
        </span> -->
        <span>
          <v-chip
            v-if="kategori_name != ''"
            class="ma-2"
            close
            @click:close="
              kategori_name = '';
              kategori = '';
            "
          >
            {{ kategori_name }}
          </v-chip>
        </span>

        <v-card
          class="mx-auto mt-5"
          v-for="(item, index) in adsData.data"
          :key="index"
        >
          <v-img class="white--text align-end" height="200px" :src="item.photo">
            <v-card-title> {{ item.nama }}</v-card-title>
          </v-img>

          <v-card-text class="text--primary">
            <p>Link : {{ item.link }}</p>
            <p>
              Status :
              <span v-if="item.status == 1" style="color: green">Aktif</span>
              <span v-else style="color: red"> Tidak Aktif</span>
            </p>
          </v-card-text>

          <v-card-actions>
            <!-- <v-btn color="blue" text class="ml-5 mr-5">
              <v-icon>mdi-pencil</v-icon> Edit
            </v-btn> -->

            <v-btn
              v-if="item.status == 1"
              color="orange"
              text
              class="mr-5"
              @click="
                dialogType = 'status';
                dialogConfirmation = true;
                statusIklan = 0;
                idIklan = item.id_ads;
              "
            >
              Non-Aktifkan
            </v-btn>

            <v-btn
              v-if="item.status == 0"
              color="orange"
              text
              class="mr-5"
              @click="
                dialogType = 'status';
                dialogConfirmation = true;
                statusIklan = 1;
                idIklan = item.id_ads;
              "
            >
              Aktifkan
            </v-btn>
            <v-btn
              color="red"
              text
              @click="
                dialogType = 'delete';
                dialogConfirmation = true;
                idIklan = item.id_ads;
              "
            >
              <v-icon>mdi-delete</v-icon> Hapus
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-container>
    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import TambahIklan from "../components/TambahIklan.vue";
import moment from "moment";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import { deleteData, getData, postData } from "../service/ApiService";
export default {
  name: "LowonganPekerjaan",
  components: {
    Footer,
    Header,
    TambahIklan,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      dialogType: "",
      statusIklan: "",
      idIklan: "",
      model: null,
      kategoriData: null,
      kategori_name: "",
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      id_provinsi: "",
      id_kabkot: "",
      id_kelurahan: "",
      id_kecamatan: "",
      search: "",
      token_jwt: "",
      menu: false,
      msg: "",
      loading: false,
      dialogConfirmation: false,
      page: 1,
      adsData: [],
      startTime: "July 7, 2017 12:03:00",
      endTime: "July 9, 2022 14:55:00",
      userData: "",
      times: [
        { id: 0, text: "Days", time: 1 },
        { id: 1, text: "Hours", time: 1 },
        { id: 2, text: "Minutes", time: 1 },
        { id: 3, text: "Seconds", time: 1 },
      ],
      progress: 100,
      // isActive: false,
      timeinterval: undefined,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.dataNews.created_at
        ? moment(this.dataNews.created_at).format("dddd, d MMM YYYY HH:m A")
        : "";
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
    },
  },
  mounted() {},
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.getDataADS();
    this.getListKategori();
  },
  methods: {
    getListKategori() {
      const apiListKategori = "api/loker/id/kategori";
      getData(apiListKategori)
        .then((response) => {
          console.log("data kategori :", response);
          this.kategoriData = response.data;
          console.log(this.kategoriData);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    evotingFinish() {
      this.$router.push({
        name: "EVotingFinish",
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.getDataLokerFilter();
    },
    getDataLokerFilter() {
      if (this.search != "") {
        if (this.kategori != "" && this.kategori != null) {
          this.apiGetFilter =
            "api/loker?page=" +
            this.page +
            "&kategori=" +
            this.kategori +
            "&keyword=" +
            this.search;
        } else {
          this.apiGetFilter =
            "api/loker" + "?page=" + this.page + "&keyword=" + this.search;
        }
      } else {
        if (this.kategori != "") {
          this.apiGetFilter =
            "api/loker?page=" + this.page + "&kategori=" + this.kategori;
        } else {
          this.apiGetFilter = "api/loker" + "?page=" + this.page;
        }
      }

      getData(this.apiGetFilter)
        .then((response) => {
          console.log("data :", response);
          this.lokerData = response.data;
          this.totalPages = response.data.last_page;
          console.log(this.penduduks);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    selectedKategori(value) {
      this.kategori = value;
      console.log(value);
    },
    SearchbarChange(value) {
      this.search = value;
      this.getDataLokerFilter();
      console.log(value);
    },
    back() {
      this.$router.back();
    },
    getDataVoting() {
      const ApiGetVoting = "api/loker?page=" + this.page;

      getData(ApiGetVoting)
        .then((response) => {
          console.log("data :", response);
          this.lokerData = response.data;

          this.totalPages = response.data.last_page;
          console.log(this.lokerData);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    getDataADS() {
      const ApiGetADS = "api/ads-list-all";

      getData(ApiGetADS)
        .then((response) => {
          console.log("data :", response);
          this.adsData = response;
          console.log(this.adsData);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    updateStatus() {
      console.log("Data :", this.idIklan, this.statusIklan);
      this.loading = true;
      console.log(this.form);
      let formData = new FormData();

      formData.append("status", this.statusIklan);

      console.log(formData.values());
      const data = {
        api: "api/status-ads/" + this.idIklan,
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log(response);
          this.dialogConfirmation = false;
          this.loading = false;
          this.$router.go(0);
          // this.$router.reload();
          // this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.nik) {
              this.msg_error = error.response.data.nik[0];
              this.snackbar_error = true;
            } else if (error.response.data.email) {
              this.msg_error = error.response.data.email[0];
              this.snackbar_errror = true;
            } else {
              this.msg_error = error.response.data;
              this.snackbar_errror = true;
            }
          }
        });
    },
    deleteIklan() {
      console.log("Data :", this.idIklan, this.dialogType);
      this.loading = true;
      console.log(this.form);
      let formData = new FormData();

      console.log(formData.values());
      const data = {
        api: "api/delete-ads/" + this.idIklan,
      };
      deleteData(data)
        .then((response) => {
          console.log(response);
          this.dialogConfirmation = false;
          this.loading = false;
          this.$router.go(0);
          // this.$router.reload();
          // this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.nik) {
              this.msg_error = error.response.data.nik[0];
              this.snackbar_error = true;
            } else if (error.response.data.email) {
              this.msg_error = error.response.data.email[0];
              this.snackbar_errror = true;
            } else {
              this.msg_error = error.response.data;
              this.snackbar_errror = true;
            }
          }
        });
    },
    detailLoker(item) {
      this.$router.push({
        name: "DetailLoker",
        params: {
          dataLoker: item,
        },
      });
    },
    hasilVoting(item) {
      this.$router.push({
        name: "HasilVoting",
        params: {
          items: item.id_evoting,
        },
      });
    },
    tambahloker() {
      this.$router.push({
        name: "TambahLoker",
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY HH:SS");
      }
    },
  },
};
</script>

<style scoped>
.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}
.text-sub {
  color: #c82424;
}
.truncate {
  line-height: 1.5em;
  height: 3em; /* height is 2x line-height, so two lines will display */
  overflow: hidden; /* prevents extra lines from being visible */
}
</style>