<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil membuat laporan
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-card>
      <v-app-bar
        color="#F2F1F1"
        height="80px"
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-btn color="black" icon @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-flex class="mx-4">Buat Laporan </v-flex>
      </v-app-bar>
      <v-card-text>
        <v-container>
          <form
            id="myForm"
            name="myForm"
            v-on:submit.prevent="submitForm"
            enctype="multipart/form-data"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-pencil-box-outline"
                  label="Judul Laporan"
                  required
                  v-model="form.judul"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <p>
                  <v-icon style="color: #000">mdi-pencil-box-outline</v-icon>
                  Keterangan
                </p>
                <v-textarea
                  placeholder="Tulis keterangan laporan anda"
                  v-model="form.keterangan"
                  hint="Keterangan Laporan"
                  required
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  required
                  label="Foto-foto"
                  v-model="form.photo"
                  truncate-length="15"
                  small-chips
                  show-size
                  multiple
                  clearable
                ></v-file-input>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  prepend-icon="mdi-map-marker"
                  v-model="form.kategori"
                  :items="kategoris"
                  label="Kategori"
                  :rules="[rules.required]"
                  item-value="kategori"
                  item-text="kategori"
                  required
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" style="padding: 0">
                <v-subheader>Lokasi</v-subheader>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  v-model="form.alamat_lengkap"
                  label="Alamat Lengkap Lokasi"
                  hint="Alamat Lengkap Lokasi"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  prepend-icon="mdi-map-marker"
                  v-model="form.provinsi"
                  :items="provinsis"
                  label="Provinsi"
                  :rules="[rules.required]"
                  item-text="provinsi"
                  item-value="id"
                  required
                  @change="(event) => selectedProvinsi(event)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  prepend-icon="mdi-map-marker"
                  v-model="form.kabupaten"
                  :rules="[rules.required]"
                  :items="kabupatens"
                  label="Kabupaten/ Kota"
                  item-text="kabupaten_kota"
                  :disabled="kabupatens == []"
                  item-value="id"
                  required
                  @change="(event) => selectedKabupaten(event)"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <div
              style="text-align: center; width: 100%; height: 400px"
              v-if="position.lat || userLocation.lat || defaultLocation.lat"
            >
              <l-map
                @dblclick="onMapClick($event, myobj)"
                ref="map"
                :zoom="zoom"
                :center="[
                  position.lat || userLocation.lat || defaultLocation.lat,
                  position.lng || userLocation.lng || defaultLocation.lng,
                ]"
              >
                <l-tile-layer
                  :url="tileProvider.url"
                  :attribution="tileProvider.attribution"
                />
                <l-geosearch :options="geoSearchOptions"></l-geosearch>
                <l-marker
                  v-if="position.lat && position.lng"
                  visible
                  draggable
                  :icon="icon"
                  :lat-lng.sync="position"
                  @dragstart="dragging = true"
                  @dragend="dragging = false"
                >
                  <l-tooltip
                    :content="tooltipContent"
                    :options="{ permanent: true }"
                  />
                </l-marker>
              </l-map>
            </div>
            <div class="d-flex justify-end">
              <v-btn
                color="red"
                class="button-signup mt-10"
                @click="sendData()"
                :loading="loading"
                >Kirim</v-btn
              >
            </div>
          </form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getData, postData } from "../service/ApiService";

import { LMap, LMarker, LTileLayer, LTooltip } from "vue2-leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import LGeosearch from "vue2-leaflet-geosearch";
import { icon } from "leaflet";

export default {
  name: "TambahMyLapor",
  components: { LMap, LTileLayer, LMarker, LTooltip, LGeosearch },
  beforeCreate: function () {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      if (this.token_jwt == null) {
        this.$router.push("/");
      }
    }
  },
  data() {
    return {
      value: {
        type: 1,
        required: true,
      },
      defaultLocation: {
        type: {},
        default: () => ({
          lat: 8.9806,
          lng: 38.7578,
        }),
      },
      loading: false,
      geoSearchOptions: {
        provider: new OpenStreetMapProvider(),
        showMarker: false,
        autoClose: true,
      },
      userLocation: {},
      icon: icon({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      }),
      position: {},
      address: "",
      tileProvider: {
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      },
      zoom: 18,
      dragging: false,
      rules: {
        required: (value) => !!value || "Wajib di isi.",
      },
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        toolbarGroups: [
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "clipboard", groups: ["clipboard", "undo"] },
          {
            name: "editing",
            groups: ["find", "selection", "spellchecker", "editing"],
          },
          { name: "forms", groups: ["forms"] },
          "/",
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
          },
          { name: "links", groups: ["links"] },
          { name: "insert", groups: ["insert"] },
          "/",
          { name: "styles", groups: ["styles"] },
          { name: "colors", groups: ["colors"] },
          { name: "tools", groups: ["tools"] },
          { name: "others", groups: ["others"] },
          { name: "about", groups: ["about"] },
        ],
      },
      provinsis: [],
      kabupatens: [],
      kelurahans: [],
      kecamatans: [],
      kategoris: [],
      msg_error: "",
      form: {
        judul: null,
        keterangan: null,
        alamat_lengkap: null,
        kategori: null,
        lat: null,
        long: null,
        photo: null,
        provinsi: null,
        kabupaten: null,
      },
      token_jwt: "",
      dialog: false,
      id_lapor: null,
      menu: false,
      msg: "",
      photo_lainnya: null,
      judul: "",
      id_author: "",
      snackbar: false,
      snackbar_error: false,
    };
  },
  mounted() {
    this.getUserPosition();
    this.$refs.map.mapObject.on("geosearch/showlocation", this.onSearch);
  },
  watch: {
    position: {
      deep: true,
      async handler(value) {
        this.address = await this.getAddress();
        this.$emit("input", { position: value, address: this.address });
      },
    },
  },
  computed: {
    tooltipContent() {
      if (this.dragging) return "...";
      if (this.loading) return "Loading...";
      return `<strong>${this.address.replace(
        ",",
        "<br/>"
      )}</strong> <hr/><strong>lat:</strong> ${
        this.position.lat
      }<br/> <strong>lng:</strong> ${this.position.lng}`;
    },
  },
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.getListProvinsi();
    this.getListKabkot();
    this.getKategori();
  },
  methods: {
    async sendData() {
      this.loading = true;
      console.log(this.form);
      let formData = new FormData();
      formData.append("nik_lapor", this.userData.nik);
      formData.append("judul", this.form.judul);
      formData.append("keterangan", this.form.keterangan);
      formData.append("alamat_lengkap", this.form.alamat_lengkap);
      formData.append("kategori", this.form.kategori);
      formData.append("lat", this.position.lat);
      formData.append("long", this.position.lng);
      formData.append("provinsi", this.form.provinsi);
      formData.append("kabupaten", this.form.kabupaten);
      this.form.photo.forEach((item, index) => {
        console.log(item, index);
        formData.append("photo[" + index + "][foto]", item);
      });
      console.log(formData.values());
      const data = {
        api: "api/elapor",
        body: formData,
      };

      console.log("form :", this.form, "========", "photo", this.photo_lainnya);
      await postData(data)
        .then((response) => {
          console.log(response);
          this.id_lapor = response.data.id;

          this.snackbar = true;
          setTimeout(() => {
            this.$router.push("/mylapor");
            this.loading = false;
          }, 2000);
        })
        .catch((error) => {
          console.log("finish get 1");
          console.log(error);
          console.log(error.response);
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.nik) {
              this.msg_error = error.response.data.nik[0];
              this.snackbar_error = true;
            } else if (error.response.data.email) {
              this.msg_error = error.response.data.email[0];
              this.snackbar_error = true;
            } else {
              this.msg_error = error.response.data;
              this.snackbar_error = true;
            }
          }
        });
    },
    getListKabkot() {
      console.log("User Data : ", this.userData);
      const apiListKabkot = "api/kabkot?keyword=" + this.userData.id_provinsi;

      getData(apiListKabkot)
        .then((response) => {
          console.log("data kabkot :", response);
          this.kabupatens = response;
          console.log(this.kabupatens);
          this.form.kabupaten = this.userData.id_kabkot;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },

    getKategori() {
      const apiListKategori = "api/elapor-list-kategori";

      getData(apiListKategori)
        .then((response) => {
          console.log("data response kategori :", response);

          this.kategoris = response.data;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          this.form.provinsi = this.userData.id_provinsi;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.form.provinsi = value;
            this.form.kabupaten = [];
            this.kabupatens = [];

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      this.form.kabupaten = value;
    },
    deletePic() {
      this.url = "";
      this.$refs.file.value = "";
    },
    onFileChange(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.photo = e.target.files[0];
      console.log(this.photo);
    },
    back() {
      this.$router.back();
    },
    async getAddress() {
      this.loading = true;
      let address = "Unresolved address";
      try {
        const { lat, lng } = this.position;
        const result = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
        );
        if (result.status === 200) {
          const body = await result.json();
          address = body.display_name;
        }
      } catch (e) {
        console.error("Reverse Geocode Error->", e);
      }
      this.loading = false;
      console.log(address);
      return address;
    },
    async onMapClick(value) {
      // place the marker on the clicked spot
      console.log(value.latlng);
      this.position = value.latlng;
    },
    onSearch(value) {
      const loc = value.location;
      this.position = { lat: loc.y, lng: loc.x };
      console.log(this.position);
    },
    async getUserPosition() {
      if (navigator.geolocation) {
        // get GPS position
        navigator.geolocation.getCurrentPosition((pos) => {
          // set the user location
          this.userLocation = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          };
          console.log(this.userLocation);
          this.position = this.userLocation;
        });
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>
