<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil memperbaruhi laporan
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-btn color="red" class="button-signup" @click="dialog = true"
      >Detail</v-btn
    >
    <v-dialog v-if="dialog" v-model="dialog" max-width="90%">
      <v-card>
        <v-app-bar
          color="#F2F1F1"
          height="90%"
          elevate-on-scroll
          scroll-target="#scrolling-techniques-7"
        >
          <v-btn
            color="black"
            icon
            v-bind="attrs"
            v-on="on"
            @click="dialog = false"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <p
            v-if="form.status == 'menunggu'"
            class="text-sub text-end"
            style="padding: 0 5px; margin: 0 5px; color: #ad9c9c"
          >
            Menunggu...
            <v-icon style="color: #ad9c9c">mdi-clock-outline</v-icon>
          </p>
          <p
            v-if="form.status == 'proses'"
            class="text-sub text-end"
            style="padding: 0 5px; margin: 0 5px; color: orange"
          >
            Diproses...
            <v-icon style="color: orange">mdi-progress-clock</v-icon>
          </p>
          <p
            v-if="form.status == 'selesai'"
            class="text-sub text-end"
            style="padding: 0 5px; margin: 0 5px; color: #4ff54c"
          >
            Selesai... <v-icon style="color: #4ff54c">mdi-check</v-icon>
          </p>
          <v-tooltip bottom> </v-tooltip>
        </v-app-bar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <p style="margin: 0 0 40px 0; font-size: 18px" class="mb-5">
                  terlaporkan : {{ form.tanggal }}
                </p>
              </v-col>
              <v-col cols="6" class="text-end">
                <v-dialog
                  v-if="form.status == 'menunggu'"
                  transition="dialog-top-transition"
                  max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <!-- <div class="text-end"> -->
                    <v-btn
                      class="white--text"
                      color="red darken-3"
                      v-bind="attrs"
                      v-on="on"
                      >Proses</v-btn
                    >
                    <!-- </div> -->
                  </template>
                  <template>
                    <v-card>
                      <v-card-text>
                        <div class="text-h5 text-center pa-12">
                          Apakah anda yakin ingin melakukan proses laporan
                          tersebut
                        </div>
                      </v-card-text>
                      <v-card-actions class="justify-center">
                        <form
                          id="myForm"
                          name="myForm"
                          v-on:submit.prevent="submitForm"
                          enctype="multipart/form-data"
                        >
                          <v-btn text @click="sendData()" color="red darken-3"
                            >Proses</v-btn
                          >
                        </form>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>

                <v-dialog
                  v-if="form.status == 'proses'"
                  transition="dialog-top-transition"
                  max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <!-- <div class="text-end"> -->
                    <v-btn
                      class="white--text"
                      color="green darken-3"
                      v-bind="attrs"
                      v-on="on"
                      >Selesai</v-btn
                    >
                    <!-- </div> -->
                  </template>
                  <template>
                    <v-card>
                      <v-card-text>
                        <div class="text-h5 text-center pa-12">
                          Apakah anda yakin telah menyelesaikan laporan tersebut
                        </div>
                      </v-card-text>
                      <v-card-actions class="justify-center">
                        <form
                          id="myForm"
                          name="myForm"
                          v-on:submit.prevent="submitForm"
                          enctype="multipart/form-data"
                        >
                          <v-btn
                            text
                            @click="sendDatafinish()"
                            color="green darken-3"
                            >Selesai</v-btn
                          >
                        </form>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>

              <v-col cols="12">
                <h2 class="mb-5 text-center">
                  {{ form.judul }}
                </h2>
              </v-col>

              <v-col cols="12">
                <h4 class="mb-5 text-center">Kategori: {{ form.kategori }}</h4>
              </v-col>

              <v-col cols="12">
                <p style="font-size: 12px" class="mb-5 text-end">
                  Nik : {{ form.nik_lapor }}
                </p>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  label="Alamat"
                  v-model="form.alamat_lengkap"
                  hint="Alamat"
                  filled
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  label="Provinsi"
                  v-model="form.provinsi"
                  hint="Provinsi"
                  filled
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  label="Kabupaten"
                  v-model="form.kabupaten"
                  hint="Kabupaten"
                  filled
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  solo
                  name="input-7-4"
                  label="Keterangan"
                  :value="form.keterangan"
                  readonly
                ></v-textarea>
              </v-col>

              <v-slide-group class="mb-10" v-if="dataLaporPhoto.data">
                <v-slide-item
                  v-for="item in dataLaporPhoto.data"
                  :key="item"
                  class="border-0"
                >
                  <v-card
                    height="200"
                    width="250"
                    @click="zoomImage(item.foto)"
                    class="border-0 ma-1"
                  >
                    <v-img
                      :src="item.foto"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="200px"
                    >
                    </v-img>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-row>
            <v-card>
              <Map :lat="form.lat" :lng="form.long" :lokasi="form.judul" />
            </v-card>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import {
  activatedData,
  deleteData,
  getData,
  postData,
} from "../service/ApiService";
import Map from "../components/Map.vue";

export default {
  name: "DetailELapor",
  props: {
    dataItem: [],
    status: String,
  },
  components: {
    Map,
  },
  beforeCreate: function () {
    this.showDetail = true;
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      if (this.token_jwt == null) {
        this.$router.push("/");
      }
    }
  },
  data() {
    return {
      items: ["menunggu", "selesai"],
      values: ["foo", "bar"],
      value: null,
      rand: 1,
      showDetail: false,
      dataLaporPhoto: null,
      show1: false,
      rules: {
        required: (value) => !!value || "Wajib di isi.",
        min: (v) => v.length >= 8 || "Password minimal 8 digit",
        min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
        emailMatch: () => `The email and password you entered don't match`,
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
        return "Wajib Berupa Angka.";
      },
      snackbar: false,
      snackbar_error: false,
      goldars: [
        "A",
        "B",
        "AB",
        "O",
        "A+",
        "A-",
        "B+",
        "B-",
        "AB+",
        "AB-",
        "O+",
        "O-",
        "NA",
      ],
      agamas: [
        "islam",
        "kristen",
        "kathloik",
        "budha",
        "hindu",
        "konghucu",
        "lainnya",
      ],
      status_pernikahans: ["Belum Kawin", "Kawin", "Cerai Hidup", "Cerai Mati"],
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      form: {
        id_role: 4,
        nama: "",
        nik: "",
        id_kk: "",
        email: "",
        password: "",
        status: "",
        status_pernikahan: "",
        nohp: "",
        tgl_lahir: "",
        tempatlahir: "",
        jk: "",
        suku: "",
        agama: "",
        goldarah: "",
        alamat: "",
        alamat_domisili: "",
        id_provinsi: "",
        id_kabkot: "",
        id_kelurahan: "",
        id_kecamatan: "",
        id_rt: "",
        id_rw: "",
        photo: "",
      },
      id_rt: "",
      id_rw: "",
      url: "",
      token_jwt: "",
      dialog: false,
      dialogConfirmation: false,
      activePicker: null,
      menu: false,
      msg_error: null,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.form.tgl_lahir
        ? moment(this.form.tgl_lahir).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    if (this.dialog) {
      self.rand = Date.now();
    }

    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    if (this.snackbar) {
      this.hide_alert();
    }

    if (this.snackbar_error) {
      this.hide_alert_error();
    }
  },
  created() {
    this.form = this.dataItem;
    this.getDataLapor();

    console.log("data item :", this.form.id_lapor);
  },
  methods: {
    async sendData() {
      this.loading = true;
      console.log(this.form);
      let formData = new FormData();
      formData.append("status", "proses");

      console.log(formData.values());
      const data = {
        api: "api/elapor-status/" + this.form.id_lapor,
        body: formData,
      };
      await postData(data)
        .then((response) => {
          console.log(response);
          this.id_lapor = response.data.id;
          this.dialog = false;
          this.loading = false;
          this.snackbar = true;
          this.$router.push("/e-lapor-kategori");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async sendDatafinish() {
      this.loading = true;
      console.log(this.form);
      let formData = new FormData();
      formData.append("status", "selesai");

      console.log(formData.values());
      const data = {
        api: "api/elapor-status/" + this.form.id_lapor,
        body: formData,
      };
      await postData(data)
        .then((response) => {
          console.log(response);
          this.id_lapor = response.data.id;
          this.dialog = false;
          this.loading = false;
          this.snackbar = true;
          this.$router.push("/e-lapor-kategori");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataLapor() {
      console.log("consol data :", this.items);
      const ApiGetData = "api/photo/elapor/" + this.form.id_lapor;

      getData(ApiGetData)
        .then((response) => {
          console.log("respon data :", ApiGetData);

          this.dataLaporPhoto = response;
          console.log(this.dataLaporPhoto);
        })
        .catch((error) => {
          console.log("pesan error :", error);
          this.msg = true;
        });
    },
    hide_alert: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    hide_alert_error: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar_error = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    editDataPenduduk() {
      this.$router.push({
        name: "EditDataPenduduk",
        params: {
          items: this.form,
        },
      });
    },
    aktifUser() {
      if (this.status === "aktif") {
        const data = {
          api: "api/profile/",
          body: this.dataItem.nik,
        };

        deleteData(data)
          .then((response) => {
            console.log(response);
            this.$router.go();
            console.log(this.token_jwt);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              this.msg = error.response.data;
            }
          });
      } else if (this.status === "non-aktif") {
        const data = {
          api: "api/profile/active/",
          body: this.dataItem.nik,
        };
        activatedData(data)
          .then((response) => {
            console.log(response);
            this.$router.go();
            console.log(this.token_jwt);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              this.msg = error.response.data;
            }
          });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>
