<template>

  <div>

    <v-dialog v-model="dialogConfirmation" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Konfirmasi </v-card-title>

        <v-card-text> Apakah anda yakin ingin keluar? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="logout()"> Ya </v-btn>

          <v-btn color="red darken-1" text @click="dialogConfirmation = false">
            Tidak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProfile" v-if="userData != null" max-width="600px">
      <v-card>
        <v-app-bar app color="#F2F1F1" height="80px">
          <v-btn color="black" icon @click="dialogProfile = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-container>
          <v-row>
            <v-col cols="12" class="d-flex justify-center text-center">
              <v-btn icon style="width: 150px; height: 150px">
                <v-avatar color="brown" size="150">
                  <img v-if="userData.photo != ''" :src="userData.photo" />
                  <span v-if="userData.photo == ''" class="white--text text-h5" style="text-transuserdata: none">
                    <v-icon>mdi-camera</v-icon>
                  </span>
                </v-avatar>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-book-account" label="NIK" filled readonly v-model="userData.nik"
                hint="Nomor Induk Keluarga (16 Digit)">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-book-open-outline" label="No KK" v-model="userData.id_kk"
                hint="Nomor Kartu Keluarga (16 Digit)" filled readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-account" label="Nama Lengkap" filled readonly v-model="userData.nama"
                hint="Nama Lengkap Sesuai KTP">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-email" label="Email" filled readonly v-model="userData.email"
                hint="Email Aktif">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-account-group" label="Status Di KK" v-model="userData.status"
                hint="Status Sesuai Kartu Keluarga" filled readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-heart" v-model="userData.status_pernikahan" label="Status Pernikahan"
                hint="Status Pernikahan Sesuai Di KTP" filled readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-phone" type="tel" v-model="userData.nohp" label="Nomor Telepon"
                hint="Nomor Telepon Aktif" filled readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Tanggal Lahir" prepend-icon="mdi-calendar" readonly filled
                :value="computedDateFormatedMomentjs"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-map-marker" label="Tempat Lahir" v-model="userData.tempatlahir"
                hint="ex:Balikpapan" filled readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-gender-male-female" label="Jenis kelamin" v-model="userData.jk" filled
                readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-account-group" label="Suku" v-model="userData.suku" hint="Suku" filled
                readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-account-group" v-model="userData.agama" label="Agama"
                hint="Agama Sesuai KTP" filled readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-water" v-model="userData.goldarah" label="Golongan Darah"
                hint="Golongan Darah" filled readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-map-marker" label="Alamat KTP" v-model="userData.alamat"
                hint="Alamat Sesuai KTP" filled readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-map-marker" label="Alamat Domisili" v-model="userData.alamat_domisili"
                hint="Alamat Tempat Tinggal Saat Ini" filled readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-map-marker" label="RT" v-model="userData.id_rt"
                hint="RT Sesuai Tempat Tinggal Saat Ini" filled readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-map-marker" label="RW" v-model="userData.id_rw"
                hint="RW Sesuai Tempat Tinggal Saat Ini" filled readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-map-marker" v-model="userData.provinsi" label="Provinsi"
                item-text="provinsi" filled readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-map-marker" v-model="userData.kabupaten_kota" label="Kabupaten/ Kota"
                item-text="kabupaten_kota" filled readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-map-marker" v-model="userData.kecamatan" label="Kecamatan"
                item-text="kecamatan" filled readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field prepend-icon="mdi-map-marker" v-model="userData.kelurahan" label="Kelurahan"
                item-text="kelurahan" filled readonly></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-carousel v-if="ads.data" cycle height="auto" hide-delimiter-background show-arrows-on-hover>
      <v-carousel-item v-for="(item, index) in ads.data" :key="index" @click="detailAds(item)" style="cursor: pointer">
        <img :src="item.photo" width="100%" height="auto" alt="" />
      </v-carousel-item>
    </v-carousel>
    <v-container style="margin-bottom: 100px">

      <!-- <p class="text-center" style="color: #ff8540; font-size: 25px">
        Layanan Kami
      </p>
      <p class="text-left" style="color: #ff8540; font-size: 25px">Kategori</p> -->
      <v-row class="d-flex mt-2">
        <FitureCardVue img="false" icon="mdi-earth" link="/pariwisata" text="Pariwisata" />
        <FitureCardVue img="false" icon="mdi-vote" link="/e-voting" text="e-Voting" />
        <FitureCardVue img="false" icon="mdi-chart-box-outline" link="/e-survey" text="e-Survey" />
        <FitureCardVue img="false" icon="mdi-alert-octagon-outline" link="/e-lapor-kategori" text="e-Lapor"
          v-show="userData.role != 'warga'" />
        <FitureCardVue img="false" icon="mdi-alert-octagon-outline" link="/mylapor" text="e-Lapor"
          v-show="userData.role == 'warga'" />
        <FitureCardVue img="false" icon="mdi-hand-heart-outline" link="/e-bansos" text="e-Bansos" />
        <FitureCardVue img="false" icon="mdi-grave-stone" link="/pemakaman" text="Pemakaman" />
        <FitureCardVue img="false" icon="mdi-briefcase" link="/lowongan-pekerjaan" text="Lowongan Pekerjaan" />
        <FitureCardVue img="false" icon="mdi-newspaper" link="/berita" text="Berita" />
        <FitureCardVue img="false" icon="mdi-advertisements" link="/iklan" text="Iklan" v-show="userData.role != 'warga'" />
        <FitureCardVue img="peternakan.png" icon="false" link="/smart-farm" text="Peternakan" />
        <FitureCardVue img="UMKM.png" icon="false" link="/smart-farm" text="UMKM" />
        <FitureCardVue img="Perikanan.png" icon="false" link="/smart-farm" text="Perikanan" />
        <FitureCardVue img="Pertanian.png" icon="false" link="/smart-farm" text="Pertanian" />
        <FitureCardVue img="false" icon="mdi-broadcast" link="/broadcast" text="Broadcast" />
      </v-row>

      <p class="text-left" style="color: #ff8540; font-size: 25px; margin-top: 20px">
        Berita Terbaru
      </p>
      <div v-if="news != ''">
        <v-list-item class="mb-5" v-for="(item, index) in news.data" :key="index" style="align-items:unset">
          <v-card class="mx-auto mr-5" max-width="100" max-height="100" @click="detailNews(item)">
            <v-img :src="item.photo" class="white--text" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="100" width="100">
            </v-img>
          </v-card>


          <v-list-item-content style="align-items:unset;align-self:unset;display: unset;" @click="detailNews(item)">
            <v-list-item-list class="truncate">{{ item.judul }}</v-list-item-list>
            <v-list-item-subtitle>{{ format_date(item.created_at) }}</v-list-item-subtitle>
          </v-list-item-content>

        </v-list-item>
    
      </div>

    </v-container>

    <Footer />
  </div>
</template>

<script>
  import FitureCardVue from "../components/FitureCard.vue";
  import Footer from "../components/Footer.vue";
  import {
    getData
  } from "../service/ApiService";
  import moment from "moment";

  // import TimeAgo from "javascript-time-ago";
  // import id from "javascript-time-ago/locale/id.json";

  // TimeAgo.addDefaultLocale(id);

  // import Tombol from '../components/Tombol.vue';
  // @ is an alias to /src
  export default {
    props: ["id"],
    data() {
      return {
        activeTab: this.$route.path,
        tabs: [{
            id: 1,
            name: "Beranda",
            route: `/home/beranda`,
            icon: "mdi-home"
          },
          {
            id: 2,
            name: "RT",
            route: `/home/rt`,
            icon: "mdi-account-tie"
          },
          {
            id: 3,
            name: "Penduduk",
            route: `/home/penduduk`,
            icon: "mdi-account-group",
          },
        ],
        dialogConfirmation: false,
        userData: "",
        token_jwt: "",
        loading: false,
        msg: null,
        news: "",
        totalPages: "",
        ads: null,
        dialogProfile: false,
        imgs: [{
            src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
          },
          {
            src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
          },
          {
            src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
          },
          {
            src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
          },
          {
            src: require("../assets/slide-1.png"),
          },
        ],
      };
    },
    name: "Beranda",
    components: {
      FitureCardVue,
      Footer,
    },
    beforeCreate: function () {
      console.log('beranda')
   if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
    },
    created() {
      console.log(this.$route.path);
      this.getNews();
      this.getAds();
    },
    mounted() {
      if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
      }
      if (localStorage.user_data) {
        this.userData = JSON.parse(localStorage.user_data).data[0];
        console.log("data user: ", this.userData);
      }
    },
    computed: {
      computedDateFormatedMomentjs() {
        return this.userData.tgl_lahir ?
          moment(this.userData.tgl_lahir).format("DD, MMMM YYYY") :
          "";
      },
    },
    methods: {
      format_date(value) {
        if (value) {
          return moment(String(value)).format("dddd, D MMM YYYY HH:m A");
        }
      },
      // userDataat_news(value){
      //    if (value) {
      //      return value ? timeAgo.userDataat(Date.now() - 60 * 1000);
      //     }
      detailAds(data) {
        window.open(data.link, "_blank");
      },
      // },
      detailNews(data) {
        localStorage.data_news = JSON.stringify(data);
        this.$router.push({
          name: "DetailNews",
          params: {
            dataNews: data,
          },
        });
      },
      changeTab() {
        this.activeTab = this.$route.path;
        console.log(this.activeTab);
      },
      getAds() {
        const ApiGetAds = "api/ads-list";

        getData(ApiGetAds)
          .then((response) => {
            console.log("data :", response);
            this.ads = response;
            console.log(this.ads);
            this.loading = false;
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      },
      getNews() {
        const ApiGetNews = "api/news" + "?page=" + this.page;

        getData(ApiGetNews)
          .then((response) => {
            console.log("data :", response);
            this.news = response.data;
            this.totalPages = response.last_page;
            console.log(this.penduduks);
            this.loading = false;
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      },
      logout() {
        this.$session.destroy();
        this.$router.push("/");
        console.log(localStorage.token_jwt);
        localStorage.token_jwt = null;
        console.log(localStorage.token_jwt);
      },
    },
  };
</script>

<style scoped>
  .text-title {
    text-decoration: none;
    color: black;
    margin: 0 22px 0 0;
    font-size: 18px;
    font-weight: 500;
  }

  .img-logo {
    height: 80%;
    width: auto;
    margin-right: 20px;
  }

  .truncate {

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .truncate-content {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
</style>