var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"margin-bottom":"100px"},attrs:{"height":"100%"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"flex mb-2",attrs:{"cols":"6"}},[_c('p',{staticClass:"text-title"},[_vm._v(" My Lapor "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v("MyLapor merupakan aplikasi lapor segala "),_c('br'),_vm._v(" keperluan pembangunan maupun lingkungan disekitarmu.")])])],1)]),_c('v-col',{staticClass:"text-end mb-3",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"button-signup",attrs:{"color":"red"},on:{"click":function($event){return _vm.$router.push('/tambah-laporan')}}},[_vm._v("Buat Laporan")])],1)],1),_c('v-card-title',[_c('v-text-field',{staticStyle:{"width":"100%"},attrs:{"dark":"","append-icon":"mdi-magnify","label":"Cari Nik, Nama, KK, dsb..","single-line":"","filled":"","outlined":"","hide-details":""},on:{"input":_vm.SearchbarChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"table-penduduk",attrs:{"disable-pagination":"","hide-default-footer":true,"headers":_vm.headers,"items":_vm.penduduks,"total-items":_vm.totalNumberOfItems},on:{"update:pagination":_vm.paginate},scopedSlots:_vm._u([{key:"item.lapor[0].keterangan",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"truncate"},[_vm._v(" "+_vm._s(item.keterangan)+" ")])]}},{key:"item.lapor[0].status",fn:function(ref){
var item = ref.item;
return [(item.lapor[0].status == 'menunggu')?_c('p',{staticClass:"text-sub",staticStyle:{"color":"#ad9c9c"}},[_vm._v(" Menunggu... "),_c('v-icon',{staticStyle:{"color":"#ad9c9c"}},[_vm._v("mdi-clock-outline")])],1):_vm._e(),(item.lapor[0].status == 'proses')?_c('p',{staticClass:"text-sub",staticStyle:{"color":"orange"}},[_vm._v(" Diproses... "),_c('v-icon',{staticStyle:{"color":"orange"}},[_vm._v("mdi-progress-clock")])],1):_vm._e(),(item.lapor[0].status == 'selesai')?_c('p',{staticClass:"text-sub",staticStyle:{"color":"#4ff54c"}},[_vm._v(" Selesai... "),_c('v-icon',{staticStyle:{"color":"#4ff54c"}},[_vm._v("mdi-check")])],1):_vm._e()]}},{key:"item.aksi",fn:function(ref){
var item = ref.item;
return [_c('DetailDataMyLapor',{attrs:{"dataItem":item,"status":"lansia"}})]}}])}),_c('v-pagination',{attrs:{"loading":_vm.loading,"length":_vm.totalPages,"total-visible":"7","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left"},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }