<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      app
      color="#F2F1F1"
      dense
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-btn color="black" icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>
    <v-container style="height: 100%; margin-bottom: 100px">
      <form
        id="myForm"
        name="myForm"
        v-on:submit.prevent="submitForm"
        enctype="multipart/form-data"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              prepend-icon="mdi-map-marker"
              label="Nama Wisata"
              required
              v-model="form.namawisata"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              v-model="form.id_provinsi"
              :items="provinsis"
              label="Provinsi"
              :rules="[rules.required]"
              item-text="provinsi"
              item-value="id"
              required
              @change="(event) => selectedProvinsi(event)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              v-model="form.id_kabkot"
              :rules="[rules.required]"
              :items="kabupatens"
              label="Kabupaten/ Kota"
              item-text="kabupaten_kota"
              item-value="id"
              :disabled="kabupatens == ''"
              required
              @change="(event) => selectedKabupaten(event)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              v-model="form.id_kecamatan"
              :items="kecamatans"
              label="Kecamatan"
              :rules="[rules.required]"
              item-text="kecamatan"
              item-value="id"
              :disabled="kecamatans == ''"
              required
              @change="(event) => selectedKecamatan(event)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              prepend-icon="mdi-map-marker"
              v-model="form.id_kelurahan"
              :items="kelurahans"
              :rules="[rules.required]"
              label="Kelurahan"
              :disabled="kelurahans == ''"
              item-text="kelurahan"
              item-value="id"
              required
              @change="(event) => selectedKelurahan(event)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-file-input
              required
              label="Foto Poster"
              v-model="form.photo_poster"
              truncate-length="15"
              show-size
              clearable
            ></v-file-input>
          </v-col>
          <v-col cols="12">
            <v-file-input
              required
              label="Foto Lainnya"
              v-model="photo_lainnya"
              truncate-length="15"
              small-chips
              show-size
              multiple
              clearable
            ></v-file-input>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-subheader>Deskripsi</v-subheader>
          </v-col>
          <v-col cols="12">
            <ckeditor
              :editor="editor"
              v-model="form.desk"
              :config="editorConfig"
            ></ckeditor>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-subheader>Lokasi</v-subheader>
          </v-col>
          <!-- <v-col cols="12">
            <v-text-field
              prepend-icon="mdi-map-marker"
              v-model="form.lat"
              label="Latitude"
              hint="Latitude"
              required
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              prepend-icon="mdi-map-marker"
              v-model="form.lng"
              label="Langitude"
              hint="Langitude"
              required
            >
            </v-text-field>
          </v-col> -->
        </v-row>
      </form>
      <div
        style="width: 100%; height: 500px"
        v-if="position.lat || userLocation.lat || defaultLocation.lat"
      >
        <l-map
          @dblclick="onMapClick($event, myobj)"
          ref="map"
          :zoom="zoom"
          :center="[
            position.lat || userLocation.lat || defaultLocation.lat,
            position.lng || userLocation.lng || defaultLocation.lng,
          ]"
        >
          <l-tile-layer
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
          />
          <l-geosearch :options="geoSearchOptions"></l-geosearch>
          <l-marker
            v-if="position.lat && position.lng"
            visible
            draggable
            :icon="icon"
            :lat-lng.sync="position"
            @dragstart="dragging = true"
            @dragend="dragging = false"
          >
            <l-tooltip
              :content="tooltipContent"
              :options="{ permanent: true }"
            />
          </l-marker>
        </l-map>
      </div>
      <div class="d-flex justify-end">
        <v-btn
          color="red"
          type="submit"
          class="button-signup mt-10"
          @click="sendData()"
          :loading="loading"
          >Kirim</v-btn
        >
      </div>
    </v-container>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil membuat voting.
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="red" dark>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          Mohon tunggu..
          <v-progress-circular
            indeterminate
            color="white"
            class="ml-6"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Footer />
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import Footer from "../components/Footer.vue";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getData, postData } from "../service/ApiService";

import { LMap, LMarker, LTileLayer, LTooltip } from "vue2-leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import LGeosearch from "vue2-leaflet-geosearch";
import { icon } from "leaflet";

export default {
  name: "TambahPariwisata",
  components: { Footer, LMap, LTileLayer, LMarker, LTooltip, LGeosearch },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      value: {
        type: 1,
        required: true,
      },
      defaultLocation: {
        type: {},
        default: () => ({
          lat: 8.9806,
          lng: 38.7578,
        }),
      },
      loading: false,
      geoSearchOptions: {
        provider: new OpenStreetMapProvider(),
        showMarker: false,
        autoClose: true,
      },
      userLocation: {},
      icon: icon({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      }),
      position: {},
      address: "",
      tileProvider: {
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      },
      zoom: 18,
      dragging: false,
      rules: {
        required: (value) => !!value || "Wajib di isi.",
      },
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        toolbarGroups: [
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "clipboard", groups: ["clipboard", "undo"] },
          {
            name: "editing",
            groups: ["find", "selection", "spellchecker", "editing"],
          },
          { name: "forms", groups: ["forms"] },
          "/",
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
          },
          { name: "links", groups: ["links"] },
          { name: "insert", groups: ["insert"] },
          "/",
          { name: "styles", groups: ["styles"] },
          { name: "colors", groups: ["colors"] },
          { name: "tools", groups: ["tools"] },
          { name: "others", groups: ["others"] },
          { name: "about", groups: ["about"] },
        ],
      },
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      form: {
        namawisata: null,
        kota: null,
        desk: null,
        lat: null,
        lng: null,
        photo_poster: null,
        id_provinsi: null,
        id_kabkot: null,
        id_kecamatan: null,
        id_kelurahan: null,
      },
      token_jwt: "",
      id_pariwisata: null,
      menu: false,
      msg: "",
      url: "",
      photo_lainnya: null,
      judul: "",
      id_author: "",
      snackbar: false,
      snackbar_error: false,
    };
  },
  mounted() {
    this.getUserPosition();
    this.$refs.map.mapObject.on("geosearch/showlocation", this.onSearch);
  },
  watch: {
    position: {
      deep: true,
      async handler(value) {
        this.address = await this.getAddress();
        this.$emit("input", { position: value, address: this.address });
      },
    },
  },
  computed: {
    tooltipContent() {
      if (this.dragging) return "...";
      if (this.loading) return "Loading...";
      return `<strong>${this.address.replace(
        ",",
        "<br/>"
      )}</strong> <hr/><strong>lat:</strong> ${
        this.position.lat
      }<br/> <strong>lng:</strong> ${this.position.lng}`;
    },
  },
  created() {
    this.getListProvinsi();
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
  },
  methods: {
    async getAddress() {
      this.loading = true;
      let address = "Unresolved address";
      try {
        const { lat, lng } = this.position;
        const result = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
        );
        if (result.status === 200) {
          const body = await result.json();
          address = body.display_name;
        }
      } catch (e) {
        console.error("Reverse Geocode Error->", e);
      }
      this.loading = false;
      console.log(address);
      return address;
    },
    async onMapClick(value) {
      // place the marker on the clicked spot
      console.log(value.latlng);
      this.position = value.latlng;
    },
    onSearch(value) {
      const loc = value.location;
      this.position = { lat: loc.y, lng: loc.x };
      console.log(this.position);
    },
    async getUserPosition() {
      if (navigator.geolocation) {
        // get GPS position
        navigator.geolocation.getCurrentPosition((pos) => {
          // set the user location
          this.userLocation = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          };
          console.log(this.userLocation);
          this.position = this.userLocation;
        });
      }
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.form.id_provinsi = value;
            this.form.id_kabkot = "";
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.form.id_kabkot = value;
            this.kabupatens.findIndex((x) => x.id === value);
            this.form.kota = this.kabupatens
              .filter((x) => x.id === value)
              .map((x) => x.kabupaten_kota)[0];
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.form.id_kecamatan = value;
            this.form.id_kelurahan = "";
            this.kelurahans = "";

            this.kelurahans = response;
            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.form.id_kelurahan = value;
      }
    },
    deletePic() {
      this.url = "";
      this.$refs.file.value = "";
    },
    onFileChange(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.photo = e.target.files[0];
      console.log(this.photo);
    },
    back() {
      this.$router.back();
    },
    async sendData() {
      this.loading = true;
      console.log(this.form);
      let formData = new FormData();
      formData.append("namawisata", this.form.namawisata);
      formData.append("kota", this.form.kota);
      formData.append("desk", this.form.desk);
      formData.append("lat", this.position.lat);
      formData.append("lng", this.position.lng);
      formData.append("photo", this.form.photo_poster);
      formData.append("provinsi", this.form.id_provinsi);
      formData.append("kabupaten", this.form.id_kabkot);
      formData.append("kecamatan", this.form.id_kecamatan);
      formData.append("kelurahan", this.form.id_kelurahan);
      console.log(formData.values());
      const data = {
        api: "api/wisata",
        body: formData,
      };

      console.log("form :", this.form, "========", "photo", this.photo_lainnya);
      await postData(data)
        .then((response) => {
          console.log(response);
          this.id_pariwisata = response.data.id;
        })
        .catch((error) => {
          console.log("finish get 1");
          console.log(error);
          console.log(error.response);
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.nik) {
              this.msg_error = error.response.data.nik[0];
              this.snackbar_error = true;
            } else if (error.response.data.email) {
              this.msg_error = error.response.data.email[0];
              this.snackbar_error = true;
            } else {
              this.msg_error = error.response.data;
              this.snackbar_error = true;
            }
          }
        });

      let formDataPhotoLainnya = new FormData();
      this.photo_lainnya.forEach((item, index) => {
        console.log(item, index);
        formDataPhotoLainnya.append(
          index + "[idparawisata]",
          this.id_pariwisata
        );
        formDataPhotoLainnya.append(index + "[photowisata]", item);
      });
      // this.photo_lainnya.forEach((item,index) => {
      //   console.log(item,index)
      //   // formDataPhotoLainnya.append("namawisata", this.form.namawisata);
      // });
      const dataPhotoLainnya = {
        api: "api/photo/wisata",
        body: formDataPhotoLainnya,
      };

      console.log("start get 2");

      await postData(dataPhotoLainnya)
        .then((response) => {
          console.log(response);
          this.dialog = false;
          this.loading = false;
          this.snackbar = true;
          this.$router.push("/");
        })
        .catch((error) => {
          console.log("finish get 2");

          console.log(error);
          console.log(error.response);
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.nik) {
              this.msg_error = error.response.data.nik[0];
              this.snackbar_error = true;
            } else if (error.response.data.email) {
              this.msg_error = error.response.data.email[0];
              this.snackbar_error = true;
            } else {
              this.msg_error = error.response.data;
              this.snackbar_error = true;
            }
          }
        });
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>