<template>
  <v-col xl="3" lg="3" sm="3" cols="4" xs="6" class="text-center">
    <v-btn
      color="white"
      icon
      :to="link"
      style="background-color: #c82424"
      x-large
    >
      <v-icon v-if="img == 'false'">{{ icon }}</v-icon>
      <img
        style="height: 30px; filter: sepia(100%); -webkit-filter: sepia(100%)"
        v-if="img != 'false'"
        :src="require('../assets/' + img + '')"
      />
    </v-btn>
    <p
      class="text-center"
      style="color: #c82424; font-size: 12px; margin-top: 10px"
    >
      {{ text }}
    </p>
  </v-col>
</template>

<script>
export default {
  name: "Tombol",
  props: {
    text: String,
    link: String,
    icon: String,
    img: String,
  },
};
</script>