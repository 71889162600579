<template>
  <v-card height="100%">
    <v-expansion-panels v-model="panel">
      <v-expansion-panel v-model="panel">
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          Data Penduduk
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" class="flex justify-end text-right mb-2">
              <TambahDataPenduduk text="Tambah Data" v-show="userData.role != 'warga'" />
            </v-col>
          </v-row>
          <v-card-title>
            <v-expansion-panels
              v-show="userData.role == 'superadmin' || userData.role == 'admin'"
            >
              <v-expansion-panel>
                <v-expansion-panel-header> Filter </v-expansion-panel-header>
                <v-expansion-panel-content class="text-center">
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    label="Provinsi"
                    v-model="id_provinsi"
                    :items="provinsis"
                    item-text="provinsi"
                    return-object
                    required
                    @change="(event) => selectedProvinsi(event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kabkot"
                    :items="kabupatens"
                    label="Kabupaten/ Kota"
                    return-object
                    item-text="kabupaten_kota"
                    :disabled="kabupatens == ''"
                    required
                    @change="(event) => selectedKabupaten(event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kecamatan"
                    :items="kecamatans"
                    label="Kecamatan"
                    item-text="kecamatan"
                    return-object
                    :disabled="kecamatans == ''"
                    required
                    @change="(event) => selectedKecamatan(event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    v-model="id_kelurahan"
                    :items="kelurahans"
                    label="Kelurahan"
                    return-object
                    :disabled="kelurahans == ''"
                    item-text="kelurahan"
                    required
                    @change="(event) => selectedKelurahan(event)"
                  ></v-autocomplete>
                  <v-btn
                    color="red"
                    class="button-signup"
                    block
                    v-on:click="getDataPendudukFilter()"
                    >Submit</v-btn
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-text-field
              dark
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nik, Nama, KK, dsb.."
              single-line
              filled
              outlined
              hide-details
              @input="SearchbarChange"
              style="width: 100%"
            >
            </v-text-field>
            <p
              v-if="id_provinsi != ''"
              style="color: white; margin: 0; font-size: 16px"
            >
              Filter :
            </p>
            <v-chip
              v-if="id_provinsi != ''"
              class="ma-2"
              close
              @click:close="
                id_provinsi = '';
                id_kabkot = '';
                id_kelurahan = '';
                id_kecamatan = '';
              "
            >
              {{ id_provinsi }}
            </v-chip>
            <v-chip
              v-if="id_kabkot != ''"
              class="ma-2"
              close
              @click:close="
                id_kabkot = '';
                id_kelurahan = '';
                id_kecamatan = '';
              "
            >
              {{ id_kabkot }}
            </v-chip>
            <v-chip
              v-if="id_kelurahan != ''"
              class="ma-2"
              close
              @click:close="
                id_kelurahan = '';
                id_kecamatan = '';
              "
            >
              {{ id_kelurahan }}
            </v-chip>
            <v-chip
              v-if="id_kecamatan != ''"
              class="ma-2"
              close
              @click:close="id_kecamatan = ''"
            >
              {{ id_kecamatan }}
            </v-chip>
          </v-card-title>
          <v-data-table
            class="table-penduduk"
            disable-pagination
            :hide-default-footer="true"
            :headers="headers"
            :items="penduduks"
            :total-items="totalNumberOfItems"
            @update:pagination="paginate"
          >
            <template
              v-slot:item.nama="{ item }"
              style="text-transform: capitalize"
            >
              <td class="truncate">{{ item.nama }}</td>
            </template>

            <template v-slot:item.aksi="{ item }">
              <DetailDataPenduduk :dataItem="item" status="aktif" />
            </template>
            <!-- <template v-slot:item.tempatlahir="{ item }">
        <td class="truncate">{{ item.tempatlahir }}</td>
      </template> -->
            <!-- <template v-slot:item.tgl_lahir="{ item }">
        {{ format_date(item.tgl_lahir) }}
      </template> -->
          </v-data-table>

          <v-pagination
            :loading="loading"
            v-model="page"
            :length="totalPages"
            total-visible="7"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          >
          </v-pagination>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import { getData } from "../service/ApiService";
import DetailDataPenduduk from "./DetailDataPenduduk.vue";
import TambahDataPenduduk from "./TambahDataPenduduk.vue";
export default {
  name: "DataTable",
  components: {
    DetailDataPenduduk,
    TambahDataPenduduk,
  },
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      panel: 0,
      userData: "",
      hasSaved: false,
      isEditing: false,
      dialog: false,
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      id_provinsi: "",
      id_kabkot: "",
      id_kelurahan: "",
      id_kecamatan: "",
      search: "",
      page: 1,
      totalPages: 0,
      apiGetFilter: "",
      pageSize: 3,
      pageSizes: [3, 6, 9],
      loading: true,
      headers: [
        {
          text: "NIK",
          align: "start",
          value: "nik",
        },
        {
          text: "No KK",
          value: "id_kk",
        },
        {
          text: "Nama",
          value: "nama",
        },
        {
          text: "Aksi",
          value: "aksi",
          sortable: false,
        },

        //   { text: 'Aksi', value: 'aksi',class: 'my-header-style' },
      ],
      penduduks: [],
      activePicker: null,
      menu: false,
    };
  },
  watch: {
    isEditing() {
      if (this.isEditing) {
        this.hasSaved = true;
      }
      console.log("Foo Changed!");
    },
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.form.tgl_lahir
        ? moment(this.form.tgl_lahir).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
    this.getDataPenduduk();
    this.getListProvinsi();
  },
  created() {},
  methods: {
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getDataPenduduk() {
      const apiMasterPenduduk =
        "api/profile" + "?page=" + this.page + "&keyword=" + this.search;

      getData(apiMasterPenduduk)
        .then((response) => {
          console.log("data :", response);
          this.penduduks = response.data.data;
          this.totalPages = response.data.last_page;
          console.log(this.penduduks);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getDataPendudukFilter() {
      if (this.search != "") {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/profile" +
            "?page=" +
            1 +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&kel=" +
            this.id_kelurahan +
            "&keyword=" +
            this.search;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/profile" +
            "?page=" +
            1 +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&keyword=" +
            this.search;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/profile" +
            "?page=" +
            1 +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&keyword=" +
            this.search;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/profile" +
            "?page=" +
            1 +
            "&prov=" +
            this.id_provinsi +
            "&keyword=" +
            this.search;
        } else {
          this.apiGetFilter =
            "api/profile" + "?page=" + 1 + "&keyword=" + this.search;
        }
      } else {
        if (this.id_kelurahan != "") {
          this.apiGetFilter =
            "api/profile" +
            "?page=" +
            1 +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan +
            "&kel=" +
            this.id_kelurahan;
        } else if (this.id_kecamatan != "") {
          this.apiGetFilter =
            "api/profile" +
            "?page=" +
            1 +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot +
            "&kec=" +
            this.id_kecamatan;
        } else if (this.id_kabkot != "") {
          this.apiGetFilter =
            "api/profile" +
            "?page=" +
            1 +
            "&prov=" +
            this.id_provinsi +
            "&kab=" +
            this.id_kabkot;
        } else if (this.id_provinsi != "") {
          this.apiGetFilter =
            "api/profile" + "?page=" + 1 + "&prov=" + this.id_provinsi;
        } else {
          this.apiGetFilter = "api/profile" + "?page=" + 1;
        }
      }

      // this.penduduks = null;
      getData(this.apiGetFilter)
        .then((response) => {
          console.log("data :", response);
          this.penduduks = response.data.data;
          this.totalPages = response.data.last_page;
          console.log(this.penduduks);
          this.loading = false;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value.id;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.id_provinsi = value.provinsi;
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kabkot = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value.id;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.id_kabkot = value.kabupaten_kota;
            this.kelurahans = "";
            this.kecamatans = "";
            this.id_kelurahan = "";
            this.id_kecamatan = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value.id;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.id_kecamatan = value.kecamatan;
            this.kelurahans = "";
            this.kelurahans = response;
            this.id_kelurahan = "";

            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.id_kelurahan = value.kelurahan;
      }
    },
    SearchbarChange(value) {
      this.search = value;
      this.penduduks = [];
      this.getDataPendudukFilter();
      console.log(value);
    },
    handlePageChange(value) {
      this.page = value;
      this.penduduks = [];
      this.getDataPenduduk();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.truncate {
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.hidden-sm-and-down .v-icon {
  color: red !important;
}

.v-card__title {
  background: rgb(252, 55, 55);
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>