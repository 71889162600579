<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      Berhasil mendaftarkan
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>
    <v-container class="text-end">
      <v-dialog v-model="dialog" max-width="600px">
        <template
          v-slot:activator="{ on, attrs }"
          class="flex justify-end text-right"
        >
          <v-btn color="red" class="button-signup" v-bind="attrs" v-on="on"
            >Daftar Pernikahan</v-btn
          >
        </template>
        <v-card>
          <v-app-bar
            color="#F2F1F1"
            height="80px"
            elevate-on-scroll
            scroll-target="#scrolling-techniques-7"
          >
            <v-btn
              color="black"
              icon
              v-bind="attrs"
              v-on="on"
              @click="dialog = false"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-app-bar-tittle> Daftar Identitas </v-app-bar-tittle>
          </v-app-bar>
          <v-card-text>
            <v-container>
              <form
                id="myForm"
                name="myForm"
                v-on:submit.prevent="submitForm"
                enctype="multipart/form-data"
              >
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <v-text-field
                          v-model="form.nik_suami"
                          :loading="isLoading"
                          item-text="Description"
                          item-value="API"
                          label="NIK Suami"
                          placeholder="Cari NIK .."
                          counter="16"
                          :rules="[rules.required, rules.min_16]"
                          prepend-icon="mdi-account-search"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-btn
                          color="red"
                          class="button-signup"
                          @click="searchNIK()"
                          >Cari</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-text-field
                      filled
                      readonly
                      v-model="form.nama_suami"
                      label="Nama Suami"
                      prepend-icon="mdi-account"
                    ></v-text-field>
                    <v-file-input
                      prepend-icon="mdi-file-upload"
                      label="File Buku Nikah Suami PDF "
                      required
                      accept=".pdf"
                      :rules="[rules.required]"
                      v-model="form.file_buku_nikah_suami"
                      hint="file berupa pdf"
                      chips
                      small-chips
                      truncate-length="15"
                    >
                    </v-file-input>
                  </v-col>

                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <v-text-field
                          v-model="form.nik_istri"
                          :loading="isLoading2"
                          item-text="Description"
                          item-value="API"
                          label="NIK Istri"
                          placeholder="Cari NIK .."
                          counter="16"
                          :rules="[rules.required, rules.min_16]"
                          prepend-icon="mdi-account-search"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-btn
                          color="red"
                          class="button-signup"
                          @click="searchNIKistri()"
                          >Cari</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-text-field
                      filled
                      readonly
                      v-model="form.nama_istri"
                      label="Nama Istri"
                      prepend-icon="mdi-account"
                    ></v-text-field>
                    <v-file-input
                      prepend-icon="mdi-file-upload"
                      label="File Buku Nikah Istri   PDF "
                      required
                      accept=".pdf"
                      :rules="[rules.required]"
                      v-model="form.file_buku_nikah_istri"
                      hint="file berupa pdf"
                      chips
                      small-chips
                      truncate-length="15"
                    >
                    </v-file-input>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      :rules="[rules.required]"
                      prepend-icon="mdi-map-marker"
                      label="Tempat Menikah"
                      v-model="form.tempat_kawin"
                      hint="ex:Balikpapan"
                      required
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      :rules="[rules.required]"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Tanggal Lahir"
                          prepend-icon="mdi-calendar"
                          readonly
                          :rules="[rules.required]"
                          :value="computedDateFormattedMomentjs"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :rules="[rules.required]"
                        v-model="form.tanggal_kawin"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-book-account"
                      label="No. Akta Nikah"
                      required
                      v-model="form.akta_kawin"
                      hint="Hanya Angka"
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                    <v-text-field
                      prepend-icon="mdi-book-account"
                      label="No. Seri Nikah"
                      required
                      v-model="form.seri_kawin"
                      hint="Hanya Angka"
                      :rules="[rules.required]"
                    >
                    </v-text-field>

                    <v-file-input
                      prepend-icon="mdi-file-upload"
                      label="File Akta Nikah PDF "
                      required
                      accept=".pdf"
                      :rules="[rules.required]"
                      v-model="form.file_akta_kawin"
                      hint="file berupa pdf"
                      chips
                      small-chips
                      truncate-length="15"
                    >
                    </v-file-input>
                  </v-col>

                  <!-- batas akhir -->

                  <v-col cols="12">
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="id_provinsi"
                      :items="provinsis"
                      label="Provinsi"
                      :rules="[rules.required]"
                      return-object
                      item-text="provinsi"
                      required
                      @change="(event) => selectedProvinsi(event)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="id_kabupaten"
                      :rules="[rules.required]"
                      :items="kabupatens"
                      label="Kabupaten/ Kota"
                      return-object
                      item-text="kabupaten_kota"
                      :disabled="kabupatens == ''"
                      required
                      @change="(event) => selectedKabupaten(event)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="id_kecamatan"
                      :items="kecamatans"
                      label="Kecamatan"
                      :rules="[rules.required]"
                      item-text="kecamatan"
                      return-object
                      :disabled="kecamatans == ''"
                      required
                      @change="(event) => selectedKecamatan(event)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      prepend-icon="mdi-map-marker"
                      v-model="id_kelurahan"
                      :items="kelurahans"
                      :rules="[rules.required]"
                      return-object
                      label="Kelurahan"
                      :disabled="kelurahans == ''"
                      item-text="kelurahan"
                      required
                      @change="(event) => selectedKelurahan(event)"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      prepend-icon="mdi-map-marker"
                      label="RT"
                      v-model="form.rt"
                      hint="RT Sesuai Tempat Tinggal Saat Ini"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      prepend-icon="mdi-map-marker"
                      :rules="[rules.required]"
                      label="RW"
                      v-model="form.rw"
                      hint="RW Sesuai Tempat Tinggal Saat Ini"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-btn
                    style="color: white"
                    color="red"
                    type="submit"
                    value="Submit"
                    :loading="loading"
                    block
                  >
                    Simpan
                  </v-btn>
                </v-row>
              </form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { getData, postData } from "../service/ApiService";

export default {
  name: "TambahPernikahan",
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      show1: false,
      rules: {
        required: (value) => !!value || "Wajib di isi.",
        min: (v) => v.length >= 8 || "Password minimal 8 digit",
        min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
        return "Wajib Berupa Angka.";
      },
      snackbar: false,
      isLoading: false,
      isLoading2: false,
      snackbar_error: false,
      agamas: [
        "islam",
        "kristen",
        "katholik",
        "budha",
        "hindu",
        "konghucu",
        "lainnya",
      ],
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      id_provinsis: "",
      id_kabupatens: "",
      id_kelurahans: "",
      id_kecamatans: "",
      form: {
        nama_suami: "",
        tinggi: "",
        nama: "",
        nik_anak: "",
        kk: "",
        email: "",
        password: "",
        tanggal_kawin: "",
        tempatlahir: "",
        jk: "",
        suku: "",
        agama: "",
        goldarah: "",
        alamat: "",
        alamat_domisili: "",
        provinsi: "",
        kabupaten: "",
        kelurahan: "",
        kecamatan: "",
        rt: "",
        rw: "",
        photo: "",
      },
      url: "",
      token_jwt: "",
      dialog: false,
      activePicker: null,
      menu: false,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.form.tanggal_kawin
        ? moment(this.form.tanggal_kawin).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    if (this.snackbar) {
      this.hide_alert();
    }

    if (this.snackbar_error) {
      this.hide_alert_error();
    }

    this.getListProvinsi();
  },
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
  },
  methods: {
    searchNIK() {
      console.log(this.form.nik_suami);
      this.isLoading = true;
      const apiListData = "api/pencarian/" + this.form.nik_suami;

      getData(apiListData)
        .then((response) => {
          console.log("items :", response);
          this.form.nama_suami = response.data[0].nama;
          // this.form.nik_suami = this.form.nik_suami;
          console.log(this.form.nama_suami);

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.form.nama_suami = "NIK Tidak Ditemukan.";
          console.log("pesan error :", error);
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    searchNIKistri() {
      console.log(this.form.nik_istri);
      this.isLoading2 = true;
      const apiListDataIstri = "api/pencarian/" + this.form.nik_istri;

      getData(apiListDataIstri)
        .then((response) => {
          console.log("items :", response);
          this.form.nama_istri = response.data[0].nama;
          // this.form.nik_istri = this.form.nik_istri;
          console.log(this.form.nama_istri);

          this.isLoading2 = false;
        })
        .catch((error) => {
          this.isLoading2 = false;
          this.form.nama_istri = "NIK Tidak Ditemukan.";
          console.log("pesan error :", error);
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
          this.msg = true;
        });
    },
    hide_alert: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    hide_alert_error: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar_error = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value.id;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.form.provinsi = value.provinsi;
            this.form.kabupaten = "";
            this.form.kelurahan = "";
            this.form.kecamatan = "";
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value.id;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.form.kabupaten = value.kabupaten_kota;
            this.form.kelurahan = "";
            this.form.kecamatan = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value.id;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.form.kecamatan = value.kecamatan;
            this.form.kelurahan = "";
            this.kelurahans = "";

            this.kelurahans = response;
            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
            this.msg = true;
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.form.kelurahan = value.kelurahan;
      }
    },
    submitForm() {
      this.loading = true;
      console.log(this.form);
      let formData = new FormData();
      formData.append("nama_suami", this.form.nama_suami);
      formData.append("nik_suami", this.form.nik_suami);
      formData.append("file_buku_nikah_suami", this.form.file_buku_nikah_suami);
      formData.append("nama_istri", this.form.nama_istri);
      formData.append("nik_istri", this.form.nik_istri);
      formData.append("file_buku_nikah_istri", this.form.file_buku_nikah_istri);
      formData.append("akta_kawin", this.form.akta_kawin);
      formData.append("seri_kawin", this.form.seri_kawin);
      formData.append("tanggal_kawin", this.form.tanggal_kawin);
      formData.append("tempat_kawin", this.form.tempat_kawin);
      formData.append("file_akta_kawin", this.form.file_akta_kawin);
      formData.append("provinsi", this.form.provinsi);
      formData.append("kabupaten", this.form.kabupaten);
      formData.append("kecamatan", this.form.kecamatan);
      formData.append("kelurahan", this.form.kelurahan);
      formData.append("rt", this.form.rt);
      formData.append("rw", this.form.rw);
      // if (this.userData.role != "superadmin") {
      //   this.form.id_role = 4;
      // }
      // formData.append("id_role", this.form.id_role);
      console.log(formData.values());
      const data = {
        api: "api/pernikahan",
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log(response);
          this.dialog = false;
          this.loading = false;
          this.snackbar = true;
          this.$router.reload();
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.nik_anak) {
              this.msg_error = error.response.data.nik_anak[0];
              this.snackbar_error = true;
            } else if (error.response.data.email) {
              this.msg_error = error.response.data.email[0];
              this.snackbar_errror = true;
            } else {
              this.msg_error = error.response.data;
              this.snackbar_errror = true;
            }
          }
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    deletePic() {
      this.url = "";
      this.$refs.file.value = "";
    },
    onFileChange(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.form.photo = e.target.files[0];
      console.log(this.form);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>