<template>
  <v-app app>
    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
* {
  font-family: "Alata", sans-serif;
}
</style>
