<template>
  <div>
    <v-snackbar color="green" top style="color: white" v-model="snackbar">
      {{ this.msg }}
    </v-snackbar>
    <v-snackbar color="red" top style="color: white" v-model="snackbar_error">
      {{ this.msg_error }}
    </v-snackbar>

    <v-card>
      <v-app-bar
        app
        color="#F2F1F1"
        height="80px"
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-btn color="black" icon v-bind="attrs" v-on="on" @click="back()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-container>
          <form
            id="myForm"
            name="myForm"
            v-on:submit.prevent="submitForm"
            enctype="multipart/form-data"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-book-open-outline"
                  label="Nama Pemakaman"
                  required
                  :rules="[rules.required]"
                  name="nama_pemakaman"
                  v-model="form.nama_pemakaman"
                  hint="Nama Pemakaman"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  label="Alamat Lengkap"
                  required
                  :rules="[rules.required]"
                  name="alamat_lengkap"
                  v-model="form.alamat_lengkap"
                  hint="Alamat Lengkap"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  prepend-icon="mdi-circle-slice-5"
                  label="Status"
                  required
                  :rules="[rules.required]"
                  name="alamat_lengkap"
                  v-model="form.status"
                  hint="Status"
                  :items="statuses"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-phone"
                  label="No HP"
                  required
                  :rules="[rules.required]"
                  name="no_hp"
                  v-model="form.no_hp"
                  hint="No HP"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  name="provinsi"
                  prepend-icon="mdi-map-marker"
                  v-model="form.id_provinsi"
                  :items="provinsis"
                  label="Provinsi"
                  :rules="[rules.required]"
                  item-value="id"
                  item-text="provinsi"
                  required
                  @change="(event) => selectedProvinsi(event)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  name="kabkot"
                  prepend-icon="mdi-map-marker"
                  v-model="form.id_kabkot"
                  :rules="[rules.required]"
                  :items="kabupatens"
                  label="Kabupaten/ Kota"
                  item-value="id"
                  item-text="kabupaten_kota"
                  :disabled="kabupatens == ''"
                  required
                  @change="(event) => selectedKabupaten(event)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  name="kecamatan"
                  prepend-icon="mdi-map-marker"
                  v-model="form.id_kecamatan"
                  :items="kecamatans"
                  label="Kecamatan"
                  :rules="[rules.required]"
                  item-text="kecamatan"
                  item-value="id"
                  :disabled="kecamatans == ''"
                  required
                  @change="(event) => selectedKecamatan(event)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  name="kelurahan"
                  prepend-icon="mdi-map-marker"
                  v-model="form.id_kelurahan"
                  :items="kelurahans"
                  :rules="[rules.required]"
                  item-value="id"
                  label="Kelurahan"
                  :disabled="kelurahans == ''"
                  item-text="kelurahan"
                  required
                  @change="(event) => selectedKelurahan(event)"
                ></v-autocomplete>
              </v-col>

              <!-- <v-col cols="12">
                <v-text-field
                  name="nik"
                  prepend-icon="mdi-book-account"
                  label="NIK"
                  required
                  :rules="[rules.required, rules.min_16]"
                  counter="16"
                  v-model="form.nik"
                  hint="Nomor Induk Keluarga (16 Digit)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="no_kk"
                  prepend-icon="mdi-book-open-outline"
                  label="No KK"
                  required
                  counter="16"
                  v-model="form.id_kk"
                  hint="Nomor Kartu Keluarga (16 Digit)"
                  :rules="[rules.required, rules.min_16]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="nama_lengkap"
                  prepend-icon="mdi-account"
                  label="Nama Lengkap"
                  required
                  :rules="[rules.required, rules.min_16]"
                  v-model="form.nama"
                  hint="Nama Lengkap Sesuai KTP"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="email"
                  prepend-icon="mdi-email"
                  label="Email"
                  :rules="[rules.required]"
                  required
                  v-model="form.email"
                  hint="Email Aktif"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="userData.role == 'superadmin'">
                <v-autocomplete
                  name="id_role"
                  prepend-icon="mdi-account"
                  v-model="form.id_role"
                  :items="roles"
                  :rules="[rules.required]"
                  item-value="id"
                  item-text="nama"
                  label="Role"
                  hint="Role"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="status_kk"
                  prepend-icon="mdi-account-group"
                  label="Status Di KK"
                  v-model="form.status"
                  hint="Status Sesuai Kartu Keluarga"
                  required
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  name="status_pernikahan"
                  prepend-icon="mdi-heart"
                  v-model="form.status_pernikahan"
                  :items="status_pernikahans"
                  :rules="[rules.required]"
                  label="Status Pernikahan"
                  hint="Status Pernikahan Sesuai Di KTP"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="no_telp"
                  prepend-icon="mdi-phone"
                  type="tel"
                  v-model="form.nohp"
                  label="Nomor Telepon"
                  hint="Nomor Telepon Aktif"
                  :rules="[numberRule, rules.required]"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  :rules="[rules.required]"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      name="tgl_lahir"
                      label="Tanggal Lahir"
                      prepend-icon="mdi-calendar"
                      readonly
                      :rules="[rules.required]"
                      :value="computedDateFormattedMomentjs"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :rules="[rules.required]"
                    v-model="form.tgl_lahir"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    min="1950-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="tmpt_lhr"
                  :rules="[rules.required]"
                  prepend-icon="mdi-map-marker"
                  label="Tempat Lahir"
                  v-model="form.tempatlahir"
                  hint="ex:Balikpapan"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-radio-group
                  name="jk"
                  required
                  :rules="[rules.required]"
                  label="Jenis Kelamin"
                  v-model="form.jk"
                  prepend-icon="mdi-gender-male-female"
                >
                  <v-radio label="Pria" value="pria"></v-radio>
                  <v-radio label="Wanita" value="wanita"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="suku"
                  prepend-icon="mdi-account-group"
                  label="Suku"
                  :rules="[rules.required]"
                  v-model="form.suku"
                  hint="Suku"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  name="agama"
                  prepend-icon="mdi-account-group"
                  v-model="form.agama"
                  :items="agamas"
                  :rules="[rules.required]"
                  label="Agama"
                  hint="Agama Sesuai KTP"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  name="goldar"
                  prepend-icon="mdi-water"
                  v-model="form.goldarah"
                  :rules="[rules.required]"
                  :items="goldars"
                  label="Golongan Darah"
                  hint="Golongan Darah"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="alamat_ktp"
                  prepend-icon="mdi-map-marker"
                  label="Alamat KTP"
                  v-model="form.alamat"
                  :rules="[rules.required]"
                  hint="Alamat Sesuai KTP"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="alamat_domisili"
                  prepend-icon="mdi-map-marker"
                  label="Alamat Domisili"
                  :rules="[rules.required]"
                  v-model="form.alamat_domisili"
                  hint="Alamat Tempat Tinggal Saat Ini"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="rt"
                  prepend-icon="mdi-map-marker"
                  label="RT"
                  v-model="form.id_rt"
                  hint="RT Sesuai Tempat Tinggal Saat Ini"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="rw"
                  prepend-icon="mdi-map-marker"
                  :rules="[rules.required]"
                  label="RW"
                  v-model="form.id_rw"
                  hint="RW Sesuai Tempat Tinggal Saat Ini"
                  required
                ></v-text-field>
              </v-col> -->
              <v-col cols="12">
                <div
                  style="width: 100%; height: 500px"
                  v-if="position.lat || userLocation.lat || defaultLocation.lat"
                >
                  <l-map
                    ref="map"
                    @dblclick="onMapClick()"
                    :zoom="zoom"
                    :center="[
                      position.lat || userLocation.lat || defaultLocation.lat,
                      position.lng || userLocation.lng || defaultLocation.lng,
                    ]"
                  >
                    <l-tile-layer
                      :url="tileProvider.url"
                      :attribution="tileProvider.attribution"
                    />
                    <l-geosearch :options="geoSearchOptions"></l-geosearch>
                    <l-marker
                      v-if="position.lat && position.lng"
                      visible
                      draggable
                      :icon="icon"
                      :lat-lng.sync="position"
                      @dragstart="dragging = true"
                      @dragend="dragging = false"
                    >
                      <l-tooltip
                        :content="tooltipContent"
                        :options="{ permanent: true }"
                      />
                    </l-marker>
                  </l-map>
                </div>
              </v-col>
              <v-btn
                style="color: white"
                color="green"
                type="submit"
                value="Submit"
                block
              >
                Simpan
              </v-btn>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// import FitureCardVue from '../components/FitureCard.vue';
// import NewsCardVue from '../components/NewsCard.vue';
// import Tombol from '../components/Tombol.vue';
// @ is an alias to /src
// import axios from "axios";
import moment from "moment";
import { getData, postData } from "../service/ApiService";

import { LMap, LMarker, LTileLayer, LTooltip } from "vue2-leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import LGeosearch from "vue2-leaflet-geosearch";
import { icon } from "leaflet";

export default {
  name: "EditDataPemakaman",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LGeosearch,
  },
  props: ["items"],
  beforeCreate: function () {
  if (localStorage.token_jwt) {
        this.token_jwt = localStorage.token_jwt;
        if (this.token_jwt == null) {
          this.$router.push("/");
      }
    }
  },
  data() {
    return {
      value: {
        type: 1,
        required: true,
      },
      defaultLocation: {
        type: {},
        default: () => ({
          lat: 8.9806,
          lng: 38.7578,
        }),
      },
      loading: false,
      geoSearchOptions: {
        provider: new OpenStreetMapProvider(),
        showMarker: false,
        autoClose: true,
      },
      userLocation: {},
      icon: icon({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      }),
      position: {},
      address: "",
      tileProvider: {
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      },
      zoom: 18,
      dragging: false,
      show1: false,
      rules: {
        required: (value) => !!value || "Wajib di isi.",
        min: (v) => v.length >= 8 || "Password minimal 8 digit",
        min_16: (v) => v.length <= 16 || "Maksimal 16 digit",
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999999) return true;
        return "Wajib Berupa Angka.";
      },
      snackbar: false,
      snackbar_error: false,
      statuses: ["Penuh", "Tersedia"],
      provinsis: "",
      kabupatens: "",
      kelurahans: "",
      kecamatans: "",
      form: {
        nama_pemakaman: "",
        alamat_lengkap: "",
        no_hp: "",
        id_provinsi: "",
        id_kabkot: "",
        id_kelurahan: "",
        status: "",
        id_kecamatan: "",
      },
      url: "",
      token_jwt: "",
      dialog: false,
      activePicker: null,
      menu: false,
      msg: "",
    };
  },
  watch: {
    position: {
      deep: true,
      async handler(value) {
        this.address = await this.getAddress();
        this.$emit("input", { position: value, address: this.address });
      },
    },
  },
  computed: {
    tooltipContent() {
      if (this.dragging) return "...";
      if (this.loading) return "Loading...";
      return `<strong>${this.address.replace(
        ",",
        "<br/>"
      )}</strong> <hr/><strong>lat:</strong> ${
        this.position.lat
      }<br/> <strong>lng:</strong> ${this.position.lng}`;
    },
    computedDateFormattedMomentjs() {
      return this.form.tgl_lahir
        ? moment(this.form.tgl_lahir).format("DD, MMMM YYYY")
        : "";
    },
  },
  mounted() {
    this.form = this.items;
    console.log(this.form);

    this.position = { lat: this.form.lat, lng: this.form.long };
    this.$refs.map.mapObject.on("geosearch/showlocation", this.onSearch);
    let file = this.form.photo;
    this.url = file;
    console.log(this.form);

    if (localStorage.token_jwt) {
      this.token_jwt = localStorage.token_jwt;
      console.log("token jwt :", localStorage.token_jwt);
    }

    if (this.snackbar) {
      this.hide_alert();
    }

    if (this.snackbar_error) {
      this.hide_alert_error();
    }

    this.getListProvinsi();
    this.getListKabkot();
    this.getListKecamatan();
    this.getListKelurahan();
  },
  created() {
    if (localStorage.user_data) {
      this.userData = JSON.parse(localStorage.user_data).data[0];
      console.log("data user: ", this.userData);
    }
  },
  methods: {
    async getAddress() {
      this.loading = true;
      let address = "Unresolved address";
      try {
        const { lat, lng } = this.position;
        const result = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
        );
        if (result.status === 200) {
          const body = await result.json();
          address = body.display_name;
        }
      } catch (e) {
        console.error("Reverse Geocode Error->", e);
      }
      this.loading = false;
      return address;
    },
    async onMapClick(value) {
      // place the marker on the clicked spot
      this.position = value.latlng;
    },
    onSearch(value) {
      const loc = value.location;
      this.position = { lat: loc.y, lng: loc.x };
      console.log(loc, this.position);
    },
    async getUserPosition() {
      if (navigator.geolocation) {
        // get GPS position
        navigator.geolocation.getCurrentPosition((pos) => {
          // set the user location
          this.userLocation = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          };
          console.log(this.userLocation);
          this.position = this.userLocation;
        });
      }
    },
    back() {
      this.$router.back();
    },
    getListProvinsi() {
      const apiListProvinsi = "api/provinsi";
      getData(apiListProvinsi)
        .then((response) => {
          console.log("data provinsi :", response);
          this.provinsis = response;
          console.log(this.provinsi);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },
    getListKabkot() {
      const apiListKabkot = "api/kabkot?keyword=" + this.form.id_provinsi;

      getData(apiListKabkot)
        .then((response) => {
          console.log("data kabkot :", response);
          this.kabupatens = response;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },
    getListKecamatan() {
      const apiListKecamatan = "api/kec?keyword=" + this.form.id_kabkot;

      getData(apiListKecamatan)
        .then((response) => {
          console.log("data kecamatan :", response);
          this.kecamatans = response;
          console.log(this.kecamatans);
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },
    getListKelurahan() {
      const apiListKelurahan = "api/kel?keyword=" + this.form.id_kecamatan;
      getData(apiListKelurahan)
        .then((response) => {
          console.log("data kelurahan :", response);
          this.kelurahans = response;
        })
        .catch((error) => {
          console.log("pesan error :", error.response.data);
        });
    },
    hide_alert: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    hide_alert_error: function (event) {
      console.log(event);
      // `event` is the native DOM event
      window.setInterval(() => {
        this.snackbar_error = false;
        console.log("hide alert after 3 seconds");
      }, 3000);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    selectedProvinsi(value) {
      console.log(value);
      if (value != null) {
        const apiListKabupaten = "api/kabkot?keyword=" + value;

        getData(apiListKabupaten)
          .then((response) => {
            console.log("data kabupaten :", response);
            this.form.id_provinsi = value;
            this.form.id_kabkot = "";
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kabupatens = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kabupatens = response;
            console.log(this.kabupatens);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
          });
      }
    },
    selectedKabupaten(value) {
      console.log(value);
      if (value != null) {
        const apiListKecamatan = "api/kec?keyword=" + value;

        getData(apiListKecamatan)
          .then((response) => {
            console.log("data kecamatan :", response);
            this.form.id_kabkot = value;
            this.form.id_kelurahan = "";
            this.form.id_kecamatan = "";
            this.kelurahans = "";
            this.kecamatans = "";

            this.kecamatans = response;
            console.log(this.kecamatans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
          });
      }
    },
    selectedKecamatan(value) {
      console.log(value);
      if (value != null) {
        const apiListKelurahan = "api/kel?keyword=" + value;

        getData(apiListKelurahan)
          .then((response) => {
            console.log("data kelurahan :", response);
            this.form.id_kecamatan = value;
            this.form.id_kelurahan = "";
            this.kelurahans = "";

            this.kelurahans = response;
            console.log(this.kelurahans);
          })
          .catch((error) => {
            console.log("pesan error :", error.response.data);
          });
      }
    },
    selectedKelurahan(value) {
      console.log(value);
      if (value != null) {
        this.form.id_kelurahan = value;
      }
    },
    submitPhoto() {
      console.log(this.form);
      let formDataPhoto = new FormData();
      formDataPhoto.append("photo", this.form.photo);
      console.log(formDataPhoto.values());

      const data = {
        api: "api/profile/photo/" + this.form.nik,
        body: formDataPhoto,
      };
      postData(data)
        .then((response) => {
          console.log(response);
          this.snackbar = true;
          this.msg = "Berhasil mengganti photo";
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.nik) {
              this.msg_error = error.response.data.nik[0];
              this.snackbar_error = true;
            } else if (error.response.data.email) {
              this.msg_error = error.response.data.email[0];
              this.snackbar_errror = true;
            } else {
              this.msg_error = error.response.data;
              this.snackbar_errror = true;
            }
          }
        });
    },
    submitForm() {
      console.log(this.form);
      let formData = new FormData();
      formData.append("nama_pemakaman", this.form.nama_pemakaman);
      formData.append("alamat_lengkap", this.form.alamat_lengkap);
      formData.append("no_hp", this.form.no_hp);
      formData.append("status", this.form.status);
      formData.append("provinsi", this.form.id_provinsi);
      formData.append("kabupaten", this.form.id_kabkot);
      formData.append("kecamatan", this.form.id_kecamatan);
      formData.append("kelurahan", this.form.id_kelurahan);
      formData.append("lat", this.position.lat);
      formData.append("long", this.position.lng);
      console.log(formData.values());
      const data = {
        api: "api/pemakaman/" + this.form.id_pemakaman,
        body: formData,
      };
      postData(data)
        .then((response) => {
          console.log(response);
          this.dialog = false;
          this.msg = "Berhasil mengedit akun";
          this.snackbar = true;
          this.$router.push("/master-penduduk");
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.nik) {
              this.msg_error = error.response.data.nik[0];
              this.snackbar_error = true;
            } else if (error.response.data.email) {
              this.msg_error = error.response.data.email[0];
              this.snackbar_errror = true;
            } else {
              this.msg_error = error.response.data;
              this.snackbar_errror = true;
            }
          }
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    deletePic() {
      this.url = "";
      this.$refs.file.value = "";
    },
    onFileChange(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.form.photo = e.target.files[0];
      console.log(this.form);
      this.submitPhoto();
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.delete-text {
  color: red;
  cursor: pointer;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.button-signup {
  border-radius: 10px;
  color: white !important;
  text-transform: none;
  text-decoration: none;
}

.v-card__title {
  background: #f54c4c;
}

.text-title {
  text-decoration: none;
  color: black;
  margin: 0 22px 0 0;
  font-size: 18px;
  font-weight: 500;
}

.img-logo {
  height: 80%;
  width: auto;
  margin-right: 20px;
}
</style>